import { useQuery } from "react-query";
import { Link, useNavigate } from "react-router-dom";

import BackButton from "../../components/back-button/back-button";
import BannerAdsTable from "../../components/banner-ads-table/banner-ads-table";
import SpecialOffersTable from "../../components/special-offers-table/special-offers-table";
import { getBannerAdCapacity, getUserBannerAds } from "../../lib/api/banner-ad";
import { getUserSpecialOffers } from "../../lib/api/special-offer";
import { GradientBorder } from "../../styled/gradient-border";
import { Loader } from "../../styled/loader";

import {
  Container,
  Content,
  SectionTitle,
  InfoText,
  UploadButton,
  InfoLink,
} from "./styled";

interface AdvertisementsMenuPageProps {}

const AdvertisementsMenuPage = (props: AdvertisementsMenuPageProps) => {
  const navigate = useNavigate();

  const businessAdsQuery = useQuery("/me/banner-ads", () => getUserBannerAds());

  const specialOffersQuery = useQuery("/me/special-offers", () =>
    getUserSpecialOffers()
  );

  const bannerAdCapacityQuery = useQuery("/settings/banner-ads-enabled", () =>
    getBannerAdCapacity()
  );

  if (
    businessAdsQuery.isLoading ||
    specialOffersQuery.isLoading ||
    bannerAdCapacityQuery.isLoading
  ) {
    return (
      <Container>
        <Content>
          <Loader />
        </Content>
      </Container>
    );
  }

  if (
    businessAdsQuery.isError ||
    !businessAdsQuery.data ||
    specialOffersQuery.isError ||
    !specialOffersQuery.data ||
    bannerAdCapacityQuery.isError ||
    !bannerAdCapacityQuery.data
  ) {
    return (
      <Container>
        <Content>
          <p>Could not communicate with servers at this time.</p>
        </Content>
      </Container>
    );
  }

  return (
    <Container>
      <BackButton handleBack={() => navigate("/account")} />
      <Content>
        <InfoLink>
          <Link to="/account/advertisements/information">
            How Advertising Works
          </Link>
        </InfoLink>
        <SectionTitle>Scrolling Banner Advertisement</SectionTitle>
        <InfoText>*Advertisement will appear in map section.</InfoText>
        <InfoText>*Recommended image size: 400px x 100px.</InfoText>
        {bannerAdCapacityQuery.data && bannerAdCapacityQuery.data === "1" ? (
          <UploadButton
            onClick={() =>
              navigate("/account/advertisements/banner-advertisement/new")
            }
          >
            UPLOAD
          </UploadButton>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <SectionTitle>Banner Advertisements Closed</SectionTitle>
            <InfoText>
              *Advertisements are currently at maximum capacity. Please click
              below to be notified when advertisements are open.
            </InfoText>
            <UploadButton
              onClick={() =>
                navigate("/account/advertisements/register-interest")
              }
            >
              REGISTER INTEREST
            </UploadButton>
          </div>
        )}
        <SectionTitle>Current Banner Advertisements</SectionTitle>
        <InfoText style={{ marginBottom: 20 }}>
          *Select advertisement image to update
        </InfoText>

        <BannerAdsTable
          bannerAdvertisements={businessAdsQuery.data}
          onItemClick={(uid: string) => {
            navigate("/account/advertisements/banner-advertisement/" + uid);
          }}
        />

        <SectionTitle style={{ marginTop: 60 }}>Special Offers</SectionTitle>
        <InfoText>
          *Advertisement will appear in business info section.
        </InfoText>
        <InfoText>*Recommended image size: 300px x 300px.</InfoText>
        <UploadButton
          onClick={() => navigate("/account/advertisements/special-offer/new")}
        >
          UPLOAD
        </UploadButton>
        <SectionTitle>Current Special Offers</SectionTitle>
        <InfoText style={{ marginBottom: 20 }}>
          *Select offer image to update
        </InfoText>
        <SpecialOffersTable
          specialOffers={specialOffersQuery.data}
          onItemClick={(uid: string) => {
            navigate("/account/advertisements/special-offer/" + uid);
          }}
        />
      </Content>
    </Container>
  );
};

export default AdvertisementsMenuPage;
