import styled from "styled-components";
import { SecondaryButton } from "../../../styled/buttons";

export const ArticlesTableContainer = styled.div``;

export const Cards = styled.div``;

export const ArticleCardWrapper = styled.div`
  margin: 40px 0;
`;

export const LoadMoreButton = styled(SecondaryButton)`
  width: 100%;
  margin: 20px 0;
`;
