import { Subtitle, Title } from "../../styled/typography";
import { useNavigate } from "react-router-dom";
import BackButton from "../../components/back-button/back-button";
import { Content, Container, Text } from "./styled";

interface HowAdvertisingWorksPageProps {}

const HowAdvertisingWorksPage = (props: HowAdvertisingWorksPageProps) => {
  const navigate = useNavigate();

  return (
    <Container>
      <BackButton handleBack={() => navigate("/account/advertisements")} />
      <Content>
        <Title>Advertising</Title>
        <p>
          Advertising on the USA PACS App is a great way to get your message
          directly to gun owners and 2nd Amendment Americans. Unlike social
          media ads which do everything they can to limit your reach to your
          desired demographic, the USAPACS app provides your ad directly to the
          2nd Amendment demographic without limits. 2nd Amendment Americans want
          to support companies that support them. Let them know who you are and
          entice them to patronize your company with a USAPACS app scrolling ad
          and/or Business Special Offer.
        </p>
        <p>
          USAPACS welcomes advertising from companies that support the safety of
          American citizens and law enforcement. We welcome ads from
          manufacturers and dealers of self-defense equipment, and weapons such
          as firearms, knives, and ammunition.
        </p>
        <Subtitle>Policy</Subtitle>
        <p>
          Advertising contrary to the U.S. Constitution will not be allowed.
          Advertising displayed on the USAPACS app must reflect the support of
          the US Constitution particularly the 2nd Amendment.
        </p>
        <p>
          Although USA PACS supports the 1st Amendment, and freedom of speech,
          the following will not be allowed in advertising: nudity, pornography,
          profanity, incendiary messages, violence or support of violence,
          Political views (political views may get the app turned off therefore
          USA PACS will leave politics to other platforms)
        </p>
        <Subtitle>Scrolling Ads</Subtitle>
        <p>
          Scrolling ads are a great way to get your desired demographic directly
          to your website or provided link. Your ad will continually scroll at
          the bottom of the app with other advertisers. Advertisements will be
          visible to users once they are reviewed by USAPACS (normally within 24
          hours). This includes any edits of your advertisement.
        </p>
        <p>
          When you purchase your scrolling ad you will need to:
          <ul style={{ listStyle: "none" }}>
            <li>
              1. Upload an image of your ad with an enticement to get the users
              to click on the banner ad and take them to your link
            </li>
            <li>2. Provide the link for the ad</li>
          </ul>
        </p>
        <Subtitle> Business Special Offers</Subtitle>
        <p>
          Business Special Offers are the perfect way to entice your desired
          demographic to come directly to your business. Not only are users of
          the app looking for businesses that support the 2nd Amendment, they
          are looking for those businesses that want to entice them to their
          business with a special offer such as: “Purchase one burger get one
          FREE, just show this ad to your server”. They want to patronize those
          who support them, but also want to be enticed by something special.
          There are numerous ways that users of the app will be aware of your
          special offer. Users of the app will see the map pin of your business
          in a special color and know that there is a special offer associated
          with your business location. Users of the app may search exclusively
          for businesses that have a special offer.
        </p>
        <p>
          When you purchase your Business Special Offer you will need to
          <ul style={{ listStyle: "none" }}>
            <li>
              1. Upload an image of your offer. This image should be catchy and
              show what you are offering specifically in the image.
            </li>
            <li>
              2. Provide a Description of your offer, such as: Buy one burger
              get one free, or Free order of fries with your burger, etc
            </li>
            <li>
              3. Describe how to redeem your offer, such as: Please show this ad
              to your server when ordering
            </li>
          </ul>
        </p>
      </Content>
    </Container>
  );
};

export default HowAdvertisingWorksPage;
