import openCarryNeutral from "../../../images/badges/openCarryPolicy/openCarryPolicy_grey.png";

const OpenCarryNeutral = ({ imageProps }: any) => {
  return (
    <img
      src={openCarryNeutral}
      alt="open-carry-policy-neutral"
      {...imageProps}
    />
  );
};

export default OpenCarryNeutral;
