import { BannerAdvertisement } from "../../ts/banner-advertisement";
import {
  BannerAdsTableContainer,
  AdContainer,
  AdImage,
  RowsContainer,
} from "./styled";

interface BannerAdsTableProps {
  bannerAdvertisements: BannerAdvertisement[];
  onItemClick?: (id: string) => void;
}

const BannerAdsTable = (props: BannerAdsTableProps) => {
  return (
    <BannerAdsTableContainer>
      <RowsContainer>
        {props.bannerAdvertisements.map((ad) => (
          <AdContainer key={ad.uid}>
            <AdImage
              src={`${ad.url}?id=${ad.updatedAt ?? "1"}`}
              onClick={() => {
                if (props.onItemClick) props.onItemClick(ad.uid);
              }}
            />
          </AdContainer>
        ))}
      </RowsContainer>
    </BannerAdsTableContainer>
  );
};

export default BannerAdsTable;
