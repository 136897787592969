import { FormikValues } from "formik";
import React from "react";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import BackButton from "../../components/back-button/back-button";
import CompleteClaimForm from "../../components/complete-claim-form/complete-claim-form";
import CompleteClaimFormConfig from "../../components/complete-claim-form/form-config";
import IconHandler from "../../components/icon-handler/icon-handler";
import { ClaimError } from "../../lib/api/errors/claim-error";
import { cancelClaim, completeClaim } from "../../lib/api/points-of-interest";
import { PrimaryButton, SecondaryButton } from "../../styled/buttons";
import { Loader } from "../../styled/loader";
import {
  ErrorIcon,
  ErrorIconContainer,
  SuccessIconContainer,
  SuccessText,
} from "../../styled/status-icons";
import { Subtitle } from "../../styled/typography";

import { PointOfInterestClaim } from "../../ts/point-of-interest-claim";
import {
  ViewClaimPageContainer,
  ClaimTitle,
  ClaimSubtitle,
  ContentWrapper,
  CancelClaimSection,
  CancelClaimButton,
} from "./styled/view-claim-styled";

export interface ViewClaimPageProps {
  claim: PointOfInterestClaim;
  onSuccess?: () => void;
  onBack?: () => void;
}

const ViewClaimPage = (props: ViewClaimPageProps) => {
  const client = useQueryClient();

  const { mutate, isLoading, isError, isSuccess, reset, error } = useMutation(
    ["/poi/claim/complete", props.claim.placeId],
    (code: string) => completeClaim(props.claim.id, code),
    {
      onSuccess: () => {
        client.invalidateQueries("/me/claims");
        client.invalidateQueries("/me/businesses");
      },
    }
  );

  const cancelMutation = useMutation(
    ["/poi/claim/cancel", props.claim.placeId],
    (values: { id: number }) => cancelClaim(values.id),
    {
      onSuccess: () => {},
    }
  );

  const handleSubmit = (code: string) => {
    mutate(code);
  };

  const handleCancelClick = (id: number) => {
    const confirmed = window.confirm(
      "Do you want to cancel this claim? Your verification code will become invalid."
    );
    if (confirmed) {
      cancelMutation.mutate({ id });
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return (
      <ErrorIconContainer
        style={{ flexDirection: "column", alignItems: "flex-start" }}
      >
        <div style={{ width: "100%", display: "flex", alignItems: "center" }}>
          <ErrorIcon>!</ErrorIcon>
          {error instanceof ClaimError ? (
            <p
              style={{ color: "red", fontWeight: "bold", margin: "0 0 0 10px" }}
            >
              {error.message === "incorrect_code"
                ? "Code is invalid."
                : error.message}
            </p>
          ) : (
            <p
              style={{ color: "red", fontWeight: "bold", margin: "0 0 0 10px" }}
            >
              Could not complete claim at this time. Please try again later
            </p>
          )}
        </div>
        <SecondaryButton
          style={{ maxWidth: 300, width: "100%", marginTop: 20 }}
          onClick={() => reset()}
        >
          BACK
        </SecondaryButton>
      </ErrorIconContainer>
    );
  }

  if (isSuccess) {
    return (
      <SuccessIconContainer style={{ flexDirection: "column" }}>
        <div style={{ display: "flex" }}>
          <IconHandler name="checkmark" iconProps={{}} />
          <SuccessText>
            Business has been claimed. Please view in your businesses.
          </SuccessText>
        </div>
        <SecondaryButton
          style={{ flex: "0 0 50px", width: 120, marginTop: 20 }}
          onClick={() => {
            if (props.onSuccess) {
              props.onSuccess();
            }
          }}
        >
          BACK
        </SecondaryButton>
      </SuccessIconContainer>
    );
  }

  if (cancelMutation.isSuccess) {
    return (
      <ViewClaimPageContainer>
        {props.onBack ? (
          <BackButton handleBack={() => props.onBack!()} />
        ) : null}
        <ContentWrapper>
          <Subtitle>This claim has been cancelled.</Subtitle>
        </ContentWrapper>
      </ViewClaimPageContainer>
    );
  }

  return (
    <ViewClaimPageContainer>
      {props.onBack ? <BackButton handleBack={() => props.onBack!()} /> : null}
      <ContentWrapper>
        <ClaimTitle>{props.claim.businessName}</ClaimTitle>
        <ClaimSubtitle>{props.claim.businessAddress}</ClaimSubtitle>

        <CompleteClaimForm
          formConfig={CompleteClaimFormConfig}
          onSuccess={(values: FormikValues) => {
            const code = values.claimCode;

            if (!code) {
              return;
            }

            handleSubmit(code);
          }}
        />
        <CancelClaimSection>
          <CancelClaimButton
            onClick={() => {
              handleCancelClick(props.claim.id);
            }}
          >
            Cancel Claim
          </CancelClaimButton>
        </CancelClaimSection>
      </ContentWrapper>
    </ViewClaimPageContainer>
  );
};

export default ViewClaimPage;
