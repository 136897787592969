import IconHandler from "../../icon-handler/icon-handler";

export interface BadgeDisplayProps {
  policy: string;
  alignment: number;
}

const BadgeDisplay = ({ policy, alignment }: BadgeDisplayProps) => {
  if (alignment === 0) {
    return (
      <IconHandler name={`${policy}-neutral`} iconProps={{ width: "100%" }} />
    );
  }

  if (alignment === 1) {
    return (
      <IconHandler name={`${policy}-positive`} iconProps={{ width: "100%" }} />
    );
  }

  if (alignment >= 2) {
    return (
      <IconHandler name={`${policy}-negative`} iconProps={{ width: "100%" }} />
    );
  }

  return null;
};

export default BadgeDisplay;
