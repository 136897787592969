import styled from "styled-components";
import { mobileMax } from "../../../lib/media-breakpoints";

export const UserBusinessPageContainer = styled.div``;

export const ContentWrapper = styled.div`
  margin: 67px 60px 30px 60px;

  @media (max-width: ${mobileMax}px) {
    margin: 35px 30px;
  }
`;

export const BusinessSectionWrapper = styled.div``;

export const BusinessPoliciesWrapper = styled.div``;
