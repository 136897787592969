import { Loader } from "../../styled/loader";
import { FileInput } from "../advertisement-image-picker/styled";
import { AdImage, Container } from "./styled";

interface BannerAdImageUpdateProps {
  imageSrc: string;
  imageWidth: number;
  imageHeight: number;
  handleClick: (image: File) => void;
  loading: boolean;
}

const BannerAdImageUpdate = (props: BannerAdImageUpdateProps) => {
  return (
    <Container>
      {props.loading !== true ? (
        <AdImage
          src={props.imageSrc}
          alt=""
          style={{ width: props.imageWidth, height: props.imageHeight }}
        />
      ) : (
        <div
          style={{
            width: props.imageWidth,
            height: props.imageHeight,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loader style={{ margin: 0 }} />
        </div>
      )}
      <FileInput
        style={{ marginTop: 20 }}
        text={"UPLOAD"}
        onChange={(e) => {
          if (e && e.target.files && e.target.files[0]) {
            const file = e.target.files[0];

            props.handleClick(file);
            e.target.value = "";
          } else {
          }
        }}
        accept="image/png,image/jepg,image/jpg"
      />
    </Container>
  );
};

export default BannerAdImageUpdate;
