import styled from "styled-components";
import { mobileMax } from "../../../lib/media-breakpoints";

export const SearchBusinessInputContainer = styled.div`
  position: relative;

  @media (max-width: ${mobileMax}px) {
    // height: 80px;
    // margin-top: 20px;
    // margin-bottom: 10px;
    display: flex;
    flex-direction: column;
  }

  @media (min-width: ${mobileMax + 1}px) {
    // margin-top: 30px;
    margin-left: 20px;
  }
`;

export const SearchBusinessTextInput = styled.input`
  font-size: 18px;
  color: #464646;
  text-indent: 10px;

  border: none;

  margin: 0 0 0 0;

  @media (min-width: ${mobileMax + 1}px) {
    width: calc(100% - 45px);
    height: 100%;
    padding-bottom: 5px;
  }

  @media (max-width: ${mobileMax}px) {
    flex: 1;
    margin-left: 20px;
    margin-right: 20px;
    background: #eeeff0;
    height: 35px;
    border-radius: 5px;
  }

  :focus {
    outline: none;
  }
`;

export const SearchImageWrapper = styled.div`
  position: absolute;
  cursor: pointer;

  @media (min-width: ${mobileMax + 1}px) {
    right: 5px;
    top: -5px;
  }

  @media (max-width: ${mobileMax}px) {
    right: 5%;
    height: 35px;
    width: 35px;
    top: 3px;

    img {
      width: 100%;
      height: 100%;
    }
  }
`;

export const RegularBorder = styled.div``;
