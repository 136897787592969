import styled from "styled-components";

export const PostBadgeContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TitleLabel = styled.div`
  margin: 10px 0 20px 20px;
  font-size: 16px;
  color: #546680;
  font-weight: 500;
`;

export const BadgesTable = styled.div``;

export const PostTableBadgeRowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 80px;
  align-items: stretch;
`;
