import React from "react";
import {
  MenuTitleContainer,
  MenuTitleLabel,
} from "../styled/menu-title-styled";

const MenuTitle = ({ label }: { label: string }) => {
  return (
    <MenuTitleContainer>
      <MenuTitleLabel>{label}</MenuTitleLabel>
    </MenuTitleContainer>
  );
};

export default MenuTitle;
