import styled from "styled-components";
import { mobileMax } from "../lib/media-breakpoints";

export const InformationContent = styled.div`
  margin: 60px 48px;

  @media (max-width: ${mobileMax}px) {
    margin: 60px 20px 100px 20px;
  }
`;
