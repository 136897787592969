import styled from "styled-components";

export const PhotoPickerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

interface FileInputProps {
  text: string;
  onChange: (e: any) => void;
}

export const FileInput = styled.input.attrs((props) => ({
  type: "file",
}))<FileInputProps>`
  width: 73px;
  height: 33px;
  overflow: hidden;
  cursor: pointer;
  margint ::-webkit-file-upload-button {
    visibility: hidden;
  }

  ::before {
    content: "${(props) => props.text}";
    border: solid #516581 2px;
    height: 29px;
    width: 68px;
    font-size: 10px;
    border-radius: 3px;
    background: none;
    color: #516581;
    display: flex;
    text-align: center;

    flex-direction: column;
    align-content: center;
    justify-content: center;
    text-align: center;
  }
`;

export const DisplayImage = styled.img`
  width: 100%;
  margin-top: 20px;
`;

export const Placeholder = styled.div`
  width: 100%;
  background: #cfcfcf;
  align-self: center;
`;

export const DisplayContainer = styled.div`
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
`;
