import React, { useEffect } from "react";
import ConfirmEmail from "../../components/confirm-email/confirm-email";
import { getFirebaseAuth } from "../../lib/firebase/firebase";
import { ConfirmEmailPageContainer } from "./styled/confirm-email-page-styled";

import useAuth from "../../lib/custom-hooks/useAuth";

const ConfirmEmailPage = () => {
  const { isAuth } = useAuth();
  const user = getFirebaseAuth();

  return (
    <ConfirmEmailPageContainer>
      <ConfirmEmail email={user.currentUser?.email ?? ""} />
    </ConfirmEmailPageContainer>
  );
};

export default ConfirmEmailPage;
