import { useMutation, useQueryClient } from "react-query";
import {
  deletePhoto,
  setPrimaryPhoto,
  uploadBusinessPhoto,
} from "../../lib/api/business-photo";
import { getUserBusinessesResponse } from "../../lib/api/user";
import SentryHandler from "../../lib/sentry/sentry";
import { BusinessPhoto } from "../../ts/business-photo";
import { PointOfInterest } from "../../ts/point-of-interest";

export const useSetPrimaryPhoto = (
  placeId: string,
  pointOfInterestId: number,
  businessPhotos: BusinessPhoto[],
  clearState: () => void
) => {
  const client = useQueryClient();

  const setPrimaryMutation = useMutation(
    ["/poi/business-photo/primary", placeId],
    (values: { photoId: number }) =>
      setPrimaryPhoto(pointOfInterestId, values.photoId),
    {
      onSettled: (values) => {},
      onSuccess: (data, variables, context: any) => {
        client.setQueryData<{ pages: getUserBusinessesResponse[] } | undefined>(
          ["/me/businesses"],
          (old) => {
            if (old) {
              try {
                let found: PointOfInterest | undefined = undefined;
                old.pages.forEach((page) => {
                  page.businesses.forEach((business) => {
                    let foundBusiness = page.businesses.find(
                      (a) => a.placeId === placeId
                    );
                    if (foundBusiness) {
                      found = foundBusiness;
                    }
                  });

                  if (found) {
                    let tmp = businessPhotos.slice();

                    let currentIndex = tmp.findIndex(
                      (a) => a.id === variables.photoId
                    );
                    if (currentIndex > -1) {
                      let tmpItem = tmp[currentIndex];
                      tmp.splice(currentIndex, 1);
                      tmp.unshift(tmpItem);

                      found.businessPhotos = tmp;
                    }
                  }
                });
              } catch (e: any) {
                SentryHandler.reportException(e);
              }
            }

            return old;
          }
        );
      },
      onMutate: async (variables) => {
        await client.cancelQueries(["/poi", placeId]);

        clearState();
        let currentPhotos = businessPhotos;
        client.setQueryData<PointOfInterest | undefined>(
          ["/poi", placeId],
          (old) => {
            if (old && old.businessPhotos) {
              try {
                let tmp = old.businessPhotos.slice();

                let currentIndex = tmp.findIndex(
                  (a) => a.id === variables.photoId
                );
                if (currentIndex > -1) {
                  let tmpItem = tmp[currentIndex];
                  tmp.splice(currentIndex, 1);
                  tmp.unshift(tmpItem);

                  old.businessPhotos = tmp;
                }
              } catch (e: any) {
                SentryHandler.reportException(e);
              }
            }

            return old;
          }
        );
        return currentPhotos;
      },
      onError: (err, newPhoto, context: any) => {
        client.setQueryData<PointOfInterest | undefined>(
          ["/poi", placeId],
          (old) => {
            if (old) {
              old.businessPhotos = context;
            }

            return old;
          }
        );
      },
    }
  );

  return setPrimaryMutation;
};

export const useDeleteMutation = (
  placeId: string,
  pointOfInterestId: number,
  businessPhotos: BusinessPhoto[],
  clearState: () => void
) => {
  const client = useQueryClient();
  const deleteMutation = useMutation(
    ["/poi/business-photo-delete", placeId],
    (values: { photoId: number }) =>
      deletePhoto(pointOfInterestId, values.photoId),
    {
      onMutate: async (values) => {
        client.cancelQueries(["/poi", placeId]);
        clearState();
        let current = businessPhotos;
        client.setQueryData<PointOfInterest | undefined>(
          ["/poi", placeId],
          (old) => {
            if (old) {
              let tmp = businessPhotos.slice();
              let found = tmp.findIndex((a) => a.id === values.photoId);
              if (found > -1) {
                tmp.splice(found, 1);
                old.businessPhotos = tmp;
              }
            }
            return old;
          }
        );

        return current;
      },
      onSuccess: (data, values) => {
        client.setQueryData<{ pages: getUserBusinessesResponse[] } | undefined>(
          ["/me/businesses"],
          (old) => {
            try {
              if (old) {
                let found: PointOfInterest | undefined = undefined;
                old.pages.forEach((page) => {
                  page.businesses.forEach((business) => {
                    let foundBusiness = page.businesses.find(
                      (a) => a.placeId === placeId
                    );
                    if (foundBusiness) {
                      found = foundBusiness;
                    }
                  });

                  if (found) {
                    let tmp = businessPhotos.slice();
                    let foundPhoto = tmp.findIndex(
                      (a) => a.id === values.photoId
                    );
                    if (foundPhoto > -1) {
                      tmp.splice(foundPhoto, 1);
                      found.businessPhotos = tmp;
                    }
                  }
                });
              }
            } catch (e: any) {
              SentryHandler.reportException(e);
            }

            return old;
          }
        );
      },
      onError: (err, newPhoto, context: any) => {
        client.setQueryData<PointOfInterest | undefined>(
          ["/poi", placeId],
          (old) => {
            if (old) {
              old.businessPhotos = context;
            }
            return old;
          }
        );
      },
    }
  );

  return deleteMutation;
};

export const useUploadMutation = (
  placeId: string,
  pointOfInterestId: number,
  businessPhotos: BusinessPhoto[],
  clearState: () => void
) => {
  const client = useQueryClient();
  const uploadMutation = useMutation(
    ["/poi", placeId],
    (values: { image: File; placement: number }) =>
      uploadBusinessPhoto(values.image, pointOfInterestId, values.placement),
    {
      onSuccess: (data) => {
        client.setQueryData<PointOfInterest | undefined>(
          ["/poi", placeId],
          (old) => {
            if (old && old.businessPhotos) {
              old.businessPhotos.push(data);
            }
            return old;
          }
        );
      },
      onError: () => {},
      onMutate: () => {},
    }
  );

  return uploadMutation;
};
