import { GradientBorder } from "../../styled/gradient-border";
import { BannerAdvertisement } from "../../ts/banner-advertisement";
import { Container, SponsorImage, SponsorLabel, SponsorRow } from "./styled";

interface SponsorsTableProps {
  ads: BannerAdvertisement[];
}

const SponsorsTable = (props: SponsorsTableProps) => {
  return (
    <Container>
      {props.ads.map((ad) => {
        let formattedUrl: string = ad.websiteLink;

        const queryParamIndex = formattedUrl.indexOf("?");
        if (queryParamIndex && queryParamIndex > -1) {
          formattedUrl = formattedUrl.slice(0, queryParamIndex);
        }

        return (
          <SponsorRow
            key={ad.id}
            onClick={() => {
              if (ad.websiteLink && ad.websiteLink !== "") {
                window.open(ad.websiteLink);
              }
            }}
          >
            <SponsorImage src={`${ad.url}?id=${ad.updatedAt ?? "1"}`} />
            <SponsorLabel>{formattedUrl}</SponsorLabel>
            <GradientBorder
              style={{ height: 3, width: "100%", marginTop: 20 }}
            />
          </SponsorRow>
        );
      })}
    </Container>
  );
};

export default SponsorsTable;
