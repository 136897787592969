export const formatBusinessCategory = (businessCategory?: string) => {
  let finalCategory = "";
  if (businessCategory && businessCategory !== "") {
    let category = businessCategory.replaceAll("_", " ");
    let words = category.split(" ");

    for (let i = 0; i < words.length; i++) {
      words[i] = words[i][0].toUpperCase() + words[i].substring(1);
      finalCategory += words[i] + (i < words.length - 1 ? " " : "");
    }
  }

  return finalCategory;
};
