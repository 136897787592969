import { useEffect } from "react";
import { createContext, useContext, useState } from "react";
import SentryHandler from "../sentry/sentry";

interface LocationContextValues {
  location?: GeolocationPosition;
  permission?: string;
}

export interface Location {
  lng: number;
  lat: number;
}

const LocationContext = createContext<LocationContextValues>({
  location: undefined,
});

const useLocation = () => {
  const [location, setLocation] =
    useState<GeolocationPosition | undefined>(undefined);

  return {
    location,
    setLocation,
  };
};

export function LocationProvider({ children }: any) {
  const { location, setLocation } = useLocation();

  const [permission, setPermission] =
    useState<PermissionState | undefined>(undefined);

  const getPermission = async () => {
    try {
      const permission = await navigator?.permissions?.query({
        name: "geolocation",
      });
      if (permission) {
        permission.onchange = async () => {
          const permission = await navigator?.permissions?.query({
            name: "geolocation",
          });
          setPermission(permission.state);
        };
        setPermission(permission.state);
      }
    } catch (e: any) {
      SentryHandler.reportException(e);
    }
  };

  if (navigator && navigator.geolocation) {
    try {
      navigator.geolocation.getCurrentPosition((position) => {
        setLocation(position);
      });
    } catch (e: any) {
      SentryHandler.reportException(e);
    }
  } else {
  }

  useEffect(() => {
    getPermission();
  }, []);

  return (
    <LocationContext.Provider value={{ location, permission }}>
      {children}
    </LocationContext.Provider>
  );
}

export default function LocationConsumer() {
  const ctx = useContext(LocationContext);
  return ctx;
}
