import React, { useState } from "react";
import {
  FileInput,
  PhotoPickerContainer,
} from "./styled/formik-photo-picker-styled";

export interface FormikPhotoPickerProps {
  onChange: (file: any) => void;
  buttonText: string;
  inputName: string;
}

const FormikPhotoPicker = (props: FormikPhotoPickerProps) => {
  const [currentlySelected, setCurrentlySelected] = useState<File | undefined>(
    undefined
  );

  return (
    <PhotoPickerContainer>
      <FileInput
        text={props.buttonText}
        onChange={(e) => {
          const file = e.target.files[0];
          setCurrentlySelected(file);
          props.onChange(file);
        }}
        accept="image/png,image/jepg,image/jpg"
      />
      <div>
        {currentlySelected ? (
          <img
            src={URL.createObjectURL(currentlySelected)}
            alt="selected"
            style={{ height: 84, width: 84, marginTop: 15 }}
          />
        ) : null}
      </div>
    </PhotoPickerContainer>
  );
};

export default FormikPhotoPicker;
