import { createContext } from "react";
import { MarkerData } from "../components/Map.tsx/MapView";
import { QueryInfoState } from "../Dashboard";

export const MapContext = createContext<{
  map: google.maps.Map | undefined;
  setMap: any;
}>({
  map: undefined,
  setMap: undefined,
});

export const ActiveMarkerContext = createContext<{
  activeMarker: string | undefined;
  setActiveMarker: any;
}>({
  activeMarker: undefined,
  setActiveMarker: undefined,
});

export const QueryValueContext = createContext<{
  queryInfo?: QueryInfoState;
  value: string;
  displayValue: string;
  setValue: (value: string, displayValue: string) => void;
}>({
  value: "",
  displayValue: "",
  setValue: () => {},
});

export const MarkersContext = createContext<{
  markers: MarkerData[];
  setMarkers?: any;
}>({ markers: [] });
