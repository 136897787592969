import styled from "styled-components";
import { applyBoxShadow } from "../../../styled/box-shadow";

export const ReferralCodeDisplayContainer = styled.div`
  ${applyBoxShadow()}
  height:39px;
  max-width: 294px;
  width: 100%;
  display: flex;
  align-items: center;
`;

export const LeftSide = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  overflow: auto;
`;

export const RightSide = styled.div``;

export const CodeLabel = styled.label`
  margin-left: 20px;
  margin-right: 5px;
  color: #989898;
`;
