import { useState } from "react";

import NewBannerAdvertisementPageSwitch from "./components/new-banner-ad-switch";

interface NewBannerAdvertisementPageProps {}

export interface NewBannerAdvertisementPageState {
  step: number;
}

const NewBannerAdvertisementPage = (props: NewBannerAdvertisementPageProps) => {
  const [state, setState] = useState<NewBannerAdvertisementPageState>({
    step: 1,
  });

  return <NewBannerAdvertisementPageSwitch {...state} />;
};

export default NewBannerAdvertisementPage;
