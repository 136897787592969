import styled from "styled-components";
import { mobileMax } from "../../../lib/media-breakpoints";

export const MainMenuPageContainer = styled.div`
  flex: 1;
  display: flex;
  min-height: calc(100vh - 150px);
`;

export const TitleSection = styled.div`
  display: flex;
  align-items: center;
  flex: 0 0 40%;

  @media (max-width: ${mobileMax}px) {
    display: none;
  }
`;

export const MenusSection = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background: #fafafa;
`;

export const TitleSectionLabel = styled.h1`
  margin-bottom: 10em;
  margin-left: 20%;
  font-size: 30px;
  font-weight: 700;
  color: #244169;
`;

export const RightSideWrapper = styled.div`
  background: #fafafa;
  align-self: stretch;
  justify-self: stretch;
  flex: 1;
  overflow: auto;

  @media (min-width: ${mobileMax + 1}px) {
    height: calc(100vh - 150px);
  }
`;
