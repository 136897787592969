import { PointOfInterest } from "../../ts/point-of-interest";
import {
  MARKER_GREEN,
  MARKER_GREY,
  MARKER_PURPLE,
  MARKER_RED,
} from "./marker-colors";

export const DetermineMarkerColor = (pointOfInterest: PointOfInterest) => {
  const businessOffers = pointOfInterest.businessOffers;

  if (businessOffers) {
    if (businessOffers.length > 0) {
      return MARKER_PURPLE;
    }
  }

  let postedBadges = pointOfInterest.badgeAlignments;

  if (!postedBadges) {
    return MARKER_GREY;
  }

  var positiveFound = false;
  var negativeFound = false;

  //loop through badges for positive badge
  // Object.keys(postedBadges).forEach((incidentType) => {
  //   //if 51% alcohol and enabled, automatically return red
  //   if (incidentType === "menuPolicy") {
  //     if (postedBadges?.menuPolicy === 1) {
  //       return MARKER_RED;
  //     }
  //   } else {
  //     if (postedBadge.alignment === 1) {
  //       if (postedBadge.incidentType !== "metalDetectorPolicy") {
  //         positiveFound = true;
  //       } else {
  //         negativeFound = true;
  //       }
  //     }
  //     if (postedBadge.alignment > 1) {
  //       negativeFound = true;
  //     }
  //   }
  // });

  if (postedBadges.menuPolicy === 1) {
    return MARKER_RED;
  }

  if (postedBadges.metalDetectorPolicy === 1) {
    negativeFound = true;
  }

  if (postedBadges.concealedCarryPolicy === 1) {
    positiveFound = true;
  }

  if (postedBadges.openCarryPolicy === 1) {
    positiveFound = true;
  }

  if (postedBadges.leosaPolicy === 1) {
    positiveFound = true;
  }

  if (postedBadges.leosaPolicy > 1) {
    negativeFound = true;
  }

  if (postedBadges.openCarryPolicy > 1) {
    negativeFound = true;
  }

  if (postedBadges.concealedCarryPolicy > 1) {
    negativeFound = true;
  }

  if (positiveFound) {
    return MARKER_GREEN;
  }

  if (negativeFound) {
    return MARKER_RED;
  }

  return MARKER_GREY;
};
