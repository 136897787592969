import styled from "styled-components";
import { mobileMax } from "../../../lib/media-breakpoints";
import { applyBoxShadow } from "../../../styled/box-shadow";

export const NavBarContainer = styled.nav`
  width: 100%;

  height: 150px;

  display: flex;
  align-items: center;

  z-index: 5000;

  box-shadow: 0 3px 10px rgb(0 0 0 / 10%), 0 2px 2px rgb(0 0 0 / 6%),
    0 0 2px rgb(0 0 0 / 7%);

  @media (max-width: ${mobileMax}px) {
    // height: 65px;
    flex: 0 0 65px;
  }
`;
