import * as firebase from "firebase/app";
import { FirebaseError } from "firebase/app";
import {
  AuthCredential,
  AuthErrorCodes,
  createUserWithEmailAndPassword,
  EmailAuthProvider,
  fetchSignInMethodsForEmail,
  getAuth,
  reauthenticateWithCredential,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  updateEmail,
  updatePassword,
  User,
} from "firebase/auth";

const app = firebase.initializeApp({
  apiKey: "AIzaSyAB6sPNtgIUMdxQ96r_fzMKQCMhwnn0sGw",
  authDomain: "usa-pacs.firebaseapp.com",
  databaseURL: "https://usa-pacs.firebaseio.com",
  projectId: "usa-pacs",
  storageBucket: "usa-pacs.appspot.com",
  messagingSenderId: "290508859223",
  appId: "1:290508859223:web:49aa16c7deb27e871b0012",
  measurementId: "G-4Y0PJQDGRP",
});

export const getFirebaseAuth = () => {
  const auth = getAuth(app);

  return auth;
};

export const loginFirebase = async (email: string, password: string) => {
  const auth = getAuth(app);

  await signInWithEmailAndPassword(auth, email, password);
};

export const registerWithEmailPassword = async (
  email: string,
  password: string
) => {
  const auth = getAuth(app);

  const response = await createUserWithEmailAndPassword(auth, email, password);

  if (!response) {
    throw Error("Could not create user at this time");
  }

  return response.user;
};

export const checkEmailExists = async (email: string) => {
  const auth = getAuth(app);

  const providers = await fetchSignInMethodsForEmail(auth, email);

  if (providers.length > 0) {
    return true;
  }

  return false;
};

export const sendForgotPassword = async (email: string) => {
  const auth = getAuth(app);

  await sendPasswordResetEmail(auth, email);
};

export const sendVerificationEmail = async (user: User) => {
  const auth = getAuth(app);

  if (!auth.currentUser) {
    throw Error("User is not logged in");
  }

  await sendEmailVerification(user);
};

export const getFirebaseUserToken = async (): Promise<string> => {
  const auth = getAuth(app);

  if (!auth.currentUser) {
    throw Error("User is not currently logged in");
  }

  const token = await auth.currentUser.getIdToken();

  return token;
};

export const updateUserEmail = async (
  newEmail: string,
  password: string
): Promise<boolean | string> => {
  const auth = getAuth(app);

  if (!auth.currentUser) {
    throw Error("User is not logged in");
  }

  //reauth user
  const email = auth.currentUser.email;
  if (!email) {
    return false;
  }

  try {
    const cred: AuthCredential = EmailAuthProvider.credential(email, password);

    await reauthenticateWithCredential(auth.currentUser, cred);

    //update email
    await updateEmail(auth.currentUser, newEmail);

    await sendVerificationEmail(auth.currentUser);
  } catch (e) {
    if (e instanceof FirebaseError) {
      if (e.code === AuthErrorCodes.INVALID_PASSWORD) {
        return "Incorrect password.";
      }
      if (e.code === AuthErrorCodes.EMAIL_EXISTS) {
        return "Email already in use.";
      }

      return "Could not update email.";
    }

    return false;
  }

  return true;
};

export const updateUserPassword = async (
  currentPassword: string,
  newPassword: string
): Promise<boolean | string> => {
  const auth = getAuth(app);

  if (!auth.currentUser) {
    throw Error("User is not logged in");
  }

  //reauth user
  const email = auth.currentUser.email;
  if (!email) {
    return false;
  }

  try {
    const cred: AuthCredential = EmailAuthProvider.credential(
      email,
      currentPassword
    );

    await reauthenticateWithCredential(auth.currentUser, cred);

    //update password

    await updatePassword(auth.currentUser, newPassword);
  } catch (e) {
    if (e instanceof FirebaseError) {
      if (e.code === AuthErrorCodes.INVALID_PASSWORD) {
        return "Incorrect current password.";
      }
      if (e.code === AuthErrorCodes.WEAK_PASSWORD) {
        return "Password must be at least 6 characters.";
      }

      return "Could not update password.";
    }

    return false;
  }

  return true;
};
