import { ErrorMessage, Field, FieldProps, FormikProps } from "formik";

import { InfoText, SectionTitle } from "../../pages/AdvertisementsMenu/styled";
import { StyledTextArea } from "../../styled/form-elements";
import AdvertisementImagePicker from "../advertisement-image-picker/advertisement-image-picker";
import {
  AdvertisementTextInput,
  ErrorMessageText,
} from "../banner-ad-form/styled";
import SelectBusinessTableWrapper from "../select-business-table/select-business-table-wrapper";
import SpecialOfferPlansTable from "../special-offer-plans-table/special-offer-plans-table";

import { Content } from "./styled";
import { useDeterminePlans } from "./util";

import * as Yup from "yup";
import { useEffect, useState } from "react";
import { SecondaryButton } from "../../styled/buttons";
import {
  AdvertiementTextArea,
  AdvertisementTextField,
  CharacterCounterLabel,
} from "../../styled/advertisement-form-elements";
import { useQuery } from "react-query";
import { getSpecialOfferPlans } from "../../lib/api/special-offer";
import { Loader } from "../../styled/loader";
import { useRef } from "react";

const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

export interface SpecialOfferFormProps {}

export interface SpecialOfferFormValues {
  title: string;
  description: string;
  planUId: string;
  redeemMethod?: string;
  image?: File;
  businessIds?: number[] | null | undefined;
  salesCode?: string;
}

export const SpecialOfferFormSchema: Yup.SchemaOf<SpecialOfferFormValues> =
  Yup.object({
    title: Yup.string()
      .required("Please enter a title")
      .max(200, "Title must be less than 200 characters."),
    description: Yup.string()
      .required("Please enter a description")
      .max(1500, "Description must be less than 1500 characters."),
    planUId: Yup.string().required("Please select a plan."),
    redeemMethod: Yup.string()
      .optional()
      .max(1500, "How to Redeem must be less than 1500 characters."),
    image: Yup.mixed()
      .test("exists", "Please upload an offer image.", (value) =>
        value ? true : false
      )
      .test("fileSize", "The image is too large. ", (value) => {
        if (!value) return false;
        return value.size <= 6000000;
      })
      .test("fileType", "Unsupported File Format", (value) => {
        if (!value) return false;
        return SUPPORTED_FORMATS.includes(value.type);
      }),
    businessIds: Yup.mixed(),
    salesCode: Yup.string().optional(),
  });

const SpecialOfferForm = (
  props: SpecialOfferFormProps & FormikProps<SpecialOfferFormValues>
) => {
  const [descriptionCharacterCount, setDescriptionCharacterCount] =
    useState<number>(0);

  const [redeemCharacterCounter, setRedeemCharacterCounter] =
    useState<number>(0);

  const [selectedBusinesses, setBusinesses] = useState<number[]>([]);

  const { plans, changeSelected, isLoading, isError } = useDeterminePlans();

  const { handleSubmit, setFieldValue } = props;

  const handlePlanChange = (uid: string) => {
    setFieldValue("planUId", uid);
  };

  useEffect(() => {
    changeSelected(selectedBusinesses.length);
  }, [selectedBusinesses, changeSelected]);

  useEffect(() => {
    setFieldValue("businessIds", selectedBusinesses);
  }, [selectedBusinesses, setFieldValue]);

  if (isLoading) {
    return (
      <Content>
        <Loader />
      </Content>
    );
  }

  if (isError) {
    return (
      <Content>
        <p>Could not communicate with server at this time.</p>
      </Content>
    );
  }

  return (
    <form onSubmit={handleSubmit}>
      <Content>
        <SectionTitle>Special Offer Image</SectionTitle>
        <InfoText>
          *Advertisement will appear in business info section.
        </InfoText>
        <InfoText>*Recommended image size: 300px x 300px.</InfoText>
        <ErrorMessage name="image">
          {(msg) => <ErrorMessageText>{msg}</ErrorMessageText>}
        </ErrorMessage>
        <AdvertisementImagePicker
          imageWidth={200}
          imageHeight={200}
          onChange={function (file: any): void {
            setFieldValue("image", file);
          }}
          inputName={"image"}
        />
        <SectionTitle>Title</SectionTitle>
        <ErrorMessage name="title">
          {(msg) => <ErrorMessageText>{msg}</ErrorMessageText>}
        </ErrorMessage>
        <Field name="title">
          {({ field }: FieldProps) => <AdvertisementTextField {...field} />}
        </Field>
        <SectionTitle>Description</SectionTitle>
        <InfoText>*Will be displayed when your offer is viewed.</InfoText>
        <ErrorMessage name="description">
          {(msg) => <ErrorMessageText>{msg}</ErrorMessageText>}
        </ErrorMessage>
        <CharacterCounterLabel>
          {descriptionCharacterCount} / 1500 characters
        </CharacterCounterLabel>
        <Field name="description">
          {({ field }: FieldProps) => (
            <AdvertiementTextArea
              {...field}
              onChange={(e) => {
                setDescriptionCharacterCount(e.currentTarget.value.length);
                field.onChange(e);
              }}
            ></AdvertiementTextArea>
          )}
        </Field>
        <SectionTitle>How to Redeem (optional)</SectionTitle>
        <InfoText>*Will be displayed when your offer is viewed.</InfoText>
        <ErrorMessage name="redeemMethod">
          {(msg) => <ErrorMessageText>{msg}</ErrorMessageText>}
        </ErrorMessage>
        <CharacterCounterLabel>
          {redeemCharacterCounter} / 1500 characters
        </CharacterCounterLabel>
        <Field name="redeemMethod">
          {({ field }: FieldProps) => (
            <AdvertiementTextArea
              {...field}
              onChange={(e) => {
                setRedeemCharacterCounter(e.currentTarget.value.length);
                field.onChange(e);
              }}
            ></AdvertiementTextArea>
          )}
        </Field>
        <SectionTitle>Businesses</SectionTitle>
        <InfoText>
          *Please select the business profile(s) you would like to associate the
          advertisement with:
        </InfoText>
        <SelectBusinessTableWrapper
          state={selectedBusinesses}
          setState={setBusinesses}
          onChange={(ids: number[]) => {
            setFieldValue("businesses", ids);
          }}
        />
        <SectionTitle>Advertisement Plan</SectionTitle>
        <ErrorMessage name="planUId">
          {(msg) => <ErrorMessageText>{msg}</ErrorMessageText>}
        </ErrorMessage>
        <SpecialOfferPlansTable plans={plans} onClick={handlePlanChange} />
        <SectionTitle>Sales Code</SectionTitle>
        <ErrorMessage name="salesCode">
          {(msg) => <ErrorMessageText>{msg}</ErrorMessageText>}
        </ErrorMessage>
        <Field name="salesCode">
          {({ field }: FieldProps) => <AdvertisementTextField {...field} />}
        </Field>
        <SecondaryButton style={{ marginTop: 20 }} type="submit">
          UPLOAD
        </SecondaryButton>
      </Content>
    </form>
  );
};

export default SpecialOfferForm;
