import {
  ArticleCardContainer,
  LeftSide,
  CardTitle,
  CardSubTitle,
  RightSide,
  Bottom,
  Top,
  PreviewText,
  ArticleImage,
  DateText,
} from "./styled/article-card-styled";

interface ArticleCardProps {
  title: string;
  category?: string;
  date: string;
  imageSrc?: string;
  previewText?: string;
}

const ArticleCard = (props: ArticleCardProps) => {
  return (
    <ArticleCardContainer>
      <Top>
        {props.imageSrc ? (
          <LeftSide>
            <ArticleImage
              src={props.imageSrc}
              alt="Article"
              height={"50"}
              width={"100"}
            />
          </LeftSide>
        ) : null}
        <RightSide>
          <CardTitle>{props.title}</CardTitle>
          <CardSubTitle>{props.category}</CardSubTitle>
        </RightSide>
      </Top>
      <Bottom>
        {props.previewText ? (
          <PreviewText>{props.previewText}</PreviewText>
        ) : null}
        <DateText>{props.date}</DateText>
      </Bottom>
    </ArticleCardContainer>
  );
};

export default ArticleCard;
