import {
  FormikConfig,
  FormikValues,
  withFormik,
  WithFormikConfig,
} from "formik";
import React from "react";
import CompleteClaimFormBody, {
  CompleteClaimFormValues,
} from "./components/form-body";

const CompleteClaimForm = (props: {
  onSuccess: (values: FormikValues) => void;
  formConfig: WithFormikConfig<
    { onSuccess: (values: FormikValues) => void },
    CompleteClaimFormValues
  >;
}) => {
  const Form = withFormik(props.formConfig)(CompleteClaimFormBody);

  return <Form onSuccess={(values) => props.onSuccess(values)} />;
};

export default CompleteClaimForm;
