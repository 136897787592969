import styled from "styled-components";
export const Content = styled.div``;

export const Wrapper = styled.div`
  margin: 10px 30px;
  display: flex;
  flex-direction: column;
`;

export const StepSection = styled.div`
  margin: 30px 0;
`;

export const StepTitle = styled.h2`
  font-size: 16px;
`;

export const StepImage = styled.img``;
