import { Link } from "react-router-dom";
import { Content, InfoText, LinkText } from "./styled";

interface AddToHomeScreenInfoProps {}

const useDetermineShouldShowHomeScreenInfo = () => {
  try {
    const shouldShow =
      window.matchMedia("(display-mode: standalone)").matches !== true;

    return shouldShow;
  } catch (e) {
    return false;
  }
};

const AddToHomeScreenInfo = (props: AddToHomeScreenInfoProps) => {
  const shouldShow = useDetermineShouldShowHomeScreenInfo();

  return shouldShow ? (
    <Content>
      <InfoText>Be sure to add USA PACS to your home screen!</InfoText>
      <Link to="/menu/home-screen?b=home" style={{ textDecoration: "none" }}>
        <LinkText>Need help? View instructions here</LinkText>
      </Link>
    </Content>
  ) : null;
};

export default AddToHomeScreenInfo;
