import { Field, FieldProps, FormikProps, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  FieldLabel,
  StyledField,
  StyledErrorMessage,
  SubmitButton,
} from "../../styled/edit-profile-form-elements";
import { GradientBorder } from "../../styled/gradient-border";
import { FieldsWrapper, FormContainer } from "../register/styled/global-styled";
import { SupportFormContainerProps } from "./support-form-container";

export interface SupportFormValues {
  firstName: string;
  lastName: string;
  contactEmail: string;
  topic: string;
  message: string;
}

export const SupportFormSchema: Yup.SchemaOf<SupportFormValues> = Yup.object({
  firstName: Yup.string().required("Please enter your first name."),
  lastName: Yup.string().required("Please enter your last name."),
  contactEmail: Yup.string().required("Please enter your email."),
  topic: Yup.string().required("Please choose your topic."),
  message: Yup.string().required("Please enter your message."),
});

const SupportForm = (
  props: SupportFormContainerProps & FormikProps<SupportFormValues>
) => {
  const { handleSubmit, isSubmitting } = props;

  return (
    <form onSubmit={handleSubmit} style={{ width: "100%" }}>
      <FormContainer>
        <FieldsWrapper>
          <FieldLabel>First Name</FieldLabel>
          <ErrorMessage
            name="firstName"
            render={(msg: string) => (
              <StyledErrorMessage style={{ marginBottom: 10 }}>
                {msg}
              </StyledErrorMessage>
            )}
          />
          <Field name="firstName">
            {({ field }: FieldProps) => <StyledField type="input" {...field} />}
          </Field>
          <GradientBorder />
        </FieldsWrapper>
        <FieldsWrapper>
          <FieldLabel>Last Name</FieldLabel>
          <ErrorMessage
            name="lastName"
            render={(msg: string) => (
              <StyledErrorMessage style={{ marginBottom: 10 }}>
                {msg}
              </StyledErrorMessage>
            )}
          />
          <Field name="lastName">
            {({ field }: FieldProps) => <StyledField type="input" {...field} />}
          </Field>
          <GradientBorder />
        </FieldsWrapper>
        <FieldsWrapper>
          <FieldLabel>Email</FieldLabel>
          <ErrorMessage
            name="contactEmail"
            render={(msg: string) => (
              <StyledErrorMessage style={{ marginBottom: 10 }}>
                {msg}
              </StyledErrorMessage>
            )}
          />
          <Field name="contactEmail">
            {({ field }: FieldProps) => <StyledField type="input" {...field} />}
          </Field>
          <GradientBorder />
        </FieldsWrapper>
        <FieldsWrapper>
          <FieldLabel>Topic</FieldLabel>
          <ErrorMessage
            name="topic"
            render={(msg: string) => (
              <StyledErrorMessage style={{ marginBottom: 10 }}>
                {msg}
              </StyledErrorMessage>
            )}
          />
          <Field name="topic" as="select" className="styled-dropdown">
            <option value="">SELECT</option>
            <option value="Advertisements">Advertisements</option>
            <option value="Business Verification">Business Verification</option>
            <option value="Claimed Business">Claimed Business</option>
            <option value="Technical Issue">Technical Issue</option>
            <option value="Other">Other</option>
          </Field>
          <GradientBorder />
        </FieldsWrapper>
        <FieldsWrapper>
          <FieldLabel>Message</FieldLabel>
          <ErrorMessage
            name="message"
            render={(msg: string) => (
              <StyledErrorMessage style={{ marginBottom: 10 }}>
                {msg}
              </StyledErrorMessage>
            )}
          />
          <Field
            name="message"
            as="textarea"
            className="styled-textarea"
          ></Field>
          <GradientBorder />
        </FieldsWrapper>
        <SubmitButton
          type="submit"
          disabled={isSubmitting}
          active={!isSubmitting}
          style={{ width: "80%", alignSelf: "center", maxWidth: 500 }}
        >
          SUBMIT
        </SubmitButton>
      </FormContainer>
    </form>
  );
};

export default SupportForm;
