import { Elements } from "@stripe/react-stripe-js";
import { StripeElementsOptions } from "@stripe/stripe-js";
import { useQuery } from "react-query";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import BackButton from "../../components/back-button/back-button";

import BannerAdCheckoutForm from "../../components/banner-ad-checkout-form/banner-ad-checkout-form";
import BannerAdCheckoutReview from "../../components/banner-ad-checkout-review/banner-ad-checkout-review";
import { STRIPE_RETURN_DOMAIN } from "../../lib/api/axios-instance";
import { createBannerAdPaymentIntent } from "../../lib/api/stripe";
import { stripePromise } from "../../lib/stripe/stripe";
import {
  AdvertisementsPageTitle,
  Content,
  Wrapper,
} from "../../styled/advertisement-form-elements";
import { Loader } from "../../styled/loader";
import { Subtitle } from "../../styled/typography";
import { InfoText } from "../AdvertisementsMenu/styled";

interface CompleteBannerAdPaymentPageProps {}

const CompleteBannerAdPaymentPage = (
  props: CompleteBannerAdPaymentPageProps
) => {
  let [searchParams] = useSearchParams();
  const navigate = useNavigate();

  let objectId = searchParams.get("o");
  let planId = searchParams.get("i");

  const { data, isLoading, isError, error } = useQuery(
    [objectId, "/banner-ad/web/payment-intent", planId],
    () => createBannerAdPaymentIntent(objectId ?? "", planId ?? ""),
    {
      retry: false,
    }
  );

  if (!objectId || !planId) {
    if (objectId) {
      return (
        <Navigate
          to={`/account/advertisements/banner-advertisement/${objectId}`}
        />
      );
    } else {
      return <Navigate to="/account/advertisements/" />;
    }
  }

  if (isLoading) {
    return (
      <Content>
        <Wrapper>
          <Loader />
        </Wrapper>
      </Content>
    );
  }

  if (
    !data ||
    isError ||
    !data.clientSecret ||
    !data.paymentIntentAmount ||
    !data.plan.days
  ) {
    return (
      <Content>
        <BackButton
          handleBack={() =>
            navigate("/account/advertisements/banner-advertisement/" + objectId)
          }
        />
        <Wrapper>
          <AdvertisementsPageTitle>Payment Error</AdvertisementsPageTitle>
          <InfoText>
            {error && error instanceof Error
              ? `${error.message}`
              : "Could not communicate with servers at this time."}
          </InfoText>
        </Wrapper>
      </Content>
    );
  }

  const clientSecret = data;

  const options: StripeElementsOptions = {
    clientSecret: data.clientSecret,
    appearance: {
      theme: "stripe",
    },
  };

  return (
    <Content>
      <BackButton
        handleBack={() =>
          navigate("/account/advertisements/banner-advertisement/" + objectId)
        }
      />
      <Wrapper>
        <Subtitle>Summary</Subtitle>
        <BannerAdCheckoutReview
          paymentIntentAmount={data.paymentIntentAmount}
          planName={data.plan.planName}
          days={data.plan.days}
        />
        {clientSecret && (
          <Elements stripe={stripePromise} options={options}>
            <Subtitle style={{ marginTop: 60, marginBottom: 0 }}>
              Payment
            </Subtitle>
            <BannerAdCheckoutForm
              clientSecret={data.clientSecret}
              returnUrl={`${STRIPE_RETURN_DOMAIN}/account/advertisements/banner-advertisement/${objectId}`}
            />
          </Elements>
        )}
      </Wrapper>
    </Content>
  );
};

export default CompleteBannerAdPaymentPage;
