import styled from "styled-components";

export const CategorySectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const CategoryCircle = styled.div`
  width: 94px;
  flex: 0 0 94px;
  border-radius: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const CategoryLabel = styled.div`
  margin-top: 10px;
  font-weight: bold;
  text-align: center;
  font-size: 14px;
  color: #0b0b0b;
`;
