import styled from "styled-components";

export const TableContainer = styled.div`
  border: solid 1px #bbc3d1;
  max-height: 300px;
  overflow: scroll;
`;

export const Row = styled.div<{ selected: boolean }>`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  border-bottom: solid 1px #f2f2f2;
  justify-content: center;

  background: ${(props) => (props.selected ? "#ededed" : "white")};
`;

export const BusinessName = styled.div`
  margin-top: 10px;
  font-size: 16px;
  margin-left: 10px;
`;

export const BusinessAddress = styled.div`
  font-size: 14px;
  margin-left: 10px;
  margin-top: 2px;
  //   color: #c0c0c0;
  margin-bottom: 10px;
`;
