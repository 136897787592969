import { withFormik } from "formik";
import StepThreeForm, {
  StepThreeFormValues,
  stepThreeSchema,
} from "../components/step-three-form";

export interface StepThreeFormProps {
  disabled: boolean;
}

export const CreateStepThreeForm = ({
  handleSubmit,
  disabled,
}: {
  handleSubmit: (values: StepThreeFormValues) => void;
  disabled: boolean;
}) => {
  const Form = withFormik<StepThreeFormProps, StepThreeFormValues>({
    handleSubmit: (values) => {
      handleSubmit(values);
    },
    mapPropsToValues: () => ({
      unit: "",
      rank: "",
      department: "",
      frontPhoto: undefined,
      backPhoto: undefined,
    }),
    validationSchema: stepThreeSchema,
    validateOnBlur: false,
    validateOnChange: false,
    validateOnMount: false,
  })(StepThreeForm);

  return <Form disabled={disabled} />;
};
