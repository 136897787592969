import styled from "styled-components";

export const ViewBulletItemPageContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  max-width: 600px;
  align-self: center;
  display: flex;
  flex-direction: column;

  margin: 40px 20px;
`;

export const ImageSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

export const Title = styled.div`
  color: #2d3e64;
  font-size: 20px;
  margin: 20px 30px 5px 30px;
`;

export const Cost = styled.div`
  color: #a5a5a5;
  font-size: 16px;
  margin: 5px 30px 10px 30px;
`;

export const Description = styled.div`
  margin: 10px 30px 5px 30px;
`;

export const RedeemButton = styled.button<{ active: boolean }>`
  width: 100%;
  max-width: 250px;
  height: 50px;

  align-self: center;
  margin-top: 40px;
  border: none;
  border-radius: 4px;
  color: white;
  background: ${(props) => (props.active ? "#405173" : "#6B6B6B")};
`;
