import { ErrorMessage, Field, FormikProps } from "formik";
import React from "react";
import {
  FieldsWrapper,
  FormContainer,
  FormLabel,
  SubmitButton,
} from "../../register/styled/global-styled";
import { ForgotPasswordFormProps } from "../util/create-forgotpassword-form";

import * as Yup from "yup";

export interface ForgotPasswordFormValues {
  email: string;
}

export const forgotPasswordSchema: Yup.SchemaOf<ForgotPasswordFormValues> = Yup.object(
  {
    email: Yup.string()
      .email("Please enter a valid email")
      .required("Please enter your email"),
  }
);

const ForgotPasswordForm = (
  props: ForgotPasswordFormProps & FormikProps<ForgotPasswordFormValues>
) => {
  const { handleSubmit, values, isSubmitting } = props;

  let canSubmit = false;
  try {
    forgotPasswordSchema.validateSync(values);
    canSubmit = true;
  } catch (e) {
    canSubmit = false;
  }

  return (
    <form onSubmit={handleSubmit}>
      <FormContainer>
        <FieldsWrapper>
          <FormLabel htmlFor="email">Email Address</FormLabel>
          <ErrorMessage
            name="email"
            className="error-message"
            component="span"
          />
          <Field name="email" type="email" />
        </FieldsWrapper>
        <SubmitButton
          type="submit"
          disabled={!canSubmit || isSubmitting}
          active={canSubmit && !isSubmitting}
        >
          SEND EMAIL
        </SubmitButton>
        {props.submitted ? (
          <p style={{ textAlign: "center" }}>
            An email has been sent to you. Please follow the instructions to
            reset your password.
          </p>
        ) : null}
      </FormContainer>
    </form>
  );
};

export default ForgotPasswordForm;
