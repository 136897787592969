import styled from "styled-components";
import { mobileMax } from "../../lib/media-breakpoints";

export const RedeemBulletItemFormContainer = styled.div``;

export const StyledField = styled.input.attrs((props) => ({}))`
  margin: 0;
  border: none;
  height: 20px;
  background: none;
  border: none;

  font-size: 14px;

  :focus {
    outline: none;
  }
`;

export const FieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 30px;

  @media (max-width: ${mobileMax}px) {
    margin-left: 25px;
    margin-right: 25px;
  }

  @media (min-width: ${mobileMax + 1}px) {
    width: 500px;
  }

  span.error-message {
    color: red;
    font-weight: 700;
    margin-bottom: 7px;

    @media (max-width: ${mobileMax}px) {
      font-size: 14px;
    }
    @media (min-width: ${mobileMax + 1}px) {
      font-size: 16px;
    }
  }
`;
