import { IncidentReport } from "../../../ts/point-of-interest";
import BadgesTable from "../../badges-table/badges-table";
import { BusinessBadgesContainer } from "../styled/business-badges";

export interface BusinessBadgesProps {
  reports: IncidentReport[];
}

const BusinessBadges = (props: BusinessBadgesProps) => {
  return (
    <BusinessBadgesContainer>
      <BadgesTable reports={props.reports} />
    </BusinessBadgesContainer>
  );
};

export default BusinessBadges;
