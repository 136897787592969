import styled from "styled-components";

export const BadgeRowContainer = styled.div`
  height: 100px;
  display: flex;
  align-items: center;
`;

export const LeftSection = styled.div``;

export const RightSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  margin-right: 5px;
`;

export const PolicyLabel = styled.div`
  color: #c4c4c3;
  font-size: 14px;
`;

export const PostedByLabel = styled.div`
  color: #c4c4c3;
  font-size: 14px;
  margin-top: 5px;
`;

export const BadgeDisplayWrapper = styled.div`
  height: 64px;
  width: 64px;
  margin-left: 20px;
`;
