import { ErrorMessage, Field, FieldProps, FormikProps } from "formik";
import {
  AdvertisementSubmitButton,
  AdvertisementTextField,
} from "../../styled/advertisement-form-elements";
import { Content, SectionTitle } from "./styled";

import * as Yup from "yup";
import { StyledErrorMessage } from "../../styled/form-elements";
import { InfoText } from "../../pages/AdvertisementsMenu/styled";

export interface BannerAdDetailsUpdateProps {
  loading: boolean;
}

export interface BannerAdDetailsUpdateValues {
  websiteLink: string;
}

export const BannerAdDetailsUpdateSchema = Yup.object({
  websiteLink: Yup.string().required("Please enter a website link."),
});

const BannerAdDetailsUpdate = (
  props: BannerAdDetailsUpdateProps & FormikProps<BannerAdDetailsUpdateValues>
) => {
  const { handleSubmit } = props;

  return (
    <form onSubmit={handleSubmit}>
      <Content>
        <SectionTitle>Update Website Link</SectionTitle>
        <InfoText style={{ marginBottom: 10 }}>
          *Advertisement will not be displayed until information is reviewed by
          USA PACS.
        </InfoText>
        <ErrorMessage name="websiteLink">
          {(msg: string) => <StyledErrorMessage>{msg}</StyledErrorMessage>}
        </ErrorMessage>
        <Field name="websiteLink">
          {({ field }: FieldProps) => (
            <AdvertisementTextField type="text" {...field} />
          )}
        </Field>

        <AdvertisementSubmitButton
          type="submit"
          disabled={props.loading === true}
          isDisabled={props.loading === true}
        >
          SAVE CHANGES
        </AdvertisementSubmitButton>
      </Content>
    </form>
  );
};

export default BannerAdDetailsUpdate;
