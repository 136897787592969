import { useState } from "react";
import {
  PlanName,
  PlanCost,
  Container,
  Row,
  TableContainer,
  RowContent,
} from "./style";

import { BannerAdPlan } from "../../ts/banner-advertisement-plan";
import { usdFormatter } from "../../lib/formatter/usd-formatter";

interface AdvertisementPlansTableProps {
  plans: BannerAdPlan[];
  onClick: (id: string) => void;
}

const AdvertisementPlansTable = (props: AdvertisementPlansTableProps) => {
  const [selected, setSelected] = useState<number | undefined>(undefined);

  return (
    <Container>
      <TableContainer>
        {props.plans.map((plan, index) => (
          <Row
            key={plan.id}
            selected={selected === index}
            onClick={() => {
              props.onClick(plan.uid);
              setSelected(index);
            }}
          >
            <RowContent>
              <PlanName selected={selected === index}>{plan.planName}</PlanName>
              <PlanCost>{usdFormatter.format(plan.cost / 100)}</PlanCost>
            </RowContent>
          </Row>
        ))}
      </TableContainer>
    </Container>
  );
};

export default AdvertisementPlansTable;
