import React from "react";
import MenuSection from "../../../components/menu/components/menu-section";
import { MenusSection } from "../styled/main-menu-page-styled";

const MenuItemsSection = () => {
  return (
    <MenusSection>
      <MenuSection
        title={"NEWS & ARTICLES"}
        items={[
          {
            label: "Current Events",
            destination: "/menu/current-events",
          },
          {
            label: "Categories",
            destination: "/menu/categories",
          },
          {
            label: "Bookmarks",
            destination: "/menu/bookmarks",
          },
        ]}
      />
      <MenuSection
        title={"INFORMATION"}
        items={[
          {
            label: "LEOSA Bill",
            destination: "/menu/leosa-bill",
          },
          {
            label: "Map Reciprocity",
            isExternal: true,
            destination:
              "https://www.usconcealedcarry.com/resources/ccw_reciprocity_map/",
          },
          {
            label: "View USAPACS Sponsors/Advertisers",
            destination: "/menu/view-sponsors",
          },
          {
            label: "How to Add to Home Screen",
            destination: "/menu/home-screen",
          },
          {
            label: "How to Enable Location Services",
            destination: "/menu/location-help",
          },
        ]}
      />

      <MenuSection
        title={"BUILD AMMUNITION"}
        items={[
          {
            label: "Your Bullets, Invite Friends, Redeem",
            destination: "/menu/ammunition",
          },
        ]}
      />
      <MenuSection
        title={"ABOUT USA PACS"}
        items={[
          {
            label: "About the App",
            destination: "/menu/about-the-app",
          },
          {
            label: "How to Use The App",
            destination: "/menu/how-to-use-the-app",
          },
          {
            label: "Support",
            destination: "/menu/support",
          },
          {
            label: "Terms",
            destination: "/menu/terms",
          },
          {
            label: "Privacy Policy",
            destination: "/menu/privacy",
          },
        ]}
      />
    </MenusSection>
  );
};

export default MenuItemsSection;
