import React from "react";
import {
  CardRowContainer,
  SubtitleLabel,
  TitleLabel,
} from "../styled/card-row-styled";

export interface CardProps {
  title: string;
  subtitle: string;
  onClick: (data: any) => void;
}

const CardRow = (props: CardProps) => {
  return (
    <CardRowContainer onClick={props.onClick}>
      <TitleLabel>{props.title}</TitleLabel>
      <SubtitleLabel>{props.subtitle}</SubtitleLabel>
    </CardRowContainer>
  );
};

export default CardRow;
