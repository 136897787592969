import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import ImageHandler from "../../components/image-handler/image-handler";
import Login from "../../components/login/login";
import { LogoContainer } from "../../components/register/styled/register-styled";
import { LoginContainer } from "./styled/login-styled";

const LoginPage = () => {
  const navigate = useNavigate();

  return (
    <LoginContainer>
      <LogoContainer>
        <div
          style={{ marginTop: "auto", cursor: "pointer" }}
          onClick={() => navigate("/register")}
        >
          <ImageHandler name="logo" />
        </div>
      </LogoContainer>
      <Login />
      <Link
        to="/forgot-password"
        style={{ textDecoration: "none", color: "black", fontSize: 16 }}
      >
        Forgot Password?
      </Link>
    </LoginContainer>
  );
};

export default LoginPage;
