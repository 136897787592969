import { useMutation, useQueryClient } from "react-query";
import { resumeBannerAd } from "../../../lib/api/banner-ad";
import { BannerAdvertisement } from "../../../ts/banner-advertisement";

export const useResumeMutation = (uid?: string) => {
  const client = useQueryClient();

  const mutation = useMutation(
    ["/banner-ad/resume", uid],
    (values: { id: number }) => resumeBannerAd(values.id),
    {
      onSuccess: (data) => {
        client.setQueryData<BannerAdvertisement | undefined>(
          ["/banner-advertisement", uid],
          (old) => {
            if (old) {
              old.status = data.status;
            }

            return old;
          }
        );
      },
    }
  );

  return mutation;
};
