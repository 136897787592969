import styled from "styled-components";

export const BulletItemRowContainer = styled.div`
  display: flex;
  cursor: pointer;
`;

export const LeftSide = styled.div`
  flex: 0 0 100px;
  height: 100px;
`;

export const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const RightSide = styled.div`
  flex: 1;
  height: 100px;

  display: flex;
  flex-direction: column;
  justify-content: center;

  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ItemTitle = styled.div`
  color: #53617f;
  font-size: 16px;
  margin-bottom: 10px;
  margin-left: 10px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const BulletAmount = styled.div`
  color: #9e9e9e;
  margin-left: 10px;
`;
