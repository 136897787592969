import EmailVerified from "./email-verified";
import VerifyingEmail from "./verifying-email";

export interface EmailConfirmationDisplayProps {
  verified: boolean;
}

const EmailConfirmationDisplay = ({
  verified,
}: EmailConfirmationDisplayProps) => {
  return verified === true ? <EmailVerified /> : <VerifyingEmail />;
};

export default EmailConfirmationDisplay;
