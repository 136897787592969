import { useRef } from "react";
import { BusinessPhoto } from "../../ts/business-photo";
import {
  ActionButton,
  BusinessPhotoDisplay,
  BusinessPhotoManagerContainer,
  Content,
  FileInput,
  Inner,
  PhotoWrapper,
  SelectedBox,
  TopBar,
} from "./styled";

interface BusinessPhotoManagerProps {
  businessPhotos: BusinessPhoto[];
  selectedId?: number;
  handleClick: (id: number) => void;
  handleUploadPhoto: (image: File) => void;
  handleDelete?: () => void;
  handleSetPrimary?: () => void;
  isLoading?: boolean;
  isError?: boolean;
}

export const MAX_PHOTOS = 5;

const BusinessPhotoManager = (props: BusinessPhotoManagerProps) => {
  const inputRef = useRef() as React.MutableRefObject<HTMLInputElement>;

  if (props.isError === true) {
    return (
      <BusinessPhotoManagerContainer>
        <Content>
          <p style={{ margin: 20, textAlign: "center" }}>
            An error occurred. Please try again later.
          </p>
        </Content>
      </BusinessPhotoManagerContainer>
    );
  }

  return (
    <BusinessPhotoManagerContainer>
      <Content>
        <TopBar>
          <ActionButton
            inActive={
              props.selectedId === undefined || props.isLoading === true
            }
            disabled={
              props.selectedId === undefined || props.isLoading === true
            }
            onClick={() => {
              if (props.handleSetPrimary) {
                props.handleSetPrimary();
              }
            }}
          >
            Set as Primary
          </ActionButton>
          <ActionButton
            inActive={
              props.selectedId === undefined || props.isLoading === true
            }
            disabled={
              props.selectedId === undefined || props.isLoading === true
            }
            onClick={() => {
              if (props.handleDelete) {
                props.handleDelete();
              }
            }}
          >
            Delete
          </ActionButton>
        </TopBar>

        <Inner>
          {props.businessPhotos.map((photo) => (
            <PhotoWrapper
              key={photo.id}
              onClick={() => props.handleClick(photo.id)}
            >
              <SelectedBox
                readOnly={true}
                type="checkbox"
                checked={props.selectedId === photo.id}
              />
              <BusinessPhotoDisplay src={photo.imageUrl} />
            </PhotoWrapper>
          ))}
          {props.businessPhotos.length < MAX_PHOTOS ? (
            <FileInput
              accept="image/png,image/jepg,image/jpg"
              ref={inputRef}
              disabled={props.isLoading}
              text="+"
              onChange={(e) => {
                if (props.isLoading !== true && e.target && e.target.files[0]) {
                  const file = e.target.files[0];
                  props.handleUploadPhoto(file);
                  inputRef.current.value = "";
                }
              }}
            />
          ) : null}
        </Inner>
      </Content>
    </BusinessPhotoManagerContainer>
  );
};

export default BusinessPhotoManager;
