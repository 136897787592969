import { useNavigate, useSearchParams } from "react-router-dom";
import BackButton from "../../components/back-button/back-button";
import { Title } from "../../styled/typography";
import { Content, StepImage, StepSection, StepTitle, Wrapper } from "./styled";

import StepOneImage from "./images/step-1.png";
import StepTwoImage from "./images/step-2.png";
import StepThreeImage from "./images/step-3.png";

interface HomeScreenProps {}

const HomeScreen = (props: HomeScreenProps) => {
  const [searchParams] = useSearchParams();

  const back = searchParams.get("b");

  const navigate = useNavigate();

  return (
    <Content>
      <BackButton
        handleBack={() => {
          if (back === "home") {
            navigate("/home");
          } else if (back === "location") {
            navigate("/menu/location-help?b=" + back);
          } else {
            navigate("/menu");
          }
        }}
      />
      <Wrapper>
        <Title>How to Add to Home Screen</Title>

        <StepSection style={{ marginTop: 0 }}>
          <StepTitle>
            1. Tap the Bookmarks icon located at the bottom.
          </StepTitle>
          <StepImage src={StepOneImage} width={300} height="auto" />
        </StepSection>

        <StepSection>
          <StepTitle>2. Tap the “Add to Home Screen” option.</StepTitle>
          <StepImage src={StepTwoImage} width={300} height="auto" />
        </StepSection>

        <StepSection>
          <StepTitle>
            3. USA PACS will now be available to open on your Home Screen.
          </StepTitle>
          <StepImage src={StepThreeImage} width={300} height="auto" />
        </StepSection>
      </Wrapper>
    </Content>
  );
};

export default HomeScreen;
