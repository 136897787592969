import styled from "styled-components";

export const LoadingOverlay = styled.div`
  background: #f7f7f799;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  z-index: 500;
`;
