import styled from "styled-components";
import { mobileMax } from "../../../lib/media-breakpoints";

export const PendingClaimsPageContainer = styled.div`
  @media (max-width: ${mobileMax}px) {
    width: 100vw;
  }
`;

export const ContentWrapper = styled.div`
  margin: 67px 60px 30px 60px;

  @media (max-width: ${mobileMax}px) {
    margin: 65px 15px;
  }
`;
