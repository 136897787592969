import React from "react";
import { useSearchParams } from "react-router-dom";
import AuthPageSwitch from "./components/AuthPageSwitch";
import { AuthPageContainer } from "./styled/auth-page-styled";

const AuthPage = () => {
  const [searchParams] = useSearchParams();

  const mode = searchParams.get("mode");
  const code = searchParams.get("oobCode");

  return (
    <AuthPageContainer>
      <AuthPageSwitch mode={mode} oobCode={code} />
    </AuthPageContainer>
  );
};

export default AuthPage;
