import metalDetectorPositive from "../../../images/badges/metalDetectorPolicy/metalDetectorPolicy_black.png";

const MetalDetectorPositive = ({ imageProps }: any) => {
  return (
    <img
      src={metalDetectorPositive}
      alt="metal-detector-policy-positive"
      {...imageProps}
    />
  );
};

export default MetalDetectorPositive;
