import { useNavigate } from "react-router-dom";
import BackButton from "../../components/back-button/back-button";
import UpdateEmailFormContext from "../../components/update-email-form/update-email-form-container";
import {
  Content,
  UpdateEmailPageContainer,
} from "./styled/update-email-page-styled";

interface UpdateEmailPageProps {}

const UpdateEmailPage = (props: UpdateEmailPageProps) => {
  const navigate = useNavigate();

  return (
    <UpdateEmailPageContainer>
      <BackButton handleBack={() => navigate("/account/edit")} />
      <Content>
        <UpdateEmailFormContext initialEmail="" />
      </Content>
    </UpdateEmailPageContainer>
  );
};

export default UpdateEmailPage;
