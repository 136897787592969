import styled from "styled-components";

export const ReferralContainer = styled.div``;

export const BulletsLabel = styled.p`
  color: #244169;
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 24px;
`;
