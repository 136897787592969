import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { useState, useEffect } from "react";
import { STRIPE_RETURN_DOMAIN } from "../../lib/api/axios-instance";
import { AdvertisementsPageTitle } from "../../styled/advertisement-form-elements";
import { SecondaryButton } from "../../styled/buttons";
import { Loader } from "../../styled/loader";

interface BannerAdCheckoutFormProps {
  clientSecret: string;
  returnUrl?: string;
}

const BannerAdCheckoutForm = (props: BannerAdCheckoutFormProps) => {
  const stripe = useStripe();
  const elements = useElements();

  const [mounted, setMounted] = useState<boolean>(false);
  const [message, setMessage] = useState<String | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      if (paymentIntent && paymentIntent.status) {
        switch (paymentIntent.status) {
          case "succeeded":
            setMessage("Payment succeeded!");
            break;
          case "processing":
            setMessage("Your payment is processing.");
            break;
          case "requires_payment_method":
            setMessage("Your payment was not successful, please try again.");
            break;
          default:
            setMessage("Something went wrong.");
            break;
        }
      }
    });
  }, [stripe]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url:
          props.returnUrl ?? `${STRIPE_RETURN_DOMAIN}/account/advertisements`,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occured.");
    }

    setIsLoading(false);
  };

  return (
    <form
      id="payment-form"
      onSubmit={handleSubmit}
      style={{ marginTop: 20, marginBottom: 40 }}
    >
      <div style={{ minHeight: 180, display: mounted ? "block" : "hidden" }}>
        {!mounted ? <Loader /> : null}
        <PaymentElement
          id="payment-element"
          onReady={() => {
            setMounted(true);
          }}
        />
      </div>
      <SecondaryButton
        disabled={isLoading || !stripe || !elements}
        id="submit"
        style={{ marginTop: 20 }}
      >
        <span style={{ color: isLoading ? "grey" : "white" }} id="button-text">
          {isLoading ? "Processing" : "Pay now"}
        </span>
      </SecondaryButton>
      {/* Show any error or success messages */}
      {message && (
        <div style={{ marginTop: 20 }} id="payment-message">
          {message}
        </div>
      )}
    </form>
  );
};

export default BannerAdCheckoutForm;
