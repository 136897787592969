import styled from "styled-components";

export const Container = styled.div``;

export const SponsorRow = styled.div`
  margin: 30px 0 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
`;

export const SponsorImage = styled.img`
  height: 100px;
  max-width: 400px;
  width: 100%;
`;

export const SponsorLabel = styled.div`
  font-size: 16px;
  margin-top: 10px;
  margin-left: 20px;
  font-weight: 400;
  color: #1b3963;
`;
