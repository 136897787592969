import styled from "styled-components";
import { mobileMax } from "../../../lib/media-breakpoints";

export const ArticleCategoryTableContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: evenly-spaced;
`;

export const CardsWrapper = styled.div``;

export const CardWrapper = styled.div`
  flex: 1 1 25%;
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  @media (max-width: ${mobileMax}px) {
    flex: 1 1 50%;
  }
`;
