import React from "react";
import { Link } from "react-router-dom";
import {
  MenuItemRowContainer,
  MenuItemLabel,
  MenuDivider,
} from "../styled/menu-item-styled";
import { MenuItem } from "../ts/menu";

const MenuItemRow = ({
  item,
  index,
  isLastItem,
}: {
  item: MenuItem;
  index: number;
  isLastItem: boolean;
}) => {
  return (
    <MenuItemRowContainer>
      {item.isExternal ? (
        <a
          target={"_blank"}
          rel="noreferrer"
          style={{ textDecoration: "none", height: "100%" }}
          href={item.destination}
        >
          <MenuItemLabel style={{ marginTop: index === 0 ? 13 : 10 }}>
            {item.label}
          </MenuItemLabel>
        </a>
      ) : (
        <Link
          to={{ pathname: item.destination }}
          style={{ textDecoration: "none", height: "100%" }}
        >
          <MenuItemLabel style={{ marginTop: index === 0 ? 13 : 10 }}>
            {item.label}
          </MenuItemLabel>
        </Link>
      )}
      {isLastItem === false ? <MenuDivider /> : null}
    </MenuItemRowContainer>
  );
};

export default MenuItemRow;
