import { useNavigate } from "react-router-dom";
import BackButton from "../../components/back-button/back-button";
import { Title } from "../../styled/typography";
import { Content, Wrapper } from "./styled";

interface RegisterBannerAdInterestPageProps {}

const RegisterBannerAdInterestPage = (
  props: RegisterBannerAdInterestPageProps
) => {
  const navigate = useNavigate();

  return (
    <Wrapper>
      <BackButton handleBack={() => navigate("/account/advertisements")} />
      <Content>
        <Title>Register Interest for Banner Advertisements</Title>
        <iframe
          src="/register-interest.html"
          title="register-interest"
          style={{ width: "100%", height: "100%", border: "none" }}
        />
      </Content>
    </Wrapper>
  );
};

export default RegisterBannerAdInterestPage;
