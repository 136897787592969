import { NewBannerAdvertisementPageState } from "../NewBannerAdvertisementPage";
import AdvertisementsMenuPageStepOne from "./step-one/step-one";

const NewBannerAdvertisementPageSwitch = (
  props: NewBannerAdvertisementPageState
) => {
  if (props.step === 1) {
    return <AdvertisementsMenuPageStepOne />;
  }

  return null;
};

export default NewBannerAdvertisementPageSwitch;
