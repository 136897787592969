import styled from "styled-components";
import { mobileMax } from "../../lib/media-breakpoints";

export const Content = styled.div`
  @media (min-width: ${mobileMax + 1}px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const Wrapper = styled.div`
  margin: 40px 20px;

  @media (min-width: ${mobileMax + 1}px) {
    max-width: 600px;
  }
`;

export const Text = styled.div`
  margin: 10px 0 50px 0;
  line-height: 1.4;
`;
