import React from "react";
import { useContext } from "react";
import { useMediaQuery } from "react-responsive";
import IconHandler from "../../../components/icon-handler/icon-handler";
import ResultsTable from "../../../components/results-table/results-table";
import { mobileMax } from "../../../lib/media-breakpoints";
import { GradientBorder } from "../../../styled/gradient-border";
import { Loader } from "../../../styled/loader";
import { PointOfInterest } from "../../../ts/point-of-interest";
import {
  ResultsClearButton,
  ResultsFoundDisplay,
  ResultsTableMobileHeaderContainer,
  ResultsTableQueryDisplay,
} from "../styled/results-table-mobile-header";
import { LoaderContainer } from "../styled/results-view-styled";
import { ResultsContainer } from "../styled/search-view-styled";
import { QueryValueContext } from "../util/contexts";

export interface ResultsViewProps {
  isLoading: boolean;
  results: PointOfInterest[];
  empty?: boolean;
  handleResultsClear: () => void;
}

const ResultsView = (props: ResultsViewProps) => {
  const { queryInfo } = useContext(QueryValueContext);
  const isMobile = useMediaQuery({ query: `(max-width: ${mobileMax}px)` });

  if (props.isLoading) {
    return (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    );
  }

  if (props.results.length > 0) {
    return (
      <ResultsContainer>
        {isMobile && queryInfo ? (
          <ResultsTableMobileHeader
            query={queryInfo?.displayValue}
            handleBack={() => props.handleResultsClear()}
            resultsFound={props.results.length}
          />
        ) : null}
        <ResultsTable pointsOfInterest={props.results} />
        {props.results.length === 0 ? (
          <p style={{ margin: 20 }}>No results</p>
        ) : null}
        {props.empty ? <p style={{ margin: 20 }}>No Results</p> : null}
      </ResultsContainer>
    );
  }

  if (props.empty) {
    return (
      <ResultsContainer>
        {isMobile && queryInfo ? (
          <ResultsTableMobileHeader
            query={queryInfo?.displayValue}
            handleBack={() => props.handleResultsClear()}
            resultsFound={props.results.length}
          />
        ) : null}
        <p style={{ marginLeft: 20 }}>No Results.</p>
      </ResultsContainer>
    );
  }

  return (
    <ResultsContainer>
      {isMobile && queryInfo ? (
        <ResultsTableMobileHeader
          query={queryInfo?.displayValue}
          handleBack={() => props.handleResultsClear()}
          resultsFound={props.results.length}
        />
      ) : null}
      <ResultsTable pointsOfInterest={props.results} />
    </ResultsContainer>
  );
};

interface ResultsTableMobileHeaderProps {
  query: string;
  handleBack: () => void;
  resultsFound: number;
}

const ResultsTableMobileHeader = (props: ResultsTableMobileHeaderProps) => {
  return (
    <ResultsTableMobileHeaderContainer>
      <ResultsTableQueryDisplay>{props.query}</ResultsTableQueryDisplay>
      <ResultsFoundDisplay>{props.resultsFound} Found</ResultsFoundDisplay>
      <ResultsClearButton onClick={() => props.handleBack()}>
        <IconHandler name="closeIcon" iconProps={{}} />
      </ResultsClearButton>
      <GradientBorder style={{ height: 3 }} />
    </ResultsTableMobileHeaderContainer>
  );
};

export default ResultsView;
