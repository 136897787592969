import styled from "styled-components";
import { mobileMax } from "../lib/media-breakpoints";

export const MobileOnly = styled.span`
  width: 100%;
  height: 100%;
  display: inline-block;
  @media (min-width: ${mobileMax + 1}px) {
    display: none;
  }
`;

export const DesktopOnly = styled.span`
  width: 100%;
  height: 100%;
  display: inline-block;
  @media (max-width: ${mobileMax}px) {
    display: none;
  }
`;
