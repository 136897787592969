import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import BackButton from "../../components/back-button/back-button";
import { SupportFormValues } from "../../components/support-form/support-form";
import SupportFormContainer from "../../components/support-form/support-form-container";
import { SubmitSupportForm } from "../../lib/api/user";
import { Title } from "../../styled/typography";
import { SupportPageContainer, Content } from "./styled/support-page-styled";

interface SupportPageProps {}

const SupportPage = (props: SupportPageProps) => {
  const navigate = useNavigate();

  const {
    mutate,
    isLoading,
    isError,
    error,
    data,
  } = useMutation("/usapacs/support-form", (values: SupportFormValues) =>
    SubmitSupportForm(values)
  );

  const handleSubmit = (values: SupportFormValues) => {
    mutate(values);

    return true;
  };

  return (
    <SupportPageContainer>
      <BackButton handleBack={() => navigate("/menu")} />
      <Content>
        <SupportFormContainer
          onSubmit={(values: SupportFormValues) => handleSubmit(values)}
        />
        {data === true ? (
          <p>Thanks for your submission. USA PACS will be in touch shortly.</p>
        ) : null}
        {isError === true ? (
          <p>
            Something went wrong while submitting your form. Please try again
            later.
          </p>
        ) : null}
      </Content>
    </SupportPageContainer>
  );
};

export default SupportPage;
