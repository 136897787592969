import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import BackButton from "../../components/back-button/back-button";
import { EditProfileFormValues } from "../../components/edit-profile-form/components/form-body";
import EditProfileForm from "../../components/edit-profile-form/edit-profile-form";
import { getUserDetails, updateUserDetails } from "../../lib/api/user";
import { SecondaryButton } from "../../styled/buttons";
import { Loader } from "../../styled/loader";
import { ErrorIcon, ErrorIconContainer } from "../../styled/status-icons";
import { Content, EditProfileContainer } from "./styled/edit-profile-container";

export interface EditProfilePageProps {}

const EditProfilePage = (props: EditProfilePageProps) => {
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const { data, isLoading, isError } = useQuery("/me", () => getUserDetails());

  const mutate = useMutation(
    "/me",
    (values: EditProfileFormValues) =>
      updateUserDetails(values.firstName, values.lastName),
    {
      onSuccess: (data) => {
        queryClient.setQueryData("/me", () => data);
        navigate("/account/edit");
      },
    }
  );

  const handleSubmit = async (values: EditProfileFormValues) => {
    mutate.mutate(values);
  };

  if (isLoading || mutate.isLoading) {
    return <Loader />;
  }

  if (isError || !data) {
    return (
      <ErrorIconContainer style={{ color: "red" }}>
        <ErrorIcon>!</ErrorIcon>
        <p style={{ marginLeft: 15 }}>
          Could not get details at this time. Please try again later.
        </p>
      </ErrorIconContainer>
    );
  }

  if (mutate.isError) {
    return (
      <ErrorIconContainer style={{ flexDirection: "column" }}>
        <div style={{ display: "flex", alignItems: "center", color: "red" }}>
          <ErrorIcon>!</ErrorIcon>
          <p style={{ marginLeft: 20, color: "red" }}>
            Could not update profile at this time. Please try again later.
          </p>
        </div>
        <SecondaryButton onClick={() => mutate.reset()}>BACK</SecondaryButton>
      </ErrorIconContainer>
    );
  }

  return (
    <EditProfileContainer>
      <BackButton handleBack={() => navigate("/account/edit")} />
      <Content>
        <EditProfileForm
          firstName={data.firstName}
          lastName={data.lastName}
          onSubmit={handleSubmit}
        />
      </Content>
    </EditProfileContainer>
  );
};

export default EditProfilePage;
