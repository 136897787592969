import { createContext } from "react";

import { PointOfInterest } from "../../../ts/point-of-interest";
import { VIEW_BUSINESS_STATE } from "../view-business";
import BusinessBadges from "./business-badges";
import BusinessDetails from "./business-details";
import BusinessPostBadge from "./business-post-badge";
import ViewOffer from "./view-offer";

export interface ViewBusinessSwitchProps {
  state: VIEW_BUSINESS_STATE;
  pointOfInterest: PointOfInterest;
  businessOffer?: number;
  handleClose?: () => void;
  setState: React.Dispatch<
    React.SetStateAction<{
      step: VIEW_BUSINESS_STATE;
      businessOffer: number | undefined;
    }>
  >;
  onPostBadgeSuccess: (newBadgeAlignments: any) => void;
}

export const BusinessContext =
  createContext<PointOfInterest | undefined>(undefined);

const ViewBusinessSwitch = ({
  state,
  businessOffer,
  pointOfInterest,
  setState,
  onPostBadgeSuccess,
}: ViewBusinessSwitchProps) => {
  const handlePostBadgeSuccess = (newBadgeAlignments: any) => {
    setState((old) => ({ ...old, step: VIEW_BUSINESS_STATE.VIEW_DETAILS }));
    onPostBadgeSuccess(newBadgeAlignments);
  };

  switch (state) {
    case VIEW_BUSINESS_STATE.VIEW_DETAILS:
      return (
        <BusinessContext.Provider value={pointOfInterest}>
          <BusinessDetails
            onBusinessOfferClick={(index: number) => {
              setState((old) => ({
                step: VIEW_BUSINESS_STATE.VIEW_OFFER,
                businessOffer: index,
              }));
            }}
            onPostedBadgesClick={() => {
              setState((old) => ({
                ...old,
                step: VIEW_BUSINESS_STATE.VIEW_BADGES,
              }));
            }}
            onPostBadgeButtonClick={() => {
              setState((old) => ({
                ...old,
                step: VIEW_BUSINESS_STATE.POST_BADGE,
              }));
            }}
          />
        </BusinessContext.Provider>
      );
    case VIEW_BUSINESS_STATE.VIEW_BADGES:
      return <BusinessBadges reports={pointOfInterest.incidents} />;
    case VIEW_BUSINESS_STATE.POST_BADGE:
      return (
        <BusinessPostBadge
          onSuccess={handlePostBadgeSuccess}
          placeId={pointOfInterest.placeId}
        />
      );
    case VIEW_BUSINESS_STATE.VIEW_OFFER:
      return pointOfInterest.businessOffers &&
        businessOffer !== undefined &&
        pointOfInterest.businessOffers[businessOffer] ? (
        <ViewOffer offer={pointOfInterest.businessOffers[businessOffer]} />
      ) : (
        <p>Could not load offer</p>
      );
    default:
      return null;
  }
};

export default ViewBusinessSwitch;
