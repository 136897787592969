import { useQuery } from "react-query";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import BackButton from "../../components/back-button/back-button";

import { getUserDetails } from "../../lib/api/user";
import { Title } from "../../styled/typography";
import AdvertisementsMenuPage from "../AdvertisementsMenu/AdvertisementsMenuPage";
import CompleteBannerAdPaymentPage from "../CompleteBannerAdPayment/CompleteBannerAdPaymentPage";
import CompleteSpecialOfferPaymentPage from "../CompleteSpecialOfferPayment/CompleteSpecialOfferPaymentPage";
import EditProfilePage from "../EditProfile/EditProfile";
import HowAdvertisingWorksPage from "../HowAdvertisingWorks/HowAdvertisingWorksPage";
import { RightSideWrapper } from "../MainMenuPage/styled/main-menu-page-styled";
import NewBannerAdvertisementPage from "../NewBannerAdvertisement/NewBannerAdvertisementPage";
import NewSpecialOfferPage from "../NewSpecialOffer/NewSpecialOfferPage";
import PendingClaimsPage from "../PendingClaims/PendingClaims";
import RegisterBannerAdInterestPage from "../RegisterBannerAdInterest/RegisterBannerAdInterestPage";
import UpdateBannerAdPage from "../UpdateBannerAd/UpdateBannerAdPage";
import UpdateEmailPage from "../UpdateEmailPage/UpdateEmailPage";
import UpdatePasswordPage from "../UpdatePassword/UpdatePasswordPage";
import UpdateProfileMenuPage from "../UpdateProfileMenu/UpdateProfileMenuPage";
import UpdateSpecialOfferPage from "../UpdateSpecialOffer/UpdateSpecialOffer";
import UserBusinessController from "../UserBusinessController/UserBusinessController";
import UserBusinessesPage from "../UserBusinesses/UserBusinessesPage";
import VerifyLeosaPage from "../VerifyLeosa/VerifyLeosaPage";
import AccountMenuItems from "./components/account-menu-items";

import {
  AccountMenuPageContainer,
  TitleSection,
  TitleSectionLabel,
} from "./styled/account-menu-styled";

const AccountMenuPage = () => {
  const navigate = useNavigate();
  const { data, isError } = useQuery("/me/details", getUserDetails);

  if (isError) {
    return <span>Error. Could not communicate with servers at this time.</span>;
  }

  let userType = "";

  if (data?.accountTypeId === 0) {
    userType = "Citizen";
  } else if (data?.accountTypeId === 1) {
    userType = "Pending LEOSA Verification";
  } else if (data?.accountTypeId === 2) {
    userType = "LEOSA Verified";
  } else {
    userType = "Citizen";
  }

  return (
    <AccountMenuPageContainer>
      <TitleSection>
        <Routes>
          <Route
            index
            element={<TitleSectionLabel>Account</TitleSectionLabel>}
          />
          <Route
            path="/businesses"
            element={<TitleSectionLabel>Businesses</TitleSectionLabel>}
          />
          <Route
            path="/claims"
            element={<TitleSectionLabel>Claims</TitleSectionLabel>}
          />
          <Route
            path="/edit"
            element={<TitleSectionLabel>Update Profile</TitleSectionLabel>}
          />
          <Route
            path="/update-profile"
            element={<TitleSectionLabel>Update Profile</TitleSectionLabel>}
          />
          <Route
            path="/update-email"
            element={<TitleSectionLabel>Update Profile</TitleSectionLabel>}
          />
          <Route
            path="/update-password"
            element={<TitleSectionLabel>Update Profile</TitleSectionLabel>}
          />
          <Route
            path="/advertisements"
            element={<TitleSectionLabel>Advertisements</TitleSectionLabel>}
          />

          <Route
            path="/advertisements/banner-advertisement/new"
            element={<TitleSectionLabel>Advertisements</TitleSectionLabel>}
          />
          <Route
            path="/verify-leosa"
            element={<TitleSectionLabel>LEOSA Verification</TitleSectionLabel>}
          />
          <Route
            path="/advertisements/banner-advertisement/completed"
            element={<TitleSectionLabel>Advertisements</TitleSectionLabel>}
          />
          <Route
            path="/advertisements/information"
            element={<TitleSectionLabel>Advertisements</TitleSectionLabel>}
          />
          <Route
            path="/advertisements/banner-advertisement/payment"
            element={<TitleSectionLabel>Advertisements</TitleSectionLabel>}
          />
          <Route
            path="/advertisements/banner-advertisement/:bannerUId"
            element={<TitleSectionLabel>Advertisements</TitleSectionLabel>}
          />
          <Route
            path="/advertisements/special-offer/new"
            element={<TitleSectionLabel>Advertisements</TitleSectionLabel>}
          />
          <Route
            path="/advertisements/special-offer/payment"
            element={<TitleSectionLabel>Advertisements</TitleSectionLabel>}
          />
          <Route
            path="/advertisements/special-offer/:specialOfferUId"
            element={<TitleSectionLabel>Advertisements</TitleSectionLabel>}
          />
          <Route
            path="/advertisements/register-interest"
            element={<TitleSectionLabel>Advertisements</TitleSectionLabel>}
          />
          <Route path="*" element={<Navigate to="/page-not-found" />} />
        </Routes>
      </TitleSection>
      <RightSideWrapper>
        <Routes>
          <Route
            index
            element={
              <AccountMenuItems username={data?.username} userType={userType} />
            }
          />
          <Route path="/businesses" element={<UserBusinessController />} />
          <Route path="/claims" element={<PendingClaimsPage />} />
          <Route path="/edit" element={<UpdateProfileMenuPage />} />
          <Route path="/update-profile" element={<EditProfilePage />} />
          <Route path="/update-email" element={<UpdateEmailPage />} />
          <Route path="/update-password" element={<UpdatePasswordPage />} />
          <Route path="/verify-leosa" element={<VerifyLeosaPage />} />
          <Route path="/advertisements" element={<AdvertisementsMenuPage />} />

          <Route
            path="/advertisements/banner-advertisement/new"
            element={<NewBannerAdvertisementPage />}
          />
          <Route
            path="/advertisements/banner-advertisement/completed"
            element={<p>Thank you for your payment</p>}
          />
          <Route
            path="/advertisements/banner-advertisement/payment"
            element={<CompleteBannerAdPaymentPage />}
          />
          <Route
            path="/advertisements/information"
            element={<HowAdvertisingWorksPage />}
          />
          <Route
            path="/advertisements/banner-advertisement/:bannerUId"
            element={<UpdateBannerAdPage />}
          />
          <Route
            path="/advertisements/special-offer/new"
            element={<NewSpecialOfferPage />}
          />
          <Route
            path="/advertisements/special-offer/payment"
            element={<CompleteSpecialOfferPaymentPage />}
          />
          <Route
            path="/advertisements/special-offer/:specialOfferUId"
            element={<UpdateSpecialOfferPage />}
          />
          <Route
            path="/advertisements/register-interest"
            element={<RegisterBannerAdInterestPage />}
          />
        </Routes>
      </RightSideWrapper>
    </AccountMenuPageContainer>
  );
};

export default AccountMenuPage;
