import openCarryPositive from "../../../images/badges/openCarryPolicy/openCarryPolicy_green.png";

const OpenCarryPositive = ({ imageProps }: any) => {
  return (
    <img
      src={openCarryPositive}
      alt="open-carry-policy-positive"
      {...imageProps}
    />
  );
};

export default OpenCarryPositive;
