import { Category } from "../../ts/category";

import ImageHandler from "../image-handler/image-handler";
import {
  CategorySectionContainer,
  CategoryCircle,
  CategoryLabel,
} from "./styled/category-section-styled";

export interface CategorySectionProps {
  category: Category;
}

const CategorySection = (props: CategorySectionProps) => {
  return (
    <CategorySectionContainer>
      <CategoryCircle style={{ background: props.category.color }}>
        <ImageHandler
          name={props.category.icon}
          imageProps={{ height: 40, width: 40 }}
        />
      </CategoryCircle>
      <CategoryLabel>{props.category.label}</CategoryLabel>
    </CategorySectionContainer>
  );
};

export default CategorySection;
