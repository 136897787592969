import React, { useContext } from "react";
import { useMutation, useQueryClient } from "react-query";
import { postBadgeForPointOfInterest } from "../../../lib/api/points-of-interest";
import useAuth from "../../../lib/custom-hooks/useAuth";
import { USAPACSUser } from "../../../lib/ts/User";
import {
  MarkersContext,
  QueryValueContext,
} from "../../../pages/Dashboard/util/contexts";
import { IncidentReport, PointOfInterest } from "../../../ts/point-of-interest";
import PostBadge from "../../post-badge/post-badge";

export interface BusinessPostBadgeProps {
  placeId: string;
  onSuccess: (newBadgeAlignemnts: any) => void;
}

const BusinessPostBadge = (props: BusinessPostBadgeProps) => {
  const { authHandler } = useAuth();
  const queryClient = useQueryClient();
  const { queryInfo } = useContext(QueryValueContext);
  const { markers, setMarkers } = useContext(MarkersContext);

  const mutation = useMutation(
    (newIncidentReport: IncidentReport) => {
      return postBadgeForPointOfInterest(
        props.placeId,
        newIncidentReport.incidentType,
        newIncidentReport.alignment
      );
    },
    {
      onSuccess: (data, variables, context) => {
        props.onSuccess(data.newBadgeAlignments);

        try {
          queryClient.setQueriesData(["/poi/search", queryInfo], (old) => {
            let cacheData: PointOfInterest[] = old as PointOfInterest[];

            if (cacheData) {
              let foundItemIndex = cacheData.findIndex(
                (poi) => poi.placeId === props.placeId
              );

              if (foundItemIndex > -1) {
                let poi = cacheData[foundItemIndex];

                if (poi) {
                  if (data.newBadgeAlignments) {
                    poi.badgeAlignments = data.newBadgeAlignments;
                  }

                  if (data.newIncidents) {
                    poi.incidents = data.newIncidents;
                  } else {
                  }
                }
              } else {
              }
            } else {
              //if no cache data, just update marker POI
              let markerData = markers.find((a) => a.placeId === props.placeId);
              if (markerData) {
                markerData.pointOfInterest.badgeAlignments =
                  data.newBadgeAlignments;
              }
            }

            return cacheData;
          });
        } catch (e) {}

        try {
          queryClient.setQueryData<PointOfInterest | undefined>(
            ["/poi", props.placeId],
            (old) => {
              if (old && data.newBadgeAlignments) {
                if (data.newBadgeAlignments) {
                  old.badgeAlignments = data.newBadgeAlignments;
                }
                if (data.newIncidents) {
                  old.incidents = data.newIncidents;
                } else {
                }
              }

              return old;
            }
          );
        } catch (e) {}
      },
      onError: (error: Error) => {
        if (error.message === "post_timeout") {
          alert(
            "You have posted too many badges recently. Please try again later."
          );
        } else if (error.message === "business_timeout") {
          alert(
            "You have Already Posted for This Business Today. Please post again tomorrow."
          );
        } else {
          alert("Could not post badge at this time. Please try again later.");
        }
      },
    }
  );

  const handlePostBadgeSubmit = async (policy: string, alignment: number) => {
    const newIncidentReport: IncidentReport = {
      createdAt: "",
      placeId: props.placeId,
      userUId: "",
      incidentType: policy,
      alignment: alignment,
    };

    mutation.mutate(newIncidentReport);
  };

  // if (mutation.isLoading) {
  //   return <p>Loading...</p>;
  // }

  return (
    <PostBadge
      placeId={props.placeId}
      onSubmit={handlePostBadgeSubmit}
      isLoading={mutation.isLoading}
    />
  );
};

export default BusinessPostBadge;
