import React, { useContext } from "react";
import { SpecialOffer } from "../../../ts/special-offer";
import Body from "./body";
import { BusinessContext } from "./view-business-switch";

export interface BusinessDetailsProps {
  onPostedBadgesClick: () => void;
  onPostBadgeButtonClick: () => void;
  onBusinessOfferClick: (index: number) => void;
}

const BusinessDetails = ({
  onPostedBadgesClick,
  onPostBadgeButtonClick,
  onBusinessOfferClick,
}: BusinessDetailsProps) => {
  const pointOfInterest = useContext(BusinessContext);

  if (!pointOfInterest) {
    return <p>Could not get details at this time.</p>;
  }

  return (
    <Body
      handleBusinessOfferClick={(index: number) => onBusinessOfferClick(index)}
      shouldShowPostedBadgesButton={pointOfInterest.incidents.length > 0}
      handlePostedBadgesClick={onPostedBadgesClick}
      handlePostBadgeButtonClick={onPostBadgeButtonClick}
    />
  );
};

export default BusinessDetails;
