import { useNavigate } from "react-router-dom";
import BackButton from "../../components/back-button/back-button";
import { InformationParagraph, Title } from "../../styled/typography";
import { InformationContent } from "../../styled/wrappers";

interface AboutTheAppPageProps {}

const AboutTheAppPage = (props: AboutTheAppPageProps) => {
  const navigate = useNavigate();
  return (
    <>
      <BackButton handleBack={() => navigate("/menu")} />
      <InformationContent>
        <InformationParagraph>
          <Title>About USAPACS USA Police and Citizen Safety LLC</Title>
          <p>
            USAPACS is committed to promoting the safety of police officers,
            active and retired, as well as citizens, armed and unarmed,
            throughout the United States, by providing them a free app with the
            following information and features:
          </p>
          <ul>
            <li>
              An interactive map showing businesses throughout the USA such as
              Restaurants, coffee shops, retail stores, hotels, hospitals, etc,
              and if a particular business allows, or does not allow, law
              enforcement, under LEOSA, and armed citizens on their premises.
            </li>
            <li>
              When a user clicks a location on the map, the app displays badges
              for that particular business. These badges indicate whether that
              business ALLOWS on their premises, or DOES NOT ALLOW on their
              premises:
            </li>
            <ul>
              <li>
                Active and retired Law Enforcement carry of firearms as per the
                Federal LEOSA (Law Enforcement Officers Safety Act)
              </li>
              <li>Right to Carry Citizens CONCEALED CARRY of firearms</li>
              <li>OPEN CARRY of firearms</li>
            </ul>
            <li>The app also shows badges that indicate if a business:</li>
            <ul>
              <li>Is 51% Alcohol (is primarily a bar)</li>
              <li>Has a metal detector</li>
            </ul>
          </ul>
          <p>
            With this information, users of the app are able to make informed
            decisions about whether to visit a business, or boycott the
            business, based on the business’s support or non-support of LEOSA,
            and their support or non-support of the 2nd Amendment, and their
            state laws regarding Right to Carry.{" "}
          </p>
          <p>
            Police Officers and Citizens alike may choose to patronize
            businesses that support their right to carry, while avoiding, and
            not patronizing, those businesses that do not support their right to
            carry.
          </p>
          <p>
            The information provided by USAPACS can make the difference in the
            safety of both Law Enforcement and Citizens by allowing them to
            visit businesses where the likelihood that police officers and armed
            citizens are present is greater than a business that does not allow
            Armed citizens and police (under LEOSA).
          </p>
          <Title>LAW ENFORCEMENT</Title>
          <p>
            Law Enforcement Officers, active and retired, are able to post the
            LEOSA badge to a business to show the business’s SUPPORT (blue) or
            NON-SUPPORT (red) of LEOSA. When a Law Enforcement Officer posts a
            red NON-SUPPORT LEOSA Badge to a business, a report will be sent to
            USAPACS who will then send a registered letter to that business to
            inform them of the LEOSA bill. CITIZENS
          </p>
          <p>
            All citizens are able to post badges to a business to show a
            business’s SUPPORT (green) or NON-SUPPORT (red) of citizen’s right
            to Concealed Carry and Open Carry.{" "}
          </p>
          <Title>BUSINESS OWNERS</Title>
          <p>
            Owners of businesses are able to claim their business on the app,
            and update their business’s information and the badge for support or
            non-support of LEOSA.
          </p>
          <p>
            Owners of the businesses are able to claim their business on the app
            and update their business’s information and the badges for support
            or non-support of Concealed Carry and Open Carry, as well as post
            the badge for 51%.
          </p>
          <Title>ADVERTISING</Title>
          <p>
            Businesses can show their support for LEOSA and Citizens Right to
            Carry by purchasing advertising that will channel directly to their
            desired demographic of law enforcement and 2nd Amendment supporters.
            Business owners may advertise with two options:
          </p>
          <ul>
            <li>
              <b>BANNER ADS</b> - scroll display at the bottom of the app
              allowing users to click the ad and go directly to that business’s
              website or media ad.
            </li>
            <li>
              <b>SPECIAL OFFERS</b> - identified by a Special Offers Badge,
              appearing on the USAPACS interactive map to highlight that
              company’s Special Offer to further entice Law Enforcement Officers
              and Citizens to visit that business and redeem their special
              offer.
            </li>
          </ul>
          <p>
            Citizens will not only see businesses that support the 2nd
            Amendment, they will also see, identify, and potentially choose
            businesses that also provide a special offer, discount, BOGO, or
            freebie, directly to them and enticing them to patronize that
            business.
          </p>
          <Title>AMMUNITION</Title>
          <p>
            Users of the app acquire ammunition (points) whenever they post to a
            business a new badge or badges
          </p>
          <p>
            Users of the app acquire ammunition (points) whenever they recommend
            new users to the app and that citizen or law enforcement officer
            registers for the USAPACS app.
          </p>
          <p>
            Users of the app are able to redeem their ammunition (points) for
            products and services through the app.
          </p>
          <Title>IMPORTANT DISCLOSURE:</Title>{" "}
          <p>
            Because USAPACS is user based and shares information provided by the
            users of the app as well as the owners of the businesses displayed
            on the app, users should not rely solely on the information provided
            by USAPACS and SHOULD VERIFY the validity of the apps information by
            identifying the proper signage, and policies of a particular
            business before entering that business.
          </p>
        </InformationParagraph>
      </InformationContent>
    </>
  );
};

export default AboutTheAppPage;
