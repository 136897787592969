import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import CategoriesSection from "../../../components/categories-section/categories-section";
import SearchBusinessInput from "../../../components/search-business-input/search-business-input";
import useGeoLocation from "../../../lib/custom-hooks/useLocation";
import { useMediaQuery } from "react-responsive";

import { PointOfInterest } from "../../../ts/point-of-interest";
import {
  CategoriesSectionWrapper,
  Instructions,
  SearchbarWrapper,
  SearchInputWrapper,
  SearchViewContainer,
} from "../styled/search-view-styled";
import BusinessView from "./BusinessView";
import ResultsView from "./ResultsView";
import { mobileMax } from "../../../lib/media-breakpoints";
import { Wrapper } from "@googlemaps/react-wrapper";
import { useContext } from "react";
import { QueryValueContext } from "../util/contexts";
import { useState } from "react";
import { MAPS_KEY } from "../../../lib/map-helpers/key";
import AddToHomeScreenInfo from "../../../components/add-to-home-screen-info/add-to-home-screen-info";
import LocationPrompt from "../../../components/location-prompt/location-prompt";

export interface SearchViewProps {
  results: PointOfInterest[];
  handleSearch: (value: string) => Promise<void>;
  isLoading: boolean;
  empty?: boolean;
}

const SearchView = ({
  results,
  handleSearch,
  isLoading,
  empty,
}: SearchViewProps) => {
  const navigate = useNavigate();

  const { location, permission } = useGeoLocation();

  const { setValue } = useContext(QueryValueContext);

  const isMobile = useMediaQuery({ query: `(max-width: ${mobileMax}px)` });

  const [mobileState, setMobileState] = useState<number>(1);

  return (
    <>
      <SearchViewContainer>
        {!isMobile || mobileState === 1 ? (
          <SearchInputWrapper>
            <SearchbarWrapper>
              <Wrapper apiKey={`${MAPS_KEY}`} libraries={["places"]}>
                <SearchBusinessInput
                  onAutoComplete={(placeId) => {
                    navigate(`/home/business/${placeId}`);
                  }}
                  onSubmit={(value: string) => {
                    setMobileState(2);

                    handleSearch(value);
                  }}
                />
              </Wrapper>
            </SearchbarWrapper>
            {mobileState === 1 ? (
              <Instructions>
                Get started by typing in a category such as "Coffee" or the
                exact name of a business
              </Instructions>
            ) : null}
          </SearchInputWrapper>
        ) : null}
        {!location && permission !== "granted" && mobileState === 1 ? (
          <LocationPrompt />
        ) : null}
        {isMobile && mobileState === 1 ? <AddToHomeScreenInfo /> : null}
        {!isMobile || mobileState === 1 ? (
          <CategoriesSectionWrapper>
            <CategoriesSection
              handleClick={(value: string) => {
                handleSearch(value);

                setMobileState(2);
              }}
              categories={[
                {
                  color: "#522AF6",
                  label: "Special Offers",
                  icon: "specialOffers",
                },
              ]}
            />
          </CategoriesSectionWrapper>
        ) : null}
        {!isMobile || mobileState === 2 ? (
          <ResultsView
            results={results}
            isLoading={isLoading}
            empty={empty}
            handleResultsClear={() => {
              setMobileState(1);
              if (isMobile) {
                setValue("", "");
              }
            }}
          />
        ) : null}
      </SearchViewContainer>

      <Routes>
        <Route index element={null} />
        <Route path="business/:placeId" element={<BusinessView />} />
        <Route path="*" element={<Navigate to="/page-not-found" />} />
      </Routes>
    </>
  );
};

export default SearchView;
