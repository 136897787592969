import * as Sentry from "@sentry/react";

const SentryHandler = {
  reportException: (e: Error) => {
    Sentry.captureException(e);
  },
  captureMessage: (message: string) => {
    Sentry.captureMessage(message);
  },
};

export default SentryHandler;
