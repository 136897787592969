import { ErrorMessage, Field, FormikProps } from "formik";

import {
  FieldsWrapper,
  FormContainer,
  FormLabel,
  SubmitButton,
} from "../../register/styled/global-styled";
import { LoginButton } from "../styled/login-styled";
import { loginSchema } from "../util/loginValidation";

export interface LoginFormValues {
  email: string;
  password: string;
}

const LoginForm = (props: FormikProps<LoginFormValues>) => {
  const { handleSubmit, isSubmitting, isValid, values } = props;

  let canSubmit = false;
  try {
    loginSchema.validateSync(values);
    canSubmit = true;
  } catch (e) {
    canSubmit = false;
  }

  return (
    <form onSubmit={handleSubmit}>
      <FormContainer>
        <FieldsWrapper>
          <FormLabel htmlFor="email">Email Address</FormLabel>
          <ErrorMessage
            name="email"
            component="span"
            className="error-message"
          />
          <Field type="email" name="email" />
        </FieldsWrapper>
        <FieldsWrapper>
          <FormLabel htmlFor="password">Password</FormLabel>
          <ErrorMessage
            name="password"
            component="span"
            className="error-message"
          />
          <Field type="password" name="password" />
        </FieldsWrapper>
        <LoginButton
          disabled={isSubmitting}
          active={canSubmit && !isSubmitting}
        >
          LOGIN
        </LoginButton>
      </FormContainer>
    </form>
  );
};

export default LoginForm;
