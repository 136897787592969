import styled from "styled-components";

export const BulletRedeemPageContainer = styled.div``;

export const Header = styled.div``;

export const Content = styled.div`
  margin: 40px 30px;
`;

export const Body = styled.div``;

export const BulletLabel = styled.div`
  font-size: 20px;
  font-weight: bold;
`;
