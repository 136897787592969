import styled from "styled-components";
export const ArticlePageContainer = styled.div``;

export const ImageSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
`;

export const ContentSection = styled.div``;

export const ContentText = styled.pre`
  color: grey;
  border: none;
  background: none;
  text-align: left;

  color: black;
  font-family: "Roboto", sans-serif;

  white-space: pre-line;
`;

export const Content = styled.div`
  margin: 40px 20px;
`;
