import styled from "styled-components";

export const Content = styled.div`
  text-align: center;
`;

export const InfoText = styled.p`
  color: black;
  font-weight: bold;
  font-size: 16px;
  margin-top: 0px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 0;
`;

export const LinkText = styled.p`
  color: #6881a3;
  font-size: 14px;
  margin-left: 20px;
  margin-top: 5px;
  margin-bottom: 5px;
`;
