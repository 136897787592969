import { useMutation, useQueryClient } from "react-query";
import { updateSpecialOfferImage } from "../../../lib/api/special-offer";
import { SpecialOffer } from "../../../ts/special-offer";

export const useUpdateImageMutation = (uid?: string) => {
  const client = useQueryClient();

  const mutation = useMutation(
    ["/business-offer/update"],
    (values: { id: number; image: File }) =>
      updateSpecialOfferImage(values.id, values.image),
    {
      onSuccess: (data) => {
        client.setQueryData<SpecialOffer | undefined>(
          ["/business-offer", uid],
          (old) => {
            if (old) {
              old.updatedAt = data.updatedAt;
              old.imageUrl = data.imageUrl;
              old.status = data.status;
            }

            return old;
          }
        );
      },
    }
  );

  return mutation;
};
