import { useState, useEffect } from "react";
import { DetermineMarkerColor } from "../../../../lib/map-helpers/determine-marker-color";
import MARKER_PATH from "./marker-icon-path";

export interface MarkerProps extends google.maps.MarkerOptions {
  placeId: string;
  onClick?: () => void;
  color: string;
  active: boolean;
  scale: number;
}

export const Marker: React.FC<MarkerProps> = ({
  onClick,
  placeId,
  color,
  scale,
  active,
  ...options
}: MarkerProps) => {
  const [marker, setMarker] = useState<google.maps.Marker>();

  useEffect(() => {
    if (!marker) {
      setMarker(new google.maps.Marker());
    }

    if (onClick && marker) {
      marker?.addListener("click", () => {
        onClick();
      });
    }

    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);

  useEffect(() => {
    if (marker) {
      marker.setOptions({
        ...options,
        zIndex: active === true ? 2 : 1,
        icon: {
          path: MARKER_PATH,
          fillColor: color,
          scale: scale,
          fillOpacity: 1,
        },
      });
    }
  }, [marker, options, color, scale]);

  return null;
};
