import { ErrorMessage, Field, FieldProps, FormikProps } from "formik";
import {
  FieldLabel,
  FieldSection,
  ModifiedGradientBorder,
  StyledErrorMessage,
  StyledField,
  FormContainer,
  SubmitButton,
} from "../../styled/edit-profile-form-elements";

import * as Yup from "yup";
import { Disclaimer } from "../register/styled/global-styled";
import { UpdateEmailFormContextProps } from "./update-email-form-container";

export interface UpdateEmailFormValues {
  newEmail: string;
  password: string;
}

export const UpdateEmailFormSchema: Yup.SchemaOf<UpdateEmailFormValues> = Yup.object(
  {
    newEmail: Yup.string().required("Please enter a new email."),
    password: Yup.string().required("Please enter your current password."),
  }
);

const UpdateEmailForm = (
  props: UpdateEmailFormContextProps & FormikProps<UpdateEmailFormValues>
) => {
  const { handleSubmit, isSubmitting, response } = props;

  return (
    <form onSubmit={handleSubmit}>
      <FormContainer>
        <FieldSection>
          <FieldLabel>New Email</FieldLabel>
          <ErrorMessage
            name="newEmail"
            render={(msg) => <StyledErrorMessage>{msg}</StyledErrorMessage>}
          />
          <Field name="newEmail">
            {({ field }: FieldProps) => <StyledField type="input" {...field} />}
          </Field>
          <ModifiedGradientBorder />
        </FieldSection>
        <FieldSection>
          <FieldLabel>Your Password</FieldLabel>
          <ErrorMessage
            name="password"
            render={(msg) => <StyledErrorMessage>{msg}</StyledErrorMessage>}
          />
          <Field name="password">
            {({ field }: FieldProps) => (
              <StyledField type="password" {...field} />
            )}
          </Field>
          <ModifiedGradientBorder />
        </FieldSection>
        <SubmitButton
          type="submit"
          disabled={isSubmitting}
          active={!isSubmitting}
        >
          SAVE
        </SubmitButton>
        {response ? (
          <p style={{ marginTop: 25, color: "#324368" }}>{response}</p>
        ) : null}
      </FormContainer>
    </form>
  );
};

export default UpdateEmailForm;
