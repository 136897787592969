import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { SpecialOfferFormValues } from "../../components/special-offer-form/special-offer-form";
import SpecialOfferFormWrapper from "../../components/special-offer-form/special-offer-form-wrapper";
import { createSpecialOffer } from "../../lib/api/special-offer";
import { Loader } from "../../styled/loader";
import useAuth from "../../lib/custom-hooks/useAuth";
import { Content, Wrapper } from "../../styled/advertisement-form-elements";
import BackButton from "../../components/back-button/back-button";

interface NewSpecialOfferPageProps {}

const NewSpecialOfferPage = (props: NewSpecialOfferPageProps) => {
  // const { isAuth, authHandler } = useAuth();
  const navigate = useNavigate();

  const { mutate, isLoading, isError, isSuccess } = useMutation(
    ["/special-offer/web"],
    (values: SpecialOfferFormValues) =>
      createSpecialOffer(
        values.title,
        values.description,
        values.businessIds ?? [],
        values.redeemMethod,
        values.image,
        values.salesCode
      ),
    {
      onSuccess: (data, values) => {
        navigate(
          `/account/advertisements/special-offer/payment?o=${data.uid}&i=${values.planUId}`
        );
      },
    }
  );

  const handleSubmit = (values: SpecialOfferFormValues) => {
    mutate(values);
  };

  if (isLoading) {
    return (
      <Content>
        <Wrapper>
          <Loader />
        </Wrapper>
      </Content>
    );
  }

  if (isError) {
    return (
      <Content>
        <Wrapper>
          <p>Could not create special offer at this time.</p>
        </Wrapper>
      </Content>
    );
  }

  return (
    <Content>
      <BackButton handleBack={() => navigate("/account/advertisements")} />
      <Wrapper>
        <SpecialOfferFormWrapper onSubmit={handleSubmit} />
      </Wrapper>
    </Content>
  );
};

export default NewSpecialOfferPage;
