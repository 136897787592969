import React from "react";
import { ForgotPasswordContainer } from "./styled/forgot-password-styled";
import CreateForgotPasswordForm from "./util/create-forgotpassword-form";

const ForgotPassword = () => {
  return (
    <ForgotPasswordContainer>
      <CreateForgotPasswordForm />
    </ForgotPasswordContainer>
  );
};

export default ForgotPassword;
