import { GradientBorder } from "../../styled/gradient-border";
import { Title } from "../../styled/typography";

import {
  ConfirmBulletItemAddressContainer,
  LabelHeader,
  SubmitButton,
  ValueText,
} from "./styled";

interface ConfirmBulletItemAddressProps {
  streetAddress: string;
  streetAddressTwo?: string;
  city: string;
  state: string;
  zip: string;
  country: string;

  handleSubmit: () => void;
}

const ConfirmBulletItemAddress = (props: ConfirmBulletItemAddressProps) => {
  return (
    <ConfirmBulletItemAddressContainer>
      <Title>Shipping Confirmation</Title>
      <LabelHeader>Street Address</LabelHeader>
      <ValueText>{props.streetAddress}</ValueText>
      <GradientBorder style={{ height: 3, marginBottom: 30 }} />

      {props.streetAddressTwo ? (
        <>
          <LabelHeader>Street Address Line 2</LabelHeader>
          <ValueText>{props.streetAddressTwo}</ValueText>
          <GradientBorder style={{ height: 3, marginBottom: 30 }} />
        </>
      ) : null}

      <LabelHeader>City</LabelHeader>
      <ValueText>{props.city}</ValueText>
      <GradientBorder style={{ height: 3, marginBottom: 30 }} />

      <LabelHeader>State</LabelHeader>
      <ValueText>{props.state}</ValueText>
      <GradientBorder style={{ height: 3, marginBottom: 30 }} />

      <LabelHeader>ZIP/Postal Code</LabelHeader>
      <ValueText>{props.zip}</ValueText>
      <GradientBorder style={{ height: 3, marginBottom: 30 }} />

      <LabelHeader>Country</LabelHeader>
      <ValueText>{props.country}</ValueText>
      <GradientBorder style={{ height: 3, marginBottom: 30 }} />

      <SubmitButton onClick={() => props.handleSubmit()}>SUBMIT</SubmitButton>
    </ConfirmBulletItemAddressContainer>
  );
};

export default ConfirmBulletItemAddress;
