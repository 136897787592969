import React from "react";
import { useQuery } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import BackButton from "../../components/back-button/back-button";
import { getUserDetails } from "../../lib/api/user";
import useAuth from "../../lib/custom-hooks/useAuth";
import { SecondaryButton } from "../../styled/buttons";
import { GradientBorder } from "../../styled/gradient-border";
import { Loader } from "../../styled/loader";
import { ErrorIconContainer, ErrorIcon } from "../../styled/status-icons";
import {
  UpdateProfileMenupageContainer,
  Section,
  SectionTitle,
  SectionValue,
  Content,
  TopSide,
  EditButton,
  LeftSide,
  EditButtonContainer,
} from "./styled/update-profile-menu-page-styled";

export interface UpdateProfileMenuPageProps {}

const UpdateProfileMenuPage = (props: UpdateProfileMenuPageProps) => {
  const navigate = useNavigate();
  const { authHandler } = useAuth();

  const { data, isLoading, isError } = useQuery("/me", () => getUserDetails());

  if (isLoading) {
    return <Loader />;
  }

  if (isError || !data) {
    return (
      <ErrorIconContainer style={{ color: "red" }}>
        <ErrorIcon>!</ErrorIcon>
        <p>Could not get details at this time. Please try again later.</p>
      </ErrorIconContainer>
    );
  }

  return (
    <UpdateProfileMenupageContainer>
      <BackButton handleBack={() => navigate("/account")} />
      <Content>
        <Section>
          <TopSide>
            <LeftSide>
              <SectionTitle>NAME</SectionTitle>
              <SectionValue>
                {data.firstName} {data.lastName}
              </SectionValue>
            </LeftSide>
            <EditButtonContainer>
              <Link to="/account/update-profile">
                <EditButton>EDIT</EditButton>
              </Link>
            </EditButtonContainer>
          </TopSide>
          <GradientBorder style={{ height: 3 }} />
        </Section>
        <Section>
          <TopSide>
            <LeftSide>
              <SectionTitle>EMAIL</SectionTitle>
              <SectionValue>
                {authHandler ? authHandler.getUserEmail() : null}
              </SectionValue>
            </LeftSide>
            <EditButtonContainer>
              <Link to="/account/update-email">
                <EditButton>EDIT</EditButton>
              </Link>
            </EditButtonContainer>
          </TopSide>
          <GradientBorder style={{ height: 3 }} />
        </Section>
        <Section>
          <TopSide>
            <LeftSide>
              <SectionTitle>PASSWORD</SectionTitle>
              <SectionValue>******</SectionValue>
            </LeftSide>
            <EditButtonContainer>
              <Link to="/account/update-password">
                <EditButton>EDIT</EditButton>
              </Link>
            </EditButtonContainer>
          </TopSide>
          <GradientBorder style={{ height: 3 }} />
        </Section>
        {/* Show Leosa Verification button to citizen users */}
        {data.accountTypeId === 0 ? (
          <Section>
            <Link to="/account/verify-leosa">
              <SecondaryButton style={{ maxWidth: 250 }}>
                Submit LEOSA Verification
              </SecondaryButton>
            </Link>
          </Section>
        ) : null}
      </Content>
    </UpdateProfileMenupageContainer>
  );
};

export default UpdateProfileMenuPage;
