import { useQuery } from "react-query";
import { getSpecialOffer } from "../../../lib/api/special-offer";

export const useDataQuery = (uid?: string) => {
  const query = useQuery(["/business-offer", uid], () =>
    getSpecialOffer(uid ?? "")
  );

  return query;
};
