import React from "react";

const MenuText = ({ iconProps }: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={71}
      height={33}
      {...iconProps}
    >
      <text
        fill="#667fa2"
        fontSize={25}
        fontFamily="Roboto-Bold, Roboto"
        fontWeight={700}
      >
        <tspan x={0} y={0}>
          {"MENU"}
        </tspan>
      </text>
    </svg>
  );
};

export default MenuText;
