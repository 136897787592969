import { Link, useNavigate } from "react-router-dom";
import BackButton from "../../components/back-button/back-button";
import { InformationParagraph, Subtitle, Title } from "../../styled/typography";
import { InformationContent } from "../../styled/wrappers";

interface HowToUseTheAppPageProps {}

const HowToUseTheAppPage = (props: HowToUseTheAppPageProps) => {
  const navigate = useNavigate();
  return (
    <>
      <BackButton handleBack={() => navigate("/menu")} />
      <InformationContent>
        <InformationParagraph>
          <Title>How to use the USAPACS app</Title>
          <p>USA Police and Citizen Safety</p>
          <Subtitle>All Users</Subtitle>
          <p>Register: All users must be registered to use the app.</p>
          <p>
            We will not sell your personal details to a third party – in
            addition, advertisers on our app will NOT have access to your data.
          </p>
          <p>To register, set up the following:</p>
          <ul>
            <li>
              User Name{" "}
              <span
                style={{
                  fontWeight: "bold",
                  fontSize: 14,
                  marginLeft: 5,
                }}
              >
                *CAUTION -once you set your user name, it cannot be changed so
                please choose wisely.
              </span>
            </li>
            <li>Name</li>
            <li>Email</li>
            <li>Password</li>
          </ul>
          <p>
            Your Citizen User registration is now complete, and you can use the
            app immediately. Now you can visit venues that will enhance your
            safety and protect your family. Have fun!
          </p>
          <Subtitle>LEOSA Users</Subtitle>
          <p>
            Take a stand to support the LEOSA law! Only LEOSA qualified officers
            can post the LEOSA badge to a business. If you are Law Enforcement,
            including U.S. Military, active and retired, submit your LEOSA user
            registration information and Police Identification upload. Your
            upload is secure and once USAPACS verifies your credentials, your
            credential images are deleted, and you will be able to post LEOSA
            Badges to businesses. Congratulations Officer, now you have the
            power to inform the business world and enforce LEOSA!
          </p>
          <Subtitle>Business Owners</Subtitle>
          <p>
            Congratulations! You now have the opportunity to promote your
            business directly to the 2nd Amendment community. Once you have
            registered as a USAPACS app user, claim your Business. We want to
            make sure that you promote your business as you see fit, ensure that
            the posted badges are accurate, and that ONLY you can claim your
            business. To do so, search for and locate your business on the
            USAPACS app. You can do this by typing your business name into the
            search window. Once you find your business click “Claim Business”.
            We will send a letter by U.S. Mail to the business address provided
            by Google to ensure that only you can claim your business. Watch for
            that letter, it will include a verification code that you must have
            to claim your business. Once you have received the letter, open the
            app and open your personal account (see the human silhouette at the
            top right of the screen). Click on “My Business Claims” and enter
            your Verification code. That’s it! You may now update your
            business’s badges for LEOSA, Concealed Carry, Open Carry, and 51%.
            You may also choose to advertise directly to the 2nd Amendment
            demographic by clicking “Advertisements” and uploading Scrolling
            Banner ads that scroll at the bottom of the app or Special Offers
            that entice users directly to your venue.
          </p>
          <Subtitle>Using the Map Function</Subtitle>
          <p>
            With the USAPACS app map you will find businesses that have badges
            posted that show if they allow or do not allow on their premises the
            following:
          </p>
          <ul>
            <li>
              {" "}
              LEOSA (armed law enforcement,{" "}
              <Link to="/menu/leosa-bill" target={"_blank"} rel="noreferrer">
                see LEOSA Bill in the index of this app)
              </Link>
            </li>
            <li> Concealed Carry</li>
            <li>- Open Carry</li>
          </ul>
          <p>You will also see if the business has badges posted for:</p>
          <li>51% business (Bar)</li>
          <Subtitle>Search by Pins</Subtitle>
          <p>
            When viewing the map function, businesses will be identified with a
            colored pin on the map.
          </p>
          <ul>
            <li>
              GREEN PIN: One or more badges have been posted showing that the
              business allows LEOSA and/or citizen firearms carry.
            </li>

            <li>
              RED PIN: Business does not allow LEOSA and/or citizen carry of
              firearms on their premises.
            </li>

            <li>
              PURPLE PIN: Business has a Special Offer for YOU! Click on the pin
              and see the details of the special offer and instructions on how
              to redeem it.
            </li>

            <li>
              GREY PIN: No badges have yet been posted for this business and the
              business is waiting for you to post the appropriate badges and
              earn valuable ammunition to redeem.{" "}
            </li>
          </ul>
          <p>
            For further information on any business, click on their pin for the
            details of that business, their posted badges, and their special
            offer (when purple).
          </p>
          <p>
            You may click on any pin within the map and see what badges have
            been posted to the business, if the badges are grey then they have
            not yet been posted. If you are at the business and know what badges
            the business allows, or does not allow, you may click on “Post a
            badge” and submit the badges while earning valuable ammunition to
            redeem on the app. Do not guess when posting badges! Be accurate!
            Numerous posting of incorrect badges may cause your access to the
            app to be terminated.
          </p>
          <Subtitle>IMPORTANT DISCLOSURE:</Subtitle>{" "}
          <p>
            Because USAPACS is user based and shares information provided by the
            users of the app as well as the owners of the businesses displayed
            on the app, users should not rely solely on the information provided
            by USAPACS and SHOULD VERIFY the validity of the apps information by
            identifying the proper signage, and policies of a particular
            business before entering that business.
          </p>
          <Subtitle>Search by Business Name</Subtitle>
          <p>
            In the search box, type the name of the specific business that you
            seek. Listings will display below the search window. Click/touch the
            desired business in the list to see its Pin on the map and details
            of the business including posted Badges and special offers.
          </p>
          <Subtitle>Search by Category</Subtitle>
          <p>
            In the search box, enter the category that you seek, for instance
            coffee, restaurant, etc. Click/touch the search button and the map
            will find the businesses in that category.
          </p>
          <Subtitle>Search for Special Offers</Subtitle>
          <p>
            Simply click/touch the purple Special Offers Icon and find
            businesses offering you something very special
          </p>
          <Title>In the USA PACS Menu</Title>
          <Subtitle>News and Articles</Subtitle>
          <p>
            Follow current events and trends regarding the 2nd Amendment and
            Americans protecting themselves and their families.
          </p>
          <Subtitle>LEOSA Bill</Subtitle>
          <p>
            Read and discover the LEOSA Bill, Law Enforcement Officers Safety
            Act.
          </p>
          <Subtitle>Map Reciprocity</Subtitle>
          <p>
            This feature takes you to a site for map reciprocity of your states
            right to carry. Note: By clicking this feature you will temporarily
            leave the USAPACS app.
          </p>
          <Subtitle>Build Ammunition</Subtitle>
          <p>
            Do you like free stuff? Every time you invite friends to the app, or
            post badges to a business, you will earn valuable ammunition. Build
            your ammunition supply so that you can redeem your bullets for free
            gear, goods, services, and perks! Whenever you post badges, your
            ammunition supply will automatically increase.
          </p>
          <Subtitle>See your Ammunition Count</Subtitle>
          <p>
            You can see your current bullet count, anytime, on the app by
            clicking the MENU (3 horizontal bars at the top left of the app),
            and click “Your Bullets, Invite Friends, Redeem”
          </p>
          <Subtitle>Invite Friends to the App and Build Ammunition</Subtitle>
          <p>
            Click “Your Bullets, Invite Friends, Redeem”, click “Share Your
            Invite Code”, enter someone from your contacts, email, or phone
            number. Your User Name is their referral code. When they sign up
            using your code, you earn valuable ammunition.
          </p>
          <Subtitle>Redeem Your Ammunition for Great Free Gear</Subtitle>
          <p>
            You have earned ammunition by posting badges to businesses on the
            apps map and by inviting friends to the app. Now you may redeem your
            bullets for Fantastic free gear, goods, services, and perks. Click
            “Your Bullets, Invite Friends, Redeem”, click “Redeem Your Bullets”
            to see all the offers. Click on any offer for the details. Choose
            and redeem an offer by clicking on “Redeem Offer” at the details
            page of the offer. The offer will be sent to you and the ammunition
            you spent on the offer will be deducted from your bullet count.
          </p>
          <Subtitle>Promote the App</Subtitle>
          <p>
            When you promote USAPACS to others, you support law Enforcement, the
            2nd Amendment, safety for police and citizens, and the American way
            of life. Please help by vigorously sharing the app with everyone
            that you know!
          </p>
        </InformationParagraph>
      </InformationContent>
    </>
  );
};

export default HowToUseTheAppPage;
