import React from "react";

const BookmarkIcon = ({ props, iconProps }: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 330 330"
      style={{}}
      xmlSpace="preserve"
      {...iconProps}
    >
      <path d="M265 0H65c-8.284 0-15 6.716-15 15v300a15 15 0 0 0 24.37 11.713L165 254.21l90.63 72.503a14.989 14.989 0 0 0 9.372 3.287A14.999 14.999 0 0 0 280 315V15c0-8.284-6.716-15-15-15zm-15 283.79-75.63-60.503c-2.739-2.191-6.055-3.287-9.37-3.287s-6.631 1.096-9.37 3.287L80 283.79V30h170v253.79z" />
    </svg>
  );
};

export default BookmarkIcon;
