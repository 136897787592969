import styled from "styled-components";

export const Title = styled.h1`
  color: #1b3963;
  font-size: 25px;
`;

export const Subtitle = styled.h2`
  color: #1b3963;
  font-size: 20px;
  font-weight: 500;
`;

export const ParagraphText = styled.p`
  color: #244169;
  font-size: 16px;
`;

export const LinkText = styled.span`
  a {
    color: #2480ff;
    text-decoration: none;
    font-size: 16px;
  }
`;

export const InformationTitle = styled.p`
  font-weight: bold;
  font-size: 16px;
`;

export const InformationText = styled.pre`
  color: grey;
  border: none;
  background: none;

  color: black;
  font-family: "Roboto", sans-serif;

  white-space: pre-wrap; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word;
`;

export const InformationParagraph = styled.div`
  h1 {
    margin-top: 50px;
    font-size: 1.5em;
  }

  h2 {
    font-size: 1.2em;
  }

  p {
    line-height: 1.8;
  }

  li {
    padding: 0;
    margin: 15px 0;
  }

  ul {
    margin: 0;
  }
`;

export const SuccessText = styled.p`
  color: #1b3963;
  font-weight: bold;
  margin: 0;
`;
