import { ErrorMessage, Field, FieldProps, FormikProps } from "formik";
import {
  FieldLabel,
  FieldSection,
  ModifiedGradientBorder,
  StyledErrorMessage,
  StyledField,
  FormContainer,
  SubmitButton,
} from "../../styled/edit-profile-form-elements";

import * as Yup from "yup";

import { UpdatePasswordFormContextProps } from "./update-password-form-container";
import useAuth from "../../lib/custom-hooks/useAuth";

export interface UpdatePasswordFormValues {
  newPassword: string;
  currentPassword: string;
}

export const UpdatePasswordFormSchema: Yup.SchemaOf<UpdatePasswordFormValues> = Yup.object(
  {
    currentPassword: Yup.string().required(
      "Please enter your current password."
    ),
    newPassword: Yup.string().required("Please enter a new password."),
  }
);

const UpdatePasswordForm = (
  props: UpdatePasswordFormContextProps & FormikProps<UpdatePasswordFormValues>
) => {
  const { authHandler } = useAuth();

  const { handleSubmit, isSubmitting, response } = props;

  return (
    <form onSubmit={handleSubmit}>
      <FormContainer>
        <input
          type="email"
          style={{
            display: "none",
          }}
          value={authHandler.getUserEmail() ?? ""}
          disabled={true}
        />

        <FieldSection>
          <FieldLabel>Current Password</FieldLabel>
          <ErrorMessage
            name="currentPassword"
            render={(msg) => <StyledErrorMessage>{msg}</StyledErrorMessage>}
          />
          <Field name="currentPassword">
            {({ field }: FieldProps) => (
              <StyledField type="password" {...field} />
            )}
          </Field>
          <ModifiedGradientBorder />
        </FieldSection>
        <FieldSection>
          <FieldLabel>New Password</FieldLabel>
          <ErrorMessage
            name="newPassword"
            render={(msg) => <StyledErrorMessage>{msg}</StyledErrorMessage>}
          />
          <Field name="newPassword">
            {({ field }: FieldProps) => (
              <StyledField type="password" {...field} />
            )}
          </Field>
          <ModifiedGradientBorder />
        </FieldSection>
        <SubmitButton
          type="submit"
          active={!isSubmitting}
          disabled={isSubmitting}
        >
          SAVE
        </SubmitButton>
        {response ? (
          <p style={{ marginTop: 25, color: "#324368" }}>{response}</p>
        ) : null}
      </FormContainer>
    </form>
  );
};

export default UpdatePasswordForm;
