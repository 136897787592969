import styled from "styled-components";
import { mobileMax } from "../lib/media-breakpoints";
import { applyBoxShadow } from "./box-shadow";

export const PrimaryButton = styled.button`
  color: white;
  font-size: 12px;
  flex: 0 0 42px;
  width: 147px;
  border: none;
  border-radius: 3px;
  @media (max-width: ${mobileMax}px) {
    align-self: center;
  }
  ${applyBoxShadow()}
  background:#8A98B3;
`;

export const SecondaryButton = styled.button<{ inActive?: boolean }>`
  color: white;
  font-size: 12px;
  flex: 0 0 42px;
  width: 100%;
  height: 39px;
  border: none;
  border-radius: 3px;
  @media (max-width: ${mobileMax}px) {
    align-self: center;
  }
  ${applyBoxShadow()}
  background:${(props) => (props.inActive ? "#8A98B3" : "#244169")};
`;

export const TransparentButton = styled.button<{ active: boolean }>`
  color: ${(props) => (props.active ? "white" : "#244169")};
  height: 30px;
  width: 100%;
  max-width: 90px;
  background: ${(props) => (props.active ? "#244169" : "none")};
  border-radius: 2px;
  border: #244169 solid 3px;
  font-size: 12px;
  font-weight: bold;
`;

export const TransparentActionButton = styled.button<{ active: boolean }>`
  background: none;
  border-radius: 2px;
  border: #244169 solid 3px;
  padding: 5px 10px;
  font-weight: 400;
  color: ${(props) => (props.active ? "black" : "grey")};
`;
