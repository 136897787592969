import { withFormik } from "formik";

import StepTwoForm, {
  StepTwoFormValues,
  StepTwoSchema,
} from "../components/step-two-form";

export interface StepTwoFormProps {
  disabled?: boolean;
}

export const CreateStepTwoForm = ({
  handleSubmit,
  existingValues,
  disabled,
}: {
  handleSubmit: (values: StepTwoFormValues) => void;
  existingValues: StepTwoFormValues;
  disabled: boolean;
}) => {
  const Form = withFormik<StepTwoFormProps, StepTwoFormValues>({
    handleSubmit: (values) => {
      handleSubmit(values);
    },
    mapPropsToValues: () => ({
      firstName: existingValues.firstName,
      lastName: existingValues.lastName,
      username: existingValues.username,
      referralCode: existingValues.referralCode,
      isLeosa: existingValues.isLeosa,
    }),
    validationSchema: StepTwoSchema,
    validateOnBlur: false,
    validateOnChange: false,
    validateOnMount: false,
  })(StepTwoForm);

  return <Form disabled={disabled} />;
};
