import { useNavigate } from "react-router-dom";
import BackButton from "../../components/back-button/back-button";
import UpdatePasswordFormContext from "../../components/update-password-form/update-password-form-container";
import {
  UpdatePasswordPageContainer,
  Content,
} from "./styled/update-password-page-styled";

interface UpdatePasswordPageProps {}

const UpdatePasswordPage = (props: UpdatePasswordPageProps) => {
  const navigate = useNavigate();
  return (
    <UpdatePasswordPageContainer>
      <BackButton handleBack={() => navigate("/account/edit")} />
      <Content>
        <UpdatePasswordFormContext />
      </Content>
    </UpdatePasswordPageContainer>
  );
};

export default UpdatePasswordPage;
