import { LogoContainer } from "../styled/register-styled";

import ImageHandler from "../../image-handler/image-handler";

const LogoSwitch = ({ step }: { step: number }) => {
  if (step !== 1) {
    return null;
  }

  return (
    <LogoContainer>
      <div style={{ marginTop: "auto" }}>
        <ImageHandler name={"logo"} />
      </div>
    </LogoContainer>
  );
};

export default LogoSwitch;
