import { useContext } from "react";
import IconHandler from "../../../../components/icon-handler/icon-handler";
import { UserLocationButtonContainer } from "../../styled/user-location-button";
import { MapContext } from "../../util/contexts";
import useGeoLocation from "../../../../lib/custom-hooks/useLocation";

const UserLocationButton = () => {
  const { location } = useGeoLocation();
  const { map } = useContext(MapContext);

  if (!location || !map) {
    return null;
  }

  return (
    <UserLocationButtonContainer
      style={{ position: "absolute", top: 0, left: 0, zIndex: 4000 }}
      onClick={() =>
        map.panTo({
          lat: location.coords.latitude,
          lng: location.coords.longitude,
        })
      }
    >
      <IconHandler name="userLocation" iconProps={{ width: 20, height: 20 }} />
    </UserLocationButtonContainer>
  );
};

export default UserLocationButton;
