import React from "react";
import { TransparentButton } from "../../styled/buttons";
import {
  PolicyUpdateSectionContainer,
  PolicyUpdateText,
  ButtonSection,
} from "./styled/policy-update-section-styled";

export interface PolicyUpdateSectionProps {
  text: string;
  policyType: string;
  currentAlignment?: number;
  handleClick: (alignment: number) => void;
  values: number[];
  disabled: boolean;
}

const PolicyUpdateSection = (props: PolicyUpdateSectionProps) => {
  return (
    <PolicyUpdateSectionContainer>
      <PolicyUpdateText>{props.text}</PolicyUpdateText>
      <ButtonSection>
        <TransparentButton
          style={{ marginRight: 40 }}
          active={props.currentAlignment === props.values[0]}
          onClick={() => props.handleClick(props.values[0])}
        >
          YES
        </TransparentButton>
        <TransparentButton
          active={props.currentAlignment === props.values[1]}
          onClick={() => props.handleClick(props.values[1])}
        >
          NO
        </TransparentButton>
      </ButtonSection>
    </PolicyUpdateSectionContainer>
  );
};

export default PolicyUpdateSection;
