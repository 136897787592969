import styled from "styled-components";
import { TransparentActionButton } from "../../styled/buttons";
import { InformationTitle } from "../../styled/typography";

export const SectionTitle = styled(InformationTitle)`
  margin: 25px 0 5px 0;
`;

export const Container = styled.div``;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SectionDisplay = styled.p`
  margin-top: 0px;
`;

export const ActionButton = styled(TransparentActionButton)`
  margin: 10px 0;
`;
