import React from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { getUserDetails } from "../../lib/api/user";
import { SecondaryButton } from "../../styled/buttons";
import { Loader } from "../../styled/loader";
import { ErrorIcon, ErrorIconContainer } from "../../styled/status-icons";
import { LinkText, ParagraphText } from "../../styled/typography";
import BulletDisplay from "./components/bullet-display";
import ReferralCodeDisplay from "./components/referral-code-display";
import { ReferralContainer } from "./styled/referral-styled";

const Referral = () => {
  const { data, isLoading, isError } = useQuery(
    ["/me/details"],
    getUserDetails
  );

  if (isLoading) {
    return <Loader style={{ margin: "50px auto" }} />;
  }

  if (isError) {
    return (
      <p>
        Could not retreive information at this time. Please try again later.
      </p>
    );
  }

  return (
    <ReferralContainer>
      <BulletDisplay amount={data?.bullets ?? 0} />
      <LinkText style={{ marginTop: 0 }}>
        <Link to="/menu/how-to-use-the-app">How Invites Work</Link>
      </LinkText>
      <ParagraphText>Share your invite code</ParagraphText>
      {data && data.username ? (
        <>
          <ReferralCodeDisplay referralCode={data.username} />
          <Link to="/menu/ammunition/offers">
            <SecondaryButton style={{ maxWidth: 294, marginTop: 12 }}>
              REDEEM YOUR BULLETS
            </SecondaryButton>
          </Link>
        </>
      ) : (
        <ErrorIconContainer
          style={{
            justifyContent: "center",
            display: "flex",
            textAlign: "center",
          }}
        >
          <ErrorIcon>!</ErrorIcon>
          <p style={{ color: "red", marginLeft: 10 }}>
            Could not display referral code at this time. Please try again
            later.
          </p>
        </ErrorIconContainer>
      )}
    </ReferralContainer>
  );
};

export default Referral;
