import { formatPolicy } from "../../../lib/format-policy";
import BadgeDisplay from "../../badges-display/components/badge-display";

import {
  BadgeRowContainer,
  LeftSection,
  RightSection,
  PolicyLabel,
  PostedByLabel,
  BadgeDisplayWrapper,
} from "../styled/badges-row-styled";

export enum PolicyDisplayStrings {
  leosaPolicy = "Leosa Policy",
}

export interface BadgeRowProps {
  policy: string;
  alignment: number;
  username?: string;
  createdAt?: string;
}

export const BadgeRow = ({
  policy,
  alignment,
  username,
  createdAt,
}: BadgeRowProps) => {
  return (
    <BadgeRowContainer>
      <LeftSection>
        <BadgeDisplayWrapper>
          <BadgeDisplay policy={policy} alignment={alignment} />
        </BadgeDisplayWrapper>
      </LeftSection>
      <RightSection>
        <PolicyLabel>{formatPolicy(policy)}</PolicyLabel>
        {username !== "" ? (
          <PostedByLabel>Posted By: {username}</PostedByLabel>
        ) : null}
      </RightSection>
    </BadgeRowContainer>
  );
};

export default BadgeRow;
