import React from "react";

import "@googlemaps/typescript-guards";
import { useDeepCompareEffectForMaps } from "../../../../lib/google-maps-hooks/google-map-hooks";
import { useContext } from "react";
import { MapContext } from "../../util/contexts";

export interface MapProps extends google.maps.MapOptions {
  onClick?: (e: google.maps.MapMouseEvent) => void;
  onIdle?: (map: google.maps.Map) => void;
}

const Map: React.FC<MapProps> = ({
  onClick,
  onIdle,
  children,
  center,
  ...options
}) => {
  const { map, setMap } = useContext(MapContext);

  const ref = React.useRef<HTMLDivElement>(null);

  useDeepCompareEffectForMaps(() => {
    if (map) {
      map.setOptions(options);
    }
  }, [map, options]);

  React.useEffect(() => {
    if (ref.current && !map) {
      setMap(
        new window.google.maps.Map(ref.current, {
          zoom: 13,
          minZoom: 5,
          center: center,
          styles: [
            {
              featureType: "poi",
              stylers: [{ visibility: "off" }],
            },
            {
              featureType: "transit",
              stylers: [{ visibility: "off" }],
            },
          ],
          mapTypeControl: false,
          fullscreenControl: false,
          streetViewControl: false,
          keyboardShortcuts: false,
        })
      );
    }
  }, [ref, map]);

  React.useEffect(() => {
    if (map) {
      ["click", "idle"].forEach((eventName) =>
        google.maps.event.clearListeners(map, eventName)
      );

      if (onClick) {
        map.addListener("click", onClick);
      }

      if (onIdle) {
        map.addListener("idle", () => onIdle(map));
      }
    }
  }, [map, onClick, onIdle]);

  return (
    <>
      <div ref={ref} style={{ width: "100%", height: "100%", flex: "1" }} />
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          // set the map prop on the child component
          return React.cloneElement(child, { map });
        }
      })}
    </>
  );
};

export default Map;
