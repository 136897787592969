import styled from "styled-components";
import { applyLightBoxShadow } from "../../styled/box-shadow";

export const ConfirmBulletItemAddressContainer = styled.div`
  max-width: 600px;
  margin: 0 auto;
  width: 100%;
`;

export const LabelHeader = styled.p`
  font-size: 16px;
  margin-bottom: 5px;
`;

export const ValueText = styled.p`
  font-size: 14px;
  margin-bottom: 5px;
  margin-left: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 0;
`;

export const SubmitButton = styled.button.attrs(() => ({
  type: "button",
}))`
  background: #667fa2;
  color: white;
  height: 42px;
  max-width: 147px;
  width: 100%;
  border: none;
  border-radius: 4px;
  margin: 40px auto;
  ${applyLightBoxShadow()};
  display: block;
`;
