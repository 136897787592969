import { withFormik } from "formik";

import StepOneForm, {
  StepOneFormValues,
  stepOneSchema,
} from "../components/step-one-form";

export interface StepOneFormProps {}

export const CreateStepOneForm = ({
  handleSubmit,
  existingValues,
}: {
  handleSubmit: (values: StepOneFormValues) => void;
  existingValues: StepOneFormValues;
}) => {
  const Form = withFormik<StepOneFormProps, StepOneFormValues>({
    handleSubmit: (values) => {
      handleSubmit(values);
    },
    mapPropsToValues: () => ({
      email: existingValues.email,
      password: existingValues.password,
    }),
    validationSchema: stepOneSchema,
    validateOnBlur: false,
    validateOnChange: false,
    validateOnMount: false,
  })(StepOneForm);

  return <Form />;
};
