import styled from "styled-components";

export const AddImagePromptContainer = styled.div`
  background: #e7e7e7;
  width: 100%;
  height: 100%;
  position: relative;
`;

export const AddIcon = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  colro: black;
  font-size: 18px;
  border: #c6c6c6 solid 1px;
  position: absolute;
  bottom: -10px;
  right: -10px;
`;
