import styled from "styled-components";
import IconHandler from "../components/icon-handler/icon-handler";
import { applyBoxShadow } from "../styled/box-shadow";

const BadgeContainer = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  background: none;
`;

const LeosaBadge = () => {
  return (
    <BadgeContainer>
      <IconHandler
        name="leosaPolicy-positive"
        iconProps={{ width: "40", height: "40" }}
      />
    </BadgeContainer>
  );
};

export default LeosaBadge;
