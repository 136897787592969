import { useMutation, useQueryClient } from "react-query";
import { bookmarkArticle, deleteBookmark } from "../../lib/api/article";
import { Article } from "../../ts/article";
import IconHandler from "../icon-handler/icon-handler";
import {
  ArticleHeaderContainer,
  LeftSide,
  ArticleTitle,
  ArticleDate,
  RightSide,
  BookmarkButton,
} from "./styled/article-header-styled";

interface ArticleHeaderProps {
  articleId: string;
  title: string;
  date: string;
  isBookmarked: boolean;
}

const ArticleHeader = (props: ArticleHeaderProps) => {
  const client = useQueryClient();

  const bookmarkMutation = useMutation(
    ["/me/bookmarks", props.articleId],
    () => bookmarkArticle(props.articleId),
    {
      onSuccess: () => {
        client.invalidateQueries("/me/bookmarks");

        client.setQueryData<Article | undefined>(
          ["/article", props.articleId],
          (old) => {
            if (old) {
              old.bookmarked = true;
            }

            return old;
          }
        );
      },
    }
  );

  const deleteBookmarkMutation = useMutation(
    ["/me/bookmarks/remove", props.articleId],
    () => deleteBookmark(props.articleId),
    {
      onSuccess: () => {
        client.invalidateQueries("/me/bookmarks");

        client.setQueryData<Article | undefined>(
          ["/article", props.articleId],
          (old) => {
            if (old) {
              old.bookmarked = false;
            }

            return old;
          }
        );
      },
    }
  );

  let dateString = "";
  try {
    let date = new Date(props.date);

    var dd = String(date.getDate()).padStart(2, "0");
    var mm = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = date.getFullYear();

    dateString = mm + "/" + dd + "/" + yyyy;
  } catch (e) {}

  return (
    <ArticleHeaderContainer>
      <LeftSide>
        <ArticleTitle>{props.title}</ArticleTitle>
        <ArticleDate>{dateString}</ArticleDate>
      </LeftSide>
      <RightSide>
        <BookmarkButton>
          {props.isBookmarked ? (
            <span
              onClick={() => {
                if (!deleteBookmarkMutation.isLoading) {
                  deleteBookmarkMutation.mutate();
                }
              }}
            >
              <IconHandler
                name="bookmarkActive"
                iconProps={{ width: "30", height: "50", fill: "black" }}
              />
            </span>
          ) : (
            <span
              onClick={() => {
                if (!bookmarkMutation.isLoading) {
                  bookmarkMutation.mutate();
                }
              }}
            >
              <IconHandler
                name="bookmark"
                iconProps={{ width: "30", height: "50", fill: "black" }}
              />
            </span>
          )}
        </BookmarkButton>
      </RightSide>
    </ArticleHeaderContainer>
  );
};

export default ArticleHeader;
