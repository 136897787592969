import { useState } from "react";
import {
  PhotoPickerContainer,
  FileInput,
  DisplayImage,
  Placeholder,
  DisplayContainer,
} from "./styled";

interface AdvertisementImagePickerProps {
  onChange: (file: any) => void;
  inputName: string;
  imageHeight: number;
  imageWidth: number;
}

const AdvertisementImagePicker = (props: AdvertisementImagePickerProps) => {
  const [currentlySelected, setCurrentlySelected] =
    useState<File | undefined>(undefined);

  return (
    <PhotoPickerContainer>
      <DisplayContainer>
        {currentlySelected ? (
          <DisplayImage
            src={URL.createObjectURL(currentlySelected)}
            alt="selected"
            style={{ height: props.imageHeight, maxWidth: props.imageWidth }}
          />
        ) : (
          <Placeholder
            style={{ height: props.imageHeight, maxWidth: props.imageWidth }}
          />
        )}
      </DisplayContainer>
      <FileInput
        text={"UPLOAD"}
        onChange={(e) => {
          if (e && e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            setCurrentlySelected(file);
            props.onChange(file);
          } else {
          }
        }}
        accept="image/png,image/jepg,image/jpg"
      />
    </PhotoPickerContainer>
  );
};

export default AdvertisementImagePicker;
