import styled from "styled-components";

export const ResultsTableListingContainer = styled.div`
  display: flex;
`;

export const LeftSection = styled.div`
  display: flex;
  flex-direction: column;
  // flex: 1;

  width: 100%;
`;

export const RightSection = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
`;

export const TopSection = styled.div`
  max-width: 300px;
`;

export const BottomSection = styled.div`
  display: flex;
`;

export const BusinessNameLabel = styled.p`
  font-size: 16px;
  font-weight: 700;
  margin: 15px 20px 2px 20px;
  flex: 0 0 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const InfoSection = styled.div`
  margin: 0 0 15px 20px;
  flex: 1 0 50px;
  display: flex;

  overflow: hidden;

  font-size: 12px;
  font-weight: 400;
  color: #8c8c8c;

  @media (max-width: 320px) {
    flex-direction: column;
  }
`;

export const BusinessCategoryLabel = styled.p`
  margin: 0 0 10px 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  @media (max-width: 320px) {
    margin: 0;
  }
`;
export const BusinessDistanceLabel = styled.p`
  margin: 0 0 10px 5px;

  @media (max-width: 320px) {
    width: 100%;
    margin: 0;
  }
`;

export const BadgeSection = styled.div`
  margin: 0 20px 0 auto;
  flex: 0 0 185px;

  img {
    width: 34px;
    height: auto;
  }
`;

export const ImageSection = styled.div`
  height: 60px;
  width: 60px;
  overflow: hidden;
  margin-right: 10px;
  margin-bottom: 0px;
  margin-top: 0px;
`;

export const PrimaryPhoto = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 5px;
`;
