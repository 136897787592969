import { useQuery } from "react-query";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import BackButton from "../../components/back-button/back-button";
import { getAmmunitionOffer } from "../../lib/api/ammunition";
import { getUserDetails } from "../../lib/api/user";
import { Loader } from "../../styled/loader";
import {
  ViewBulletItemPageContainer,
  ImageSection,
  Title,
  Cost,
  Content,
  Description,
  RedeemButton,
} from "./styled";

interface ViewBulletItemPageProps {}

const ViewBulletItemPage = (props: ViewBulletItemPageProps) => {
  const navigate = useNavigate();
  const { offer } = useParams();

  const offerQuery = useQuery(["/ammunition/offer", offer], () =>
    getAmmunitionOffer(offer ?? "")
  );

  const userQuery = useQuery("/me/details", () => getUserDetails());

  if (!offer) {
    return <Navigate to="/menu/ammunition/offers" />;
  }

  if (offerQuery.isLoading || userQuery.isLoading) {
    return (
      <ViewBulletItemPageContainer>
        <Loader />
      </ViewBulletItemPageContainer>
    );
  }

  if (
    offerQuery.isError ||
    userQuery.isError ||
    !offerQuery.data ||
    !userQuery.data ||
    (!userQuery.data.bullets && userQuery.data.bullets !== 0)
  ) {
    return (
      <ViewBulletItemPageContainer>
        <BackButton
          handleBack={() => {
            navigate("/menu/ammunition/offers");
          }}
        />

        <p>Could not load offer at this time.</p>
      </ViewBulletItemPageContainer>
    );
  }

  return (
    <ViewBulletItemPageContainer>
      <BackButton
        handleBack={() => {
          navigate("/menu/ammunition/offers");
        }}
      />
      <Content>
        <ImageSection>
          {offerQuery.data.imageUrl ? (
            <img
              src={offerQuery.data.imageUrl}
              alt="Offer Item"
              height={200}
              width={"100%"}
              style={{ maxWidth: 400 }}
            />
          ) : null}
        </ImageSection>
        <Title>{offerQuery.data.title}</Title>
        <Cost>{offerQuery.data.cost} Bullets</Cost>
        <Description>{offerQuery.data.description}</Description>
        <RedeemButton
          active={offerQuery.data.cost <= userQuery.data.bullets}
          onClick={() => {
            if (
              userQuery.data.bullets &&
              offerQuery.data.cost <= userQuery.data.bullets &&
              offer
            ) {
              navigate(`/menu/ammunition/redeem/${offer}`);
            }
          }}
        >
          REDEEM
        </RedeemButton>
      </Content>
    </ViewBulletItemPageContainer>
  );
};

export default ViewBulletItemPage;
