import { IncidentReport } from "../../ts/point-of-interest";
import BadgeRow from "./components/badge-row";
import {
  BadgesTableContainer,
  TitleLabel,
  TableContainer,
} from "./styled/badges-table-styled";

export interface BadgesTableProps {
  reports: IncidentReport[];
}

const BadgesTable = ({ reports }: BadgesTableProps) => {
  return (
    <BadgesTableContainer>
      <TitleLabel></TitleLabel>
      <TableContainer>
        {reports.map((report, index) => (
          <div
            key={index}
            style={index % 2 === 0 ? { background: "#F8F8F8" } : {}}
          >
            <BadgeRow
              policy={report.incidentType}
              alignment={report.alignment}
              createdAt={report.createdAt}
              username={report.user ? report.user.username : ""}
            />
          </div>
        ))}
      </TableContainer>
    </BadgesTableContainer>
  );
};

export default BadgesTable;
