import { withFormik } from "formik";

import SetPasswordForm, {
  setPasswordFormSchema,
  SetPasswordValues,
} from "../components/setPasswordForm";

export interface ResetPasswordFormProps {}

export const CreateSetPasswordForm = ({
  onSubmit,
}: {
  onSubmit: (newPassword: string) => Promise<void>;
}) => {
  const Form = withFormik<ResetPasswordFormProps, SetPasswordValues>({
    handleSubmit: async (values) => {
      onSubmit(values.password);
    },
    validationSchema: setPasswordFormSchema,
    validateOnBlur: false,
    validateOnChange: false,
    validateOnMount: false,
    mapPropsToValues: () => ({
      password: "",
      confirmPassword: "",
    }),
  })(SetPasswordForm);

  return <Form />;
};
