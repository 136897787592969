import { ErrorMessage, Field, FormikProps } from "formik";
import React from "react";

import * as Yup from "yup";
import {
  FieldsWrapper,
  FormContainer,
  FormLabel,
  SubmitButton,
} from "../../register/styled/global-styled";

export interface SetPasswordValues {
  password: string;
  confirmPassword: string;
}

export const setPasswordFormSchema: Yup.SchemaOf<SetPasswordValues> = Yup.object(
  {
    password: Yup.string().required("Please enter a new password"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Please confirm your password"),
  }
);

const SetPasswordForm = (props: FormikProps<SetPasswordValues>) => {
  const { handleSubmit, isSubmitting, values } = props;

  let canSubmit = false;
  try {
    setPasswordFormSchema.validateSync(values);
    canSubmit = true;
  } catch (e) {
    canSubmit = false;
  }

  return (
    <form onSubmit={handleSubmit}>
      <FormContainer>
        <FieldsWrapper>
          <FormLabel>New Password</FormLabel>
          <ErrorMessage
            name="password"
            component="span"
            className="error-message"
          />
          <Field name="password" type={"password"} />
        </FieldsWrapper>
        <FieldsWrapper>
          <FormLabel>Confirm new Password</FormLabel>
          <ErrorMessage
            name="confirmPassword"
            component="span"
            className="error-message"
          />
          <Field name="confirmPassword" type={"password"} />
        </FieldsWrapper>
        <SubmitButton
          disabled={isSubmitting}
          active={canSubmit && !isSubmitting}
        >
          SUBMIT
        </SubmitButton>
      </FormContainer>
    </form>
  );
};

export default SetPasswordForm;
