import { Dispatch, SetStateAction } from "react";
import { useInfiniteQuery } from "react-query";
import { getUserBusinesses } from "../../lib/api/user";
import { Loader } from "../../styled/loader";
import { PointOfInterest } from "../../ts/point-of-interest";
import SelectBusinessTable from "./select-business-table";

interface SelectBusinessTableWrapperProps {
  onChange: (ids: number[]) => void;
  state: number[];
  setState: Dispatch<SetStateAction<number[]>>;
}

const SelectBusinessTableWrapper = (props: SelectBusinessTableWrapperProps) => {
  const { data, isLoading, isFetching, isError, fetchNextPage, hasNextPage } =
    useInfiniteQuery(
      ["/me/businesses"],
      ({ pageParam = 0 }) => getUserBusinesses(pageParam),
      {
        getNextPageParam: (lastPage) => lastPage.nextPage,
      }
    );

  if (isLoading) {
    return <Loader />;
  }

  if (isError || !data) {
    return null;
  }

  const businesses: PointOfInterest[] = [];

  data.pages.forEach((page) => {
    businesses.push(...page.businesses);
  });

  return (
    <SelectBusinessTable
      selected={props.state}
      setSelected={props.setState}
      businesses={businesses}
      onChange={props.onChange}
    />
  );
};

export default SelectBusinessTableWrapper;
