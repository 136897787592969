import React from "react";
import {
  AddIcon,
  AddImagePromptContainer,
} from "../styled/add-image-prompt-styled";

const AddImagePrompt = () => {
  return <AddImagePromptContainer></AddImagePromptContainer>;
};

export default AddImagePrompt;
