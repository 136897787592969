import React, { useState } from "react";
import { PointOfInterest } from "../../ts/point-of-interest";

import Header from "./components/header";
import ViewBusinessSwitch from "./components/view-business-switch";

import { ViewBusinessContainer } from "./styled/view-business-styled";

export interface ViewBusinessProps {
  pointOfInterest: PointOfInterest;
  handleClose?: () => void;
  onPostBadgeSuccess: (newBadgeAlignments: any) => void;
}

export enum VIEW_BUSINESS_STATE {
  VIEW_DETAILS = 1,
  VIEW_BADGES = 2,
  POST_BADGE = 3,
  UPDATE_PHOTOS = 4,
  VIEW_OFFER = 5,
}

const ViewBusiness = (props: ViewBusinessProps) => {
  const [state, setState] = useState<{
    step: VIEW_BUSINESS_STATE;
    businessOffer: number | undefined;
  }>({
    step: VIEW_BUSINESS_STATE.VIEW_DETAILS,
    businessOffer: undefined,
  });

  return (
    <ViewBusinessContainer>
      <Header
        handleClose={() => {
          if (state.step === VIEW_BUSINESS_STATE.VIEW_DETAILS) {
            if (props.handleClose) {
              props.handleClose();
            }
          }

          if (state.step === VIEW_BUSINESS_STATE.VIEW_BADGES) {
            setState({ ...state, step: VIEW_BUSINESS_STATE.VIEW_DETAILS });
          }

          if (state.step === VIEW_BUSINESS_STATE.POST_BADGE) {
            setState({ ...state, step: VIEW_BUSINESS_STATE.VIEW_DETAILS });
          }

          if (state.step === VIEW_BUSINESS_STATE.VIEW_OFFER) {
            setState({ ...state, step: VIEW_BUSINESS_STATE.VIEW_DETAILS });
          }
        }}
        businessName={props.pointOfInterest.name}
        businessCategory={props.pointOfInterest.category}
        badgeAlignments={props.pointOfInterest.badgeAlignments}
        distanceFromUser={props.pointOfInterest.milesFromUser}
      />
      <ViewBusinessSwitch
        {...props}
        businessOffer={state.businessOffer}
        state={state.step}
        setState={setState}
        onPostBadgeSuccess={props.onPostBadgeSuccess}
      />
    </ViewBusinessContainer>
  );
};

export default ViewBusiness;
