import styled from "styled-components";
import { mobileMax } from "../../lib/media-breakpoints";

export const SuggestPostPageContainer = styled.div``;

export const Content = styled.div`
  @media (min-width: ${mobileMax + 1}px) {
    display: flex;
    flex-direction: column;

    align-items: center;
  }
`;
