export const PostablePolicies: string[] = [
  "leosaPolicy",
  "concealedCarryPolicy",
  "openCarryPolicy",
  "metalDetectorPolicy",
];

export const BusinessControlledPolicies: {
  text: string;
  policy: string;
}[] = [
  {
    text:
      "We allow armed Law Enforcement: on duty, off duty, retired as per LEOSA",
    policy: "leosaPolicy",
  },

  {
    text: "We allow Right to Carry citizens to carry concealed",
    policy: "openCarryPolicy",
  },
  {
    text: "We allow Open Carry",
    policy: "concealedCarryPolicy",
  },
  {
    text: "My establishment requires a metal detector to enter",
    policy: "menuPolicy",
  },
  {
    text: "My menu contains 51% or more alcoholic beverages",
    policy: "menuPolicy",
  },
];
