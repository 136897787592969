import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { RedeemBulletItemFormValues } from "../../components/redeem-bullet-item-form/redeem-bullet-item-form";

import * as Sentry from "@sentry/react";

import { redeemOffer } from "../../lib/api/ammunition";
import { Loader } from "../../styled/loader";
import StepHandler from "./StepHandler";
import { Content, RedeemBulletItemPageContainer } from "./styled";
import { ParagraphText, Subtitle, Title } from "../../styled/typography";
import BackButton from "../../components/back-button/back-button";
import { ErrorIcon, ErrorIconContainer } from "../../styled/status-icons";

interface RedeemBulletItemPageProps {}

const RedeemBulletItemPage = (props: RedeemBulletItemPageProps) => {
  const navigate = useNavigate();
  const client = useQueryClient();

  const [state, setState] = useState<{
    step: number;
    formState?: RedeemBulletItemFormValues;
  }>({
    step: 1,
    formState: undefined,
  });

  const params = useParams();

  const redeemMutation = useMutation(
    ["/ammunition/offers/redeem", params.offer],
    (values: RedeemBulletItemFormValues) =>
      redeemOffer(params.offer ?? "", values),
    {
      onSuccess: () => {
        client.invalidateQueries("/me/details");
      },
    }
  );

  if (!params.offer) {
    return <Navigate to="/menu/ammunition" />;
  }

  if (redeemMutation.isLoading) {
    return (
      <RedeemBulletItemPageContainer>
        <Loader />
      </RedeemBulletItemPageContainer>
    );
  }

  if (redeemMutation.isError) {
    if (redeemMutation.error instanceof Error) {
      return (
        <RedeemBulletItemPageContainer>
          <BackButton
            handleBack={() => {
              if (state.step === 1) {
                navigate(`/menu/ammunition/offer/${params.offer}`);
              } else {
                setState({
                  ...state,
                  step: state.step - 1,
                });
              }
            }}
          />
          <Title style={{ margin: 20 }}>Offer was not redeemed.</Title>
          <p style={{ color: "red", marginLeft: 20 }}>
            {redeemMutation.error.message}
          </p>
        </RedeemBulletItemPageContainer>
      );
    }

    return (
      <RedeemBulletItemPageContainer>
        <BackButton
          handleBack={() => {
            if (state.step === 1) {
              navigate(`/menu/ammunition/offer/${params.offer}`);
            } else {
              setState({
                ...state,
                step: state.step - 1,
              });
            }
          }}
        />
        <Title style={{ margin: 20 }}>Offer was not redeemed.</Title>
        <p style={{ color: "red", marginLeft: 20 }}>
          Could not redeem offer at this time.
        </p>
      </RedeemBulletItemPageContainer>
    );
  }

  if (redeemMutation.isSuccess) {
    return (
      <RedeemBulletItemPageContainer>
        <BackButton
          handleBack={() => {
            navigate("/menu/ammunition");
          }}
        />
        <div style={{ textAlign: "center", margin: "40px 20px" }}>
          <Title>This offer has been redeemed.</Title>
          <Subtitle
            style={{
              fontSize: 16,
              fontWeight: "normal",
              color: "black",
            }}
          >
            USA PACS will be in touch with more details.
          </Subtitle>
        </div>
      </RedeemBulletItemPageContainer>
    );
  }

  return (
    <RedeemBulletItemPageContainer>
      <BackButton
        handleBack={() => {
          if (state.step === 1) {
            navigate(`/menu/ammunition/offer/${params.offer}`);
          } else {
            setState({
              ...state,
              step: state.step - 1,
            });
          }
        }}
      />
      <Content>
        <StepHandler
          step={state.step}
          formState={state.formState}
          onFormSubmit={(values: RedeemBulletItemFormValues) => {
            setState({
              step: 2,
              formState: values,
            });
          }}
          handleRedeem={() => {
            try {
              if (state.formState) {
                redeemMutation.mutate(state.formState);
              } else {
                throw Error("Form state not set in redeem item");
              }
            } catch (e) {
              Sentry.captureException(e);
            }
          }}
        />
      </Content>
    </RedeemBulletItemPageContainer>
  );
};

export default RedeemBulletItemPage;
