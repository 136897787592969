import openCarryNegative from "../../../images/badges/openCarryPolicy/openCarryPolicy_red.png";

const OpenCarryNegative = ({ imageProps }: any) => {
  return (
    <img
      src={openCarryNegative}
      alt="open-carry-policy-negative"
      {...imageProps}
    />
  );
};

export default OpenCarryNegative;
