import { useState } from "react";

import { Navigate, useNavigate, useParams } from "react-router-dom";

import BackButton from "../../components/back-button/back-button";
import { BannerAdDetailsUpdateValues } from "../../components/banner-ad-details-update/banner-ad-details-update";
import BannerAdImageUpdate from "../../components/banner-ad-image-update/banner-ad-image-update";
import BannerAdInfo from "../../components/banner-ad-info/banner-ad-info";

import { Loader } from "../../styled/loader";

import {
  AdvertisementsGradientBorder,
  AdvertisementsPageTitle,
  AdvertisementSubmitButton,
  Content,
  Wrapper,
} from "../../styled/advertisement-form-elements";
import { InfoText, SectionTitle } from "../AdvertisementsMenu/styled";
import SentryHandler from "../../lib/sentry/sentry";
import { useDataQuery } from "./util/dataQuery";
import { usePauseMutation } from "./util/pauseMutation";
import { useResumeMutation } from "./util/resumeMutation";
import { useUpdateImageMutation } from "./util/useUpdateImageMutation";
import BannerAdsDetailsUpdateWrapper from "../../components/banner-ad-details-update/banner-ad-details-update-wrapper";
import { useUpdateDetailsMutation } from "./util/updateDetailsMutation";
import { StyledErrorMessage } from "../../styled/form-elements";
import AdvertisementPlansTableWrapper from "../../components/advertisement-plans-table/advertisement-plans-table-wrapper";
import { SuccessText } from "../../styled/typography";
import { DeleteButton } from "./styled";
import { useDeleteMutation } from "./util/deleteMutation";

interface UpdateBannerAdPageProps {}

const UpdateBannerAdPage = (props: UpdateBannerAdPageProps) => {
  const params = useParams();

  const navigate = useNavigate();

  const uid = params.bannerUId;

  const [planErrorMessage, setPlanErrorMessage] =
    useState<string | undefined>(undefined);
  const [selectedAdPlan, setPlan] = useState<string | undefined>(undefined);

  const dataQuery = useDataQuery(uid);
  const pauseMutation = usePauseMutation(uid);
  const resumeMutation = useResumeMutation(uid);
  const updateImageMutation = useUpdateImageMutation(uid);
  const updateDetailsMutation = useUpdateDetailsMutation(uid);
  const deleteMutation = useDeleteMutation(uid);

  if (!uid) {
    return <Navigate to="/account/advertisements" />;
  }

  if (dataQuery.isLoading) {
    return (
      <Content>
        <Loader />
      </Content>
    );
  }

  if (dataQuery.isError || !dataQuery.data) {
    return (
      <Content>
        <p>Could not get banner advertisement at this time.</p>
      </Content>
    );
  }

  return (
    <Content>
      <BackButton handleBack={() => navigate("/account/advertisements")} />
      <Wrapper>
        <AdvertisementsPageTitle>
          UPDATE BANNER ADVERTISEMENT
        </AdvertisementsPageTitle>
        <BannerAdInfo
          loading={resumeMutation.isLoading || pauseMutation.isLoading}
          handleButtonClick={() => {
            if (dataQuery.data.status === "active") {
              pauseMutation.mutate({ id: dataQuery.data.id });
            } else if (dataQuery.data.status === "inactive") {
              resumeMutation.mutate({ id: dataQuery.data.id });
            } else {
              SentryHandler.captureMessage(
                "Invalid Banner Ad Status for Handle Button Click"
              );
            }
          }}
          status={dataQuery.data.status}
          expirationDate={dataQuery.data.expirationDate}
        />
        {pauseMutation.isError || resumeMutation.isError ? (
          <SuccessText style={{ alignSelf: "center" }}>
            Could not update at this time.
          </SuccessText>
        ) : null}
        {dataQuery.data.expirationDate === null ? (
          <DeleteButton
            onClick={() => {
              const confirmed = window.confirm(
                "Do you want to delete this banner advertisement?"
              );

              if (confirmed) {
                deleteMutation.mutate();
              }
            }}
          >
            DELETE
          </DeleteButton>
        ) : null}
      </Wrapper>
      <AdvertisementsGradientBorder />
      <Wrapper>
        <SectionTitle>Banner Advertisement Image</SectionTitle>
        <InfoText style={{ marginBottom: 10 }}>
          *New image will not be displayed until reviewed by USA PACS.
        </InfoText>
        <BannerAdImageUpdate
          imageSrc={`${dataQuery.data.url}?id=${
            dataQuery.data.updatedAt ?? "1"
          }`}
          imageWidth={300}
          loading={updateImageMutation.isLoading}
          imageHeight={100}
          handleClick={(image: File) => {
            if (
              window.confirm(
                "This will put your advertisement in review until approved by USA PACS. Do you want to continue?"
              )
            ) {
              updateImageMutation.mutate({
                id: dataQuery.data.id,
                image,
              });
            }
          }}
        />
        {updateImageMutation.isError ? (
          <SuccessText style={{ alignSelf: "center" }}>
            Could not update at this time.
          </SuccessText>
        ) : null}
      </Wrapper>
      <AdvertisementsGradientBorder />
      <Wrapper>
        <BannerAdsDetailsUpdateWrapper
          loading={updateDetailsMutation.isLoading}
          onSubmit={(values: BannerAdDetailsUpdateValues) => {
            if (
              window.confirm(
                "This will put your advertisement in review until approved by USA PACS. Do you want to continue?"
              )
            ) {
              updateDetailsMutation.mutate({
                id: dataQuery.data.id,
                link: values.websiteLink,
              });
            }
          }}
          existingWebsiteLink={dataQuery.data.websiteLink}
        />
        {updateDetailsMutation.isSuccess ? (
          <SuccessText style={{ alignSelf: "center" }}>
            Changes Saved
          </SuccessText>
        ) : null}
        {updateDetailsMutation.isError ? (
          <SuccessText style={{ alignSelf: "center" }}>
            Could not update at this time.
          </SuccessText>
        ) : null}
      </Wrapper>
      <AdvertisementsGradientBorder />
      <Wrapper>
        <SectionTitle>Extend Advertisement</SectionTitle>
        <InfoText>*Payment will be confirmed on next page.</InfoText>
        {planErrorMessage ? (
          <StyledErrorMessage>{planErrorMessage}</StyledErrorMessage>
        ) : null}
        <AdvertisementPlansTableWrapper
          onClick={(uid: string) => {
            setPlan(uid);
          }}
        />
        <AdvertisementSubmitButton
          isDisabled={false}
          onClick={() => {
            if (selectedAdPlan) {
              navigate(
                `/account/advertisements/banner-advertisement/payment?o=${uid}&i=${selectedAdPlan}`
              );
            } else {
              setPlanErrorMessage("Please select a plan.");
            }
          }}
        >
          CHECKOUT
        </AdvertisementSubmitButton>
      </Wrapper>
    </Content>
  );
};

export default UpdateBannerAdPage;
