import { withFormik } from "formik";
import SupportForm, {
  SupportFormSchema,
  SupportFormValues,
} from "./support-form";

export interface SupportFormContainerProps {
  onSubmit: (values: SupportFormValues) => void;
}

const SupportFormContainer = (props: SupportFormContainerProps) => {
  const Form = withFormik<SupportFormContainerProps, SupportFormValues>({
    handleSubmit: (values) => {
      props.onSubmit(values);
    },
    validationSchema: SupportFormSchema,
    validateOnBlur: false,
    validateOnChange: false,
    validateOnMount: false,
    mapPropsToValues: () => ({
      firstName: "",
      lastName: "",
      contactEmail: "",
      topic: "",
      message: "",
    }),
  })(SupportForm);

  return <Form {...props} />;
};

export default SupportFormContainer;
