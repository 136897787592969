import React from "react";
import ConcealedCarryBadge from "./icons/badges/concealed-carry-badge";
import LeosaBadge from "./icons/badges/leosa-badge";
import MenuPolicyBadge from "./icons/badges/menu-policy-badge";
import MetalDetectorBadge from "./icons/badges/metalDetectorBadge";
import OpenCarryBadge from "./icons/badges/open-carry-badge";
import CheckmarkIcon from "./icons/checkmark";
import Bookmark from "./icons/bookmark";

import ChevronLeft from "./icons/chevron-left";
import CloseIcon from "./icons/close-icon";
import Cross from "./icons/cross";
import Hamburger from "./icons/hamburger";
import MenuText from "./icons/menu-text";
import ShareIcon from "./icons/share";
import UserIcon from "./icons/user-icon";
import UserLocation from "./icons/user-location";
import BookmarkActiveIcon from "./icons/bookmark-filled";
import RightArrowIcon from "./icons/right-arrow";
import LeftArrowIcon from "./icons/left-arrow";

const ICON_COLORS = {
  NEUTRAL: "#E9E9E8",
  POSITIVE: "#76E65C",
  LEOSA_POSITIVE: "#3154E0",
  NEGATIVE: "#D91400",
  //for badges that have two colors. metal detector, menu
  ENABLED: "#000000",
};

const IconHandler = ({ name, iconProps }: { name: string; iconProps: any }) => {
  const Icons: any = {
    chevronLeft: <ChevronLeft iconProps={iconProps} />,
    user: <UserIcon iconProps={iconProps} />,
    hamburger: <Hamburger iconProps={iconProps} />,
    menuText: <MenuText iconProps={iconProps} />,
    cross: <Cross iconProps={iconProps} />,
    checkmark: <CheckmarkIcon iconProps={iconProps} />,
    closeIcon: <CloseIcon iconProps={iconProps} />,
    userLocation: <UserLocation iconProps={iconProps} />,
    share: <ShareIcon iconProps={iconProps} />,
    bookmark: <Bookmark iconProps={iconProps} />,
    bookmarkActive: <BookmarkActiveIcon iconProps={iconProps} />,
    rightArrow: <RightArrowIcon iconProps={iconProps} />,
    leftArrow: <LeftArrowIcon iconProps={iconProps} />,
    "leosaPolicy-positive": (
      <LeosaBadge
        iconProps={iconProps}
        props={{ fillColor: ICON_COLORS.LEOSA_POSITIVE }}
      />
    ),
    "leosaPolicy-negative": (
      <LeosaBadge
        iconProps={iconProps}
        props={{ fillColor: ICON_COLORS.NEGATIVE }}
      />
    ),
    "leosaPolicy-neutral": (
      <LeosaBadge
        iconProps={iconProps}
        props={{ fillColor: ICON_COLORS.NEUTRAL }}
      />
    ),
    "concealedCarryPolicy-neutral": (
      <ConcealedCarryBadge
        iconProps={iconProps}
        props={{ fillColor: ICON_COLORS.NEUTRAL }}
      />
    ),
    "concealedCarryPolicy-negative": (
      <ConcealedCarryBadge
        iconProps={iconProps}
        props={{ fillColor: ICON_COLORS.NEGATIVE }}
      />
    ),
    "concealedCarryPolicy-positive": (
      <ConcealedCarryBadge
        iconProps={iconProps}
        props={{ fillColor: ICON_COLORS.POSITIVE }}
      />
    ),
    "openCarryPolicy-positive": (
      <OpenCarryBadge
        iconProps={iconProps}
        props={{ fillColor: ICON_COLORS.POSITIVE }}
      />
    ),
    "openCarryPolicy-negative": (
      <OpenCarryBadge
        iconProps={iconProps}
        props={{ fillColor: ICON_COLORS.NEGATIVE }}
      />
    ),
    "openCarryPolicy-neutral": (
      <OpenCarryBadge
        iconProps={iconProps}
        props={{ fillColor: ICON_COLORS.NEUTRAL }}
      />
    ),
    "menuPolicy-neutral": (
      <MenuPolicyBadge
        iconProps={iconProps}
        props={{ fillColor: ICON_COLORS.NEUTRAL }}
      />
    ),
    "menuPolicy-positive": (
      <MenuPolicyBadge
        iconProps={iconProps}
        props={{ fillColor: ICON_COLORS.ENABLED }}
      />
    ),
    "metalDetectorPolicy-neutral": (
      <MetalDetectorBadge
        iconProps={iconProps}
        props={{ fillColor: ICON_COLORS.NEUTRAL }}
      />
    ),
    "metalDetectorPolicy-positive": (
      <MetalDetectorBadge
        iconProps={iconProps}
        props={{ fillColor: ICON_COLORS.ENABLED }}
      />
    ),
  };

  if (!Icons[name]) {
    return null;
  }

  return Icons[name];
};

export default IconHandler;
