import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import BackButton from "../../../../components/back-button/back-button";
import { BannerAdFormValues } from "../../../../components/banner-ad-form/banner-ad-form";
import BannerAdFormWrapper from "../../../../components/banner-ad-form/banner-ad-form-wrapper";
import { createBannerAd } from "../../../../lib/api/banner-ad";
import {
  Content,
  Wrapper,
} from "../../../../styled/advertisement-form-elements";
import { Loader } from "../../../../styled/loader";
import { Container } from "./styled";
interface AdvertisementsMenuPageStepOneProps {}

const AdvertisementsMenuPageStepOne = (
  props: AdvertisementsMenuPageStepOneProps
) => {
  const navigate = useNavigate();

  const uploadBannerAdMutation = useMutation(
    "/banner-ad",
    (values: BannerAdFormValues) =>
      createBannerAd(
        values.websiteLink,
        values.image,
        values.planId,
        values.salesCode
      ),
    {
      onSuccess: (data, values) => {
        navigate(
          `/account/advertisements/banner-advertisement/payment?o=${data.uid}&i=${values.planId}`
        );
      },
    }
  );

  const handleSubmit = (
    image: File,
    websiteLink: string,
    planId: string,
    salesCode?: string
  ) => {
    uploadBannerAdMutation.mutate({ image, websiteLink, planId, salesCode });
  };

  if (uploadBannerAdMutation.isLoading) {
    return (
      <Content>
        <Wrapper>
          <Loader />
        </Wrapper>
      </Content>
    );
  }

  if (uploadBannerAdMutation.isError) {
    return (
      <Content>
        <Wrapper>
          <p>
            Could not create banner ad at this time. Please try again later.
          </p>
        </Wrapper>
      </Content>
    );
  }

  return (
    <Content>
      <BackButton handleBack={() => navigate("/account/advertisements")} />
      <Wrapper>
        <BannerAdFormWrapper onSubmit={handleSubmit} />
      </Wrapper>
    </Content>
  );
};

export default AdvertisementsMenuPageStepOne;
