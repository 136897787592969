import styled from "styled-components";
import { mobileMax } from "../../../lib/media-breakpoints";

export const ViewClaimPageContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: ${mobileMax}px) {
    align-items: center;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  margin: 67px 60px 30px 60px;

  @media (max-width: ${mobileMax}px) {
    margin: 65px 15px;
  }
`;

export const ClaimTitle = styled.div`
  color: #244169;
  font-size: 30px;
  font-weight: bold;
  margin: 0 0 10px 0;
`;

export const ClaimSubtitle = styled.div`
  margin: 10px 0 0 0;
`;

export const CancelClaimSection = styled.div``;

export const CancelClaimButton = styled.button`
  border: none;
  background: none;
  margin-top: 40px;
  color: blue;
  font-size: 14px;
`;
