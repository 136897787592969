import React from "react";
import { Category } from "../../ts/category";
import CategorySection from "../category-section/category-section";
import {
  CategoriesSectionContainer,
  CategoriesSectionLabel,
  CategoriesScrollableContainer,
} from "./styled/categories-section-styled";

export interface CategoriesSectionProps {
  categories: Category[];
  handleClick?: (value: string) => void;
}

const CategoriesSection = (props: CategoriesSectionProps) => {
  return (
    <CategoriesSectionContainer>
      <CategoriesSectionLabel>Categories</CategoriesSectionLabel>
      <CategoriesScrollableContainer>
        {props.categories.map((category, index) => (
          <div
            style={{ marginRight: 50 }}
            key={index}
            onClick={() => {
              if (props.handleClick) {
                props.handleClick(category.label);
              }
            }}
          >
            <CategorySection category={category} />
          </div>
        ))}
      </CategoriesScrollableContainer>
    </CategoriesSectionContainer>
  );
};

export default CategoriesSection;
