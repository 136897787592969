import styled from "styled-components";

export const BulletItemTableContainer = styled.div``;

export const TableDivider = styled.div`
  height: 1px;
  background: #eff0f0;
  margin-top: 20px;
`;

export const RowWrapper = styled.div`
  margin-top: 20px;
`;
