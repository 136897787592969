import styled from "styled-components";
import { mobileMax } from "../../../lib/media-breakpoints";
import { applyLightBoxShadow } from "../../../styled/box-shadow";

export const ArticleCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 160px;

  border: solid 1px #939fb5;
  border-radius: 8px;
  ${applyLightBoxShadow()}
`;

export const ArticleImage = styled.img``;

export const Top = styled.div`
  display: flex;
  align-items: center;
  margin: 19px 21px 5px 21px;
`;

export const Bottom = styled.div`
  margin: 10px 21px 0 21px;
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const LeftSide = styled.div`
  flex: 0 0 50px;
  margin-right: 11px;
`;

export const RightSide = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 100%;
`;

export const CardTitle = styled.h1`
  color: #1b3963;
  margin: 0;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  font-size: 14px;
  @media (min-width: ${mobileMax + 1}px) {
    font-size: 16px;
  }
`;

export const CardSubTitle = styled.h2`
  color: #000000;
  margin: 5px 0 0 0;

  font-size: 11px;
  @media (min-width: ${mobileMax + 1}px) {
    font-size: 13px;
  }
`;

export const PreviewText = styled.p`
  color: #8c8c8c;
  font-size: 12px;
  margin: 0;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const DateText = styled.p`
  color: #8c8c8c;
  margin-left: auto;
  margin-top: auto;
  text-align: end;

  font-size: 11px;

  @media (min-width: ${mobileMax + 1}px) {
    font-size: 14px;
  }
`;
