import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { deletePhoto, setPrimaryPhoto } from "../../lib/api/business-photo";
import SentryHandler from "../../lib/sentry/sentry";
import { BusinessPhoto } from "../../ts/business-photo";
import { PointOfInterest } from "../../ts/point-of-interest";
import BusinessPhotoManager, { MAX_PHOTOS } from "./business-photo-manager";
import {
  useDeleteMutation,
  useSetPrimaryPhoto,
  useUploadMutation,
} from "./hooks";

interface BusinessPhotoManagerWrapperProps {
  businessPhotos: BusinessPhoto[];
  pointOfInterestId: number;
  placeId: string;
}

const BusinessPhotoManagerWrapper = (
  props: BusinessPhotoManagerWrapperProps
) => {
  const setPrimaryMutation = useSetPrimaryPhoto(
    props.placeId,
    props.pointOfInterestId,
    props.businessPhotos,
    () => setSelected(undefined)
  );

  const deleteMutation = useDeleteMutation(
    props.placeId,
    props.pointOfInterestId,
    props.businessPhotos,
    () => setSelected(undefined)
  );

  const uploadMutation = useUploadMutation(
    props.placeId,
    props.pointOfInterestId,
    props.businessPhotos,
    () => setSelected(undefined)
  );

  const [currentlySelectedPhotoId, setSelected] = useState<number | undefined>(
    undefined
  );

  const handleChange = (photoId: number) => {
    if (photoId !== currentlySelectedPhotoId) {
      setSelected(photoId);
    } else {
      setSelected(undefined);
    }
  };

  const handleSetPrimary = () => {
    if (currentlySelectedPhotoId !== undefined) {
      setPrimaryMutation.mutate({ photoId: currentlySelectedPhotoId });
    }
  };

  const handleDelete = () => {
    if (currentlySelectedPhotoId !== undefined) {
      deleteMutation.mutate({ photoId: currentlySelectedPhotoId });
    }
  };

  const handleUploadPhoto = (image: File) => {
    if (props.businessPhotos.length > MAX_PHOTOS) {
      SentryHandler.captureMessage(
        "User tried to upload photo but had the max amount of photos in client"
      );
      return;
    }

    uploadMutation.mutate({
      image,
      placement: props.businessPhotos.length,
    });
  };

  return (
    <BusinessPhotoManager
      handleClick={handleChange}
      selectedId={currentlySelectedPhotoId}
      businessPhotos={props.businessPhotos}
      handleSetPrimary={handleSetPrimary}
      isLoading={
        setPrimaryMutation.isLoading ||
        deleteMutation.isLoading ||
        uploadMutation.isLoading
      }
      isError={
        setPrimaryMutation.isError ||
        deleteMutation.isError ||
        uploadMutation.isError
      }
      handleDelete={handleDelete}
      handleUploadPhoto={handleUploadPhoto}
    />
  );
};

export default BusinessPhotoManagerWrapper;
