import React from "react";
import IconHandler from "../../icon-handler/icon-handler";
import ReactGA from "react-ga";
import {
  ReferralCodeDisplayContainer,
  LeftSide,
  RightSide,
  CodeLabel,
} from "../styled/referral-code-display-styled";

const ReferralCodeDisplay = ({ referralCode }: { referralCode: string }) => {
  const handleShare = async () => {
    try {
      const shareData: ShareData = {
        text: `Visit the USA PACS Web App! Use my referral code, ${referralCode} when registering to receive free ammunition:`,
        url: "https://usapacs.app",
      };

      ReactGA.event({
        category: "ammunition",
        action: "copied_code",
      });

      if (navigator.canShare(shareData) === true) {
        await navigator.share(shareData);

        ReactGA.event({
          category: "ammunition",
          action: "shared_code",
        });
      } else {
        alert("Sharing is not support on your device.");
      }
    } catch (e) {}
  };

  return (
    <ReferralCodeDisplayContainer onClick={() => handleShare()}>
      <LeftSide>
        <CodeLabel>{referralCode}</CodeLabel>
        <div style={{ marginLeft: "auto", marginRight: 5 }}>
          <IconHandler name="share" iconProps={{}} />
        </div>
      </LeftSide>
      <RightSide></RightSide>
    </ReferralCodeDisplayContainer>
  );
};

export default ReferralCodeDisplay;
