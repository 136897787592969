import concealedPositive from "../../../images/badges/concealedCarryPolicy/concealed_green.png";

const ConcealedPositive = ({ imageProps }: any) => {
  return (
    <img
      src={concealedPositive}
      alt="concealed-carry-positive"
      {...imageProps}
    />
  );
};

export default ConcealedPositive;
