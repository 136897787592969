import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import ArticleCategoryTable from "../../components/article-category-table/article-category-table";
import BackButton from "../../components/back-button/back-button";
import { getArticleCategories } from "../../lib/api/article";
import { Loader } from "../../styled/loader";

import {
  CategoriesPageContainer,
  Content,
} from "./styled/categories-page-styled";

interface CategoriesPageProps {}

const CategoriesPage = (props: CategoriesPageProps) => {
  const navigate = useNavigate();

  const { data, isLoading, isError } = useQuery("/article/categories", () =>
    getArticleCategories()
  );

  if (isLoading) {
    return (
      <CategoriesPageContainer>
        <Loader />
      </CategoriesPageContainer>
    );
  }

  if (isError || !data) {
    return (
      <CategoriesPageContainer>
        <p>Could not get categories at this time. Please try again later.</p>
      </CategoriesPageContainer>
    );
  }

  return (
    <CategoriesPageContainer>
      <BackButton handleBack={() => navigate("/menu")} />
      <Content>
        <ArticleCategoryTable
          categories={data}
          onRowClick={(categoryId: number) =>
            navigate("/menu/current-events/" + categoryId)
          }
        />
      </Content>
    </CategoriesPageContainer>
  );
};

export default CategoriesPage;
