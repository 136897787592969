const Cross = ({ props, iconProps }: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={15.358}
      height={15.358}
      {...iconProps}
    >
      <g data-name="Group 14" fill="none" stroke="#6b6b6b">
        <path data-name="Line 1" d="m.354.354 14.651 14.651" />
        <path data-name="Line 2" d="M15.005.354.354 15.005" />
      </g>
    </svg>
  );
};

export default Cross;
