import styled from "styled-components";
import { mobileMax } from "../lib/media-breakpoints";

export const InfoIcon = styled.div<{ desktopOnly: Boolean }>`
  width: 25px;
  height: 25px;
  border: solid #797979 2px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #818181;
  font-weight: 500;
  font-size: 18px;

  ${(props) =>
    props.desktopOnly === true
      ? `
  @media(max-width:${mobileMax}px){
    display:none
  }
  `
      : ""}
`;
