import { SpecialOffer } from "../../../ts/special-offer";
import { SpecialOfferPlan } from "../../../ts/special-offer-plan";
import { getFirebaseUserToken } from "../../firebase/firebase";
import SentryHandler from "../../sentry/sentry";

import axios from "../axios-instance";

interface CreateSpecialOfferResponse {
  businessOffer: SpecialOffer;
  message?: string;
}

export const createSpecialOffer = async (
  title: string,
  description: string,
  pointOfInterestIds: number[],
  redeemMethod?: string,
  image?: File,
  salesCode?: string
) => {
  try {
    const token = await getFirebaseUserToken();

    const formData = new FormData();

    if (!image) {
      throw Error("Special Offer image not set");
    }

    formData.append("title", title);
    formData.append("description", description);
    formData.append(
      "pointOfInterestIdsJSON",
      JSON.stringify(pointOfInterestIds)
    );
    formData.append("redeemMethod", redeemMethod ?? "");
    formData.append("image", image);
    if (salesCode) {
      formData.append("salesCode", salesCode);
    }

    const { data } = await axios.post<CreateSpecialOfferResponse>(
      "/business-offer/web",
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (data.message) {
      throw Error(data.message);
    }

    return data.businessOffer;
  } catch (e: any) {
    //TODO: dont report if is login error
    // SentryHandler.reportException(e);
    if (e.response?.data?.message) {
      throw Error(e.response.data.message);
    } else {
      throw Error("Error");
    }
  }
};

interface GetUserBusinessOffersResponse {
  businessOffers: SpecialOffer[];
  message?: string;
}

export const getUserSpecialOffers = async (): Promise<SpecialOffer[]> => {
  try {
    const token = await getFirebaseUserToken();

    const { data } = await axios.get<GetUserBusinessOffersResponse>(
      "/me/business-offers",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (data.message) {
      throw Error(data.message);
    }

    return data.businessOffers;
  } catch (e: any) {
    //TODO: dont report if is login error
    // SentryHandler.reportException(e);
    if (e.response?.data?.message) {
      throw Error(e.response.data.message);
    } else {
      throw Error("Error");
    }
  }
};

interface GetSpecialOfferResponse {
  businessOffer: SpecialOffer;
  message?: string;
}

export const getSpecialOffer = async (uid: string): Promise<SpecialOffer> => {
  try {
    const token = await getFirebaseUserToken();

    const { data } = await axios.get<GetSpecialOfferResponse>(
      "/business-offer?uid=" + uid,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (data.message) {
      throw Error(data.message);
    }

    return data.businessOffer;
  } catch (e: any) {
    //TODO: dont report if is login error
    // SentryHandler.reportException(e);
    if (e.response?.data?.message) {
      throw Error(e.response.data.message);
    } else {
      throw Error("Error");
    }
  }
};

interface PauseSpecialOfferResponse {
  businessOffer: SpecialOffer;
  message?: string;
}

export const pauseSpecialOffer = async (id: number): Promise<SpecialOffer> => {
  try {
    const token = await getFirebaseUserToken();

    const { data } = await axios.post<PauseSpecialOfferResponse>(
      "/business-offer/pause",
      {
        businessOfferId: id,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (data.message) {
      throw Error(data.message);
    }

    return data.businessOffer;
  } catch (e: any) {
    //TODO: dont report if is login error
    // SentryHandler.reportException(e);
    if (e.response?.data?.message) {
      throw Error(e.response.data.message);
    } else {
      throw Error("Error");
    }
  }
};

interface ResumeSpecialOfferResponse {
  businessOffer: SpecialOffer;
  message?: string;
}

export const resumeSpecialOffer = async (id: number): Promise<SpecialOffer> => {
  try {
    const token = await getFirebaseUserToken();

    const { data } = await axios.post<ResumeSpecialOfferResponse>(
      "/business-offer/resume",
      {
        businessOfferId: id,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (data.message) {
      throw Error(data.message);
    }

    return data.businessOffer;
  } catch (e: any) {
    //TODO: dont report if is login error
    // SentryHandler.reportException(e);
    if (e.response?.data?.message) {
      throw Error(e.response.data.message);
    } else {
      throw Error("Error");
    }
  }
};

interface UpdateBusinessOfferImageResponse {
  businessOffer: SpecialOffer;
  message?: string;
}

export const updateSpecialOfferImage = async (
  id: number,
  image: File
): Promise<SpecialOffer> => {
  try {
    const token = await getFirebaseUserToken();

    const formData = new FormData();
    formData.append("image", image);
    formData.append("businessOfferId", String(id));

    const { data } = await axios.post<UpdateBusinessOfferImageResponse>(
      "/business-offer/update",
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (data.message) {
      throw Error(data.message);
    }

    return data.businessOffer;
  } catch (e: any) {
    //TODO: dont report if is login error
    // SentryHandler.reportException(e);
    if (e.response?.data?.message) {
      throw Error(e.response.data.message);
    } else {
      throw Error("Error");
    }
  }
};

interface UpdateSpecialOfferDetailsResposne {
  businessOffer: SpecialOffer;
  message?: string;
}

export const updateSpecialOfferDetails = async (
  id: number,
  title: string,
  description: string,
  redeemMethod: string
) => {
  try {
    const token = await getFirebaseUserToken();

    const { data } = await axios.post<UpdateSpecialOfferDetailsResposne>(
      "/business-offer/update-details",
      {
        businessOfferId: id,
        newTitle: title,
        newDescription: description,
        newRedeemMethod: redeemMethod,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (data.message) {
      throw Error(data.message);
    }

    return data.businessOffer;
  } catch (e: any) {
    //TODO: dont report if is login error
    // SentryHandler.reportException(e);
    if (e.response?.data?.message) {
      throw Error(e.response.data.message);
    } else {
      throw Error("Error");
    }
  }
};

interface GetSpecialOfferPlansResponse {
  plans: SpecialOfferPlan[];
  message?: string;
}

export const getSpecialOfferPlans = async (): Promise<SpecialOfferPlan[]> => {
  try {
    const { data } = await axios.get<GetSpecialOfferPlansResponse>(
      "/business-offer/plans"
    );

    if (data.message) {
      throw Error(data.message);
    }

    return data.plans;
  } catch (e: any) {
    // SentryHandler.reportException(e);
    if (e.response?.data?.message) {
      throw Error(e.response.data.message);
    } else {
      throw Error("Error");
    }
  }
};

interface DeleteBusinessOfferResponse {
  deleted: number;
  message?: string;
}

export const deleteBusinessOffer = async (uid?: string): Promise<number> => {
  try {
    const token = await getFirebaseUserToken();

    const { data } = await axios.post<DeleteBusinessOfferResponse>(
      "/business-offer/delete",
      {
        uid,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    return data.deleted;
  } catch (e: any) {
    // SentryHandler.reportException(e);
    if (e.response?.data?.message) {
      throw Error(e.response.data.message);
    } else {
      throw Error("Error");
    }
  }
};
