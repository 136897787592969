import { withFormik } from "formik";
import { useState } from "react";
import { updateUserEmail } from "../../lib/firebase/firebase";
import UpdateEmailForm, {
  UpdateEmailFormSchema,
  UpdateEmailFormValues,
} from "./update-email-form";

export interface UpdateEmailFormContextProps {
  initialEmail: string;
  response?: string;
}

const UpdateEmailFormContext = (props: UpdateEmailFormContextProps) => {
  const [formResponse, setFormResponse] = useState<string | undefined>(
    undefined
  );

  const handleSubmit = async (values: UpdateEmailFormValues) => {
    const response = await updateUserEmail(values.newEmail, values.password);

    if (typeof response === "string") {
      setFormResponse(response);

      return false;
    }

    if (response === false) {
      setFormResponse(
        "Could not update email at this time. Please try again later."
      );

      return false;
    }

    setFormResponse(
      "Email change has been saved. A verification email has been sent to your inbox. Please follow the instructions to verify and complete the change."
    );

    return true;
  };

  const Form = withFormik<UpdateEmailFormContextProps, UpdateEmailFormValues>({
    handleSubmit: (values) => {
      handleSubmit(values);
    },
    mapPropsToValues: (props) => ({
      newEmail: props.initialEmail,
      password: "",
    }),
    validationSchema: UpdateEmailFormSchema,
    validateOnBlur: false,
    validateOnChange: false,
    validateOnMount: false,
  })(UpdateEmailForm);

  return <Form {...props} response={formResponse} />;
};

export default UpdateEmailFormContext;
