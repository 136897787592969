import { Dispatch, SetStateAction, useEffect } from "react";
import { PointOfInterest } from "../../ts/point-of-interest";
import { BusinessAddress, BusinessName, Row, TableContainer } from "./styled";

interface SelectBusinessTableProps {
  businesses: PointOfInterest[];
  onChange: (ids: number[]) => void;
  selected: number[];
  setSelected: Dispatch<SetStateAction<number[]>>;
}

const SelectBusinessTable = (props: SelectBusinessTableProps) => {
  const { selected, setSelected } = props;

  return (
    <TableContainer>
      {props.businesses.map((business) => (
        <Row
          onClick={() => {
            let index = selected.findIndex((a) => a === business.id);

            if (index > -1) {
              let tmp = selected.slice();
              tmp.splice(index, 1);
              setSelected(tmp);
            } else {
              let tmp = selected.slice();
              tmp.push(business.id);
              setSelected(tmp);
            }
          }}
          key={business.placeId}
          selected={selected.findIndex((a) => a === business.id) > -1}
        >
          <BusinessName>{business.name}</BusinessName>
          <BusinessAddress>{business.formattedAddress}</BusinessAddress>
        </Row>
      ))}
    </TableContainer>
  );
};

export default SelectBusinessTable;
