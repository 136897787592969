import {
  BulletAmount,
  BulletItemRowContainer,
  ImageWrapper,
  ItemTitle,
  LeftSide,
  RightSide,
} from "./styled";

interface BulletItemRowProps {
  itemTitle: string;
  bulletAmount: number;
  imageSrc?: string;
}

const BulletItemRow = (props: BulletItemRowProps) => {
  return (
    <BulletItemRowContainer>
      <LeftSide>
        {props.imageSrc ? (
          <ImageWrapper>
            <img
              src={props.imageSrc}
              alt="bullet offer"
              height="100%"
              width="100%"
            />
          </ImageWrapper>
        ) : null}
      </LeftSide>
      <RightSide>
        <ItemTitle>{props.itemTitle}</ItemTitle>
        <BulletAmount>{props.bulletAmount} Bullets</BulletAmount>
      </RightSide>
    </BulletItemRowContainer>
  );
};

export default BulletItemRow;
