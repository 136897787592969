import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { PointOfInterest } from "../../ts/point-of-interest";
import BusinessPhotoManagerPage from "../BusinessPhotoManager/BusinessPhotoManager";
import UserBusinessPage from "../UserBusiness/UserBusiness";
import UserBusinessesPage from "../UserBusinesses/UserBusinessesPage";

interface UserBusinessControllerState {
  page: number;
  activeBusiness?: PointOfInterest;
}

const UserBusinessController = () => {
  const navigate = useNavigate();

  const [state, setState] = useState<UserBusinessControllerState>({
    page: 1,
    activeBusiness: undefined,
  });

  if (state.page === 1) {
    return (
      <UserBusinessesPage
        handleBack={() => navigate("/account")}
        handleTransition={(poi: PointOfInterest) =>
          setState({ page: 2, activeBusiness: poi })
        }
      />
    );
  } else if (state.page === 2 && state.activeBusiness) {
    return (
      <UserBusinessPage
        pointOfInterest={state.activeBusiness}
        placeId={state.activeBusiness.placeId}
        handleBack={() => setState({ page: 1, activeBusiness: undefined })}
        handlePhotoClick={() =>
          setState({ page: 3, activeBusiness: state.activeBusiness })
        }
      />
    );
  } else if (state.page === 3 && state.activeBusiness) {
    return (
      <BusinessPhotoManagerPage
        placeId={state.activeBusiness.placeId}
        handleBack={() =>
          setState({ page: 2, activeBusiness: state.activeBusiness })
        }
      />
    );
  } else {
    return null;
  }
};

export default UserBusinessController;
