import styled from "styled-components";

export const SpecialOfferTableContainer = styled.div``;

export const RowsContainer = styled.div`
  display: flex;
  overflow-x: scroll;
  height: 170px;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    height: 10px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
  }
`;

export const AdContainer = styled.div`
  margin: 0 20px;
  height: 100px;
`;

export const AdImage = styled.img`
  width: 150px;
  height: 150px;
`;
