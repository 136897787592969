import { useNavigate } from "react-router-dom";
import MissingPageImage from "../../components/image-handler/image-components/missing-page-image";
import { SecondaryButton } from "../../styled/buttons";
import { ParagraphText, Title } from "../../styled/typography";
import {
  ImageWrapper,
  MissingPageContainer,
  ModifiedText,
} from "./styled/missing-page-styled";

interface MissingPageProps {}

const MissingPage = (props: MissingPageProps) => {
  const navigate = useNavigate();

  return (
    <MissingPageContainer>
      <ImageWrapper>
        <MissingPageImage imageProps={{ width: "100%", height: "auto" }} />
      </ImageWrapper>

      <ModifiedText>
        THE URL MAY HAVE BEEN MISTYPED OR THE PAGE MAY HAVE BEEN RELOCATED.
      </ModifiedText>
      <SecondaryButton onClick={() => navigate("/home")} style={{ width: 300 }}>
        RETURN TO USA PACS
      </SecondaryButton>
    </MissingPageContainer>
  );
};

export default MissingPage;
