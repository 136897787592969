import styled from "styled-components";

export const BusinessInfoHeaderContainer = styled.div`
  display: flex;
  max-height: 140px;
  height: 100%;
`;

export const LeftSide = styled.div`
  margin-right: 20px;
`;

export const RightSide = styled.div`
  display: flex;
  flex-direction: column;
  // margin-left: 20px;
  margin-bottom: 20px;
  justify-content: center;
`;

export const BusinessNameLabel = styled.p`
  margin: 0;
  font-size: 20px;
  color: #bfbfbf;
`;

export const BusinessAddressLabel = styled.p`
  margin: 5px 0 0 0;
  color: #bfbfbf;
  font-size: 16px;
`;

export const ImageWrapper = styled.div`
  border: solid #d6d6d6 1px;
  border-radius: 2px;
  height: 100px;
  width: 100px;
  cursor: pointer;
  position: relative;
`;
