import { withFormik } from "formik";

import SpecialOfferUpdateDetails, {
  SpecialOfferUpdateDetailsProps,
  SpecialOfferUpdateDetailsSchema,
  SpecialOfferUpdateDetailsValues,
} from "./special-offer-details-update";

interface UpdateSpecialOfferDetailsWrapperProps {
  existingTitle?: string;
  existingDescription?: string;
  existingRedeemMethod?: string;
  onSubmit: (values: SpecialOfferUpdateDetailsValues) => void;
  loading: boolean;
}

const UpdateSpecialOfferDetailsWrapper = (
  props: UpdateSpecialOfferDetailsWrapperProps
) => {
  const Form = withFormik<
    SpecialOfferUpdateDetailsProps,
    SpecialOfferUpdateDetailsValues
  >({
    handleSubmit: (values) => {
      props.onSubmit(values);
    },
    mapPropsToValues: () => ({
      title: props.existingTitle ?? "",
      description: props.existingDescription ?? "",
      redeemMethod: props.existingRedeemMethod ?? "'",
    }),
    validateOnBlur: false,
    validateOnMount: false,
    validateOnChange: false,
    validationSchema: SpecialOfferUpdateDetailsSchema,
  })(SpecialOfferUpdateDetails);

  return <Form loading={props.loading} />;
};

export default UpdateSpecialOfferDetailsWrapper;
