import React from "react";
import { useMediaQuery } from "react-responsive";
import { formatBusinessCategory } from "../../lib/format-business-category";
import { mobileMax } from "../../lib/media-breakpoints";
import ResultsTableListingDesktop from "./results-table-listing-desktop";
import ResultsTableListingMobile from "./results-table-listing-mobile";

export interface ResultsTableListingProps {
  placeId: string;
  businessName: string;
  businessAddress: string;
  category: string;
  milesFromUser?: number;
  primaryPhotoUrl?: string;

  badgeAlignments?: {
    leosaPolicy: number;
    concealedCarryPolicy: number;
    openCarryPolicy: number;
    menuPolicy: number;
    metalDetectorPolicy: number;
  };
}

const ResultsTableListing = (props: ResultsTableListingProps) => {
  const isDesktop = useMediaQuery({
    query: `(min-width:${mobileMax + 1}px)`,
  });

  let finalCategory = formatBusinessCategory(props.category);

  return isDesktop ? (
    <ResultsTableListingDesktop {...props} category={finalCategory} />
  ) : (
    <ResultsTableListingMobile {...props} category={finalCategory} />
  );
};

export default ResultsTableListing;
