import { useQuery } from "react-query";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import ArticleHeader from "../../components/article-header/article-header";
import BackButton from "../../components/back-button/back-button";
import { getArticle } from "../../lib/api/article";
import { Loader } from "../../styled/loader";
import {
  ArticlePageContainer,
  Content,
  ContentSection,
  ContentText,
  ImageSection,
} from "./styled/view-article-page-styled";

interface ViewArticlePageProps {}

const ViewArticlePage = (props: ViewArticlePageProps) => {
  const { article, category } = useParams();
  const navigate = useNavigate();

  const { data, isLoading, error } = useQuery(["/article", article], () =>
    getArticle(article ?? "")
  );

  if (isLoading) {
    return (
      <ArticlePageContainer>
        <Loader />
      </ArticlePageContainer>
    );
  }

  if (error || !data) {
    return <p>Could not load article.</p>;
  }

  if (!article) {
    return <Navigate to="/menu/current-events" />;
  }

  return (
    <ArticlePageContainer>
      <BackButton
        handleBack={() => {
          if (category) {
            if (category === "bookmark") {
              navigate("/menu/bookmarks");
            } else {
              navigate(`/menu/current-events/${category}`);
            }
          } else {
            navigate("/menu/current-events");
          }
        }}
      />
      <Content>
        {data.imageURL ? (
          <ImageSection>
            <img
              src={data.imageURL}
              alt=""
              width={"auto"}
              height="100%"
              style={{ maxHeight: 300 }}
            />
          </ImageSection>
        ) : null}

        <ArticleHeader
          articleId={article}
          title={data.title}
          date={data.createdAt}
          isBookmarked={data.bookmarked ? true : false}
        />

        <ContentSection>
          {data.content ? <ContentText>{data.content}</ContentText> : ""}
        </ContentSection>
      </Content>
    </ArticlePageContainer>
  );
};

export default ViewArticlePage;
