import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";

import IconHandler from "../../components/icon-handler/icon-handler";
import { StepThreeFormValues } from "../../components/register/components/step-three-form";
import { CreateStepThreeForm } from "../../components/register/utils/stepThreeFormikContext";
import { verifyLeosa } from "../../lib/api/user";
import { USAPACSUser } from "../../lib/ts/User";
import { SecondaryButton } from "../../styled/buttons";
import { Loader } from "../../styled/loader";
import {
  ErrorIcon,
  ErrorIconContainer,
  SuccessIconContainer,
  SuccessText,
} from "../../styled/status-icons";

interface VerifyLeosaPageProps {}

const VerifyLeosaPage = (props: VerifyLeosaPageProps) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { mutate, isLoading, isError, data } = useMutation(
    "/me/leosa-verify",
    (values: StepThreeFormValues) =>
      verifyLeosa({
        ...values,
      }),
    {
      onSuccess: async () => {
        await queryClient.cancelQueries("me");

        const cache = queryClient.setQueryData<USAPACSUser | undefined>(
          "/me/details",
          (old) => {
            if (!old) {
              return old;
            }

            old.accountTypeId = 1;

            return old;
          }
        );

        // navigate("/account");
      },
    }
  );

  const handleSubmit = (values: StepThreeFormValues) => {
    mutate(values);
  };

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return (
      <ErrorIconContainer style={{ flexDirection: "column" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ErrorIcon>!</ErrorIcon>
          <p style={{ color: "red", marginLeft: 5 }}>
            Could not submit verification at this time. Please try again later.
          </p>
        </div>
        <SecondaryButton
          style={{ maxWidth: 300, marginTop: 20 }}
          onClick={() => navigate("/account")}
        >
          BACK
        </SecondaryButton>
      </ErrorIconContainer>
    );
  }

  if (data) {
    return (
      <SuccessIconContainer style={{ flexDirection: "column" }}>
        <div className="success-content" style={{ display: "flex" }}>
          <IconHandler name="checkmark" iconProps={{}} />
          <SuccessText>
            Your verification has been submitted. USA PACS will be in touch.
          </SuccessText>
        </div>

        <SecondaryButton
          style={{ maxWidth: 300, marginTop: 20 }}
          onClick={() => navigate("/account")}
        >
          BACK
        </SecondaryButton>
      </SuccessIconContainer>
    );
  }

  return (
    <CreateStepThreeForm
      disabled={isLoading}
      handleSubmit={(values: StepThreeFormValues) => {
        handleSubmit(values);
      }}
    />
  );
};

export default VerifyLeosaPage;
