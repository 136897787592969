import styled from "styled-components";
import { mobileMax } from "../../lib/media-breakpoints";
import { SecondaryButton } from "../../styled/buttons";

export const BusinessPhotoManagerContainer = styled.div``;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const TopBar = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
`;

export const Inner = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  // justify-content: space-evenly;
`;

export const ActionButton = styled(SecondaryButton)`
  height: 40px;
  flex: 0 0 120px;
  margin-top: 20px;

  :not(:first-child) {
    margin-left: 40px;
  }
`;

export const SelectedBox = styled.input.attrs({
  type: "checkbox",
})`
  height: 15px;
  width: 15px;

  position: absolute;
  top: 0;
  left: 0;
`;

export const PhotoWrapper = styled.div`
  height: 200px;
  flex: 0 0 200px;
  cursor: pointer;
  position: relative;
  margin: 20px 20px 0 0;

  @media (max-width: ${mobileMax}px) {
    height: 150px;
    flex: 0 0 40%;
  }
`;

export const BusinessPhotoDisplay = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

export interface FileInputProps {
  text: string;
  onChange: (e: any) => void;
}

export const FileInput = styled.input.attrs((props) => ({
  type: "file",
}))<FileInputProps>`
  overflow: hidden;
  cursor: pointer;

  color: #a8a8a8;

  flex: 0 0 200px;
  height: 200px;
  margin: 20px 20px 0 0;

  @media (max-width: ${mobileMax}px) {
    height: 150px;
    flex: 0 0 40%;
  }

  ::-webkit-file-upload-button {
    visibility: hidden;
  }

  ::before {
    content: "${(props) => props.text}";

    width: 100%;
    height: 100%;

    border: none;
    background: #a8a8a8;
    color: black;
    font-size: 30px;
    display: flex;
    text-align: center;

    flex-direction: column;
    align-content: center;
    justify-content: center;
    text-align: center;
  }
`;
