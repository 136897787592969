import React from "react";
import { PointOfInterest } from "../../ts/point-of-interest";
import ResultsTableListing from "../results-table-listing/results-table-listing";
import {
  ModifiedGradientBorder,
  ResultsTableContainer,
} from "./styled/results-table-styled";

export interface ResultsTableProps {
  pointsOfInterest: PointOfInterest[];
}

const ResultsTable = (props: ResultsTableProps) => {
  return (
    <ResultsTableContainer>
      {props.pointsOfInterest.map((pointOfInterest, index) => (
        <div
          key={pointOfInterest.placeId}
          style={index % 2 === 0 ? { background: "#F8F8F8" } : {}}
        >
          <ResultsTableListing
            placeId={pointOfInterest.placeId}
            businessName={pointOfInterest.name}
            badgeAlignments={pointOfInterest.badgeAlignments}
            businessAddress={pointOfInterest.formattedAddress}
            category={pointOfInterest.category}
            milesFromUser={pointOfInterest.milesFromUser}
            primaryPhotoUrl={
              pointOfInterest.businessPhotos &&
              pointOfInterest.businessPhotos.length > 0
                ? pointOfInterest.businessPhotos[0].imageUrl
                : undefined
            }
          />
          {index < props.pointsOfInterest.length - 1 ? (
            <ModifiedGradientBorder />
          ) : null}
        </div>
      ))}
    </ResultsTableContainer>
  );
};

export default ResultsTable;
