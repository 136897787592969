import styled from "styled-components";

export const BusinessViewContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  overflow-y: auto;
  width: 100%;
  height: 100%;

  background: white;

  z-index: 4000;
`;

export const LoaderContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 4000;
  background: white;
`;
