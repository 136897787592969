import React from "react";

const BookmarkActiveIcon = ({ props, iconProps }: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 472.615 472.615"
      style={{
        enableBackground: "new 0 0 472.615 472.615",
      }}
      xmlSpace="preserve"
      {...iconProps}
    >
      <path d="M236.307 0H96.738v472.615l139.569-107.03 139.57 107.03V0z" />
    </svg>
  );
};

export default BookmarkActiveIcon;
