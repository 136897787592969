import { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { useMediaQuery } from "react-responsive";
import { mobileMax } from "../../lib/media-breakpoints";
import { QueryValueContext } from "../../pages/Dashboard/util/contexts";

import { GradientBorder } from "../../styled/gradient-border";
import ImageHandler from "../image-handler/image-handler";
import {
  RegularBorder,
  SearchBusinessInputContainer,
  SearchBusinessTextInput,
  SearchImageWrapper,
} from "./styled/search-business-input-styled";

export interface SearchBusinessInputProps {
  onSubmit: (value: string) => void;
  onAutoComplete: (placeId: string) => void;
}

const SearchBusinessInput = ({
  onSubmit,
  onAutoComplete,
}: SearchBusinessInputProps) => {
  const [autoCompleteWidget, setAutoCompleteWidget] = useState<
    google.maps.places.Autocomplete | undefined
  >(undefined);

  const isDesktop = useMediaQuery({ query: `(min-width: ${mobileMax + 1}px)` });

  const { displayValue } = useContext(QueryValueContext);

  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (ref.current && !autoCompleteWidget) {
      setAutoCompleteWidget(
        new google.maps.places.Autocomplete(ref.current, {
          types: ["establishment"],
          componentRestrictions: { country: ["US"] },
          fields: ["place_id"],
        })
      );
    }

    if (autoCompleteWidget) {
      autoCompleteWidget.addListener("place_changed", () => {
        const result = autoCompleteWidget.getPlace();
        const placeId = result.place_id;

        if (placeId) {
          if (ref.current) {
            ref.current.value = "";
          }
          onAutoComplete(placeId);
        }
      });
    }
  }, [ref, autoCompleteWidget]);

  useEffect(() => {
    if (ref && ref.current) {
      ref.current.value = displayValue;
    }
  }, [displayValue]);

  const handleKeyPress = (e: any) => {
    if (e.key === "Enter") {
      if (e.target.value !== "") {
        e.target.blur();
        onSubmit(e.target.value);
      }
    }
  };

  return (
    <SearchBusinessInputContainer>
      <div style={{ position: "relative", margin: "auto 0", display: "flex" }}>
        <SearchBusinessTextInput
          ref={ref}
          onKeyPress={handleKeyPress}
          placeholder="Search for a business"
        />
        <SearchImageWrapper
          onClick={() => {
            if (ref && ref.current) {
              onSubmit(ref.current.value);
            }
          }}
        >
          <ImageHandler
            name="search"
            imageProps={{
              width: 35,
              height: 35,
            }}
          />
        </SearchImageWrapper>
      </div>
      {isDesktop ? <GradientBorder style={{ height: 3 }} /> : <RegularBorder />}
    </SearchBusinessInputContainer>
  );
};

export default SearchBusinessInput;
