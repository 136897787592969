import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { getBannerAds } from "../../lib/api/banner-ad";
import { Loader } from "../../styled/loader";

import ImageDisplay from "./components/image-display";
import {
  BannerAdsDisplayWrapper,
  ContentContainer,
} from "./styled/banner-ad-display-styled";

interface BannerAdsDisplayProps {}

const SECONDS_REFETCH = 150;

const BannerAdsDisplay = (props: BannerAdsDisplayProps) => {
  const [existingAdIds, setExistingAdIds] = useState<number[]>([]);
  const { data, isLoading, isError, refetch } = useQuery(
    "/banner-ads",
    () => getBannerAds(existingAdIds),
    {
      onSuccess: (data) => {
        try {
          const newIds = data.map((a) => a.id);

          if (newIds.length > 0) {
            setExistingAdIds(existingAdIds.concat(newIds));
          } else {
            //clear existing and refetch
            setExistingAdIds([]);
          }
        } catch (e) {}
      },
    }
  );

  useEffect(() => {
    const interval = setInterval(() => {
      refetch();
    }, 1000 * SECONDS_REFETCH);

    return () => clearInterval(interval);
  }, [refetch]);

  if (isLoading) {
    return (
      <BannerAdsDisplayWrapper>
        <Loader />
      </BannerAdsDisplayWrapper>
    );
  }

  if (isError || !data) {
    return null;
  }

  if (data.length < 1) {
    return null;
  }

  return (
    <BannerAdsDisplayWrapper>
      <ContentContainer>
        <ImageDisplay ads={data} />
        {/* <div
          style={{
            background: "#667fa2",
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: 30,
          }}
        >
          <p>YOUR ADVERTISEMENT HERE</p>
        </div> */}
      </ContentContainer>
    </BannerAdsDisplayWrapper>
  );
};

export default BannerAdsDisplay;
