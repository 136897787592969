import styled from "styled-components";
import { ParagraphText } from "../../../styled/typography";

export const MissingPageContainer = styled.div`
  background: #fafafa;
  height: 100vh;
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  @media (max-width: 600px) {
  }
`;

export const ImageWrapper = styled.div`
  width: 600px;

  @media (max-width: 600px) {
    width: 300px;
  }
`;

export const ModifiedText = styled(ParagraphText)`
  @media (max-width: 600px) {
    margin-left: 30px;
    margin-right: 30px;
  }
`;
