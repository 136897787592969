import styled from "styled-components";

export const SupportPageContainer = styled.div``;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;

  p {
    text-align: center;
  }
`;
