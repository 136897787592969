import styled from "styled-components";
import { mobileMax } from "../../lib/media-breakpoints";
import { AdvertisementSubmitButton } from "../../styled/advertisement-form-elements";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Content = styled.div`
  @media (min-width: ${mobileMax + 1}px) {
    max-width: 600px;
    width: 100%;
    margin: 60px 0;
  }

  @media (max-width: ${mobileMax}px) {
    margin: 60px 20px;
  }
`;

export const FieldTitle = styled.div``;
export const FieldValue = styled.div``;

export const DeleteButton = styled(AdvertisementSubmitButton)``;
