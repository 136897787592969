import axios from "axios";

let domain = "https://usapacs.app";

if (window && window.location) {
  const origin = window.location.origin;

  if (origin.includes("www")) {
    domain = "https://www.usapacs.app";
  }
}

export const STRIPE_RETURN_DOMAIN = domain;

// export const API_URL = "http://192.168.86.20:4000";
// export const API_URL = `https://usapacs.net`;
// export const API_URL = "http://127.0.0.1:4000";
export const API_URL = "https://api.usapacs.app/";

const instance = axios.create({
  baseURL: API_URL,
});

export default instance;
