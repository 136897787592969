import { useQueryClient, useMutation } from "react-query";
import {
  updateBannerAdDetails,
  updateBannerAdImage,
} from "../../../lib/api/banner-ad";
import { BannerAdvertisement } from "../../../ts/banner-advertisement";

export const useUpdateDetailsMutation = (uid?: string) => {
  const client = useQueryClient();
  const mutation = useMutation(
    ["banner-ad/update-details", uid],
    (values: { id: number; link: string }) =>
      updateBannerAdDetails(values.id, values.link),
    {
      onMutate: (values) => {
        let previous = "";
        client.setQueryData<BannerAdvertisement | undefined>(
          ["/banner-advertisement", uid],
          (old) => {
            if (old) {
              previous = old.websiteLink;
              old.websiteLink = values.link;
            }

            return old;
          }
        );

        return previous;
      },
      onSuccess: (data) => {
        client.setQueryData<BannerAdvertisement | undefined>(
          ["/banner-advertisement", uid],
          (old) => {
            if (old) {
              old.websiteLink = data.websiteLink;
              old.status = data.status;
            }

            return old;
          }
        );
      },
      onError: (error, values, context: any) => {
        client.setQueryData<BannerAdvertisement | undefined>(
          ["/banner-advertisement", uid],
          (old) => {
            if (old) {
              old.websiteLink = context;
            }

            return old;
          }
        );
      },
    }
  );

  return mutation;
};
