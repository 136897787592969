import styled from "styled-components";
import { applyGradient } from "../../../styled/blue-gradient-background";

export const RegisterPageContainer = styled.div`
  ${applyGradient()}
  min-height:100vh;
  display: flex;
  flex-direction: column;
  align-items: center;

  form {
    width: 100%;
  }
`;
