import styled from "styled-components";
import { mobileMax } from "../lib/media-breakpoints";

export const ErrorIcon = styled.div`
  height: 20px;
  flex: 0 0 20px;
  color: red;
  border: solid red 1px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: ${mobileMax}px) {
    height: 20px;
  }
`;

export const ErrorIconContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 60px 40px;
  flex-wrap: wrap;

  @media (max-width: ${mobileMax}px) {
    margin: 60px 10px;
  }
`;

export const SuccessIconContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 60px 40px;

  @media (max-width: ${mobileMax}px) {
    margin: 60px 10px;
    text-align: center;
  }

  .success-content {
    @media (max-width: ${mobileMax}px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
`;

export const SuccessText = styled.p`
  color: green;
  margin: 0 0 0 10px;
`;
