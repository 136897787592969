import { IncidentReport, PointOfInterest } from "../../../ts/point-of-interest";
import { PointOfInterestClaim } from "../../../ts/point-of-interest-claim";
import { getFirebaseUserToken } from "../../firebase/firebase";

import axios from "../axios-instance";
import { ClaimError } from "../errors/claim-error";

/*Performs a text search using the backend API

If certain queries are passed in, backend will perform a different type of search.

Returns PointOfInterest[]
*/
export const textSearch = async (
  query?: string,
  lat?: number,
  lng?: number,
  topRightCornerCoordinate?: google.maps.LatLng,
  bottomLeftCornerCoordinate?: google.maps.LatLng
): Promise<PointOfInterest[]> => {
  try {
    if (!query || query === "") {
      return [];
    }

    if (query === "Special Offers") {
      if (!topRightCornerCoordinate || !bottomLeftCornerCoordinate) {
        return [];
      }

      const token = await getFirebaseUserToken();

      const data = await axios.post<{ pointsOfInterest: PointOfInterest[] }>(
        `/coordinate-search/poi-business-offers`,
        {
          coordinateA: {
            lat: topRightCornerCoordinate.lat(),
            long: topRightCornerCoordinate.lng(),
          },
          coordinateB: {
            lat: bottomLeftCornerCoordinate.lat(),
            long: bottomLeftCornerCoordinate.lng(),
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return data.data.pointsOfInterest;
    } else {
      if (!lat || !lng) {
        return [];
      }

      const token = await getFirebaseUserToken();

      const { data } = await axios.get<{ pointsOfInterest: PointOfInterest[] }>(
        `/poi/search?query=${query}&lat=${lat}&lng=${lng}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return data.pointsOfInterest;
    }
  } catch (e) {
    return [];
  }
};

/*Returns Place Details + POI Details*/
export const getPointOfInterest = async (
  placeId: string
): Promise<PointOfInterest> => {
  const { data } = await axios.get<{ pointOfInterest: PointOfInterest }>(
    `/poi?placeId=${placeId}`
  );

  return data.pointOfInterest;
};

export const postBadgeForPointOfInterest = async (
  placeId: string,
  policy: string,
  alignment: number
) => {
  const token = await getFirebaseUserToken();

  const { data } = await axios.post<{
    report: IncidentReport;
    message?: string;
  }>(
    "/incident-report/by-place-id",
    {
      placeId,
      incidentType: policy,
      alignment,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (data.message) {
    throw new Error(data.message);
  }

  return data.report;
};

export const claimBusiness = async (
  placeId: string,
  lat: number,
  long: number
): Promise<PointOfInterestClaim> => {
  const token = await getFirebaseUserToken();

  const { data } = await axios.post<{
    claim: PointOfInterestClaim;
    message?: string;
  }>(
    "/poi/claim",
    {
      placeId: placeId,
      lat,
      long,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (data.message) {
    throw new ClaimError(data.message);
  }

  return data.claim;
};

export const completeClaim = async (
  claimId: number,
  code: string
): Promise<PointOfInterestClaim> => {
  const token = await getFirebaseUserToken();

  const { data } = await axios.post<{
    claim: PointOfInterestClaim;
    message?: string;
  }>(
    "/poi/claim/complete",
    {
      claimId,
      code,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (data.message) {
    throw new ClaimError(data.message);
  }

  return data.claim;
};

/*Fetches only the USA PACS details for a Point Of Interest (excludes places details)*/
export const getPointOfInterestDetails = async (
  placeId: string
): Promise<PointOfInterest> => {
  const { data } = await axios.get<PointOfInterest | string>(
    `/poi/details?placeId=${placeId}`
  );

  if (typeof data === "string") {
    throw Error("Could not get poi details");
  }

  return data;
};

export const updatePointOfInterestPolicy = async (
  id: number,
  policy: string,
  policyValue: number
) => {
  const token = await getFirebaseUserToken();

  const { data } = await axios.post<PointOfInterest | string>(
    `/poi/update-policy`,
    {
      pointOfInterestId: id,
      policy,
      policyValue,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (typeof data === "string") {
    throw Error(data);
  }

  return data;
};

interface CancelClaimResponse {
  claim: PointOfInterestClaim;
  message?: string;
}

export const cancelClaim = async (
  id: number
): Promise<PointOfInterestClaim> => {
  try {
    const token = await getFirebaseUserToken();

    const { data } = await axios.post<CancelClaimResponse>(
      `/poi/claim/cancel`,
      {
        claimId: id,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (typeof data === "string") {
      throw Error(data);
    }

    return data.claim;
  } catch (e: any) {
    //TODO: dont report if is login error
    // SentryHandler.reportException(e);
    if (e.response?.data?.message) {
      throw Error(e.response.data.message);
    } else {
      throw Error("Error");
    }
  }
};
