import { useState, useEffect } from "react";

import MARKER_PATH from "./marker-icon-path";

interface MarkerProps extends google.maps.MarkerOptions {}

export const UserLocationMarker: React.FC<MarkerProps> = ({
  ...options
}: MarkerProps) => {
  const [marker, setMarker] = useState<google.maps.Marker>();

  useEffect(() => {
    if (!marker) {
      setMarker(new google.maps.Marker());
    }

    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);

  useEffect(() => {
    if (marker) {
      marker.setOptions({
        ...options,
        icon: {
          fillColor: "#6580ED",
          fillOpacity: 1,
          path: google.maps.SymbolPath.CIRCLE,
          scale: 12,
          strokeColor: "#F8FFFF",
          strokeWeight: 2,
        },
      });
    }
  }, [marker, options]);

  return null;
};
