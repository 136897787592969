import React from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";
import { useEffect } from "react";
const RouteChangeTracker = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
  }, [location]);

  return <div></div>;
};

export default RouteChangeTracker;
