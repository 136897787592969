import styled from "styled-components";
import { mobileMax } from "../../../lib/media-breakpoints";

export const MenuItemRowContainer = styled.div`
  height: 44px;
  display: flex;
  flex-direction: column;
  background: #fafafa;
  cursor: pointer;
`;

export const MenuItemLabel = styled.p`
  margin: 10px 0 0 30px;

  text-decoration: none !important;
  color: black !important;

  @media (min-width: ${mobileMax + 1}px) {
    font-size: 16px;
  }

  @media (max-width: ${mobileMax}px) {
    font-size: 14px;
  }
`;

export const MenuDivider = styled.div`
  margin-top: auto;
  margin-left: 30px;
  flex: 0 0 1px;
  border-bottom: solid #ededed 1px;
`;
