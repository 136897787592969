import styled from "styled-components";
import { applyBoxShadow } from "../../../styled/box-shadow";

export const CardsTableContainer = styled.div``;

export const TableTitle = styled.div`
  color: #244169;
  font-size: 30px;
  font-weight: 700;
`;

export const TableSection = styled.div`
  display: flex;
  flex-direction: column;
`;
