import styled from "styled-components";
import { applyGradient } from "../../../styled/blue-gradient-background";
import { applyBoxShadow } from "../../../styled/box-shadow";

export const ArticleCategoryCardContainer = styled.div`
  width: 110px;
`;

export const CardWrap = styled.div`
  height: 110px;
  position: relative;
  ${applyGradient()};
  padding: 1px;
  border-radius: 8px;

  ${applyBoxShadow()};
  display: flex;
  justify-content: stretch;
`;

export const Content = styled.div`
  background: white;
  border-radius: 8px;
  width: 100%;
  overflow: hidden;
`;

export const Label = styled.div`
  color: #1b3963;
  font-size: 12px;
  text-align: left;
  margin-top: 9px;
  height: 55px;
`;

export const CardImage = styled.img.attrs((props) => ({
  src: props.src,
}))`
  object-fit: cover;
  height: 100%;
  width: 100%;
`;
