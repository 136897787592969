import styled from "styled-components";
import { applyBoxShadow } from "./box-shadow";
import { GradientBorder } from "./gradient-border";

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FieldSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
`;

export const FieldLabel = styled.label`
  color: #244169;
  font-size: 16px;
  font-weight: 700;
  margin: 0 0 5px 0;
`;

export const TextInput = styled.input.attrs((props) => ({}))`
  margin: 0;
  height: 20px;
  background: none;
  border: none;

  :focus {
    outline: none;
  }
`;

export const ModifiedGradientBorder = styled(GradientBorder)`
  height: 3px;
`;

export const SubmitButton = styled.button<{ active: boolean }>`
  border: none;
  ${applyBoxShadow()}
  background:${(props) => (props.active ? "#244169" : "#667FA2")};
  color: white;
  font-size: 12px;
  font-weight: bold;
  height: 39px;
  max-width: 156px;
  margin: 30px 0 0 0;
`;

export const StyledErrorMessage = styled.span`
  font-weight: bold;
  color: red;
  font-size: 14px;
  margin: 5px 0;
`;

export const StyledTextArea = styled.textarea`
  height: 200px;
  border: solid black 1px;
  padding: 10px;
`;
