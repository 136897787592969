import React from "react";

const LeftArrowIcon = ({ iconProps }: any) => {
  return (
    <svg
      width={17}
      height={17}
      xmlns="http://www.w3.org/2000/svg"
      {...iconProps}
      viewBox="-3 0 22 15"
    >
      <path d="M10.854 4.854 7.207 8.5l3.646 3.646-.707.707L5.793 8.5l4.354-4.354.707.708zM17 8.5c0 4.687-3.813 8.5-8.5 8.5S0 13.187 0 8.5 3.813 0 8.5 0 17 3.813 17 8.5zm-1 0C16 4.364 12.636 1 8.5 1S1 4.364 1 8.5 4.364 16 8.5 16 16 12.636 16 8.5z" />
    </svg>
  );
};

export default LeftArrowIcon;
