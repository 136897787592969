import { VerifyingEmailContainer } from "../styled/verifying-email-styled";

const VerifyingEmail = () => {
  return (
    <VerifyingEmailContainer>
      Verifying Email. Please wait.
    </VerifyingEmailContainer>
  );
};

export default VerifyingEmail;
