import styled from "styled-components";
import { TextInput } from "../../styled/form-elements";

export const Container = styled.div``;

export const SectionTitle = styled.div``;

export const AdvertisementTextInput = styled(TextInput)``;

export const ErrorMessageText = styled.p`
  color: red;
  font-weight: bold;
`;
