import { EditProfileFormValues } from "./components/form-body";
import EditProfileFormContext from "./components/form-context";
import { EditProfileFormContainer } from "./styled/edit-profile-form-styled";

interface EditProfileFormProps {
  firstName: string;
  lastName: string;
  onSubmit: (values: EditProfileFormValues) => Promise<void>;
}

const EditProfileForm = (props: EditProfileFormProps) => {
  return (
    <EditProfileFormContainer>
      <EditProfileFormContext
        firstName={props.firstName}
        lastName={props.lastName}
        onSubmit={props.onSubmit}
      />
    </EditProfileFormContainer>
  );
};

export default EditProfileForm;
