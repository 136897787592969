import { SetStateAction, useEffect, useState } from "react";

import { useParams, useNavigate } from "react-router-dom";

import BackButton from "../../components/back-button/back-button";
import BannerAdImageUpdate from "../../components/banner-ad-image-update/banner-ad-image-update";
import BannerAdInfo from "../../components/banner-ad-info/banner-ad-info";
import SelectBusinessTable from "../../components/select-business-table/select-business-table";
import { SpecialOfferUpdateDetailsValues } from "../../components/special-offer-details-update/special-offer-details-update";
import UpdateSpecialOfferDetailsWrapper from "../../components/special-offer-details-update/special-offer-details-update-wrapper";
import { useDeterminePlans } from "../../components/special-offer-form/util";
import SpecialOfferPlansTable from "../../components/special-offer-plans-table/special-offer-plans-table";
import SentryHandler from "../../lib/sentry/sentry";

import {
  AdvertisementsGradientBorder,
  AdvertisementSubmitButton,
  Wrapper,
  Content,
  AdvertisementsPageTitle,
} from "../../styled/advertisement-form-elements";
import { StyledErrorMessage } from "../../styled/form-elements";

import { Loader } from "../../styled/loader";
import { SuccessText } from "../../styled/typography";
import { InfoText, SectionTitle } from "../AdvertisementsMenu/styled";
import { DeleteButton } from "../UpdateBannerAd/styled";

import { useDataQuery } from "./util/dataQuery";
import { useDeleteMutation } from "./util/deleteMutation";
import { useResumeMutation } from "./util/resumeMutation";
import { useUpdateDetailsMutation } from "./util/updateDetailsMutation";
import { useUpdateImageMutation } from "./util/updateImageMutation";
import { usePauseMutation } from "./util/usePauseMutation";

interface UpdateSpecialOfferPageProps {}

const UpdateSpecialOfferPage = (props: UpdateSpecialOfferPageProps) => {
  const params = useParams();

  const { plans, changeSelected } = useDeterminePlans();

  const navigate = useNavigate();

  const uid = params.specialOfferUId;

  const [planErrorMessage, setPlanErrorMessage] =
    useState<string | undefined>(undefined);
  const [selectedAdPlan, setPlan] = useState<string | undefined>(undefined);

  const dataQuery = useDataQuery(uid);
  const pauseMutation = usePauseMutation(uid);
  const resumeMutation = useResumeMutation(uid);
  const updateImageMutation = useUpdateImageMutation(uid);
  const updateDetailsMutation = useUpdateDetailsMutation(uid);
  const deleteMutation = useDeleteMutation(uid);

  useEffect(() => {
    if (dataQuery.data?.pointsOfInterest) {
      changeSelected(dataQuery.data.pointsOfInterest.length);
    }
  }, [dataQuery.data, changeSelected]);

  if (dataQuery.isLoading) {
    return (
      <Content>
        <Loader />
      </Content>
    );
  }

  if (dataQuery.isError || !dataQuery.data) {
    return (
      <Content>
        <p>Could not get special offer at this time.</p>
      </Content>
    );
  }

  return (
    <Content>
      <BackButton handleBack={() => navigate("/account/advertisements")} />
      <Wrapper>
        <AdvertisementsPageTitle>UPDATE SPECIAL OFFER</AdvertisementsPageTitle>
        <BannerAdInfo
          loading={pauseMutation.isLoading || resumeMutation.isLoading}
          handleButtonClick={() => {
            if (dataQuery.data.status === "active") {
              pauseMutation.mutate({ id: dataQuery.data.id });
            } else if (dataQuery.data.status === "inactive") {
              resumeMutation.mutate({ id: dataQuery.data.id });
            } else {
              SentryHandler.captureMessage(
                "Invalid status passed to handleButtonClick on BannerAdInfo"
              );
            }
          }}
          status={dataQuery.data?.status}
          expirationDate={dataQuery.data?.expirationDate}
        />
        {pauseMutation.isError || resumeMutation.isError ? (
          <SuccessText style={{ alignSelf: "center" }}>
            Could not update at this time.
          </SuccessText>
        ) : null}
        {dataQuery.data.expirationDate === null ? (
          <DeleteButton
            onClick={() => {
              const confirmed = window.confirm(
                "Do you want to delete this special offer?"
              );

              if (confirmed) {
                deleteMutation.mutate();
              }
            }}
          >
            DELETE
          </DeleteButton>
        ) : null}
      </Wrapper>
      <AdvertisementsGradientBorder />
      <Wrapper>
        <SectionTitle style={{ marginBottom: 20 }}>
          Special Offer Image
        </SectionTitle>
        <BannerAdImageUpdate
          handleClick={(image: File) => {
            if (
              window.confirm(
                "This will put your advertisement in review until approved by USA PACS. Do you want to continue?"
              )
            ) {
              updateImageMutation.mutate({ id: dataQuery.data.id, image });
            }
          }}
          imageSrc={`${dataQuery.data.imageUrl}?id=${
            dataQuery.data.updatedAt ?? "1"
          }`}
          loading={updateImageMutation.isLoading}
          imageWidth={200}
          imageHeight={200}
        />
        {updateImageMutation.isError ? (
          <SuccessText style={{ alignSelf: "center" }}>
            Could not update at this time.
          </SuccessText>
        ) : null}
      </Wrapper>

      <AdvertisementsGradientBorder />
      <Wrapper>
        <UpdateSpecialOfferDetailsWrapper
          loading={dataQuery.isLoading || updateDetailsMutation.isLoading}
          existingTitle={dataQuery.data.title}
          existingDescription={dataQuery.data.description}
          existingRedeemMethod={dataQuery.data.redeemMethod}
          onSubmit={(values: SpecialOfferUpdateDetailsValues) => {
            if (
              window.confirm(
                "This will put your advertisement in review until approved by USA PACS. Do you want to continue?"
              )
            ) {
              updateDetailsMutation.mutate({
                id: dataQuery.data.id,
                newTitle: values.title,
                newDescription: values.description,
                newRedeemMethod: values.redeemMethod ?? "",
              });
            }
          }}
        />
        {updateDetailsMutation.isSuccess ? (
          <SuccessText style={{ alignSelf: "center" }}>
            Changes Saved
          </SuccessText>
        ) : null}
        {updateDetailsMutation.isError ? (
          <SuccessText style={{ alignSelf: "center" }}>
            Could not update at this time.
          </SuccessText>
        ) : null}
      </Wrapper>
      <AdvertisementsGradientBorder />
      <Wrapper>
        <SectionTitle>Businesses</SectionTitle>
        <SelectBusinessTable
          businesses={dataQuery.data.pointsOfInterest ?? []}
          onChange={function (ids: number[]): void {}}
          selected={[]}
          setSelected={function (value: SetStateAction<number[]>): void {}}
        />
      </Wrapper>
      <AdvertisementsGradientBorder />
      <Wrapper style={{ marginBottom: 60 }}>
        <SectionTitle>Extend Advertisement</SectionTitle>
        <InfoText>*Payment will be confirmed on next page.</InfoText>
        {planErrorMessage ? (
          <StyledErrorMessage>{planErrorMessage}</StyledErrorMessage>
        ) : null}
        <SpecialOfferPlansTable
          plans={plans}
          onClick={(uid: string) => {
            setPlan(uid);
          }}
        />
        <AdvertisementSubmitButton
          isDisabled={false}
          onClick={() => {
            if (selectedAdPlan) {
              navigate(
                `/account/advertisements/special-offer/payment?o=${uid}&i=${selectedAdPlan}`
              );
            } else {
              setPlanErrorMessage("Please select a plan.");
            }
          }}
        >
          CHECKOUT
        </AdvertisementSubmitButton>
      </Wrapper>
    </Content>
  );
};

export default UpdateSpecialOfferPage;
