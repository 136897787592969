import { useInfiniteQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import ArticlesTable from "../../components/articles-table/articles-table";
import BackButton from "../../components/back-button/back-button";
import { getArticles } from "../../lib/api/article";

import { Loader } from "../../styled/loader";

import { Article } from "../../ts/article";
import {
  Content,
  Header,
  PageContainer,
  SuggestPostButton,
} from "./styled/view-articles-page-styled";

interface ViewArticlesPageProps {
  categoryFilter?: number;
}

const ViewArticlesPage = (props: ViewArticlesPageProps) => {
  const navigate = useNavigate();

  const { categoryId } = useParams();

  const {
    data,
    isLoading,
    isFetching,
    isError,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery(
    ["/articles", categoryId],
    ({ pageParam = 0 }) => getArticles(pageParam, categoryId),
    {
      getNextPageParam: (lastPage) => lastPage.nextPage,
      staleTime: Infinity,
    }
  );

  const handleLoadMoreClick = () => {
    fetchNextPage();
  };

  const pageBack = () => {
    if (categoryId) {
      navigate(`/menu/categories`);
    } else {
      navigate("/menu");
    }
  };

  const rows: Article[] = [];

  data?.pages.forEach((page) =>
    // page.businesses.forEach((poi) => rows.push(poi))
    rows.push(...page.articles)
  );

  if (isLoading) {
    return (
      <PageContainer style={{ alignItems: "center", justifyContent: "center" }}>
        <Loader />
      </PageContainer>
    );
  }

  if (isError) {
    return null;
  }

  return (
    <PageContainer>
      <Header>
        <BackButton handleBack={() => pageBack()} />
        <SuggestPostButton
          onClick={() => {
            navigate("/menu/suggest-post");
          }}
        >
          Suggest a Post
        </SuggestPostButton>
      </Header>
      <Content>
        <ArticlesTable
          onRowClick={(id: number, type: string, externalLink?: string) => {
            if (type === "internal") {
              if (categoryId) {
                navigate(`/menu/article/${categoryId}/${id}`);
              } else {
                navigate(`/menu/article/${id}`);
              }
            } else if (type === "external" && externalLink) {
              window.open(externalLink);
            } else {
            }
          }}
          loading={isLoading || isFetching}
          articles={rows}
          hasMore={hasNextPage ?? false}
          onLoadMoreClick={handleLoadMoreClick}
        />
      </Content>
    </PageContainer>
  );
};

export default ViewArticlesPage;
