import { usdFormatter } from "../../lib/formatter/usd-formatter";
import { Wrapper, Content, Detail, DetailTitle } from "./styled";

interface BannerAdCheckoutReviewProps {
  paymentIntentAmount: number;
  planName: string;
  days: number;
}

const BannerAdCheckoutReview = (props: BannerAdCheckoutReviewProps) => {
  return (
    <Wrapper>
      <Content>
        <DetailTitle>Payment Amount</DetailTitle>
        <Detail>{usdFormatter.format(props.paymentIntentAmount / 100)}</Detail>
        <DetailTitle>Plan</DetailTitle>
        <Detail>{props.planName}</Detail>
        <DetailTitle>Days</DetailTitle>
        <Detail>{props.days} Days</Detail>
      </Content>
    </Wrapper>
  );
};

export default BannerAdCheckoutReview;
