import styled from "styled-components";
import { mobileMax } from "../../../lib/media-breakpoints";
import { SecondaryButton } from "../../../styled/buttons";

export const UserBusinessesPageContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: ${mobileMax}px) {
    max-width: 100vw;
  }
`;

export const TableWrapper = styled.div`
  margin: 67px 60px 30px 60px;

  @media (max-width: ${mobileMax}px) {
    margin: 65px 30px;
  }
`;

export const LoadMoreButton = styled(SecondaryButton)`
  width: 88%;
  margin: 0 0 30px 0;
  align-self: center;
`;
