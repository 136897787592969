import styled from "styled-components";
import { applyBoxShadow } from "../../../styled/box-shadow";

export const UserLocationButtonContainer = styled.div`
  background: white;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-left: 20px;
  width: 35px;
  height: 35px;
  border-radius: 50%;

  position: absolute;
  left: 0;
  top: 0;
  z-index: 4000;

  ${applyBoxShadow()};
`;
