import React from "react";
import RemoteImage from "../remote-image/remote-image";
import AddImagePrompt from "./components/add-image-prompt";
import { AddIcon } from "./styled/add-image-prompt-styled";
import {
  BusinessAddressLabel,
  BusinessInfoHeaderContainer,
  BusinessNameLabel,
  ImageWrapper,
  LeftSide,
  RightSide,
} from "./styled/business-info-header-styled";

export interface BusinessInfoHeaderProps {
  businessName: string;
  businessAddress: string;
  businessPrimaryPhotoUrl?: string;
  handlePhotoClick?: () => void;
}

const BusinessInfoHeader = (props: BusinessInfoHeaderProps) => {
  return (
    <BusinessInfoHeaderContainer>
      <LeftSide>
        <ImageWrapper
          onClick={() => {
            if (props.handlePhotoClick) {
              props.handlePhotoClick();
            }
          }}
        >
          {props.businessPrimaryPhotoUrl ? (
            <RemoteImage
              src={props.businessPrimaryPhotoUrl}
              alt={"Business Image"}
            />
          ) : (
            <AddImagePrompt />
          )}
          {props.handlePhotoClick ? (
            <AddIcon>
              <span>+</span>
            </AddIcon>
          ) : null}
        </ImageWrapper>
      </LeftSide>
      <RightSide>
        <BusinessNameLabel>{props.businessName}</BusinessNameLabel>
        <BusinessAddressLabel>{props.businessAddress}</BusinessAddressLabel>
      </RightSide>
    </BusinessInfoHeaderContainer>
  );
};

export default BusinessInfoHeader;
