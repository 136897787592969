import styled from "styled-components";
import { applyGradient } from "../../../styled/blue-gradient-background";

export const LoginContainer = styled.div`
  ${applyGradient()}
  // min-height:100vh;
  flex:1;
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-bottom: 30px;

  form {
    width: 100%;
  }
`;
