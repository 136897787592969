import { Navigate, Outlet, Route, Routes } from "react-router";
import useAuth, { AuthProvider } from "./lib/custom-hooks/useAuth";
import DashboardPage from "./pages/Dashboard/Dashboard";

import ForgotPasswordPage from "./pages/ForgotPassword/ForgotPassword";
import LoginPage from "./pages/Login/Login";
import RegisterPage from "./pages/Register/Register";
import ConfirmEmailPage from "./pages/ConfirmEmail/ConfirmEmail";

import "./styles/global.css";
import { getFirebaseAuth } from "./lib/firebase/firebase";
import AuthPage from "./pages/Auth/AuthPage";

import Navigation from "./components/navigation/navigation";
import MainMenuPage from "./pages/MainMenuPage/MainMenuPage";
import AccountMenuPage from "./pages/AccountMenu/AccountMenu";
import { QueryClientProvider } from "react-query";
import { queryClient } from "./lib/react-query/react-query-client";
import { LocationProvider } from "./lib/custom-hooks/useLocation";
import MissingPage from "./pages/MissingPage/MissingPage";

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import ImageHandler from "./components/image-handler/image-handler";
import { useMediaQuery } from "react-responsive";

Sentry.init({
  dsn: "https://efcec223591641449b7faadc9cae243c@o294040.ingest.sentry.io/6159913",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  environment: process.env.NODE_ENV,
  autoSessionTracking: true,
});

const PrivateOutlet = () => {
  const { isAuth } = useAuth();
  const auth = getFirebaseAuth();
  const currentUser = auth.currentUser;
  let isMobile = useMediaQuery({ query: `(max-width: 550px)` });

  if (isAuth === null) {
    return (
      <div
        style={{
          display: "flex",
          minHeight: "100vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {isMobile ? <ImageHandler name="logo" /> : null}
      </div>
    );
  } else if (isAuth === true) {
    if (currentUser?.emailVerified === true) {
      return (
        <>
          <Navigation />
          <Outlet />
        </>
      );
    } else {
      return <Navigate to="/confirm-email" />;
    }
  } else {
    return <Navigate to="/register" />;
  }
};

const PublicOutlet = () => {
  const { isAuth } = useAuth();
  const auth = getFirebaseAuth();
  const currentUser = auth.currentUser;

  if (isAuth === null || isAuth === false) {
    return <Outlet />;
  }

  if (currentUser && currentUser.emailVerified === true) {
    return <Navigate to="/" />;
  }

  if (currentUser && currentUser.emailVerified === false) {
    return <Navigate to="/confirm-email" />;
  }

  return <Outlet />;
};

const ConfirmEmailOutlet = () => {
  const { isAuth } = useAuth();
  const auth = getFirebaseAuth();
  const currentUser = auth.currentUser;

  if (isAuth && currentUser?.emailVerified === true) {
    return <Navigate to="/" />;
  }

  if (isAuth === false) {
    return <Navigate to="/register" />;
  }

  return <Outlet />;
};

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <LocationProvider>
          <Routes>
            <Route path={"*"} element={<MissingPage />} />
            <Route element={<PrivateOutlet />}>
              <Route index element={<Navigate replace to="/home" />} />
              <Route path="/home/*" element={<DashboardPage />} />

              <Route path="/menu/*" element={<MainMenuPage />} />
              <Route path="/account/*" element={<AccountMenuPage />} />

              <Route path="/events" />
              <Route path="/events/categories" />
              <Route path="/events/bookmarks" />

              <Route path="/information/map" />

              <Route path="/referral" />
            </Route>
            <Route element={<ConfirmEmailOutlet />} path="/confirm-email">
              <Route path="" element={<ConfirmEmailPage />} />
            </Route>
            <Route path="/auth" element={<AuthPage />} />=
            <Route element={<PublicOutlet />}>
              <Route path="/register" element={<RegisterPage />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/forgot-password" element={<ForgotPasswordPage />} />
            </Route>
          </Routes>
        </LocationProvider>
      </AuthProvider>
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </QueryClientProvider>
  );
}

export default App;
