export const applyBoxShadow = () => {
  return `
   box-shadow: 0 2px 10px #8597b0;
  `;
};

export const applyLightBoxShadow = () => {
  return `
   box-shadow: 0 2px 10px #8597B0;
  `;
};
