import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { deleteBusinessOffer } from "../../../lib/api/special-offer";

import { SpecialOffer } from "../../../ts/special-offer";

export const useDeleteMutation = (uid?: string) => {
  const client = useQueryClient();
  const navigate = useNavigate();

  const mutation = useMutation(
    ["/business-offer/delete", uid],
    () => deleteBusinessOffer(uid),
    {
      onSuccess: (data) => {
        client.setQueryData<SpecialOffer[] | undefined>(
          "/me/special-offers",
          (old) => {
            if (old) {
              let found = old.findIndex((a) => a.uid === uid);
              if (found > -1) {
                let tmp = old.slice();
                tmp.splice(found, 1);

                return tmp;
              }
            }
            return old;
          }
        );
        navigate("/account/advertisements");
      },
    }
  );

  return mutation;
};
