import styled from "styled-components";
import { mobileMax } from "../../../lib/media-breakpoints";

export const UploadContainer = styled.div`
  display: flex;
  flex-direction: column;

  :first-child {
    margin-top: 64px;
  }

  :not(:first-child) {
    margin-top: 20px;
  }

  @media (max-width: ${mobileMax}px) {
    margin-left: 41px;
    margin-right: 41px;
  }

  @media (min-width: ${mobileMax + 1}px) {
    width: 500px;
  }

  span.error-message {
    color: red;
    font-weight: 700;
    margin-bottom: 7px;
    @media (max-width: ${mobileMax}px) {
      font-size: 14px;
    }
    @media (min-width: ${mobileMax + 1}px) {
      font-size: 16px;
    }
  }
`;

export const UploadButtonsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
`;
