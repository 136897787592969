import { withFormik } from "formik";
import SuggestPostForm, {
  SuggestPostFormProps,
  SuggestPostFormSchema,
  SuggestPostFormValues,
} from "./suggest-post-form";

interface SuggestPostFormWrapperProps {
  handleSubmit: (values: SuggestPostFormValues) => void;
}

const SuggestPostFormWrapper = (props: SuggestPostFormWrapperProps) => {
  const Form = withFormik<SuggestPostFormProps, SuggestPostFormValues>({
    handleSubmit: (values) => {
      props.handleSubmit(values);
    },
    mapPropsToValues: () => ({
      content: "",
      wantsToBeCredited: false,
    }),
    validateOnBlur: false,
    validateOnMount: false,
    validationSchema: SuggestPostFormSchema,
  })(SuggestPostForm);

  return <Form />;
};

export default SuggestPostFormWrapper;
