import { useMutation, useQueryClient } from "react-query";
import { updateBannerAdImage } from "../../../lib/api/banner-ad";
import { BannerAdvertisement } from "../../../ts/banner-advertisement";

export const useUpdateImageMutation = (uid?: string) => {
  const client = useQueryClient();
  const mutation = useMutation(
    ["banner-ad/update", uid],
    (values: { id: number; image: File }) =>
      updateBannerAdImage(values.id, values.image),
    {
      onSuccess: (data) => {
        client.setQueryData<BannerAdvertisement | undefined>(
          ["/banner-advertisement", uid],
          (old) => {
            if (old) {
              old.url = data.url;
              old.updatedAt = data.updatedAt;
              old.status = data.status;
            }

            return old;
          }
        );
      },
    }
  );

  return mutation;
};
