const UserLocation = ({ props, iconProps }: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 460.047 460.047"
      style={{
        enableBackground: "new 0 0 460.047 460.047",
      }}
      xmlSpace="preserve"
      {...iconProps}
    >
      <path d="M439.616 1.029 9.615 166.405a15 15 0 0 0-.913 27.615l175.93 81.379 81.379 175.93a15 15 0 0 0 27.615-.912c.755-1.963 164.899-428.76 165.398-430.069 4.689-12.383-7.601-23.859-19.408-19.319zM53.478 181.677 384.415 54.402 192.728 246.089l-139.25-64.412zm224.875 224.875-64.412-139.25L405.629 75.614 278.353 406.552z" />
    </svg>
  );
};

export default UserLocation;
