import styled from "styled-components";

export const ResultsTableListingContainer = styled.div`
  display: flex;
`;

export const ResultListingLeftSide = styled.div`
  flex: 1;
`;

export const BusinessNameLabel = styled.p`
  font-size: 16px;
  font-weight: 700;
  margin: 20px 0 5px 20px;
`;

export const AddressLabel = styled.p`
  font-size: 16px;
  margin: 0 0 5px 20px;
`;

export const InfoSection = styled.div`
  margin: 0 0 10px 20px;
  display: flex;
`;

export const BusinessCategoryLabel = styled.p`
  font-size: 16px;
  margin: 0 0 5px 0;
  color: #b6b6b6;
`;
export const BusinessDistanceLabel = styled.p`
  font-size: 16px;
  margin: 0 0 5px 5px;
  color: #b6b6b6;

  :before {
    content: "• ";
  }
`;

export const BadgeSection = styled.div`
  margin: 0 0 10px 15px;
`;

export const ResultListingRightSide = styled.div`
  flex: 0 0 55px;
  display: flex;
`;

export const ImageSection = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  height: 150px;
  width: 200px;
  align-self: center;
  margin-right: 20px;
`;

export const PrimaryPhoto = styled.img`
  height: 100%;
  width: 100%;
  border-radius: 5px;
`;
