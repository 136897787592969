import styled from "styled-components";
import { mobileMax } from "../../../lib/media-breakpoints";

export const IconsSectionContainer = styled.div`
  display: flex;

  justify-content: space-between;
  align-items: center;
  margin-left: auto;

  @media (min-width: ${mobileMax + 1}px) {
    margin-right: 72px;
    flex: 0 0 151px;
  }

  @media (max-width: ${mobileMax}px) {
    svg {
      transform: scale(0.7);
      flex: 0 0 120px;
    }
  }
`;
export const IconContainer = styled.div``;

export const MenuButton = styled.button`
  color: #667fa2;
  border: none;
  background: none;
  font-size: 25px;
  font-weight: 700;
`;
