import React from "react";
import { useContext } from "react";
import { GradientBorder } from "../../../styled/gradient-border";
import { SpecialOffer } from "../../../ts/special-offer";
import ImageSlider from "../../image-slider/image-slider";

import {
  BodyContainer,
  PostedBadgesButton,
  DirectionsButton,
  PostABadgeButton,
  InfoSection,
  InfoSectionText,
  InfoSectionTitle,
  BusinessPhotoDisplay,
  PhotoContainer,
  PhotosSection,
  BusinessOffersSection,
} from "../styled/body-styled";
import ClaimSection from "./claim-section";
import { BusinessContext } from "./view-business-switch";

export interface BodyProps {
  shouldShowPostedBadgesButton: boolean;
  handlePostedBadgesClick: () => void;
  handlePostBadgeButtonClick: () => void;
  handleBusinessOfferClick: (index: number) => void;
}

const Body = (props: BodyProps) => {
  const pointOfInterest = useContext(BusinessContext);

  if (!pointOfInterest) {
    return null;
  }

  return (
    <BodyContainer>
      {props.shouldShowPostedBadgesButton ? (
        <PostedBadgesButton
          onClick={() => {
            props.handlePostedBadgesClick();
          }}
        >
          POSTED BAGES
        </PostedBadgesButton>
      ) : null}

      {pointOfInterest.businessOffers &&
      pointOfInterest.businessOffers.length > 0 ? (
        <BusinessOffersSection>
          <ImageSlider
            onClick={(index) => {
              if (
                pointOfInterest.businessOffers &&
                pointOfInterest.businessOffers[index]
              ) {
                props.handleBusinessOfferClick(index);
              }
            }}
            imageSources={pointOfInterest.businessOffers.map(
              (a) => `${a.imageUrl}?id=${a.updatedAt ?? "1"}`
            )}
          />
        </BusinessOffersSection>
      ) : null}
      <DirectionsButton
        onClick={() => {
          window.open(
            `https://www.google.com/maps/dir/?api=1&destination_place_id=${pointOfInterest.placeId}&destination=${pointOfInterest.lat},${pointOfInterest.lng}`
          );
        }}
        style={!props.shouldShowPostedBadgesButton ? { marginTop: 20 } : {}}
      >
        Directions
      </DirectionsButton>
      <PostABadgeButton
        onClick={() => {
          props.handlePostBadgeButtonClick();
        }}
      >
        POST A BADGE
      </PostABadgeButton>

      {pointOfInterest.businessPhotos &&
      pointOfInterest.businessPhotos.length > 0 ? (
        <PhotosSection>
          {pointOfInterest.businessPhotos.map((photo) => (
            <PhotoContainer key={photo.id}>
              <BusinessPhotoDisplay src={photo.imageUrl} />
            </PhotoContainer>
          ))}
        </PhotosSection>
      ) : null}

      {pointOfInterest.formattedAddress ? (
        <InfoSection>
          <InfoSectionTitle>Address</InfoSectionTitle>

          <InfoSectionText>{pointOfInterest.formattedAddress}</InfoSectionText>

          <GradientBorder style={{ height: 3, marginTop: 11 }} />
        </InfoSection>
      ) : null}

      {pointOfInterest.openingHours?.weekday_text &&
      pointOfInterest.openingHours.weekday_text.length > 0 ? (
        <InfoSection>
          <InfoSectionTitle>Hours</InfoSectionTitle>
          {pointOfInterest.openingHours.weekday_text.map((txt, index) => (
            <InfoSectionText key={index}>{txt}</InfoSectionText>
          ))}
          <GradientBorder style={{ height: 3, marginTop: 11 }} />
        </InfoSection>
      ) : null}

      {pointOfInterest.phoneNumber ? (
        <InfoSection>
          <InfoSectionTitle>Phone Number</InfoSectionTitle>

          <InfoSectionText>{pointOfInterest.phoneNumber}</InfoSectionText>

          <GradientBorder style={{ height: 3, marginTop: 11 }} />
        </InfoSection>
      ) : null}

      {pointOfInterest.claimed === false ? (
        <ClaimSection
          placeId={pointOfInterest.placeId}
          lat={pointOfInterest.lat}
          long={pointOfInterest.lng}
        />
      ) : null}
    </BodyContainer>
  );
};

export default Body;
