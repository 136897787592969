import React from "react";
import { ModifiedCloseButton } from "../styled/header-styled";
import { BadgesAlignment } from "../../../ts/point-of-interest";
import BadgesDisplay from "../../badges-display/badges-display";
import {
  HeaderContainer,
  TopPart,
  BusinessNameLabel,
  BottomPart,
  InfoSection,
  BadgesSection,
  BusinessCategoryLabel,
  DistanceFromUserLabel,
} from "../styled/header-styled";
import IconHandler from "../../icon-handler/icon-handler";
import { formatBusinessCategory } from "../../../lib/format-business-category";

export interface HeaderProps {
  businessName: string;
  businessCategory: string;
  distanceFromUser?: number;
  badgeAlignments?: BadgesAlignment;
  handleClose?: () => void;
}

const Header = ({
  businessName,
  businessCategory,
  distanceFromUser,
  badgeAlignments,
  handleClose,
}: HeaderProps) => {
  return (
    <HeaderContainer>
      <TopPart>
        <BusinessNameLabel>{businessName}</BusinessNameLabel>
        <ModifiedCloseButton
          onClick={() => {
            if (handleClose) {
              handleClose();
            }
          }}
        >
          <IconHandler name="cross" iconProps={{}} />
        </ModifiedCloseButton>
      </TopPart>
      <BottomPart>
        <InfoSection>
          <BusinessCategoryLabel>
            {formatBusinessCategory(businessCategory)}
          </BusinessCategoryLabel>
          {distanceFromUser ? (
            <DistanceFromUserLabel>{distanceFromUser} mi</DistanceFromUserLabel>
          ) : null}
        </InfoSection>
        <BadgesSection>
          {badgeAlignments ? (
            <BadgesDisplay
              badgeAlignments={{
                ...badgeAlignments,
              }}
            />
          ) : null}
        </BadgesSection>
      </BottomPart>
    </HeaderContainer>
  );
};

export default Header;
