import { Link } from "react-router-dom";
import { LinkText } from "../add-to-home-screen-info/styled";
import { Content, Subtitle, Title } from "./styled";

interface LocationPromptProps {}

const LocationPrompt = (props: LocationPromptProps) => {
  return (
    <Content style={{ marginBottom: 20 }}>
      <Title>Unable to access your location</Title>
      <Subtitle style={{ marginBottom: 5 }}>
        For a better experience, please turn on Location Services and share with
        USA PACS.
      </Subtitle>
      <Link
        to="/menu/location-help?b=home"
        style={{ textDecoration: "none", textAlign: "center", marginTop: 0 }}
      >
        <LinkText>Need help? View instructions here</LinkText>
      </Link>
    </Content>
  );
};

export default LocationPrompt;
