import { USAPACSUser } from "../../ts/User";
import axios from "../axios-instance";

interface UsernameExistsResponse {
  data: { exists: boolean };
}

export const checkUsernameExists = async (
  username: string
): Promise<string | undefined> => {
  try {
    const res = await axios.post<{ username: string }, UsernameExistsResponse>(
      "/users/username-exists",
      { username: username }
    );

    const { data }: UsernameExistsResponse = res;

    if (data.exists) {
      return "Username exists.";
    }
  } catch (e: any) {
    if (e.response?.data?.message) {
      return e.response.data.message;
    } else {
      return "Could not validate username";
    }
  }
};

export interface CreateCitizenUserInput {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  referralCode: string;
  username: string;
  accountTypeId: number;
}

export interface CreateUserResponse {
  data: {
    user?: USAPACSUser;
    message?: string;
  };
}

export const createCitizenUser = async (
  userInput: CreateCitizenUserInput
): Promise<USAPACSUser> => {
  try {
    const { data } = await axios.post<
      CreateCitizenUserInput,
      CreateUserResponse
    >("/users", userInput);

    if (data.message) {
      throw Error(data.message);
    }

    if (!data.user) {
      throw Error("Could not register at this time.");
    }

    return data.user;
  } catch (e: any) {
    if (e.response?.data?.message) {
      throw Error(e.response.data.message);
    } else {
      throw Error("Could not register at this time.");
    }
  }
};

export interface CreateLeosaUserInput {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  referralCode: string;
  username: string;
  accountTypeId: number;
  department: string;
  unit: string;
  rank: string;
  frontPhoto: File;
  backPhoto: File;
}

export const createLeosaUser = async (userInput: any): Promise<USAPACSUser> => {
  try {
    const formData = new FormData();

    const fields = [
      "email",
      "password",
      "firstName",
      "lastName",
      "referralCode",
      "username",
      "accountTypeId",
      "department",
      "unit",
      "rank",
      "frontPhoto",
      "backPhoto",
    ];

    fields.forEach((field) => {
      formData.append(field, userInput[field] ?? "");
    });

    const { data } = await axios.post<CreateLeosaUserInput, CreateUserResponse>(
      "/users",
      formData
    );

    if (data.message) {
      throw Error(data.message);
    }

    if (!data.user) {
      throw Error("Could not register at this time.");
    }

    return data.user;
  } catch (e: any) {
    if (e.response?.data?.message) {
      throw Error(e.response.data.message);
    } else {
      throw Error("Could not register at this time.");
    }
  }
};
