import styled from "styled-components";
import { mobileMax } from "../../../lib/media-breakpoints";
import { applyGradient } from "../../../styled/blue-gradient-background";

export const MenuTitleContainer = styled.div`
  ${applyGradient()}
  display: flex;
  align-items: center;
  border-bottom: solid 2px #8195ad;

  @media (min-width: ${mobileMax + 1}px) {
    height: 58px;
  }

  @media (max-width: ${mobileMax}px) {
    height: 48px;
  }
`;

export const MenuTitleLabel = styled.h2`
  margin: 14px 0 13px 30px;
  font-weight: 500;
  color: #1b3963;

  @media (min-width: ${mobileMax + 1}px) {
    font-size: 22px;
  }

  @media (max-width: ${mobileMax}px) {
    font-size: 16px;
  }
`;
