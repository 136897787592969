import { useNavigate } from "react-router";
import ForgotPassword from "../../components/forgot-password/forgot-password";
import ImageHandler from "../../components/image-handler/image-handler";
import { LogoContainer } from "../../components/register/styled/register-styled";
import { ForgotPasswordPageContainer } from "./styled/fogot-password-page-styled";

const ForgotPasswordPage = () => {
  const navigate = useNavigate();

  return (
    <ForgotPasswordPageContainer>
      <LogoContainer>
        <div
          style={{ marginTop: "auto", cursor: "pointer" }}
          onClick={() => navigate("/register")}
        >
          <ImageHandler name="logo" />
        </div>
      </LogoContainer>
      <ForgotPassword />
    </ForgotPasswordPageContainer>
  );
};

export default ForgotPasswordPage;
