import React from "react";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ActiveMarkerContext } from "../../pages/Dashboard/util/contexts";
import { InfoIcon } from "../../styled/info-icon";
import BadgesDisplay from "../badges-display/badges-display";
import {
  ResultsTableListingContainer,
  BusinessCategoryLabel,
  BusinessDistanceLabel,
  BusinessNameLabel,
  InfoSection,
  BadgeSection,
  BottomSection,
  TopSection,
  LeftSection,
  RightSection,
  ImageSection,
  PrimaryPhoto,
} from "./styled/results-table-listing-mobile-styled";

export interface ResultsTableListingProps {
  placeId: string;
  businessName: string;
  businessAddress: string;
  category: string;
  milesFromUser?: number;
  primaryPhotoUrl?: string;

  badgeAlignments?: {
    leosaPolicy: number;
    concealedCarryPolicy: number;
    openCarryPolicy: number;
    menuPolicy: number;
    metalDetectorPolicy: number;
  };
}

const ResultsTableListingMobile = (props: ResultsTableListingProps) => {
  const { setActiveMarker } = useContext(ActiveMarkerContext);
  const navigate = useNavigate();

  return (
    <ResultsTableListingContainer
      onClick={() => {
        if (setActiveMarker) {
          setActiveMarker(props.placeId);
        }

        navigate(`/home/business/${props.placeId}`);
      }}
    >
      <LeftSection>
        <TopSection>
          <BusinessNameLabel>{props.businessName}</BusinessNameLabel>
        </TopSection>
        <BottomSection>
          <InfoSection>
            <BusinessCategoryLabel>{props.category}</BusinessCategoryLabel>
            {props.milesFromUser ? (
              <BusinessDistanceLabel>
                {props.milesFromUser} mi
              </BusinessDistanceLabel>
            ) : null}
          </InfoSection>
          <BadgeSection>
            {props.badgeAlignments ? (
              <BadgesDisplay badgeAlignments={props.badgeAlignments} />
            ) : null}
          </BadgeSection>

          {/* <InfoIcon desktopOnly={true}>
            <span>i</span>
          </InfoIcon> */}
        </BottomSection>
      </LeftSection>
      <RightSection>
        <ImageSection>
          {props.primaryPhotoUrl ? (
            <PrimaryPhoto src={props.primaryPhotoUrl} alt="" />
          ) : null}
        </ImageSection>
      </RightSection>
    </ResultsTableListingContainer>
  );
};

export default ResultsTableListingMobile;
