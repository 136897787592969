import { useState, useEffect } from "react";
import { RegisterState } from "./ts/register-state";
import BackButton from "./components/back-button";
import { RegisterContainer } from "./styled/register-styled";
import LogoSwitch from "./components/logo-switch";
import RegisterSwitch from "./components/register-switch";
import {
  createCitizenUser,
  CreateCitizenUserInput,
  createLeosaUser,
  CreateLeosaUserInput,
} from "../../lib/api/auth";
import {
  loginFirebase,
  sendVerificationEmail,
} from "../../lib/firebase/firebase";
import { FirebaseError } from "@firebase/util";
import { AuthErrorCodes } from "@firebase/auth";
import useAuth from "../../lib/custom-hooks/useAuth";
import { useNavigate } from "react-router";
import { useMutation } from "react-query";
import { USAPACSUser } from "../../lib/ts/User";
import { GradientLoader, GradientLoaderContainer } from "../../styled/loader";
import { getAuth } from "firebase/auth";

import ReactGA from "react-ga";

interface RegisterProps {
  state: RegisterState;
  setState: React.Dispatch<React.SetStateAction<RegisterState>>;
}

const Register = ({ state, setState }: RegisterProps) => {
  const [apiError, setApiError] = useState<string | undefined>(undefined);
  const { mutate, error, isLoading } = useMutation<USAPACSUser, Error>(
    "/users",
    async (values: any) => {
      if (values.isLeosa === true) {
        const input: CreateLeosaUserInput = {
          email: values.email,
          password: values.password,
          firstName: values.firstName,
          lastName: values.lastName,
          referralCode: values.referralCode,
          username: values.username,
          accountTypeId: 1,
          department: values.department,
          unit: values.unit,
          rank: values.rank,
          frontPhoto: values.frontPhoto,
          backPhoto: values.backPhoto,
        };
        const user = await createLeosaUser(input);

        if (!user) {
          throw Error("Could not register at this time.");
        }

        return user;
      } else {
        const input: CreateCitizenUserInput = {
          email: values.email!,
          password: values.password!,
          firstName: values.firstName!,
          lastName: values.lastName!,
          referralCode: values.referralCode!,
          username: values.username!,
          accountTypeId: 0,
        };
        const user = await createCitizenUser(input);

        if (!user) {
          throw Error("Could not register at this time.");
        }

        return user;
      }
    },
    {
      onSuccess: async () => {
        //login user with firebase
        if (!state.email || !state.password) {
          throw Error("Error logging in");
        }
        await loginFirebase(state.email, state.password);
        authHandler.login();
        const auth = getAuth();
        const user = auth.currentUser;
        if (user) {
          sendVerificationEmail(user);
        }
        navigate("/confirm-email");
      },
      onError: (error) => {
        if (error instanceof Error && error.message) {
          setApiError(error.message);
        } else {
          setApiError("Could not register at this time.");
        }
      },
    }
  );
  const navigate = useNavigate();

  const { authHandler } = useAuth();

  const handleBackButtonClick = () => {
    if (state.step === 1) {
      return;
    }

    setState({
      ...state,
      step: state.step - 1,
    });
  };

  const handleComplete = async (values: any) => {
    const finalValues = {
      ...state,
      ...values,
    };

    ReactGA.event({
      category: "user_action",
      action: "register_complete",
    });

    mutate(finalValues);
  };

  useEffect(() => {
    if (state.step > 1 && apiError !== undefined) {
      setApiError(undefined);
    }
  }, [state, apiError]);

  if (isLoading) {
    return (
      <RegisterContainer
        style={{
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          width: "100%",
        }}
      >
        <GradientLoaderContainer>
          <GradientLoader>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </GradientLoader>
        </GradientLoaderContainer>
      </RegisterContainer>
    );
  }

  return (
    <RegisterContainer>
      {state.step !== 1 ? (
        <BackButton
          onClick={() => {
            handleBackButtonClick();
          }}
        />
      ) : null}

      <LogoSwitch step={state.step} />

      <RegisterSwitch
        step={state.step}
        state={state}
        setState={setState}
        onComplete={handleComplete}
        disabled={isLoading}
      />
      {error ? (
        <p style={{ color: "red", textAlign: "center", fontWeight: "bold" }}>
          {error.message}
        </p>
      ) : null}
    </RegisterContainer>
  );
};

export default Register;
