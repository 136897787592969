import React from "react";
import { useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import BackButton from "../../components/back-button/back-button";
import CardsTable from "../../components/cards-table/cards-table";
import IconHandler from "../../components/icon-handler/icon-handler";
import { getUserClaims } from "../../lib/api/user";
import { Loader } from "../../styled/loader";

import { PointOfInterestClaim } from "../../ts/point-of-interest-claim";
import ViewClaimPage from "../ViewClaim/ViewClaim";
import {
  ContentWrapper,
  PendingClaimsPageContainer,
} from "./styled/pending-claims-styled";

const PendingClaimsPage = () => {
  const navigate = useNavigate();
  const { data, isLoading, isError } = useQuery(
    "/me/claims",
    () => getUserClaims(),
    {
      refetchOnWindowFocus: true,
      refetchIntervalInBackground: true,
      staleTime: 100,
    }
  );

  const [state, setState] = useState<{
    page: number;
    claim?: PointOfInterestClaim;
  }>({
    page: 1,
  });

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return (
      <p>Could not get information at this time. Please try again later.</p>
    );
  }

  return state.page === 1 ? (
    <PendingClaimsPageContainer>
      <BackButton
        handleBack={() => {
          navigate("/account");
        }}
      />
      {data && data.length > 0 ? (
        <ContentWrapper>
          <CardsTable
            handleRowClick={(data: any) => {
              setState({
                page: 2,
                claim: data,
              });
            }}
            data={data!}
            dataKeys={{
              titleKey: "businessName",
              subTitleKey: "businessAddress",
            }}
          />
        </ContentWrapper>
      ) : (
        <p style={{ textAlign: "center", marginTop: 65 }}>
          You do not have any pending claims at this time. If you are the owner
          of a business, please search for your business to claim.
        </p>
      )}
    </PendingClaimsPageContainer>
  ) : (
    <ViewClaimPage
      onBack={() =>
        setState({
          page: 1,
          claim: undefined,
        })
      }
      claim={state.claim!}
      onSuccess={() => {
        setState({
          page: 1,
          claim: undefined,
        });
      }}
    />
  );
};

export default PendingClaimsPage;
