import { BulletItem } from "../../ts/bullet-item";
import { LoadMoreButton } from "../articles-table/styled/articles-table-styled";
import BulletItemRow from "../bullet-item-row/bullet-item-row";
import { BulletItemTableContainer, RowWrapper, TableDivider } from "./styled";

interface BulletItemTableProps {
  bulletItems: BulletItem[];
  hasMore: boolean;
  handleLoadMore?: () => void;
  loading: boolean;
  handleRowClick?: (id: number) => void;
}

const BulletItemTable = (props: BulletItemTableProps) => {
  return (
    <BulletItemTableContainer>
      {props.bulletItems.map((item) => (
        <RowWrapper
          key={item.id}
          onClick={() => {
            if (props.handleRowClick) {
              props.handleRowClick(item.id);
            }
          }}
        >
          <BulletItemRow
            itemTitle={item.title}
            bulletAmount={item.cost}
            imageSrc={item.imageUrl}
          />
          <TableDivider />
        </RowWrapper>
      ))}
      {props.hasMore ? (
        <LoadMoreButton
          inActive={props.loading}
          disabled={props.loading}
          onClick={() => {
            if (props.handleLoadMore) {
              props.handleLoadMore();
            }
          }}
        >
          LOAD MORE
        </LoadMoreButton>
      ) : null}
    </BulletItemTableContainer>
  );
};

export default BulletItemTable;
