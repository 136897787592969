import React from "react";
import ReactDOM from "react-dom";

import { BrowserRouter as Router } from "react-router-dom";

import App from "./App";

import ReactGA from "react-ga";
import RouteChangeTracker from "./lib/google-analytics/page-view";

const TRACKING_ID = "UA-217221347-1";
ReactGA.initialize(TRACKING_ID);

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <App />
      <RouteChangeTracker />
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);
