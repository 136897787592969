const ShareIcon = ({ props, iconProps }: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={30}
      height={30}
      {...iconProps}
    >
      <path d="M23 3a4 4 0 0 0-4 4 4 4 0 0 0 .094.836l-9.082 4.541A4 4 0 0 0 7 11a4 4 0 0 0-4 4 4 4 0 0 0 4 4 4 4 0 0 0 3.014-1.375l9.076 4.54A4 4 0 0 0 19 23a4 4 0 0 0 4 4 4 4 0 0 0 4-4 4 4 0 0 0-4-4 4 4 0 0 0-3.014 1.375l-9.076-4.54A4 4 0 0 0 11 15a4 4 0 0 0-.094-.834l9.082-4.541A4 4 0 0 0 23 11a4 4 0 0 0 4-4 4 4 0 0 0-4-4z" />
    </svg>
  );
};

export default ShareIcon;
