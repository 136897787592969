import styled from "styled-components";
import { mobileMax } from "../lib/media-breakpoints";
import { GradientBorder } from "./gradient-border";

export const AdvertisementsPageTitle = styled.h1`
  font-size: 24px;

  @media (max-width: ${mobileMax}px) {
    font-size: 20px;
    margin-top: 40px;
  }
`;

export const AdvertisementTextField = styled.input`
  border: solid 1px #e5e5e5;
  border-radius: 2px;
  height: 20px;
`;

export const AdvertiementTextArea = styled.textarea`
  border: solid 1px #e5e5e5;
  border-radius: 2px;
  min-height: 200px;
`;

export const AdvertisementSubmitButton = styled.button<{
  isDisabled?: boolean;
}>`
  color: ${(props) => (props.isDisabled === true ? "grey" : "#516581")};

  flex: 0 0 30px;
  max-width: 200px;
  width: 100%;
  font-size: 14px;
  border: solid ${(props) => (props.isDisabled === true ? "grey" : "#516581")}
    2px;
  border-radius: 3px;
  background: none;
  margin-top: 18px;

  align-self: center;
  margin-bottom: 20px;
`;

export const AdvertisementsGradientBorder = styled(GradientBorder)`
  height: 3px;
  margin: 40px 0;

  @media (min-width: ${mobileMax + 1}px) {
    width: 100%;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${mobileMax + 1}px) {
    align-items: center;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${mobileMax + 1}px) {
    max-width: 600px;
    width: 100%;
    margin: 0 20px;
  }

  @media (max-width: ${mobileMax}px) {
    margin: 0 30px;
    margin-bottom: 60px;
  }
`;

export const CharacterCounterLabel = styled.p`
  font-size: 14px;
  color: grey;
`;
