import MenuSection from "../../../components/menu/components/menu-section";
import UserBox from "../../../components/user-box/user-box";
import useAuth from "../../../lib/custom-hooks/useAuth";
import { PrimaryButton, SecondaryButton } from "../../../styled/buttons";
import { GradientBorder } from "../../../styled/gradient-border";

import { MenusSection, UserBoxContainer } from "../styled/account-menu-styled";

export interface AccountMenuItemProps {
  username?: string;
  userType?: string;
}

const AccountMenuItems = (props: AccountMenuItemProps) => {
  const { authHandler } = useAuth();

  return (
    <MenusSection>
      <UserBoxContainer>
        <UserBox username={props.username} userType={props.userType ?? ""} />
        <GradientBorder style={{ marginTop: 40 }} />
      </UserBoxContainer>
      <MenuSection
        items={[
          {
            label: "Advertisements",
            destination: "/account/advertisements",
          },
          { label: "Edit Profile", destination: "/account/edit" },
          {
            label: "My Business Claims",
            destination: "/account/claims",
          },
          {
            label: "My Businesses",
            destination: "/account/businesses",
          },
        ]}
      />
      <div>
        <PrimaryButton
          style={{ height: 39, maxWidth: 150, marginLeft: 20, marginTop: 40 }}
          onClick={() => {
            if (authHandler) {
              authHandler.logout();
            }
          }}
        >
          Log Out
        </PrimaryButton>
      </div>
    </MenusSection>
  );
};

export default AccountMenuItems;
