import { FormikValues, WithFormikConfig } from "formik";
import {
  CompleteClaimFormValues,
  CompleteFormSchema,
} from "./components/form-body";

const CompleteClaimFormConfig: WithFormikConfig<
  { onSuccess: (values: FormikValues) => void },
  CompleteClaimFormValues
> = {
  handleSubmit: (values, { props }) => {
    props.onSuccess(values);
  },
  mapPropsToValues: () => ({
    claimCode: "",
  }),
  validateOnBlur: false,
  validateOnChange: false,
  validateOnMount: false,
  validationSchema: CompleteFormSchema,
};
export default CompleteClaimFormConfig;
