import styled from "styled-components";
import { mobileMax } from "../../../lib/media-breakpoints";
export const CategoriesSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
export const CategoriesSectionLabel = styled.p`
  color: #1b3963;
  font-weight: 700;
  font-size: 18px;
  margin-left: 20px;

  @media (max-width: ${mobileMax}px) {
    font-size: 16px;
    margin-left: 24px;
  }
`;

export const CategoriesScrollableContainer = styled.div`
  flex: 1;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  margin-left: 20px;
`;
