import { Field, FieldProps, FormikProps, ErrorMessage } from "formik";

import * as Yup from "yup";

import {
  FieldSection,
  StyledField,
  FieldLabel,
  ModifiedGradientBorder,
  FormContainer,
  SubmitButton,
  StyledErrorMessage,
} from "../../../styled/edit-profile-form-elements";

export interface EditProfileFormValues {
  firstName: string;
  lastName: string;
}

export const EditProfileFormSchema: Yup.SchemaOf<EditProfileFormValues> = Yup.object(
  {
    firstName: Yup.string().required("Please enter a valid first name."),
    lastName: Yup.string().required("Please enter a valid last name."),
  }
);

const EditProfileFormBody = (props: FormikProps<EditProfileFormValues>) => {
  const { handleSubmit, isValid, isSubmitting } = props;

  return (
    <form onSubmit={handleSubmit}>
      <FormContainer>
        <FieldSection>
          <FieldLabel>First Name</FieldLabel>
          <ErrorMessage
            name="firstName"
            render={(msg) => <StyledErrorMessage>{msg}</StyledErrorMessage>}
          />
          <Field name="firstName">
            {({ field }: FieldProps) => <StyledField type="input" {...field} />}
          </Field>
          <ModifiedGradientBorder />
        </FieldSection>
        <FieldSection>
          <FieldLabel>Last Name</FieldLabel>
          <ErrorMessage
            name="lastName"
            render={(msg) => <StyledErrorMessage>{msg}</StyledErrorMessage>}
          />
          <Field name="lastName">
            {({ field }: FieldProps) => <StyledField type="input" {...field} />}
          </Field>
          <ModifiedGradientBorder />
        </FieldSection>
        <SubmitButton
          type={"submit"}
          active={isValid && !isSubmitting}
          disabled={isSubmitting}
        >
          SAVE
        </SubmitButton>
      </FormContainer>
    </form>
  );
};

export default EditProfileFormBody;
