import { Navigate } from "react-router-dom";
import ConfirmBulletItemAddress from "../../components/confirm-bullet-item-address/confirm-bullet-item-address";
import { RedeemBulletItemFormValues } from "../../components/redeem-bullet-item-form/redeem-bullet-item-form";
import RedeemBulletItemFormWrapper from "../../components/redeem-bullet-item-form/redeem-bullet-item-form-wrapper";

interface StepHandlerProps {
  step: number;
  formState?: RedeemBulletItemFormValues;
  onFormSubmit: (values: RedeemBulletItemFormValues) => void;
  handleRedeem: () => void;
}

const StepHandler = (props: StepHandlerProps) => {
  if (props.step === 1) {
    return (
      <RedeemBulletItemFormWrapper
        onSubmit={(values: RedeemBulletItemFormValues) =>
          props.onFormSubmit(values)
        }
        initialValues={props.formState}
      />
    );
  }
  if (props.step === 2 && props.formState) {
    return (
      <ConfirmBulletItemAddress
        streetAddress={props.formState.streetAddress}
        streetAddressTwo={props.formState.streetAddressTwo}
        city={props.formState.city}
        state={props.formState.state}
        zip={props.formState.zip}
        country={props.formState.country}
        handleSubmit={() => props.handleRedeem()}
      />
    );
  }

  return <Navigate to="/menu/ammunition" />;
};

export default StepHandler;
