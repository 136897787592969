import { useInfiniteQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { LoadMoreButton } from "../../components/articles-table/styled/articles-table-styled";
import BackButton from "../../components/back-button/back-button";
import SponsorsTable from "../../components/sponsors-table/sponsors-table";
import { getSponsors } from "../../lib/api/banner-ad";

import { Loader } from "../../styled/loader";
import { Title } from "../../styled/typography";
import { BannerAdvertisement } from "../../ts/banner-advertisement";
import { Content, Wrapper, Text } from "./styled";

interface ViewSponsorsPageProps {}

const ViewSponsorsPage = (props: ViewSponsorsPageProps) => {
  const navigate = useNavigate();

  const { data, isLoading, isError, hasNextPage, fetchNextPage } =
    useInfiniteQuery(
      "/sponsors",
      ({ pageParam = 0 }) => getSponsors(pageParam),
      {
        getNextPageParam: (lastPage) => lastPage.nextPage,
      }
    );

  if (isLoading) {
    return (
      <Content>
        <BackButton handleBack={() => navigate("/menu")} />
        <Wrapper>
          <Loader />
        </Wrapper>
      </Content>
    );
  }

  if (isError || !data) {
    return (
      <Content>
        <BackButton handleBack={() => navigate("/menu")} />
        <Wrapper>
          <p>Could not get sponsors at this time.</p>
        </Wrapper>
      </Content>
    );
  }

  const ads: BannerAdvertisement[] = [];
  data.pages.forEach((page) => {
    ads.push(...page.sponsors);
  });

  return (
    <Content>
      <BackButton handleBack={() => navigate("/menu")} />
      <Wrapper>
        <Title>USA PACS Sponsors</Title>
        <Text>
          USAPACS is the app for citizens, police, and businesses to support
          each other and the 2nd Amendment. The sponsors here support you and
          your Second Amendment beliefs. They support law enforcement and the
          federal LEOSA bill to help keep police officers, their families, and
          all of us safe. USAPACS (USA Police and Citizen Safety) is a community
          of patriots working together to preserve our safety, the U.S.
          Constitution, and our way of life. We are all in it together. Please
          support our sponsors as they continue to support us.
        </Text>
        <SponsorsTable ads={ads} />
        {hasNextPage ? (
          <LoadMoreButton onClick={() => fetchNextPage()}>
            LOAD MORE
          </LoadMoreButton>
        ) : null}
      </Wrapper>
    </Content>
  );
};

export default ViewSponsorsPage;
