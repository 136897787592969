import React from "react";
import { Link } from "react-router-dom";
import ImageHandler from "../../image-handler/image-handler";
import {
  LogoSectionContainer,
  LogoImageContainer,
} from "../styled/logo-section-styled";

const LogoSection = () => {
  return (
    <LogoSectionContainer>
      <Link to="/">
        <ImageHandler name="logofull" />
      </Link>
    </LogoSectionContainer>
  );
};

export default LogoSection;
