import styled from "styled-components";
import { mobileMax } from "../../../lib/media-breakpoints";

export const PageContainer = styled.div`
  display: flex;
  height: 100%;

  flex-direction: column;
`;

export const Content = styled.div`
  margin: 0 30px 40px 30px;
`;

export const Header = styled.div`
  display: flex;
`;

export const SuggestPostButton = styled.button`
  border: none;
  background: none;
  color: grey;
  margin-left: auto;
  margin-top: 20px;
  margin-right: 20px;
  font-size: 16px;

  @media (max-width: ${mobileMax}px) {
    font-size: 14px;
  }
`;
