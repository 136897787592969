import axios from "../axios-instance";
import { BulletItem } from "../../../ts/bullet-item";
import { getFirebaseUserToken } from "../../firebase/firebase";
import { RedeemBulletItemFormValues } from "../../../components/redeem-bullet-item-form/redeem-bullet-item-form";

export interface GetAmmunitionOffersResponse {
  offers: BulletItem[];
  message?: string;
  hasMore: boolean;
  nextPage?: number;
}

export const getAmmunitionOffers = async (
  page: number
): Promise<GetAmmunitionOffersResponse> => {
  const token = await getFirebaseUserToken();

  const { data } = await axios.get<GetAmmunitionOffersResponse>(
    "/ammunition/offers?page=" + page,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (data.message) {
    throw Error(data.message);
  }
  if (!data.offers) {
    throw Error("Could not get offers at this time.");
  }

  return data;
};

interface AmmunitionOfferResponse {
  offer: BulletItem;
  message?: string;
}

export const getAmmunitionOffer = async (id: string): Promise<BulletItem> => {
  const { data } = await axios.get<AmmunitionOfferResponse>(
    `ammunition/offer?id=${id}`
  );

  if (data.message) {
    throw Error(data.message);
  }

  if (!data.offer) {
    throw Error("Could not get offer at this time.");
  }

  return data.offer;
};

interface RedeemResponse {
  offer: BulletItem;
  message?: string;
}
export const redeemOffer = async (
  offerId: string,
  values: RedeemBulletItemFormValues
): Promise<BulletItem> => {
  try {
    const token = await getFirebaseUserToken();
    const { data } = await axios.post<RedeemResponse>(
      `ammunition/offers/redeem`,
      {
        offerId,
        ...values,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (data.message) {
      throw Error(data.message);
    }

    return data.offer;
  } catch (e: any) {
    if (e.response?.data?.message) {
      throw Error(e.response.data.message);
    } else {
      throw Error("Could not redeem offer at this time.");
    }
  }
};
