import styled from "styled-components";
import { SubmitButton } from "./global-styled";

export const StepOneFormContainer = styled.div`
  display: flex;
  input[type="text"],
  input[type="password"],
  input[type="email"] {
  }
`;

export const RegisterButton = styled(SubmitButton)`
  background: ${(props) => (props.active ? "#6E7FA0" : "#8A98B3")};
`;
