import { withFormik } from "formik";
import { useState } from "react";
import { updateUserPassword } from "../../lib/firebase/firebase";

import UpdatePasswordForm, {
  UpdatePasswordFormSchema,
  UpdatePasswordFormValues,
} from "./update-password-form";

export interface UpdatePasswordFormContextProps {
  response?: string;
}

const UpdatePasswordFormContext = (props: UpdatePasswordFormContextProps) => {
  const [formResponse, setFormResponse] = useState<string | undefined>(
    undefined
  );

  const handleSubmit = async (values: UpdatePasswordFormValues) => {
    const response = await updateUserPassword(
      values.currentPassword,
      values.newPassword
    );

    if (typeof response === "string") {
      setFormResponse(response);

      return false;
    }

    if (response === false) {
      setFormResponse(
        "Could not update password at this time. Please try again later."
      );

      return false;
    }

    setFormResponse("Your password change has been saved.");

    return true;
  };

  const Form = withFormik<
    UpdatePasswordFormContextProps,
    UpdatePasswordFormValues
  >({
    handleSubmit: (values) => {
      handleSubmit(values);
    },
    mapPropsToValues: () => ({
      currentPassword: "",
      newPassword: "",
    }),
    validationSchema: UpdatePasswordFormSchema,
    validateOnBlur: false,
    validateOnChange: false,
    validateOnMount: false,
  })(UpdatePasswordForm);

  return <Form {...props} response={formResponse} />;
};

export default UpdatePasswordFormContext;
