import styled from "styled-components";

export const Content = styled.div`
  margin: 0 10px;
`;

export const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
  text-align: center;
`;

export const Subtitle = styled.div`
  color: #ababab;
  font-size: 14px;
  text-align: center;
  margin-bottom: 20px;
`;
