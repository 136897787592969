import styled from "styled-components";
import { mobileMax } from "../../../lib/media-breakpoints";

export const Content = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 60px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 1 400px;

  @media (max-width: ${mobileMax}px) {
    margin: 0 30px;
  }
`;

export const OfferTitle = styled.h1`
  font-size: 28px;
  font-weight: bold;
  margin-top: 40px;
`;

export const OfferDescription = styled.p`
  margin: 5px 0 30px 0;
`;

export const OfferRedeemMethod = styled.p`
  margin: 5px 0 0 0;
`;

export const OfferImage = styled.img`
  height: auto;
  max-height: 300px;
  width: 300px;
  align-self: center;
  margin-top: 60px;
`;

export const SectionTitle = styled.h3`
  margin: 0;
  font-size: 20px;
`;
