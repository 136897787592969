import styled from "styled-components";
import { applyGradient } from "./blue-gradient-background";
export const Loader = styled.div`
  align-self: center;
  margin-top: 20px;
  font-size: 10px;
  // margin: 17px 9px 0 30px;
  text-indent: -9999em;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #fafafa;

  position: relative;
  -webkit-animation: load3 1.4s infinite linear;
  animation: load3 1.4s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);

  ${applyGradient}

  :before {
    width: 50%;
    height: 50%;
    background: #fafafa;
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: "";
  }
  :after {
    background: #fafafa;
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: "";
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  @-webkit-keyframes load3 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load3 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;

export const GradientLoaderContainer = styled.div`
  width: 118px;
  height: 100px;
  align-self: center;
`;

export const GradientLoader = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.6);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */

  @keyframes ldio-p9mrpqpq3qt {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  div {
    left: 94px;
    top: 51px;
    position: absolute;
    animation: ldio-p9mrpqpq3qt linear 1.6949152542372883s infinite;
    background: #8c99b2;
    width: 12px;
    height: 18px;
    border-radius: 6px / 9px;
    transform-origin: 6px 49px;
  }
  div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.5536723163841808s;
    background: #8c99b2;
  }
  div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1.4124293785310735s;
    background: #8c99b2;
  }
  div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -1.271186440677966s;
    background: #8c99b2;
  }
  div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -1.1299435028248588s;
    background: #8c99b2;
  }
  div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.9887005649717514s;
    background: #8c99b2;
  }
  div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.847457627118644s;
    background: #8c99b2;
  }
  div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.7062146892655368s;
    background: #8c99b2;
  }
  div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.5649717514124294s;
    background: #8c99b2;
  }
  div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.423728813559322s;
    background: #8c99b2;
  }
  div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2824858757062147s;
    background: #8c99b2;
  }
  div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.14124293785310735s;
    background: #8c99b2;
  }
  div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
    background: #8c99b2;
  }

  div {
    box-sizing: content-box;
  }
`;
