import { withFormik } from "formik";
import SpecialOfferForm, {
  SpecialOfferFormProps,
  SpecialOfferFormSchema,
  SpecialOfferFormValues,
} from "./special-offer-form";

interface SpecialOfferFormWrapperProps {
  onSubmit: (values: SpecialOfferFormValues) => void;
}

const SpecialOfferFormWrapper = (props: SpecialOfferFormWrapperProps) => {
  const Form = withFormik<SpecialOfferFormProps, SpecialOfferFormValues>({
    handleSubmit: (values) => {
      props.onSubmit(values);
    },
    mapPropsToValues: () => ({
      description: "",
      title: "",
      redeemMethod: "",
      planUId: "",
      image: undefined,
      businessIds: [],
      salesCode: "",
    }),
    validateOnBlur: false,
    validateOnChange: false,
    validateOnMount: false,
    validationSchema: SpecialOfferFormSchema,
  })(SpecialOfferForm);

  return <Form />;
};

export default SpecialOfferFormWrapper;
