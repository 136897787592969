const CloseIcon = ({ props, iconProps }: any) => {
  return (
    <svg
      width={24}
      height={24}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...iconProps}
    >
      <title>{"glyph/circle close"}</title>
      <defs>
        <path
          d="M12 1c6.075 0 11 4.925 11 11s-4.925 11-11 11S1 18.075 1 12 5.925 1 12 1Zm0 2a9 9 0 1 0 0 18 9 9 0 0 0 0-18ZM8.293 8.293a1 1 0 0 1 1.414 0L12 10.585l2.293-2.292a1 1 0 0 1 1.32-.083l.094.083a1 1 0 0 1 0 1.414L13.415 12l2.292 2.293a1 1 0 0 1 .083 1.32l-.083.094a1 1 0 0 1-1.414 0L12 13.415l-2.293 2.292a1 1 0 0 1-1.32.083l-.094-.083a1 1 0 0 1 0-1.414L10.585 12 8.293 9.707a1 1 0 0 1-.083-1.32Z"
          id="a"
        />
      </defs>
      <use fill="#667FA2" fillRule="nonzero" xlinkHref="#a" />
    </svg>
  );
};

export default CloseIcon;
