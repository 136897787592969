import styled from "styled-components";
import { TransparentActionButton } from "../../styled/buttons";

import { InformationTitle } from "../../styled/typography";

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SectionTitle = styled(InformationTitle)`
  margin: 10px 0;
`;

export const AdImage = styled.div``;

export const Input = styled.input`
  height: 25px;
  border: solid black 1px;
  border-radius: 2px;
`;

export const SubmitButton = styled(TransparentActionButton)`
  margin-top: 10px;
`;
