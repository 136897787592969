import styled from "styled-components";
import { BANNER_HEIGHT, mobileMax } from "../../../lib/media-breakpoints";

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  margin-right: 20px;

  margin-bottom: ${BANNER_HEIGHT + 40}px;
`;

export const PostedBadgesButton = styled.button`
  margin-top: 40px;
  flex: 0 0 40px;
  background: #1a3963;
  color: white;
  font-size: 18px;
  border: none;
  margin-bottom: 20px;
`;

export const BusinessOffersSection = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  min-height: 300px;
`;

export const DirectionsButton = styled.button`
  flex: 0 0 45px;
  background: #277dee;
  color: white;
  font-size: 18px;
  border: none;
  margin-bottom: 20px;
`;

export const PostABadgeButton = styled.button`
  flex: 0 0 60px;
  font-size: 18px;
  background: #546680;
  color: white;
  border: none;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const InfoSection = styled.div`
  margin: 11px 0;
`;

export const InfoSectionText = styled.div`
  font-size: 14px;
  color: #1b3963;
`;

export const InfoSectionTitle = styled.div`
  color: #000000;
  font-size: 14px;
`;

export const PhotosSection = styled.div`
  height: 230px;
  width: 100%;

  overflow-x: auto;
  white-space: nowrap;

  @media (max-width: ${mobileMax}px) {
  }
`;

export const PhotoContainer = styled.div`
  margin: 0;
  height: 200px;
  width: 200px;
  display: inline-block;
  :not(:last-child) {
    margin-right: 20px;
  }
`;

export const BusinessPhotoDisplay = styled.img`
  height: 100%;
  width: 100%;
`;
