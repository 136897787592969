import { useEffect, useState } from "react";
import LeftArrowIcon from "../icon-handler/icons/left-arrow";

import RightArrowIcon from "../icon-handler/icons/right-arrow";
import {
  ArrowDisplay,
  ControlBubble,
  ControlsDisplay,
  ImageSliderContainer,
  ImageSliderDisplay,
  ImageSliderImage,
  Top,
} from "./styled";

interface ImageSliderProps {
  imageSources: string[];
  onClick: (index: number) => void;
}

const SECONDS_ROTATE = 20;

const ImageSlider = (props: ImageSliderProps) => {
  const [currentSlide, setSlide] = useState<number>(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setSlide((current) => {
        return current < props.imageSources.length - 1 ? current + 1 : 0;
      });
    }, 1000 * SECONDS_ROTATE);

    return () => clearInterval(interval);
  }, [currentSlide, setSlide, props.imageSources.length]);

  return (
    <ImageSliderContainer>
      <Top>
        {props.imageSources.length > 1 ? (
          <ArrowDisplay
            style={{ marginRight: 5 }}
            onClick={() =>
              setSlide((current) => {
                return current > 0
                  ? current - 1
                  : props.imageSources.length - 1;
              })
            }
          >
            <LeftArrowIcon
              iconProps={{ width: 35, height: 35, fill: "#546680" }}
            />
          </ArrowDisplay>
        ) : null}
        <ImageSliderDisplay>
          <ImageSliderImage
            src={props.imageSources[currentSlide]}
            onClick={() => props.onClick(currentSlide)}
          />
        </ImageSliderDisplay>
        {props.imageSources.length > 1 ? (
          <ArrowDisplay
            style={{ marginLeft: 5 }}
            onClick={() =>
              setSlide((current) => {
                return current < props.imageSources.length - 1
                  ? current + 1
                  : 0;
              })
            }
          >
            <RightArrowIcon
              iconProps={{ width: 35, height: 35, fill: "#546680" }}
            />
          </ArrowDisplay>
        ) : null}
      </Top>
      {props.imageSources.length > 1 ? (
        <ControlsDisplay>
          {props.imageSources.map((a, index) => (
            <ControlBubble active={currentSlide === index} />
          ))}
        </ControlsDisplay>
      ) : null}
    </ImageSliderContainer>
  );
};

export default ImageSlider;
