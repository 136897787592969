import styled from "styled-components";
export const UserTypeContainer = styled.div`
  margin-left: 30px;
`;
export const UsernameLabel = styled.p`
  color: #8c8c8c;
  font-size: 18px;
  margin: 10px 0 0 0;
`;
export const UserAccountLabel = styled.p`
  font-size: 14px;
  color: #8c8c8c;
  margin: 10px 0 0 0;
`;
