import React from "react";
import { MenuSectionContainer } from "../styled/menu-section-styled";
import { MenuItem } from "../ts/menu";
import MenuItemRow from "./menu-item";
import MenuTitle from "./menu-title";

export interface MenuSectionProps {
  title?: string;
  items: MenuItem[];
}

const MenuSection = (props: MenuSectionProps) => {
  return (
    <MenuSectionContainer>
      {props.title ? <MenuTitle label={props.title} /> : null}
      {props.items.map((item, index) => (
        <MenuItemRow
          key={index}
          item={item}
          index={index}
          isLastItem={index === props.items.length - 1}
        />
      ))}
    </MenuSectionContainer>
  );
};

export default MenuSection;
