import React from "react";
import { NavBarContainer } from "../styled/navbar-styled";
import IconsSection from "./icons-section";
import LogoSection from "./logo-section";

const Navbar = () => {
  return (
    <NavBarContainer>
      <LogoSection />
      <IconsSection />
    </NavBarContainer>
  );
};

export default Navbar;
