import React from "react";
import BadgeDisplay from "./components/badge-display";
import {
  BadgeIconContainer,
  BadgesDisplayContainer,
} from "./styled/badges-display-styled";

export interface BadgesDisplayProps {
  badgeAlignments: {
    [alignments: string]: number;
  };
}

export enum BadgeType {
  leosaPolicy = "leosaPolicy",
}

const badgeTypes = [
  "leosaPolicy",
  "concealedCarryPolicy",
  "openCarryPolicy",
  "menuPolicy",
  "metalDetectorPolicy",
];

const BadgesDisplay = (props: BadgesDisplayProps) => {
  return (
    <BadgesDisplayContainer>
      {badgeTypes.map((badgeType, index) => (
        <BadgeIconContainer key={index}>
          <BadgeDisplay
            policy={badgeType}
            alignment={props.badgeAlignments[badgeType]}
          />
        </BadgeIconContainer>
      ))}
    </BadgesDisplayContainer>
  );
};

export default BadgesDisplay;
