import LeosaBadge from "../../common/LeosaBadge";
import {
  ArticleCategoryCardContainer,
  CardImage,
  CardWrap,
  Content,
  Label,
} from "./styled/article-category-card-styled";

interface ArticleCategoryCardProps {
  imageUrl?: string;
  isLeosa: boolean;
  label: string;
}

const ArticleCategoryCard = (props: ArticleCategoryCardProps) => {
  return (
    <ArticleCategoryCardContainer>
      <CardWrap>
        <Content>
          {props.imageUrl ? <CardImage src={props.imageUrl} alt="" /> : null}
          {props.isLeosa ? <LeosaBadge /> : null}
        </Content>
      </CardWrap>
      <Label>{props.label}</Label>
    </ArticleCategoryCardContainer>
  );
};

export default ArticleCategoryCard;
