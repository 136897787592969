const UserIcon = ({ props, iconProps }: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={48}
      height={48}
      {...iconProps}
    >
      <image
        data-name="user@2x"
        width={48}
        height={48}
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAMKADAAQAAAABAAAAMAAAAADbN2wMAAADAUlEQVRoBe1ZvW7UQBCecQ4pReg5WipCm4KaJwgUVAcNKInog+hYOkR6lPDTwFUUkCfgCa4lVOkijgdIgQTyMuPEvvHGa69nfTIoXuni2d35+2a9u+MJwNCGCFzuCGCX8LcOZlfg59EmWNgkvRsIeJ31W7A/6DEDhEO4tn74ZnvjN4930ToDsPNieje16R55e6PWMYTjBJPd/eeTL7V8gZPRAIyxyRymL621u4E2MzZE3BvD5JkxmLaRc3lH7kDbvsZ5tsGA5zhl8in/0bZEK8hy/NpciDyiRYS3sAK3R7h6lX9MZ2M0J+2xLOuQY21p9SuUbdj50ffSO484T8BO9s3Dr1WO7JgPd1KgsFs7LuZpT8B4/aZ2Y+tX4Oy0WWxYim6d8+wwA2MekCvBm551KZsewNlRWZhFsO98kS+YiGAe5pVj58duaSi0owdA57w0QuF/L/t1dAVvSVedrDunBpBfUrnCUbr6Laebni6vq6tJXs6rAUglfdJqAOfpQeH7n+TXraLTQLi8rq4G8dK0GgBpmUlNdJ8+kv06uoK3pKtO1p3TA+DETDQL+JjPeTFUSTIP85YmHV2luYaOHgBllZRdHhf6raXA4rQOhLjIFhco62BdyrZQpFCQZaBp+rkkyqkEnfN8VOanDb/z/NpkkSegkj9JknsxmWlJmVQcSm+bj68u5EOBwpyRHpgH/SVz7CenxOxIoM8FW55OFwNKInoFcrv/7QdNDoCffXxSSvsDPUTgMkag1SlUtUnpDliLCRwdp6cxdaNgAMHHZAwalm1ZN2oEoK37ROMIrBs11oW0dZ9YAJyehNSNalcgJFl7be6fxjj7xHxai0n2vAA0dZ8YICwr0u3gupH/e0BR94kFoKkb+QEo6z5dgGhTN/IDiKj7xIJoUzfyAnBrNfnXVaxzIfKuLdcXqcMLwL1hY08babSJdm25vkh5LwDJ9C/TA4C+V8e/AognhXOSLgaXTEibknbMegGsIG7RPyJO+JfRjuCyu33bXza+Qf8Qga4i8Be53F3HQJnNjgAAAABJRU5ErkJggg=="
      />
    </svg>
  );
};

export default UserIcon;
