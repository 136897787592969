import styled from "styled-components";

export const Container = styled.div``;

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: solid #bcc2cf 1px;
  min-height: 273px;
  background: white;
`;

export const Row = styled.div<{ selected: boolean }>`
  flex: 0 0 90px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  cursor: pointer;

  background: ${(props) => (props.selected ? "#f3f3f3" : "white")};

  :not(:last-child) {
    border-bottom: solid 1px #eeefef;
  }
`;

export const PlanName = styled.div<{ selected: boolean }>`
  font-size: 14px;
  margin-top: 10px;
  font-weight: ${(props) => (props.selected ? "bold" : "normal")};
`;

export const PlanCost = styled.div`
  color: #909090;
  font-size: 13px;
  margin-top: 5px;
  margin-bottom: 10px;
`;

export const RowContent = styled.div`
  margin: 0 20px;
`;
