import { ErrorMessage, Field, FormikProps } from "formik";
import {
  FieldsWrapper,
  FormContainer,
  FormLabel,
  SubmitButton,
} from "../styled/global-styled";

import * as Yup from "yup";
import { RegisterButton } from "../styled/step-one-form-styled";
import { checkEmailExists } from "../../../lib/firebase/firebase";

export interface StepOneFormValues {
  email: string;
  password: string;
}

export const stepOneSchema: Yup.SchemaOf<StepOneFormValues> = Yup.object({
  email: Yup.string()
    .email("Please enter a valid email")
    .required("Please enter a email")
    .test("emailExists", "Email already exists.", async (value) => {
      if (!value && value !== "") {
        return false;
      }

      try {
        const emailExists = await checkEmailExists(value);

        return !emailExists;
      } catch (e) {
        return false;
      }
    }),
  password: Yup.string()
    .required("Please enter a password")
    .matches(/(?=.*[0-9a-zA-Z]).{6,24}/, {
      message:
        "Password must be between 6-24 characters. Letters, numbers, and symbols only.",
      excludeEmptyString: true,
    }),
});

/*Use this when determining if form can be submitted to avoid firebase requests*/
const stepOneActiveSchema: Yup.SchemaOf<StepOneFormValues> = Yup.object({
  email: Yup.string()
    .email("Please enter a valid email")
    .required("Please enter a email"),
  password: Yup.string().required("Please enter a password"),
});

const StepOneForm = (props: FormikProps<StepOneFormValues>) => {
  const {
    handleSubmit,
    setFieldTouched,
    handleChange,
    values,
    isSubmitting,
  } = props;

  let canSubmit = false;
  try {
    stepOneActiveSchema.validateSync(values);
    canSubmit = true;
  } catch (e) {
    canSubmit = false;
  }

  return (
    <form onSubmit={handleSubmit}>
      <FormContainer>
        <FieldsWrapper>
          <FormLabel htmlFor="email">Email Address</FormLabel>
          <ErrorMessage
            name="email"
            component="span"
            className="error-message"
          />
          <Field
            type="text"
            name="email"
            onChange={(e: any) => {
              setFieldTouched("email", true);
              handleChange(e);
            }}
          />
        </FieldsWrapper>
        <FieldsWrapper>
          <FormLabel htmlFor="email">Password</FormLabel>
          <ErrorMessage
            name="password"
            component="span"
            className="error-message"
          />
          <Field
            type="password"
            name="password"
            onChange={(e: any) => {
              setFieldTouched("password", true);
              handleChange(e);
            }}
          />
        </FieldsWrapper>
        <RegisterButton
          disabled={isSubmitting}
          active={canSubmit && !isSubmitting}
        >
          SIGN UP
        </RegisterButton>
      </FormContainer>
    </form>
  );
};

export default StepOneForm;
