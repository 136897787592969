import React, { useState } from "react";
import Register from "../../components/register/register";
import { RegisterState } from "../../components/register/ts/register-state";
import { RegisterPageContainer } from "./styled/register-styled";

const RegisterPage = () => {
  const [state, setState] = useState<RegisterState>({
    step: 1,
  });

  return (
    <RegisterPageContainer>
      <Register state={state} setState={setState} />
    </RegisterPageContainer>
  );
};

export default RegisterPage;
