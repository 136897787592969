import { useInfiniteQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import ArticlesTable from "../../components/articles-table/articles-table";
import BackButton from "../../components/back-button/back-button";
import { getArticles, getBookmarks } from "../../lib/api/article";
import { Loader } from "../../styled/loader";

import { Article } from "../../ts/article";
import { Content } from "../BulletRedeem/styled";
import { PageContainer } from "../ViewArticles/styled/view-articles-page-styled";

interface BookmarksPageProps {}

const BookmarksPage = (props: BookmarksPageProps) => {
  const navigate = useNavigate();

  const {
    data,
    isLoading,
    isFetching,
    isError,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery(
    ["/me/bookmarks"],
    ({ pageParam = 0 }) => getBookmarks(pageParam),
    {
      getNextPageParam: (lastPage) => lastPage.nextPage,
      staleTime: Infinity,
    }
  );

  const handleLoadMoreClick = () => {
    fetchNextPage();
  };

  const pageBack = () => {
    navigate("/menu");
  };

  const rows: Article[] = [];

  data?.pages.forEach((page) =>
    // page.businesses.forEach((poi) => rows.push(poi))
    rows.push(...page.articles)
  );

  if (isLoading) {
    return (
      <PageContainer style={{ alignItems: "center", justifyContent: "center" }}>
        <Loader />
      </PageContainer>
    );
  }

  if (isError) {
    return null;
  }

  return (
    <PageContainer>
      <BackButton handleBack={() => pageBack()} />
      <Content>
        <ArticlesTable
          onRowClick={(id: number, type: string, externalLink?: string) => {
            if (type === "internal") {
              navigate(`/menu/article/bookmark/${id}`);
            } else if (type === "external" && externalLink) {
              window.open(externalLink);
            }
          }}
          loading={isLoading || isFetching}
          articles={rows}
          hasMore={hasNextPage ?? false}
          onLoadMoreClick={handleLoadMoreClick}
        />
      </Content>
    </PageContainer>
  );
};

export default BookmarksPage;
