import { BusinessPhoto } from "../../../ts/business-photo";
import { getFirebaseUserToken } from "../../firebase/firebase";

import axios from "../axios-instance";
import { ClaimError } from "../errors/claim-error";

interface SetPrimaryPhotoResponse {
  businessPhoto: BusinessPhoto;
  message?: string;
}

export const setPrimaryPhoto = async (
  pointOfInterestId: number,
  businessPhotoId: number
): Promise<BusinessPhoto> => {
  const token = await getFirebaseUserToken();

  const { data } = await axios.post<SetPrimaryPhotoResponse>(
    "/poi/business-photo/primary",
    {
      businessPhotoId,
      pointOfInterestId,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (data.message) {
    throw data.message;
  }

  return data.businessPhoto;
};

interface DeletePhotoResponse {
  businessPhoto: BusinessPhoto;
  message?: string;
}

export const deletePhoto = async (
  pointOfInterestId: number,
  businessPhotoId: number
): Promise<BusinessPhoto> => {
  const token = await getFirebaseUserToken();
  const { data } = await axios.post<DeletePhotoResponse>(
    "/poi/business-photo/delete",
    {
      pointOfInterestId,
      businessPhotoId,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (data.message) {
    throw data.message;
  }

  return data.businessPhoto;
};

interface UploadBusinessPhotoResponse {
  data: { businessPhoto: BusinessPhoto; message?: string };
}

interface UploadBusinessPhotoInput {
  image: File;
  pointOfInterestId: string;
  placement: string;
}

export const uploadBusinessPhoto = async (
  image: File,
  pointOfInterestId: number,
  placement: number
): Promise<BusinessPhoto> => {
  const token = await getFirebaseUserToken();

  const formData = new FormData();

  formData.append("image", image);
  formData.append("pointOfInterestId", String(pointOfInterestId));
  formData.append("orderPlacement", String(placement));

  const { data } = await axios.post<
    UploadBusinessPhotoInput,
    UploadBusinessPhotoResponse
  >("/poi/business-photo", formData, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (data.message) {
    throw data.message;
  }

  return data.businessPhoto;
};
