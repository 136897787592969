import styled from "styled-components";
import { mobileMax } from "../../../lib/media-breakpoints";

import { applyBoxShadow } from "../../../styled/box-shadow";

export const CardRowContainer = styled.div`
  ${applyBoxShadow()}

  cursor: pointer;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  margin: 20px 0;

  @media (min-width: ${mobileMax + 1}px) {
  }

  @media (max-width: ${mobileMax}px) {
  }
`;

export const TitleLabel = styled.p`
  color: #244169;
  font-size: 16px;
  margin: 24px 30px 5px 30px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: ${mobileMax}px) {
  }
`;

export const SubtitleLabel = styled.p`
  color: #000000;
  font-size: 16px;
  margin: 5px 30px 24px 30px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
