import React, { useState } from "react";
import { PostablePolicies } from "../../ts/policies";
import { SubmitButton } from "../register/styled/global-styled";
import PostBadgeTableRow from "./components/post-badge-table-row";

import useAuth from "../../lib/custom-hooks/useAuth";

import ReactGA from "react-ga";

import {
  PostBadgeContainer,
  TitleLabel,
  BadgesTable,
  PostTableBadgeRowWrapper,
} from "./styled/post-badge-styled";
import { GradientBorder } from "../../styled/gradient-border";
import { useQueryClient } from "react-query";
import { useContext } from "react";
import { QueryValueContext } from "../../pages/Dashboard/util/contexts";
import { PointOfInterest } from "../../ts/point-of-interest";

export interface PostBadgeProps {
  placeId: string;
  onSubmit: (policy: string, alignment: number) => Promise<void>;
  isLoading: boolean;
}

export interface PostBadgeState {
  activePolicy?: string;
  alignment: number;
}

const PostBadge = (props: PostBadgeProps) => {
  const { authHandler } = useAuth();

  const [state, setState] = useState<PostBadgeState>({
    activePolicy: undefined,
    alignment: 0,
  });

  const handleChange = (policy: string) => {
    let newAlignment = 1;

    if (state.activePolicy === policy) {
      newAlignment = state.alignment + 1;

      if (
        (policy === "leosaPolicy" ||
          policy === "concealedCarryPolicy" ||
          policy === "openCarryPolicy") &&
        newAlignment > 2
      ) {
        newAlignment = 0;
      }

      if (policy === "metalDetectorPolicy" && newAlignment > 1) {
        newAlignment = 0;
      }
    }

    setState({
      activePolicy: policy,
      alignment: newAlignment,
    });
  };

  const handleSubmit = () => {
    try {
      if (!state.activePolicy) {
        alert("Please choose a badge to post.");
        return;
      }

      ReactGA.event({
        category: "user_action",
        action: "post_badge",
        label: state.activePolicy,
      });

      props.onSubmit(state.activePolicy, state.alignment);
    } catch (e) {}
  };

  const canSubmit = state.alignment !== 0 && state.activePolicy !== undefined;

  let postableBadges = PostablePolicies.slice();

  if (authHandler?.isLeosa() !== true) {
    postableBadges.splice(0, 1);
  }

  return (
    <PostBadgeContainer>
      <TitleLabel>Select a Badge to Post</TitleLabel>
      <BadgesTable>
        {postableBadges.map((policy, index) => (
          <PostTableBadgeRowWrapper
            key={index}
            style={index % 2 === 0 ? { background: "#F9F9F9" } : {}}
          >
            <PostBadgeTableRow
              handleChange={handleChange}
              {...props}
              activeAlignment={
                state.activePolicy === policy ? state.alignment : 0
              }
              policy={policy}
            />
            <GradientBorder style={{ margin: "auto 20px 0 20px" }} />
          </PostTableBadgeRowWrapper>
        ))}
      </BadgesTable>
      <SubmitButton
        onClick={() => handleSubmit()}
        style={{
          flex: "0 0 48px",
          alignSelf: "center",
          background: canSubmit === true && !props.isLoading ? "#2E406E" : "",
        }}
        active={canSubmit === true && !props.isLoading}
        disabled={canSubmit !== true || props.isLoading}
      >
        SUBMIT BADGE
      </SubmitButton>
    </PostBadgeContainer>
  );
};

export default PostBadge;
