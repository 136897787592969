import React from "react";
import { Loader } from "../../styled/loader";
import {
  UserTypeContainer,
  UsernameLabel,
  UserAccountLabel,
} from "./styled/user-box-styled";

export interface UserBoxProps {
  username?: string;
  userType: string;
}

const UserBox = ({ username, userType }: UserBoxProps) => {
  if (!username || !userType) {
    return <Loader />;
  }

  return (
    <UserTypeContainer>
      <UsernameLabel>{username}</UsernameLabel>
      <UserAccountLabel>{userType}</UserAccountLabel>
    </UserTypeContainer>
  );
};

export default UserBox;
