import { ErrorMessage, Field, FieldProps, FormikProps } from "formik";
import * as Yup from "yup";
import { InputButton, SubmitButton } from "../register/styled/global-styled";
import {
  BorderWrap,
  Content,
  ContentTextArea,
  QuestionText,
  StyledTitle,
} from "./styled";

export interface SuggestPostFormProps {}

export interface SuggestPostFormValues {
  content: string;
  wantsToBeCredited: boolean;
}

export const SuggestPostFormSchema: Yup.SchemaOf<SuggestPostFormValues> = Yup.object(
  {
    content: Yup.string().required("Please enter your suggestion."),
    wantsToBeCredited: Yup.boolean().required(),
  }
);

const SuggestPostForm = (
  props: FormikProps<SuggestPostFormValues> & SuggestPostFormProps
) => {
  const { handleSubmit, values, setFieldValue, isSubmitting } = props;

  return (
    <form onSubmit={handleSubmit}>
      <Content>
        <StyledTitle>What would you like to suggest?</StyledTitle>

        <ErrorMessage name="content" />
        <BorderWrap>
          <Field name="content">
            {({ field }: FieldProps) => (
              <ContentTextArea
                placeholder="Include any events or article links you'd like to see here"
                {...field}
              ></ContentTextArea>
            )}
          </Field>
        </BorderWrap>
        <QuestionText>Do you want to be credited?</QuestionText>
        <div
          style={{
            display: "flex",
            alignSelf: "center",
            justifyContent: "space-evenly",
            width: 200,
            marginTop: 20,
          }}
        >
          <InputButton
            onClick={() => {
              setFieldValue("wantsToBeCredited", true);
            }}
            active={values.wantsToBeCredited === true}
          >
            YES
          </InputButton>
          <InputButton
            onClick={() => {
              setFieldValue("wantsToBeCredited", false);
            }}
            active={values.wantsToBeCredited === false}
          >
            NO
          </InputButton>
        </div>

        <SubmitButton style={{ alignSelf: "center" }} active={!isSubmitting}>
          SUBMIT
        </SubmitButton>
      </Content>
    </form>
  );
};

export default SuggestPostForm;
