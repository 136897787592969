import { useQueryClient, useMutation } from "react-query";
import { resumeSpecialOffer } from "../../../lib/api/special-offer";
import { SpecialOffer } from "../../../ts/special-offer";

export const useResumeMutation = (uid?: string) => {
  const client = useQueryClient();

  const mutation = useMutation(
    ["/business-offer/resume", uid],
    (values: { id: number }) => resumeSpecialOffer(values.id),
    {
      onSuccess: (data) => {
        client.setQueryData<SpecialOffer | undefined>(
          ["/business-offer", uid],
          (old) => {
            if (old) {
              old.status = data.status;
            }

            return old;
          }
        );
      },
    }
  );

  return mutation;
};
