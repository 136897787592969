import { SpecialOffer } from "../../../ts/special-offer";

import {
  Content,
  OfferDescription,
  OfferImage,
  OfferRedeemMethod,
  OfferTitle,
  SectionTitle,
  Wrapper,
} from "../styled/view-offer-styled";

interface ViewOfferProps {
  offer: SpecialOffer;
}

const ViewOffer = (props: ViewOfferProps) => {
  return (
    <Content>
      <Wrapper>
        <OfferImage
          src={`${props.offer.imageUrl}?id=${props.offer.updatedAt ?? "1"}`}
          alt="offer"
        />
        <OfferTitle>{props.offer.title}</OfferTitle>
        <SectionTitle>Offer Description</SectionTitle>
        <OfferDescription>{props.offer.description}</OfferDescription>
        {props.offer.redeemMethod && props.offer.redeemMethod !== "" ? (
          <div>
            <SectionTitle>How to Redeem this Offer</SectionTitle>
            <OfferRedeemMethod>{props.offer.redeemMethod}</OfferRedeemMethod>
          </div>
        ) : null}
      </Wrapper>
    </Content>
  );
};

export default ViewOffer;
