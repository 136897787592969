export const formatPolicy = (policyString: string) => {
  if (policyString === "leosaPolicy") {
    return "LEOSA";
  }

  if (policyString === "openCarryPolicy") {
    return "Open Carry";
  }

  if (policyString === "concealedCarryPolicy") {
    return "Concealed Carry";
  }

  if (policyString === "metalDetectorPolicy") {
    return "Metal Detector";
  }

  if (policyString === "menuPolicy") {
    return "51% Alcohol";
  }

  return "";
};
