import styled from "styled-components";
import { TransparentActionButton } from "../../styled/buttons";
import { InformationTitle } from "../../styled/typography";

export const SectionTitle = styled(InformationTitle)`
  margin: 10px 0;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const AdImage = styled.img`
  max-width: 400px;
  width: 100%;
`;

export const SubmitButton = styled(TransparentActionButton)`
  margin-top: 10px;
`;
