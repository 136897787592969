import styled from "styled-components";
import { mobileMax } from "../../../lib/media-breakpoints";
export const ReferralPageContainer = styled.div``;

export const ReferralSectionWrapper = styled.div`
  margin-top: 50px;
  margin-left: 75px;
  margin-right: 75px;

  @media (max-width: ${mobileMax}px) {
    margin-top: 40px;
    margin-left: 30px;
    margin-right: 30px;
  }
`;
