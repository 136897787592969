import styled from "styled-components";
import { mobileMax } from "../../../lib/media-breakpoints";
import { GradientBorder } from "../../../styled/gradient-border";

export const ResultsTableContainer = styled.div``;

export const ModifiedGradientBorder = styled(GradientBorder)`
  @media (max-width: ${mobileMax}px) {
    height: 2px;
    margin: 0 10px 0 10px;
  }

  @media (min-width: ${mobileMax + 1}px) {
    height: 3px;
    margin: 0 20px 0 20px;
  }
`;
