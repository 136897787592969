import { FirebaseError } from "firebase/app";
import { applyActionCode, AuthErrorCodes } from "firebase/auth";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import EmailConfirmationDisplay from "../../components/email-confirmation/components/email-confirmation-display";
import ImageHandler from "../../components/image-handler/image-handler";
import { LogoContainer } from "../../components/register/styled/register-styled";
import { getFirebaseAuth } from "../../lib/firebase/firebase";
import { Loader } from "../../styled/loader";

interface RecoverEmailProps {
  oobCode: string;
}

const RecoverEmail = (props: RecoverEmailProps) => {
  const [verified, setVerified] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  const recoverEmail = async () => {
    try {
      const auth = getFirebaseAuth();

      await applyActionCode(auth, props.oobCode);

      setVerified(true);
    } catch (e) {
      if (e instanceof FirebaseError) {
        if (e.code === AuthErrorCodes.INVALID_OOB_CODE) {
          setError("Verification Link has expired. Please initiate another.");
          return;
        } else {
          setError("Could not recover email at this time.");
          return;
        }
      } else {
        setError("Could not recover email at this time.");
      }
    }
  };

  useEffect(() => {
    recoverEmail();
  }, []);

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <LogoContainer style={{ marginBottom: 20 }}>
        <div style={{ marginTop: "auto" }} onClick={() => navigate("/")}>
          <ImageHandler name="logo" />
        </div>
      </LogoContainer>
      {error === null ? (
        verified ? (
          <p>Your email has been recovered. Please sign-in again.</p>
        ) : (
          <Loader style={{ margin: 0 }} />
        )
      ) : null}
      {error !== null ? (
        <p style={{ color: "red", textAlign: "center" }}>{error}</p>
      ) : null}
    </div>
  );
};

export default RecoverEmail;
