import styled from "styled-components";
import { mobileMax } from "../../../lib/media-breakpoints";

export const BannerAdsDisplayWrapper = styled.div`
  position: absolute;
  background: #ffffffcc;
  bottom: 0;
  width: 100%;
  font-size: 22px;
  color: white;
  font-weight: bold;
  z-index: 3000;
  height: 100px;

  @media (max-width: ${mobileMax}px) {
    display: flex;
    justify-content: center;
  }

  // @media (min-width: ${mobileMax + 1}px) and (max-width: 1150px) {
  display: flex;
  justify-content: center;
  //}
`;

export const ContentContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  height: 100%;

  // @media (max-width: 550px) {
  //   max-width: 420px;
  // }

  // @media (min-width: ${mobileMax + 1}px) and (max-width: 1150px) {
  //   max-width: 420px;
  // }
  max-width: 420px;
`;
