import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { mobileMax } from "../../../lib/media-breakpoints";

import { BannerAdvertisement } from "../../../ts/banner-advertisement";

interface ImageDisplayProps {
  ads: BannerAdvertisement[];
}

const SECONDS_ROTATE = 30;

const ImageDisplay = (props: ImageDisplayProps) => {
  const [currentImage, setCurrentImage] = useState<number>(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevImage) =>
        prevImage < props.ads.length - 1 ? prevImage + 1 : 0
      );
    }, 1000 * SECONDS_ROTATE);

    return () => clearInterval(interval);
  }, [props.ads]);

  if (
    props.ads.length < 1 ||
    !props.ads[currentImage] ||
    !props.ads[currentImage].websiteLink
  ) {
    return null;
  }

  return (
    <BannerImage
      // src={props.ads[currentImage].url}
      src={`${props.ads[currentImage].url}?id=${
        props.ads[currentImage].updatedAt ?? "1"
      }`}
      clickURL={props.ads[currentImage].websiteLink}
    />
  );
};

export interface BannerImageProps {
  src: string;
  clickURL: string;
}

const BannerImage = (props: BannerImageProps) => {
  let isSmall = useMediaQuery({
    query: `(min-width: ${mobileMax + 1}px) and (max-width: 1150px)`,
  });
  let isMobile = useMediaQuery({ query: `(max-width: 550px)` });

  const handleClick = () => {
    if (
      window.confirm("You are leaving usapacs.app. Do you want to continue?")
    ) {
      window.location.href = props.clickURL;
    }
  };

  // isSmall = false;

  return isMobile || isSmall ? (
    <img
      src={`${props.src}`}
      height={"100"}
      width={"100%"}
      style={{ maxHeight: "100%" }}
      alt="banner-ad"
      onClick={handleClick}
    />
  ) : (
    <img
      src={props.src}
      height={"100"}
      width={"100%"}
      style={{ maxHeight: "100%" }}
      alt="banner-ad"
      onClick={handleClick}
    />
  );
};

export default ImageDisplay;
