import { Wrapper } from "@googlemaps/react-wrapper";
import { DetermineMarkerColor } from "../../../../lib/map-helpers/determine-marker-color";
import { Marker } from "./Marker";

import Map from "./Map";

import { useNavigate } from "react-router-dom";
import { PointOfInterest } from "../../../../ts/point-of-interest";
import useGeoLocation from "../../../../lib/custom-hooks/useLocation";
import { useContext } from "react";

import { useEffect } from "react";
import { QueryValueContext, MapContext } from "../../util/contexts";
import useAuth from "../../../../lib/custom-hooks/useAuth";
import DEFAULT_LOCATION from "../../util/default-location";
import UserLocationButton from "./UserLocationButton";
import { UserLocationMarker } from "./UserLocationMarker";
import { MAPS_KEY } from "../../../../lib/map-helpers/key";

export interface MarkerData {
  placeId: string;
  lat: number;
  lng: number;
  pointOfInterest: PointOfInterest;
}

export interface MapViewProps {
  setActiveMarker: (placeId: string) => void;
  activeMarkerPlaceId?: string;
  markerData: MarkerData[];
  shouldPerformInitialQuery: boolean;
}

const MapView = (props: MapViewProps) => {
  const { isAuth } = useAuth();
  const { setValue } = useContext(QueryValueContext);
  const { map } = useContext(MapContext);
  const { location, permission } = useGeoLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location && map && setValue) {
      if (props.shouldPerformInitialQuery === true && isAuth) {
        map.setOptions({
          center: {
            lat: location.coords.latitude,
            lng: location.coords.longitude,
          },
          zoom: 13,
        });

        setValue("Restaurants", "");
      }
    }
  }, [location, map, setValue, props.shouldPerformInitialQuery, isAuth]);

  return (
    <Wrapper apiKey={`${MAPS_KEY}`} libraries={["places"]}>
      <UserLocationButton />
      <Map
        center={
          location
            ? {
                lat: location.coords.latitude,
                lng: location.coords.longitude,
              }
            : DEFAULT_LOCATION
        }
        zoom={location ? 13 : 5}
      >
        {location ? (
          <UserLocationMarker
            position={{
              lat: location.coords.latitude,
              lng: location.coords.longitude,
            }}
          />
        ) : null}
        {props.markerData.map((result) => {
          const markerColor = DetermineMarkerColor(result.pointOfInterest);

          return (
            <Marker
              active={props.activeMarkerPlaceId === result.placeId}
              scale={props.activeMarkerPlaceId === result.placeId ? 3 : 2}
              color={markerColor}
              placeId={result.placeId}
              onClick={() => {
                props.setActiveMarker(result.placeId);
                navigate(`/home/business/${result.placeId}`);
              }}
              clickable={true}
              key={result.placeId}
              position={{
                lat: result.lat + (false ? 0.003 : 0),
                lng: result.lng - (false ? 0.001 : 0),
              }}
            />
          );
        })}
      </Map>
    </Wrapper>
  );
};

export default MapView;
