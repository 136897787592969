import { withFormik } from "formik";
import EditProfileFormBody, { EditProfileFormSchema, EditProfileFormValues } from "./form-body";

interface EditProfileFormContextProps {
  firstName: string;
  lastName: string;
  onSubmit?: (values: EditProfileFormValues) => Promise<void>;
}

const EditProfileFormContext = (props: EditProfileFormContextProps) => {
  const Form = withFormik({
    handleSubmit: (values) => {
      if (props.onSubmit) {
        props.onSubmit(values);
      }
    },
    mapPropsToValues: () => ({
      firstName: props.firstName,
      lastName: props.lastName,
    }),
    validateOnBlur: false,
    validateOnChange: false,
    validateOnMount: false,
    validationSchema: EditProfileFormSchema
  })(EditProfileFormBody);

  return <Form />;
};

export default EditProfileFormContext;
