import { Field, FormikProps, FieldProps, ErrorMessage } from "formik";
import {
  AdvertiementTextArea,
  AdvertisementSubmitButton,
  AdvertisementTextField,
  CharacterCounterLabel,
} from "../../styled/advertisement-form-elements";
import { SectionTitle } from "../banner-ad-info/styled";

import * as Yup from "yup";

import { Content } from "./styled";
import { StyledErrorMessage } from "../../styled/form-elements";
import { useState } from "react";

export interface SpecialOfferUpdateDetailsProps {
  loading: boolean;
}

export interface SpecialOfferUpdateDetailsValues {
  title: string;
  description: string;
  redeemMethod?: string;
}

export const SpecialOfferUpdateDetailsSchema: Yup.SchemaOf<SpecialOfferUpdateDetailsValues> =
  Yup.object({
    title: Yup.string()
      .required("Please enter a title")
      .max(200, "Title must be less than 200 characters."),
    description: Yup.string()
      .required("Please enter a description")
      .max(1500, "Description must be less than 1500 characters."),
    redeemMethod: Yup.string()
      .optional()
      .max(1500, "How to Redeem must be less than 1500 characters."),
  });

const SpecialOfferUpdateDetails = (
  props: SpecialOfferUpdateDetailsProps &
    FormikProps<SpecialOfferUpdateDetailsValues>
) => {
  const [descriptionCharacterCount, setDescriptionCharacterCount] =
    useState<number>(props.values.description.length);

  const [redeemCharacterCounter, setRedeemCharacterCounter] = useState<number>(
    props.values.redeemMethod?.length ?? 0
  );

  const { handleSubmit } = props;

  return (
    <form onSubmit={handleSubmit}>
      <Content>
        <SectionTitle>Update Title</SectionTitle>
        <ErrorMessage name="title">
          {(msg: string) => <StyledErrorMessage>{msg}</StyledErrorMessage>}
        </ErrorMessage>
        <Field name="title">
          {({ field }: FieldProps) => <AdvertisementTextField {...field} />}
        </Field>
        <SectionTitle>Update Description</SectionTitle>
        <ErrorMessage name="description">
          {(msg: string) => <StyledErrorMessage>{msg}</StyledErrorMessage>}
        </ErrorMessage>
        <CharacterCounterLabel>
          {descriptionCharacterCount} / 1500 characters
        </CharacterCounterLabel>
        <Field name="description">
          {({ field }: FieldProps) => (
            <AdvertiementTextArea
              {...field}
              onChange={(e) => {
                setDescriptionCharacterCount(e.currentTarget.value.length);
                field.onChange(e);
              }}
            ></AdvertiementTextArea>
          )}
        </Field>
        <SectionTitle>Update Redeem Method (optional)</SectionTitle>
        <ErrorMessage name="redeemMethod">
          {(msg: string) => <StyledErrorMessage>{msg}</StyledErrorMessage>}
        </ErrorMessage>
        <CharacterCounterLabel>
          {redeemCharacterCounter} / 1500 characters
        </CharacterCounterLabel>
        <Field name="redeemMethod">
          {({ field }: FieldProps) => (
            <AdvertiementTextArea
              {...field}
              onChange={(e) => {
                setRedeemCharacterCounter(e.currentTarget.value.length);
                field.onChange(e);
              }}
            ></AdvertiementTextArea>
          )}
        </Field>
        <AdvertisementSubmitButton
          type="submit"
          disabled={props.loading}
          isDisabled={props.loading}
        >
          SAVE
        </AdvertisementSubmitButton>
      </Content>
    </form>
  );
};

export default SpecialOfferUpdateDetails;
