import { Link } from "react-router-dom";
import { RegisterState } from "../ts/register-state";
import { CreateStepOneForm } from "../utils/stepOneFormikContext";
import { CreateStepThreeForm } from "../utils/stepThreeFormikContext";
import { CreateStepTwoForm } from "../utils/stepTwoFormikContext";
import { StepTwoFormValues } from "./step-two-form";

import { useNavigate } from "react-router";
import { StepOneFormValues } from "./step-one-form";
import { StepThreeFormValues } from "./step-three-form";

import ReactGA from "react-ga";

const RegisterSwitch = ({
  step,
  state,
  setState,
  onComplete,
  disabled,
}: {
  step: number;
  state: RegisterState;
  setState: React.Dispatch<React.SetStateAction<RegisterState>>;
  onComplete: (values: any) => Promise<void>;
  disabled: boolean;
}) => {
  switch (step) {
    case 1:
      return (
        <>
          <CreateStepOneForm
            existingValues={{
              email: state.email ?? "",
              password: state.password ?? "",
            }}
            handleSubmit={(values: StepOneFormValues) => {
              ReactGA.event({
                category: "user_action",
                action: "register_start",
              });

              setState({
                ...state,
                ...values,
                step: 2,
              });
            }}
          />
          <Link
            style={{
              display: "block",
              textAlign: "center",
              fontSize: 16,
              color: "black",
              textDecoration: "none",
            }}
            to="/login"
          >
            Already a Member? Click Here
          </Link>
        </>
      );
    case 2:
      return (
        <CreateStepTwoForm
          disabled={disabled}
          existingValues={{
            username: state.username ?? "",
            firstName: state.firstName ?? "",
            lastName: state.lastName ?? "",
            isLeosa: state.isLeosa,
            referralCode: state.referralCode ?? "",
          }}
          handleSubmit={(values: StepTwoFormValues) => {
            if (values.isLeosa === true) {
              ReactGA.event({
                category: "user_action",
                action: "register_leosa_start",
              });

              setState({
                ...state,
                ...values,
                step: 3,
              });
            } else {
              onComplete(values);
            }
          }}
        />
      );
    case 3:
      return (
        <CreateStepThreeForm
          disabled={disabled}
          handleSubmit={(values: StepThreeFormValues) => {
            onComplete(values);
          }}
        />
      );
    default:
      return null;
  }
};

export default RegisterSwitch;
