import { Field, FieldProps, FormikProps, ErrorMessage } from "formik";
import {
  FieldLabel,
  FieldSection,
  StyledErrorMessage,
  SubmitButton,
} from "../../../styled/edit-profile-form-elements";

import * as Yup from "yup";

import {
  CompleteClaimFormBodyContainer,
  StyledField,
} from "../styled/form-body-styled";

export interface CompleteClaimFormValues {
  claimCode: string;
}

export const CompleteFormSchema: Yup.SchemaOf<CompleteClaimFormValues> = Yup.object(
  {
    claimCode: Yup.string().required("Please enter your verfication code."),
  }
);

const CompleteClaimFormBody = (props: FormikProps<CompleteClaimFormValues>) => {
  const { handleSubmit, isValid } = props;

  return (
    <form onSubmit={handleSubmit}>
      <CompleteClaimFormBodyContainer>
        <FieldSection>
          <FieldLabel style={{ fontWeight: "normal", marginBottom: 10 }}>
            Verification Code
          </FieldLabel>
          <ErrorMessage
            name="claimCode"
            render={(msg: string) => (
              <StyledErrorMessage style={{ marginBottom: 10 }}>
                {msg}
              </StyledErrorMessage>
            )}
          />
          <Field
            name="claimCode"
            render={({ field }: FieldProps) => <StyledField {...field} />}
          />
        </FieldSection>
        <SubmitButton
          type="submit"
          active={isValid}
          style={{ width: "100%", maxWidth: 294, marginTop: 12 }}
        >
          COMPLETE
        </SubmitButton>
      </CompleteClaimFormBodyContainer>
    </form>
  );
};

export default CompleteClaimFormBody;
