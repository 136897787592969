import React from "react";
import { Navigate } from "react-router";
import EmailConfirmation from "../../../components/email-confirmation/email-confirmation";
import ResetPasswordForm from "../../../components/reset-password-form/reset-password-form";
import RecoverEmail from "../../RecoverEmail/RecoverEmail";

const AuthPageSwitch = ({
  mode,
  oobCode,
}: {
  mode: string | null;
  oobCode: string | null;
}) => {
  if (oobCode === null) {
    return <Navigate to="/register" />;
  }

  switch (mode) {
    case "resetPassword":
      return <ResetPasswordForm oobCode={oobCode} />;
    case "verifyEmail":
      return <EmailConfirmation oobCode={oobCode} />;
    case "recoverEmail":
      return <RecoverEmail oobCode={oobCode} />;
    default:
      return <Navigate to="/register" />;
  }
};

export default AuthPageSwitch;
