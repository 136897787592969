import styled from "styled-components";
import { mobileMax } from "../../../lib/media-breakpoints";
import { applyBoxShadow } from "../../../styled/box-shadow";

export const DashboardPageContainer = styled.div`
  display: flex;

  @media (max-width: ${mobileMax}px) {
    flex-direction: column-reverse;
    // height: calc(100vh - 65px);
    overflow: auto;
    flex: 1;
    position: relative;
  }

  @media (min-width: ${mobileMax + 1}px) {
    align-items: stretch;
    height: calc(100vh - 150px);
  }
`;

export const LeftSide = styled.div`
  @media (max-width: ${mobileMax}px) {
    max-height: 65%;
    flex: 0 1 65%;
    // height: 100%;
    ${applyBoxShadow()}
    border-radius:5px;
    overflow: auto;
    // z-index: 1;
    position: static;
  }

  @media (min-width: ${mobileMax + 1}px) {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 0 0 55%;
    max-width: 800px;
    height: 100%;
    z-index: 3000;
    box-shadow: 0px 10px 5px #8597b0;
  }
`;

export const RightSide = styled.div`
  position: relative;

  @media (max-width: ${mobileMax}px) {
    display: flex;
    flex-direction: column;
    flex: 1 0 40%;
    align-items: stretch;
    z-index: 2000;
  }

  @media (min-width: ${mobileMax + 1}px) {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: stretch;
    z-index: 1000;
  }
`;
