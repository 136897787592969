import styled from "styled-components";
import { mobileMax } from "../../../lib/media-breakpoints";
import { CloseButton } from "../../../styled/close-button";

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 20px;
`;

export const ModifiedCloseButton = styled(CloseButton)`
  margin-left: auto;
  align-self: self-start;
`;

export const TopPart = styled.div`
  display: flex;
`;

export const BusinessNameLabel = styled.div`
  color: #1b3963;
  font-size: 25px;
  font-weight: 500;
  margin-top: 40px;

  @media (max-width: ${mobileMax}px) {
    margin-top: 0;
  }
`;

export const BottomPart = styled.div`
  display: flex;
`;

export const InfoSection = styled.div`
  display: flex;
  flex: 1;

  white-space: nowrap;

  @media (max-width: ${mobileMax}px) {
    flex: 1 0 70px;
  }

  font-size: 14px;
  color: #464646;
`;

export const BadgesSection = styled.div`
  margin-left: auto;

  @media (max-width: ${mobileMax}px) {
    flex: 1 0 150px;
  }
`;

export const BusinessCategoryLabel = styled.p`
  margin-right: 5px;
`;

export const DistanceFromUserLabel = styled.p`
  :before {
    content: "• ";
  }
`;
