import styled from "styled-components";
export const ImageSliderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ImageSliderDisplay = styled.div`
  flex: 1 0 300px;
  max-width: 300px;
  height: 300px;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ImageSliderImage = styled.img`
  height: auto;
  max-height: 100%;
  width: 100%;
`;

export const ControlsDisplay = styled.div`
  display: flex;
  margin-top: 10px;
  align-items: center;
  justify-content: center;
`;

export const ControlBubble = styled.div<{ active: boolean }>`
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin: 0 5px;

  background: ${(props) => (props.active ? "black" : "white")};
  border: solid black 1px;
`;

export const Top = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ArrowDisplay = styled.span`
  margin: 0;
`;
