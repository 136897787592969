import styled from "styled-components";
export const PostBadgleTableRowContainer = styled.div`
  display: flex;
  // height: 50px;
  height: 100%;
  justify-content: center;
  width: 100%;
  cursor: pointer;
  margin: auto 0;

  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
`;

export const LeftSide = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  margin-left: 20px;
`;

export const RightSide = styled.div`
  display: flex;
  height: 100%;
  flex: 1;
  align-items: center;
  margin-left: 15px;
`;

export const PolicyTypeLabel = styled.p`
  margin: 0 0 0 0;
`;
