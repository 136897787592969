import React from "react";
import IconHandler from "../icon-handler/icon-handler";

const BackButton = (props: { handleBack: () => void }) => {
  return (
    <div style={{ alignSelf: "flex-start", maxWidth: 300 }}>
      <div
        style={{ marginTop: 30, marginLeft: 20, cursor: "pointer" }}
        onClick={() => props.handleBack!()}
      >
        <IconHandler name="chevronLeft" iconProps={() => {}} />
      </div>
    </div>
  );
};

export default BackButton;
