const Hamburger = ({ props, iconProps }: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={54}
      height={38}
      {...iconProps}
    >
      <g
        data-name="hamburger menu"
        transform="translate(-1807.495 -129.246)"
        fill="#667fa2"
      >
        <rect
          data-name="Rectangle 2"
          width={54}
          height={5}
          rx={2.5}
          transform="translate(1807.495 129.246)"
        />
        <rect
          data-name="Rectangle 3"
          width={54}
          height={6}
          rx={3}
          transform="translate(1807.495 145.246)"
        />
        <rect
          data-name="Rectangle 4"
          width={54}
          height={5}
          rx={2.5}
          transform="translate(1807.495 162.246)"
        />
      </g>
    </svg>
  );
};

export default Hamburger;
