import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import BackButton from "../../components/back-button/back-button";
import { SuggestPostFormValues } from "../../components/suggest-post-form/suggest-post-form";
import SuggestPostFormWrapper from "../../components/suggest-post-form/suggest-post-form-wrapper";
import { suggestPost } from "../../lib/api/article";
import { Loader } from "../../styled/loader";
import { Subtitle } from "../../styled/typography";

import { SuggestPostPageContainer, Content } from "./styled";

interface SuggestPostPageProps {}

const SuggestPostPage = (props: SuggestPostPageProps) => {
  const navigate = useNavigate();

  const suggestPostMutation = useMutation(
    "/suggest-post",
    (values: SuggestPostFormValues) =>
      suggestPost(values.content, values.wantsToBeCredited)
  );

  if (suggestPostMutation.isLoading) {
    return (
      <SuggestPostPageContainer>
        <Loader />
      </SuggestPostPageContainer>
    );
  }

  if (suggestPostMutation.isError) {
    return (
      <SuggestPostPageContainer>
        <BackButton handleBack={() => navigate("/menu")} />
        <p>Could not suggest post at this time.</p>
      </SuggestPostPageContainer>
    );
  }

  if (suggestPostMutation.isSuccess) {
    return (
      <SuggestPostPageContainer>
        <BackButton handleBack={() => navigate("/menu")} />
        <Subtitle style={{ margin: "20px" }}>
          Thank you. Your suggestion has been submitted.
        </Subtitle>
      </SuggestPostPageContainer>
    );
  }

  return (
    <SuggestPostPageContainer>
      <BackButton handleBack={() => navigate("/menu")} />
      <Content>
        <SuggestPostFormWrapper
          handleSubmit={(values) => {
            suggestPostMutation.mutate(values);
          }}
        />
      </Content>
    </SuggestPostPageContainer>
  );
};

export default SuggestPostPage;
