import { withFormik } from "formik";
import RedeemBulletItemForm, {
  RedeemBulletItemFormProps,
  RedeemBulletItemFormSchema,
  RedeemBulletItemFormValues,
} from "./redeem-bullet-item-form";

interface RedeemBulletItemFormWrapperProps {
  onSubmit: (values: RedeemBulletItemFormValues) => void;
  initialValues?: RedeemBulletItemFormValues;
}

const RedeemBulletItemFormWrapper = (
  props: RedeemBulletItemFormWrapperProps
) => {
  const Form = withFormik<
    RedeemBulletItemFormProps,
    RedeemBulletItemFormValues
  >({
    handleSubmit: (values) => {
      props.onSubmit(values);
    },
    mapPropsToValues: () => ({
      streetAddress: props.initialValues?.streetAddress ?? "",
      streetAddressTwo: props.initialValues?.streetAddressTwo ?? "",
      state: props.initialValues?.state ?? "",
      city: props.initialValues?.city ?? "",
      zip: props.initialValues?.zip ?? "",
      country: props.initialValues?.country ?? "",
    }),
    validateOnBlur: false,
    validateOnChange: false,
    validateOnMount: false,
    validationSchema: RedeemBulletItemFormSchema,
  })(RedeemBulletItemForm);

  return <Form />;
};

export default RedeemBulletItemFormWrapper;
