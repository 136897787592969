import styled from "styled-components";

export const Container = styled.div``;

export const Content = styled.div`
  margin: 60px 30px;
  li {
    margin: 10px 0;
  }
`;

export const Text = styled.pre`
  color: grey;
  border: none;
  background: none;
  text-align: left;

  color: black;
  font-family: "Roboto", sans-serif;

  white-space: pre-line;
`;
