import styled from "styled-components";
import { mobileMax } from "../../lib/media-breakpoints";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin: 40px 30px 0 30px;

  @media (min-width: ${mobileMax + 1}px) {
    // max-width: 300px;
    // width: 100%;
    // align-self: center;
  }
`;

export const SectionTitle = styled.div`
  color: #000000;
  font-weight: bold;
  margin: 20px 0 10px 0;

  @media (min-width: ${mobileMax + 1}px) {
    font-size: 16px;
  }

  @media (max-width: ${mobileMax}px) {
    font-size: 16px;
  }
`;

export const InfoText = styled.div`
  font-size: 12px;
  color: #1b3963;
  font-weight: 400;
  margin: 2px 0;

  @media (min-width: ${mobileMax + 1}px) {
    font-size: 14px;
  }
`;

export const UploadButton = styled.button`
  color: #516581;
  flex: 0 0 30px;
  width: 140px;
  font-size: 14px;
  border: solid #516581 2px;
  border-radius: 3px;
  background: none;
  margin-top: 18px;
  align-self: center;
  margin-bottom: 20px;
`;

export const InfoLink = styled.div`
  color: blue;
  font-size: 16px;
`;
