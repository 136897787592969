const ChevronLeft = ({ props, iconProps }: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      width={30}
      height={30}
      className="bi bi-chevron-left"
      viewBox="0 0 20 20"
      {...iconProps}
    >
      <path
        fillRule="evenodd"
        d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
      />
    </svg>
  );
};

export default ChevronLeft;
