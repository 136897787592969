import { useQuery } from "react-query";
import BackButton from "../../components/back-button/back-button";
import BusinessInfoHeader from "../../components/business-info-header/business-info-header";
import BusinessPhotoManagerWrapper from "../../components/business-photo-manager/business-photo-manager-wrapper";
import { getPointOfInterest } from "../../lib/api/points-of-interest";
import { GradientBorder } from "../../styled/gradient-border";
import { Loader } from "../../styled/loader";
import {
  BusinessPhotoManagerPageContainer,
  Content,
  Header,
  Wrapper,
} from "./styled";

interface BusinessPhotoManagerPageProps {
  placeId: string;
  handleBack: () => void;
}

const BusinessPhotoManagerPage = (props: BusinessPhotoManagerPageProps) => {
  const { data, isLoading, isError } = useQuery(["/poi", props.placeId], () =>
    getPointOfInterest(props.placeId)
  );

  if (isLoading) {
    return <Loader />;
  }

  if (isError || !data || !data.businessPhotos) {
    return <p>Could not retreive photos at this time.</p>;
  }

  return (
    <BusinessPhotoManagerPageContainer>
      <BackButton handleBack={props.handleBack} />
      <Wrapper>
        <Header>
          <BusinessInfoHeader
            businessName={data.name}
            businessAddress={data.formattedAddress}
            businessPrimaryPhotoUrl={
              data.businessPhotos && data.businessPhotos.length > 0
                ? data.businessPhotos[0].imageUrl
                : undefined
            }
          />
        </Header>
      </Wrapper>
      <GradientBorder style={{ height: 3, marginTop: 20, marginBottom: 20 }} />
      <Content>
        <BusinessPhotoManagerWrapper
          businessPhotos={data.businessPhotos}
          pointOfInterestId={data.id}
          placeId={props.placeId}
        />
      </Content>
    </BusinessPhotoManagerPageContainer>
  );
};

export default BusinessPhotoManagerPage;
