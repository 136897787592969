import { Article } from "../../ts/article";
import ArticleCard from "../article-card/article-card";
import {
  ArticleCardWrapper,
  ArticlesTableContainer,
  Cards,
  LoadMoreButton,
} from "./styled/articles-table-styled";

interface ArticlesTableProps {
  articles: Article[];
  hasMore: boolean;
  onLoadMoreClick: () => void;
  loading: boolean;
  onRowClick: (id: number, type: string, externalLink?: string) => void;
}

const ArticlesTable = (props: ArticlesTableProps) => {
  return (
    <ArticlesTableContainer>
      <Cards>
        {props.articles.map((article) => (
          <ArticleCardWrapper
            key={article.id}
            onClick={() =>
              props.onRowClick(article.id, article.type, article.externalLink)
            }
          >
            <ArticleCard
              title={article.title}
              category={article.category?.name}
              date={article.createdAt}
              imageSrc={article.imageURL}
              previewText={article.previewText}
            />
          </ArticleCardWrapper>
        ))}
      </Cards>
      {props.hasMore ? (
        <LoadMoreButton
          onClick={() => props.onLoadMoreClick()}
          inActive={props.loading}
          disabled={props.loading}
        >
          LOAD MORE
        </LoadMoreButton>
      ) : null}
    </ArticlesTableContainer>
  );
};

export default ArticlesTable;
