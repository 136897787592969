import { ErrorMessage, FormikProps, Field, FieldProps } from "formik";
import { SectionTitle, InfoText } from "../../pages/AdvertisementsMenu/styled";

import { SecondaryButton } from "../../styled/buttons";

import AdvertisementImagePicker from "../advertisement-image-picker/advertisement-image-picker";

import * as Yup from "yup";
import { ErrorMessageText } from "./styled";
import {
  AdvertisementsPageTitle,
  AdvertisementTextField,
} from "../../styled/advertisement-form-elements";
import AdvertisementPlansTableWrapper from "../advertisement-plans-table/advertisement-plans-table-wrapper";

export interface BannerAdFormProps {}

export interface BannerAdFormValues {
  websiteLink: string;
  image?: File;
  planId: string;
  salesCode?: string;
}

const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

export const BannerAdFormSchema: Yup.SchemaOf<BannerAdFormValues> = Yup.object({
  websiteLink: Yup.string().required("Please enter a website link."),
  planId: Yup.string().required("Please select an advertisement plan."),
  image: Yup.mixed()
    .test("exists", "Please upload an advertisement image.", (value) =>
      value ? true : false
    )
    .test("fileSize", "The image is too large. ", (value) => {
      if (!value) return false;
      return value.size <= 6000000;
    })
    .test("fileType", "Unsupported File Format", (value) => {
      if (!value) return false;
      return SUPPORTED_FORMATS.includes(value.type);
    }),
  salesCode: Yup.string().optional(),
});

const BannerAdForm = (
  props: BannerAdFormProps & FormikProps<BannerAdFormValues>
) => {
  const { handleSubmit, setFieldValue } = props;

  const handleImageChange = (image: File) => {
    setFieldValue("image", image);
  };

  const handlePlanChange = (id: string) => {
    setFieldValue("planId", id);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div
        style={{ display: "flex", flexDirection: "column", marginBottom: 60 }}
      >
        <AdvertisementsPageTitle>UPLOAD NEW AD</AdvertisementsPageTitle>
        <SectionTitle>Banner Ad Image</SectionTitle>
        <InfoText>*Advertisement will appear in map section.</InfoText>
        <InfoText>*Recommended image size: 400 x 100.</InfoText>
        <ErrorMessage name="image">
          {(msg) => <ErrorMessageText>{msg}</ErrorMessageText>}
        </ErrorMessage>
        <AdvertisementImagePicker
          imageWidth={400}
          imageHeight={100}
          onChange={handleImageChange}
          inputName={"image"}
        />
        <SectionTitle>Website Link</SectionTitle>
        <InfoText>
          *Users will be redirected here when your advertisement is clicked.
        </InfoText>
        <ErrorMessage name="websiteLink">
          {(msg) => <ErrorMessageText>{msg}</ErrorMessageText>}
        </ErrorMessage>
        <Field name="websiteLink">
          {({ field }: FieldProps) => <AdvertisementTextField {...field} />}
        </Field>

        <SectionTitle>Advertisement Plan</SectionTitle>
        <ErrorMessage name="planId">
          {(msg) => <ErrorMessageText>{msg}</ErrorMessageText>}
        </ErrorMessage>
        <AdvertisementPlansTableWrapper onClick={handlePlanChange} />

        <SectionTitle>Sales Code</SectionTitle>
        <ErrorMessage name="salesCode">
          {(msg) => <ErrorMessageText>{msg}</ErrorMessageText>}
        </ErrorMessage>
        <Field name="salesCode">
          {({ field }: FieldProps) => <AdvertisementTextField {...field} />}
        </Field>

        <SecondaryButton type={"submit"} style={{ marginTop: 20 }}>
          UPLOAD
        </SecondaryButton>
      </div>
    </form>
  );
};

export default BannerAdForm;
