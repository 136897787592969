import { useState } from "react";
import { useQuery } from "react-query";
import { getSpecialOfferPlans } from "../../lib/api/special-offer";
import { SpecialOfferPlan } from "../../ts/special-offer-plan";

export const useDeterminePlans = () => {
  const plansQuery = useQuery("/business-offer/plans", () =>
    getSpecialOfferPlans()
  );

  const [selectedNumber, changeSelected] = useState(0);

  let viewablePlans: SpecialOfferPlan[] = [];

  if (plansQuery.data) {
    //get plans that meet the business amount restrictions. if limit boundary is null, then allow
    viewablePlans = plansQuery.data.filter(
      (a) =>
        (a.businessMinimum <= selectedNumber || a.businessMinimum === null) &&
        (a.businessMaximum >= selectedNumber || a.businessMaximum === null)
    );
  }

  return {
    isLoading: plansQuery.isLoading,
    isError: plansQuery.isError || !plansQuery.data,
    plans: viewablePlans,
    changeSelected,
  };
};
