import styled from "styled-components";
import { applyGradient } from "../../../styled/blue-gradient-background";

export const RegisterContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const LogoContainer = styled.div`
  height: 240px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
