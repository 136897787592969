import { Article, ArticleCategory } from "../../../ts/article";
import { PostSuggestion } from "../../../ts/post-suggestion";

import { getFirebaseUserToken } from "../../firebase/firebase";

import axios from "../axios-instance";

interface ArticlesResponse {
  articles: Article[];
  hasMore: boolean;
  message?: string;
  nextPage?: number;
}

export const getArticles = async (
  page: number,
  categoryId?: string
): Promise<ArticlesResponse> => {
  const token = await getFirebaseUserToken();

  const { data } = await axios.get<ArticlesResponse>(
    `/articles?page=${page}${categoryId ? `&categoryId=${categoryId}` : ""}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  // if (data.message) {
  //   throw Error(data.message);
  // }

  return data;
};

export const getBookmarks = async (page: number): Promise<ArticlesResponse> => {
  const token = await getFirebaseUserToken();

  const { data } = await axios.get<ArticlesResponse>(
    `/me/bookmarks?page=${page}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  // if (data.message) {
  //   throw Error(data.message);
  // }

  return data;
};

interface ArticleResponse {
  article: Article;
  message?: string;
}

export const getArticle = async (id: string): Promise<Article> => {
  const token = await getFirebaseUserToken();

  const { data } = await axios.get<ArticleResponse>(
    `/article?articleId=${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return data.article;
};

interface ArticleCategoriesResponse {
  categories: ArticleCategory[];
  message?: string;
}

export const getArticleCategories = async (): Promise<ArticleCategory[]> => {
  const token = await getFirebaseUserToken();
  const { data } = await axios.get<ArticleCategoriesResponse>(
    `/articles/categories`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return data.categories;
};

interface BookmarkArticleResponse {
  success: boolean;
  message?: string;
}

export const bookmarkArticle = async (id: string): Promise<Boolean> => {
  const token = await getFirebaseUserToken();

  const { data } = await axios.post<BookmarkArticleResponse>(
    `/me/bookmarks`,
    {
      articleId: id,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return data.success;
};

export const deleteBookmark = async (id: string): Promise<Boolean> => {
  const token = await getFirebaseUserToken();

  const { data } = await axios.post<BookmarkArticleResponse>(
    `/me/bookmarks/remove`,
    {
      articleId: id,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return data.success;
};

interface SuggestPostResponse {
  postSuggestion: PostSuggestion;
  message?: string;
}

export const suggestPost = async (
  explanation: string,
  wantsToBeCredited: boolean
): Promise<PostSuggestion> => {
  try {
    const token = await getFirebaseUserToken();

    const { data } = await axios.post<SuggestPostResponse>(
      "/articles/suggest",
      {
        explanation,
        wantsToBeCredited,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data.postSuggestion;
  } catch (e: any) {
    if (e.response?.data?.message) {
      throw Error(e.response.data.message);
    } else {
      throw Error("Could not redeem offer at this time.");
    }
  }
};
