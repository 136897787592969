import { Link, useNavigate, useSearchParams } from "react-router-dom";
import BackButton from "../../components/back-button/back-button";
import { Title } from "../../styled/typography";
import { InfoText } from "../AdvertisementsMenu/styled";
import { StepSection, StepTitle, StepImage } from "../HomeScreen/styled";
import { Content, Wrapper } from "./styled";

import StepOneImage from "./images/step-one.png";
import StepTwoImage from "./images/step-two.png";
import StepThreeImage from "./images/step-three.png";
import { LinkText } from "../../components/add-to-home-screen-info/styled";

interface LocationHelpPageProps {}

const LocationHelpPage = (props: LocationHelpPageProps) => {
  const [searchParams] = useSearchParams();

  const back = searchParams.get("b");

  const navigate = useNavigate();

  return (
    <Content>
      <BackButton
        handleBack={() => {
          if (back === "home" || back === "location") {
            navigate("/home");
          } else {
            navigate("/menu");
          }
        }}
      />
      <Wrapper>
        <Title>How to Enable Location Services</Title>
        <InfoText>
          By sharing your location with USA PACS, you will see more relevant
          businesses around you.
        </InfoText>
        <StepSection style={{ marginTop: 40 }}>
          <StepTitle>
            1. Location Services must be allowed for Safari. You can check this
            setting in your {"Settings > Privacy > Location Services."}
          </StepTitle>
        </StepSection>
        <StepSection style={{ marginTop: 40 }}>
          <StepTitle>
            2. Navigate to usapacs.app on Safari. Please do not open from the
            Home Screen for this process.
          </StepTitle>
        </StepSection>

        <StepSection style={{ marginTop: 40 }}>
          <StepTitle>
            3. Tap the "aA" icon located next to "usapacs.app".
          </StepTitle>
          <StepImage src={StepOneImage} width={300} height="auto" />
        </StepSection>

        <StepSection style={{ marginTop: 40 }}>
          <StepTitle>4. Tap on "Website Settings"</StepTitle>
          <StepImage src={StepTwoImage} width={300} height="auto" />
        </StepSection>

        <StepSection style={{ marginTop: 40 }}>
          <StepTitle>5. Set Location to "Allow"</StepTitle>
          <StepImage src={StepThreeImage} width={300} height="auto" />
        </StepSection>

        <StepSection style={{ marginTop: 40 }}>
          <StepTitle>
            6. Open the USA PACS web app from your Home Screen and view
            businesses around your area.
          </StepTitle>
          <Link
            to="/menu/home-screen?b=location"
            style={{ textDecoration: "none" }}
          >
            <LinkText>
              Need help with adding USA PACS to your Home Screen? View
              instructions here
            </LinkText>
          </Link>
        </StepSection>
      </Wrapper>
    </Content>
  );
};

export default LocationHelpPage;
