import styled from "styled-components";
import { mobileMax } from "../../lib/media-breakpoints";
import { applyGradient } from "../../styled/blue-gradient-background";
import { Title } from "../../styled/typography";

export const Content = styled.div`
  margin: 40px 20px;
  display: flex;
  flex-direction: column;

  max-width: 400px;

  @media (max-width: ${mobileMax}px) {
    margin: 20px 20px;
  }
`;

export const StyledTitle = styled(Title)`
  @media (max-width: ${mobileMax}px) {
    font-size: 16px;
  }
`;

export const QuestionText = styled.div`
  margin-top: 20px;
`;

export const BorderWrap = styled.div`
  ${applyGradient()};
  padding: 1px;
  border-radius: 3px;
  width: 100%;

  height: 300px;
  margin-top: 20px;
  display: flex;
`;

export const ContentTextArea = styled.textarea.attrs({})`
  border: none;
  background: white;
  border-radius: 3px;
  padding: 15px;
  width: 100%;
  resize: none;
  margin: 0;

  font-family: "Roboto", sans-serif;
`;
