import { User } from "@firebase/auth";
import { createContext, useContext, useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router";
import { getUserDetails } from "../api/user";
import { getFirebaseAuth } from "../firebase/firebase";
import { queryClient } from "../react-query/react-query-client";

interface AuthContextValues {
  isAuth: boolean | null;
  authHandler?: any;
}

const authContext = createContext<AuthContextValues>({
  isAuth: null,
  authHandler: undefined,
});

const setFirebaseTokenCookie = async (user: User) => {
  const token = await user.getIdToken();
};

const useAuth = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [isAuth, setIsAuth] = useState<boolean | null>(null);

  const { data, isLoading } = useQuery("/me", getUserDetails, {
    enabled: isAuth === true,
    retry: false,
    onSuccess: () => {},
  });

  useEffect(() => {
    const auth = getFirebaseAuth();
    auth.onAuthStateChanged((user) => {
      if (user) {
        setFirebaseTokenCookie(user);
        setIsAuth(true);
      } else {
        //cancel any user queries
        queryClient.cancelQueries("/me/details");
        setIsAuth(false);
      }
    });
  }, []);

  return {
    isAuth,
    authHandler: {
      isLoadingUser: isLoading,
      login: () => {
        setIsAuth(true);
      },
      logout: () => {
        const auth = getFirebaseAuth();
        auth
          .signOut()
          .then(() => {
            setIsAuth(false);
            navigate("/register");
            queryClient.removeQueries();
          })
          .catch((e: Error) => {});
      },
      isLeosa: (): boolean => {
        return data?.accountTypeId === 2;
      },
      getUserEmail: (): string | null => {
        const auth = getFirebaseAuth();

        if (auth.currentUser) {
          return auth.currentUser.email;
        }

        return null;
      },
      getUser: () => {
        return data;
      },
    },
  };
};

export function AuthProvider({ children }: any) {
  const auth = useAuth();

  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export default function AuthConsumer() {
  const ctx = useContext(authContext);
  return ctx;
}
