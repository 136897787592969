import styled from "styled-components";
import { mobileMax } from "../../../lib/media-breakpoints";

export const LogoSectionContainer = styled.div`
  flex: 0 1 290px;

  img {
    height: 100%;
    width: auto;
  }

  @media (min-width: ${mobileMax + 1}px) {
    margin: 0 0 0 54px;
    height: 100px;
  }

  @media (max-width: ${mobileMax}px) {
    margin: 0 20px 0 20px;
    height: 50px;
  }
`;

export const LogoImageContainer = styled.div``;
