import styled from "styled-components";
import { mobileMax } from "../../../lib/media-breakpoints";

export const UpdateProfileMenupageContainer = styled.div``;

export const Section = styled.div`
  margin: 34px 0;
  display: flex;
  flex-direction: column;
`;

export const SectionTitle = styled.p`
  color: #244169;
  font-weight: bold;
  margin: 0 0 0 2px;
`;

export const SectionValue = styled.p`
  margin: 5px 0 2px 2px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin: 60px 70px;

  @media (max-width: ${mobileMax}px) {
    margin: 48px 20px;
  }
`;

export const TopSide = styled.div`
  display: flex;
`;

export const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
`;

export const EditButton = styled.button`
  background: #8894ac;
  color: white;
  height: 30px;
  border: none;
  align-self: center;
  margin-left: auto;
  width: 100%;
`;

export const EditButtonContainer = styled.div`
  margin-left: auto;
  flex: 0 0 80px;
  a {
    width: 100%;
  }
`;
