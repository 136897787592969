import React from "react";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ActiveMarkerContext } from "../../pages/Dashboard/util/contexts";
import { InfoIcon } from "../../styled/info-icon";
import BadgesDisplay from "../badges-display/badges-display";
import { ResultsTableListingProps } from "./results-table-listing";
import {
  ResultsTableListingContainer,
  ResultListingLeftSide,
  ResultListingRightSide,
  AddressLabel,
  BusinessCategoryLabel,
  BusinessDistanceLabel,
  BusinessNameLabel,
  InfoSection,
  BadgeSection,
  ImageSection,
  PrimaryPhoto,
} from "./styled/results-table-listing-desktop-styled";

const ResultsTableListingDesktop = (props: ResultsTableListingProps) => {
  const { setActiveMarker } = useContext(ActiveMarkerContext);
  const navigate = useNavigate();

  return (
    <ResultsTableListingContainer
      onClick={() => {
        if (setActiveMarker) {
          setActiveMarker(props.placeId);
        }

        navigate(`/home/business/${props.placeId}`);
      }}
    >
      <ResultListingLeftSide>
        <BusinessNameLabel>{props.businessName}</BusinessNameLabel>
        <AddressLabel>{props.businessAddress}</AddressLabel>
        <InfoSection>
          <BusinessCategoryLabel>{props.category}</BusinessCategoryLabel>
          {props.milesFromUser ? (
            <BusinessDistanceLabel>
              {props.milesFromUser} mi
            </BusinessDistanceLabel>
          ) : null}
        </InfoSection>
        <BadgeSection>
          {props.badgeAlignments ? (
            <BadgesDisplay badgeAlignments={props.badgeAlignments} />
          ) : null}
        </BadgeSection>
      </ResultListingLeftSide>
      <ResultListingRightSide>
        <ImageSection>
          {props.primaryPhotoUrl ? (
            <PrimaryPhoto src={props.primaryPhotoUrl} alt="" />
          ) : null}
        </ImageSection>
        <InfoIcon desktopOnly={true} style={{ marginTop: 20, marginRight: 20 }}>
          <span>i</span>
        </InfoIcon>
      </ResultListingRightSide>
    </ResultsTableListingContainer>
  );
};

export default ResultsTableListingDesktop;
