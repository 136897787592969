import React from "react";
import { formatPolicy } from "../../../lib/format-policy";
import IconHandler from "../../icon-handler/icon-handler";
import { PostBadgeProps } from "../post-badge";
import {
  PostBadgleTableRowContainer,
  LeftSide,
  RightSide,
  PolicyTypeLabel,
} from "../styled/post-badge-table-row-styled";

export interface PostBadgeTableRowProps extends PostBadgeProps {
  policy: string;
  activeAlignment: number;
  handleChange: (policy: string) => void;
}

const PostBadgeTableRow = (props: PostBadgeTableRowProps) => {
  const alignmentString = ["neutral", "positive", "negative"];

  return (
    <PostBadgleTableRowContainer
      onClick={() => {
        props.handleChange(props.policy);
      }}
    >
      <LeftSide>
        <div style={{ width: 60, height: 60 }}>
          <IconHandler
            name={`${props.policy}-${alignmentString[props.activeAlignment]}`}
            iconProps={{}}
          />
        </div>
      </LeftSide>

      <RightSide>
        <PolicyTypeLabel>{formatPolicy(props.policy)}</PolicyTypeLabel>
      </RightSide>
    </PostBadgleTableRowContainer>
  );
};

export default PostBadgeTableRow;
