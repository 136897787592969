import { withFormik } from "formik";
import { useState } from "react";
import { sendForgotPassword } from "../../../lib/firebase/firebase";

import ForgotPasswordForm, {
  ForgotPasswordFormValues,
  forgotPasswordSchema,
} from "../components/forgot-password-form";

export interface ForgotPasswordFormProps {
  submitted: boolean;
}

const CreateForgotPasswordForm = () => {
  const [submitted, setSubmitted] = useState(false);

  const Form = withFormik<ForgotPasswordFormProps, ForgotPasswordFormValues>({
    handleSubmit: async (values) => {
      try {
        await sendForgotPassword(values.email);

        setSubmitted(true);
      } catch (e) {}
    },
    mapPropsToValues: () => ({
      email: "",
    }),
    validateOnBlur: false,
    validateOnChange: false,
    validateOnMount: false,
    validationSchema: forgotPasswordSchema,
  })(ForgotPasswordForm);

  return <Form submitted={submitted} />;
};

export default CreateForgotPasswordForm;
