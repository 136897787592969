import styled from "styled-components";

export const CompleteClaimFormBodyContainer = styled.div`
  width: 100%;
  max-width: 294px;
`;

export const StyledField = styled.input`
  height: 39px;
  border: none;
  max-width: 294px;
  background: #ffffff;
  border: #e8e8e8 solid 1px;
`;
