import { EditProfileFormValues } from "../../../components/edit-profile-form/components/form-body";
import { SupportFormValues } from "../../../components/support-form/support-form";
import { LeosaRequest } from "../../../ts/leosa-request";
import { PointOfInterest } from "../../../ts/point-of-interest";
import { PointOfInterestClaim } from "../../../ts/point-of-interest-claim";
import { getFirebaseUserToken } from "../../firebase/firebase";
import { USAPACSUser } from "../../ts/User";
import axios from "../axios-instance";

export const getUserDetails = async (): Promise<USAPACSUser> => {
  const token = await getFirebaseUserToken();

  const { data } = await axios.get<USAPACSUser>("/me/details", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return data;
};

export interface getUserBusinessesResponse {
  businesses: PointOfInterest[];
  hasMore: boolean;
  nextPage?: number;
}

export const getUserBusinesses = async (
  page: number = 0
): Promise<getUserBusinessesResponse> => {
  const token = await getFirebaseUserToken();

  const { data } = await axios.get<getUserBusinessesResponse>(
    `/me/businesses?page=${page}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return data;
};

export const getUserClaims = async (): Promise<PointOfInterestClaim[]> => {
  const token = await getFirebaseUserToken();

  const { data } = await axios.get<PointOfInterestClaim[]>("/me/claims", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return data;
};

export const updateUserDetails = async (
  firstName: string,
  lastName: string
): Promise<USAPACSUser> => {
  const token = await getFirebaseUserToken();

  const { data } = await axios.post<USAPACSUser>(
    "/me/details",
    {
      firstName,
      lastName,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return data;
};

export interface ServerErrorMessage {
  message: string;
}

export interface LeosaRequestInput {
  department: string;
  unit: string;
  rank: string;
  frontPhoto: File;
  backPhoto: File;
}

export const verifyLeosa = async (leosaInput: any): Promise<boolean> => {
  const token = await getFirebaseUserToken();

  const formData = new FormData();

  const fields = ["department", "unit", "rank", "frontPhoto", "backPhoto"];

  fields.forEach((field) => {
    formData.append(field, leosaInput[field] ?? "");
  });

  const { data } = await axios.post<LeosaRequest>(
    "/me/leosa-request",
    formData,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (!data) {
    return false;
  }

  return true;
};

export const SubmitSupportForm = async (values: SupportFormValues) => {
  const { data } = await axios.post<any>("/usapacs/support-form", {
    name: values.firstName + " " + values.lastName,
    email: values.contactEmail,
    message: values.message,
    topic: values.topic,
  });

  if (!data || data.message) {
    throw Error("Could not submit form");
  }

  return true;
};
