import { BannerAdvertisement } from "../../../ts/banner-advertisement";
import { BannerAdPlan } from "../../../ts/banner-advertisement-plan";
import { SpecialOfferPlan } from "../../../ts/special-offer-plan";
import { getFirebaseUserToken } from "../../firebase/firebase";
import SentryHandler from "../../sentry/sentry";

import axios from "../axios-instance";

interface CreateBannerAdPaymentIntentResponse {
  clientSecret: string;
  plan: BannerAdPlan;
  paymentIntentAmount: number;
  message?: string;
}

export const createBannerAdPaymentIntent = async (
  bannerAdUId: string,
  planUId: string
): Promise<CreateBannerAdPaymentIntentResponse> => {
  try {
    const { data } = await axios.post<CreateBannerAdPaymentIntentResponse>(
      "/banner-ad/web/payment-intent",
      {
        bannerAdUId,
        planUId,
      }
    );

    if (data.message) {
      throw Error(data.message);
    }

    return data;
  } catch (e: any) {
    SentryHandler.reportException(e);
    if (e.response?.data?.message) {
      throw Error(e.response.data.message);
    } else {
      throw Error("Invalid payment link.");
    }
  }
};

interface CreateSpecialOfferPaymentIntentResponse {
  clientSecret: string;
  plan: SpecialOfferPlan;
  paymentIntentAmount: number;
  message?: string;
}

export const createSpecialOfferPaymentIntent = async (
  businessOfferUId: string,
  planUId: string
): Promise<CreateSpecialOfferPaymentIntentResponse> => {
  try {
    const { data } = await axios.post<CreateSpecialOfferPaymentIntentResponse>(
      "/business-offer/web/payment-intent",

      {
        businessOfferUId,
        planUId,
      }
    );

    if (data.message) {
      throw Error(data.message);
    }

    return data;
  } catch (e: any) {
    SentryHandler.reportException(e);
    if (e.response?.data?.message) {
      throw Error(e.response.data.message);
    } else {
      throw Error("Invalid payment link.");
    }
  }
};
