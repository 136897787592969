import React from "react";
import { useNavigate } from "react-router-dom";
import BackButton from "../../components/back-button/back-button";
import Referral from "../../components/referral/referral";
import {
  ReferralPageContainer,
  ReferralSectionWrapper,
} from "./styled/referral-page-styled";

const ReferralPage = () => {
  const navigate = useNavigate();
  return (
    <ReferralPageContainer>
      <BackButton handleBack={() => navigate("/menu")} />
      <ReferralSectionWrapper>
        <Referral />
      </ReferralSectionWrapper>
    </ReferralPageContainer>
  );
};

export default ReferralPage;
