import { consoleSandbox } from "@sentry/utils";
import { useMutation, useQueryClient } from "react-query";
import { updateSpecialOfferDetails } from "../../../lib/api/special-offer";
import { SpecialOffer } from "../../../ts/special-offer";

export const useUpdateDetailsMutation = (uid?: string) => {
  const client = useQueryClient();
  const mutation = useMutation(
    ["/business-offer", uid],
    (values: {
      id: number;
      newTitle: string;
      newDescription: string;
      newRedeemMethod: string;
    }) =>
      updateSpecialOfferDetails(
        values.id,
        values.newTitle,
        values.newDescription,
        values.newRedeemMethod
      ),
    {
      onSuccess: (data) => {
        client.setQueryData<SpecialOffer | undefined>(
          ["/business-offer", uid],
          (old) => {
            if (old) {
              old.title = data.title;
              old.description = data.description;
              old.redeemMethod = data.redeemMethod;
              old.status = data.status;
            }

            return old;
          }
        );
      },
      onMutate: (values) => {
        let previous: any = undefined;
        client.setQueryData<SpecialOffer | undefined>(
          ["/business-offer", uid],
          (old) => {
            if (old) {
              previous = {
                title: old.title,
                description: old.description,
                redeemMethod: old.redeemMethod,
              };
              old.title = values.newTitle;
              old.description = values.newDescription;
              old.redeemMethod = values.newRedeemMethod;
            }

            return old;
          }
        );

        return previous;
      },
      onError: (error, values, context: any) => {
        client.setQueryData<SpecialOffer | undefined>(
          ["/business-offer", uid],
          (old) => {
            if (old) {
              old.title = context.title;
              old.description = context.description;
              old.redeemMethod = context.redeemMethod;
            }

            return old;
          }
        );
      },
    }
  );

  return mutation;
};
