import { withFormik } from "formik";
import useAuth from "../../../lib/custom-hooks/useAuth";

import LoginForm, { LoginFormValues } from "../components/login-form";
import { loginSchema } from "./loginValidation";
import { useNavigate } from "react-router";
import { loginFirebase } from "../../../lib/firebase/firebase";
import { FirebaseError } from "@firebase/util";
import { AuthErrorCodes } from "@firebase/auth";

export interface LoginFormProps {}

export const CreateLoginForm = () => {
  const { authHandler } = useAuth();
  const navigate = useNavigate();
  const Form = withFormik<LoginFormProps, LoginFormValues>({
    handleSubmit: async (values, props) => {
      try {
        await loginFirebase(values.email, values.password);
        authHandler.login();
        navigate("/");
      } catch (e) {
        if (e instanceof FirebaseError) {
          if (
            e.code === AuthErrorCodes.USER_DELETED ||
            e.code === AuthErrorCodes.INVALID_PASSWORD
          ) {
            props.setFieldError("email", "Email/Password not found.");
          } else if (e.code === AuthErrorCodes.TOO_MANY_ATTEMPTS_TRY_LATER) {
            props.setFieldError("email", "Too many attempts. Try again later.");
          } else {
            props.setFieldError(
              "email",
              "Could not verify email/password at this time."
            );
          }
        } else {
          props.setFieldError("email", "Could not login at this time.");
        }
      }
    },
    validationSchema: loginSchema,
    validateOnBlur: false,
    validateOnChange: false,
    validateOnMount: false,
    mapPropsToValues: () => ({
      email: "",
      password: "",
    }),
  })(LoginForm);

  return <Form />;
};
