import { InfiniteData, useMutation, useQueryClient } from "react-query";
import BackButton from "../../components/back-button/back-button";
import BusinessInfoHeader from "../../components/business-info-header/business-info-header";
import IconHandler from "../../components/icon-handler/icon-handler";
import PolicyUpdateSection from "../../components/policy-update-section/policy-update-section";
import { updatePointOfInterestPolicy } from "../../lib/api/points-of-interest";
import { getUserBusinessesResponse } from "../../lib/api/user";
import { SecondaryButton } from "../../styled/buttons";
import { ErrorIcon, ErrorIconContainer } from "../../styled/status-icons";
import { PointOfInterest } from "../../ts/point-of-interest";

import {
  BusinessPoliciesWrapper,
  BusinessSectionWrapper,
  ContentWrapper,
  UserBusinessPageContainer,
} from "./styled/user-business-page-styled";

interface UserBusinessPageProps {
  pointOfInterest: PointOfInterest;
  placeId: string;
  handleBack: () => void;
  handlePhotoClick: () => void;
}

const UserBusinessPage = (props: UserBusinessPageProps) => {
  const client = useQueryClient();

  const { mutate, isError, reset, isLoading } = useMutation(
    ["/poi/update-policy", props.placeId],
    (values: { policy: string; policyValue: number }) =>
      updatePointOfInterestPolicy(
        props.pointOfInterest.id,
        values.policy,
        values.policyValue
      ),
    {
      onSuccess: (data, variables) => {},
      onError: (error, variables, context: any) => {
        if (context.previousData) {
          client.setQueryData("/me/businesses", (old) => {
            try {
              let snapshot = context.previousData as PointOfInterest;

              let cacheData = old as InfiniteData<getUserBusinessesResponse>;

              if (!cacheData) {
                return old;
              }

              let item: PointOfInterest | undefined;
              cacheData.pages.forEach((page) => {
                let found = page.businesses.find(
                  (a) => a.id === props.pointOfInterest.id
                );
                if (found) {
                  item = found;
                }
              });

              if (!item) {
                return old;
              }

              if (variables.policy === "leosaPolicy") {
                item.leosaPolicy = snapshot.leosaPolicy;
              }
              if (variables.policy === "openCarryPolicy") {
                item.openCarryPolicy = snapshot.openCarryPolicy;
              }
              if (variables.policy === "concealedCarryPolicy") {
                item.concealedCarryPolicy = snapshot.concealedCarryPolicy;
              }
              if (variables.policy === "metalDetectorPolicy") {
                item.metalDetector = snapshot.metalDetector;
              }
              if (variables.policy === "menuPolicy") {
                item.menuPolicy = snapshot.menuPolicy;
              }

              return cacheData;
            } catch (e) {}
          });
        }
      },
      onMutate: async (variables) => {
        await client.cancelQueries("/me/businesses");

        let snapshot: PointOfInterest | undefined;

        client.setQueriesData(["/me/businesses"], (old) => {
          try {
            let cacheData = old as InfiniteData<getUserBusinessesResponse>;

            if (!cacheData) {
              return old;
            }

            let item: PointOfInterest | undefined;
            cacheData.pages.forEach((page) => {
              let found = page.businesses.find(
                (a) => a.id === props.pointOfInterest.id
              );
              if (found) {
                item = found;
              }
            });

            if (!item) {
              return old;
            }

            snapshot = { ...item };

            if (variables.policy === "leosaPolicy") {
              item.leosaPolicy = variables.policyValue;
            }
            if (variables.policy === "openCarryPolicy") {
              item.openCarryPolicy = variables.policyValue;
            }
            if (variables.policy === "concealedCarryPolicy") {
              item.concealedCarryPolicy = variables.policyValue;
            }
            if (variables.policy === "metalDetectorPolicy") {
              item.metalDetector = variables.policyValue;
            }
            if (variables.policy === "menuPolicy") {
              item.menuPolicy = variables.policyValue;
            }

            return cacheData;
          } catch (e) {}
        });
        return { previousData: snapshot };
      },
    }
  );

  const handleChange = (policy: string, policyValue: number) => {
    mutate({
      policy,
      policyValue,
    });
  };

  if (isError) {
    return (
      <ErrorIconContainer style={{ flexDirection: "column" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <ErrorIcon>!</ErrorIcon>
          <p style={{ marginLeft: 10, color: "red" }}>
            Could not update policy at this time. Please try again later.
          </p>
        </div>
        <SecondaryButton style={{ width: 150 }} onClick={() => reset()}>
          Back
        </SecondaryButton>
      </ErrorIconContainer>
    );
  }

  return (
    <UserBusinessPageContainer>
      <BackButton handleBack={() => props.handleBack()} />
      <ContentWrapper>
        <BusinessSectionWrapper style={{ marginTop: 20 }}>
          <BusinessInfoHeader
            handlePhotoClick={props.handlePhotoClick}
            businessName={props.pointOfInterest.name}
            businessAddress={props.pointOfInterest.formattedAddress}
            businessPrimaryPhotoUrl={
              props.pointOfInterest.businessPhotos &&
              props.pointOfInterest.businessPhotos.length > 0
                ? props.pointOfInterest!.businessPhotos[0].imageUrl
                : undefined
            }
          />
        </BusinessSectionWrapper>
        <BusinessPoliciesWrapper>
          <PolicyUpdateSection
            text={
              "We allow armed Law Enforcement: on duty, off duty, retired as per LEOSA"
            }
            policyType={"leosaPolicy"}
            currentAlignment={props.pointOfInterest.leosaPolicy}
            handleClick={(alignment) => handleChange("leosaPolicy", alignment)}
            values={[1, 2]}
            disabled={isLoading}
          />
        </BusinessPoliciesWrapper>
        <BusinessPoliciesWrapper>
          <PolicyUpdateSection
            text={"We allow Right to Carry citizens to carry concealed"}
            policyType={"concealedCarryPolicy"}
            currentAlignment={props.pointOfInterest.concealedCarryPolicy}
            handleClick={(alignment) =>
              handleChange("concealedCarryPolicy", alignment)
            }
            values={[1, 2]}
            disabled={isLoading}
          />
        </BusinessPoliciesWrapper>
        <BusinessPoliciesWrapper>
          <PolicyUpdateSection
            text={"We allow Open Carry"}
            policyType={"openCarryPolicy"}
            currentAlignment={props.pointOfInterest.openCarryPolicy}
            handleClick={(alignment) =>
              handleChange("openCarryPolicy", alignment)
            }
            values={[1, 2]}
            disabled={isLoading}
          />
        </BusinessPoliciesWrapper>
        <BusinessPoliciesWrapper>
          <PolicyUpdateSection
            text={"My establishment requires a metal detector to enter"}
            policyType={"metalDetectorPolicy"}
            currentAlignment={props.pointOfInterest.metalDetector}
            handleClick={(alignment) =>
              handleChange("metalDetectorPolicy", alignment)
            }
            values={[1, 0]}
            disabled={isLoading}
          />
        </BusinessPoliciesWrapper>
        <BusinessPoliciesWrapper>
          <PolicyUpdateSection
            text={"My menu contains 51% or more alcoholic beverages"}
            policyType={"menuPolicy"}
            currentAlignment={props.pointOfInterest.menuPolicy}
            handleClick={(alignment) => handleChange("menuPolicy", alignment)}
            values={[1, 0]}
            disabled={isLoading}
          />
        </BusinessPoliciesWrapper>
      </ContentWrapper>
    </UserBusinessPageContainer>
  );
};

export default UserBusinessPage;
