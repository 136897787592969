import styled from "styled-components";
import { mobileMax } from "../../../lib/media-breakpoints";

export const BackButtonContainer = styled.div`
  height: 80px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: center;
  @media (max-width: ${mobileMax}px) {
    width: 100%;
  }

  @media (min-width: ${mobileMax + 1}px) {
    width: 550px;
  }

  svg {
    margin-top: 55px;

    @media (max-width: ${mobileMax}px) {
      margin-left: 41px;
      margin-right: 41px;
    }

    @media (min-width: ${mobileMax + 1}px) {
    }
  }
`;
