import styled from "styled-components";
import { mobileMax } from "../../../lib/media-breakpoints";
import { applyBoxShadow } from "../../../styled/box-shadow";

export const FormContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  align-items: center;

  @media (max-width: ${mobileMax}px) {
    align-items: stretch;
  }
`;

export const FieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  :first-child {
    margin-top: 64px;
  }

  :not(:first-child) {
    margin-top: 20px;
  }

  @media (max-width: ${mobileMax}px) {
    margin-left: 25px;
    margin-right: 25px;
  }

  @media (min-width: ${mobileMax + 1}px) {
    width: 500px;
  }

  input {
    text-indent: 10px;
    border: none;
    border-radius: 3px;
    flex: 0 0 42px;

    @media (max-width: ${mobileMax}px) {
      flex: 0 0 35px;
      font-size: 14px;
    }

    ${applyBoxShadow()}
  }

  span.error-message {
    color: red;
    font-weight: 700;
    margin-bottom: 7px;

    @media (max-width: ${mobileMax}px) {
      font-size: 14px;
    }
    @media (min-width: ${mobileMax + 1}px) {
      font-size: 16px;
    }
  }
`;
export const FormLabel = styled.label.attrs((props: { htmlFor: string }) => ({
  htmlFor: props.htmlFor,
}))`
  color: #1b3963;
  font-weight: 400;
  margin-bottom: 7px;

  @media (max-width: ${mobileMax}px) {
    font-size: 14px;
  }
  @media (min-width: ${mobileMax + 1}px) {
    font-size: 16px;
  }
`;

export const SubmitButton = styled.button.attrs(() => ({
  type: "submit",
}))<{ active: boolean }>`
  margin-top: 36px;
  margin-bottom: 40px;
  color: white;
  font-size: 12px;
  flex: 0 0 42px;
  width: 147px;
  border: none;
  height: 40px;

  border-radius: 3px;

  @media (max-width: ${mobileMax}px) {
    align-self: center;
  }

  ${applyBoxShadow()}

  background: ${(props) => (props.active ? "#52467C" : "#7B7B7B")}
`;

export const ErrorMessage = styled.span`
  color: red;
`;

export const Disclaimer = styled.span`
  font-size: 13px;
  color: #1b3963;
  margin-top: 10px;
`;
export const InputButton = styled.button.attrs(() => ({
  type: "button",
}))<{ active: boolean }>`
  border: solid #516581 2px;
  flex: 0 0 75px;
  height: 30px;
  font-size: 10px;
  border-radius: 3px;
  background: ${(props) => (props.active ? "#52467C" : "none")};
  color: ${(props) => (props.active ? "white" : "#1B3963")};
`;
