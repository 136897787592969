import CardRow from "./components/card-row";
import {
  CardsTableContainer,
  TableSection,
  TableTitle,
} from "./styled/cards-table-styled";

type datapiece = { [key: string]: any };

export interface CardsTableProps {
  title?: string;
  data: {
    [key: string]: any;
  }[];
  dataKeys: {
    titleKey: string;
    subTitleKey: string;
  };

  handleRowClick: (data: any) => void;
}

const CardsTable = (props: CardsTableProps) => {
  return (
    <CardsTableContainer>
      {props.title ? <TableTitle>{props.title}</TableTitle> : null}
      <TableSection>
        {props.data.map((row) => (
          <CardRow
            onClick={() => props.handleRowClick(row)}
            key={row.id}
            title={row[props.dataKeys.titleKey]}
            subtitle={row[props.dataKeys.subTitleKey]}
          />
        ))}
      </TableSection>
    </CardsTableContainer>
  );
};

export default CardsTable;
