import styled from "styled-components";

export const ArticleHeaderContainer = styled.div`
  display: flex;
`;

export const LeftSide = styled.div``;

export const ArticleTitle = styled.div`
  color: #1b3963;
  font-size: 16px;
`;

export const ArticleDate = styled.div`
  color: #8c8c8c;
  font-size: 13px;
  margin-top: 11px;
`;

export const RightSide = styled.div`
  margin-left: auto;
`;

export const BookmarkButton = styled.button`
  border: none;
  background: none;
`;
