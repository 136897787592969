import { useInfiniteQuery } from "react-query";
import CardsTable from "../../components/cards-table/cards-table";
import IconHandler from "../../components/icon-handler/icon-handler";
import { getUserBusinesses } from "../../lib/api/user";

import { Loader } from "../../styled/loader";
import { PointOfInterest } from "../../ts/point-of-interest";

import {
  UserBusinessesPageContainer,
  TableWrapper,
  LoadMoreButton,
} from "./styled/user-businesses-page-styled";

export interface UserBusinessesPageProps {
  handleTransition?: (poi: PointOfInterest) => void;
  handleBack?: () => void;
}

const UserBusinessesPage = (props: UserBusinessesPageProps) => {
  const {
    data,
    isLoading,
    isFetching,
    isError,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery(
    ["/me/businesses"],
    ({ pageParam = 0 }) => getUserBusinesses(pageParam),
    {
      getNextPageParam: (lastPage) => lastPage.nextPage,
    }
  );

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return (
      <p>Could not get information at this time. Please try again later.</p>
    );
  }

  if (!data) {
    return (
      <p>Could not get information at this time. Please try again later.</p>
    );
  }

  const rows: PointOfInterest[] = [];

  data.pages.forEach((page) =>
    // page.businesses.forEach((poi) => rows.push(poi))
    rows.push(...page.businesses)
  );

  return (
    <UserBusinessesPageContainer>
      {props.handleBack ? (
        <div
          style={{ marginTop: 30, marginLeft: 20, cursor: "pointer" }}
          onClick={() => props.handleBack!()}
        >
          <IconHandler name="chevronLeft" iconProps={() => {}} />
        </div>
      ) : null}
      <TableWrapper>
        <CardsTable
          handleRowClick={(data) => {
            if (props.handleTransition) {
              props.handleTransition(data);
            }
          }}
          data={rows}
          dataKeys={{
            titleKey: "name",
            subTitleKey: "formattedAddress",
          }}
        />
      </TableWrapper>
      {hasNextPage ? (
        <LoadMoreButton onClick={() => fetchNextPage()} inActive={isFetching}>
          LOAD MORE
        </LoadMoreButton>
      ) : null}
    </UserBusinessesPageContainer>
  );
};

export default UserBusinessesPage;
