import { useState } from "react";
import { AuthErrorCodes, confirmPasswordReset } from "@firebase/auth";
import { CreateSetPasswordForm } from "./util/createForm";
import { getFirebaseAuth } from "../../lib/firebase/firebase";
import { FirebaseError } from "@firebase/util";
import { useNavigate } from "react-router";

const ResetPasswordForm = ({ oobCode }: { oobCode: string }) => {
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async (newPassword: string) => {
    try {
      const auth = getFirebaseAuth();
      await confirmPasswordReset(auth, oobCode, newPassword);
      navigate("/login");
    } catch (e) {
      if (e instanceof FirebaseError) {
        if (e.code === AuthErrorCodes.WEAK_PASSWORD) {
          setError(
            "Password must be between 6-24 characters. Letters, numbers, and symbols only."
          );
        } else if (e.code === AuthErrorCodes.INVALID_OOB_CODE) {
          setError("This link has expired. Please reset your password again");
        } else {
          setError("Could not set password at this time.");
        }
      }
    }
  };
  return (
    <div>
      <CreateSetPasswordForm onSubmit={handleSubmit} />
      {error !== null ? (
        <p style={{ color: "red", textAlign: "center" }}>{error}</p>
      ) : null}
    </div>
  );
};

export default ResetPasswordForm;
