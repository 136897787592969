import { ErrorMessage, Field, FormikProps } from "formik";
import {
  Disclaimer,
  FieldsWrapper,
  FormContainer,
  FormLabel,
  InputButton,
  SubmitButton,
} from "../styled/global-styled";

import * as Yup from "yup";

import { checkUsernameExists } from "../../../lib/api/auth";
import { StepTwoFormProps } from "../utils/stepTwoFormikContext";
import { LoadingOverlay } from "../../../pages/Dashboard/styled/loading-overlay";

export interface StepTwoFormValues {
  firstName: string;
  lastName: string;
  username: string;
  referralCode?: string;
  isLeosa?: boolean;
}

export const StepTwoSchema: Yup.SchemaOf<StepTwoFormValues> = Yup.object({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  username: Yup.string()
    .required("Username is required")
    .matches(/^[a-zA-Z\_\-0-9]{6,64}$/, {
      excludeEmptyString: false,
      message:
        "Please enter a valid username. Must be at least 6 characters. Letters, numbers, dashes, and underscores only.",
    })
    .test("usernameExists", async function (value) {
      try {
        if (!value) {
          return false;
        }

        try {
          const usernameExists = await checkUsernameExists(value);

          if (usernameExists) {
            return this.createError({
              message: usernameExists,
              path: "username",
            });
          }

          return true;
        } catch (e) {
          return false;
        }
      } catch (e) {
        return false;
      }
    }),
  referralCode: Yup.string(),
  isLeosa: Yup.boolean().required("Please select an option"),
});

const stepTwoActiveSchema: Yup.SchemaOf<StepTwoFormValues> = Yup.object({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  username: Yup.string().required("Username is required"),
  referralCode: Yup.string(),
  isLeosa: Yup.boolean().required("Please select an option"),
});

const StepTwoForm = (
  props: StepTwoFormProps & FormikProps<StepTwoFormValues>
) => {
  const { values, handleSubmit, setFieldValue, isSubmitting } = props;

  let canSubmit = false;
  try {
    stepTwoActiveSchema.validateSync(values);
    canSubmit = true;
  } catch (e) {
    canSubmit = false;
  }

  return (
    <form onSubmit={handleSubmit}>
      <FormContainer>
        <FieldsWrapper>
          <FormLabel htmlFor="firstName">Name (First)</FormLabel>
          <ErrorMessage
            name="firstName"
            component="span"
            className="error-message"
          />
          <Field type="text" name="firstName" />
        </FieldsWrapper>
        <FieldsWrapper>
          <FormLabel htmlFor="lastName">Name (Last)</FormLabel>
          <ErrorMessage
            name="lastName"
            component="span"
            className="error-message"
          />
          <Field type="text" name="lastName" />
        </FieldsWrapper>
        <FieldsWrapper>
          <FormLabel htmlFor="username">Username</FormLabel>
          <ErrorMessage
            name="username"
            component="span"
            className="error-message"
          />
          <Field type="text" name="username" />
          <Disclaimer>
            *Note: Username cannot be updated once created
          </Disclaimer>
        </FieldsWrapper>
        <FieldsWrapper>
          <FormLabel htmlFor="referralCode">Referral Code</FormLabel>
          <ErrorMessage
            name="referralCode"
            component="span"
            className="error-message"
          />
          <Field type="text" name="referralCode" />
        </FieldsWrapper>
        <FieldsWrapper style={{ alignItems: "center", textAlign: "center" }}>
          <label style={{ color: "#1B3963", fontSize: 16 }}>
            Are you an active or retired member of <br />
            Law Enforcement?
          </label>
          <ErrorMessage
            name="isLeosa"
            component="span"
            className="error-message"
          />
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              width: 200,
              marginTop: 20,
            }}
          >
            <InputButton
              onClick={() => {
                setFieldValue("isLeosa", true);
              }}
              active={values.isLeosa === true}
            >
              YES
            </InputButton>
            <InputButton
              onClick={() => {
                setFieldValue("isLeosa", false);
              }}
              active={values.isLeosa === false}
            >
              NO
            </InputButton>
          </div>
        </FieldsWrapper>

        <SubmitButton
          disabled={isSubmitting || props.disabled === true}
          active={!isSubmitting && !props.disabled}
        >
          CREATE ACCOUNT
        </SubmitButton>
      </FormContainer>
    </form>
  );
};

export default StepTwoForm;
