import React from "react";

export interface RemoteImageProps {
  src: string;
  alt: string;
}

const RemoteImage = (props: RemoteImageProps) => {
  return <img src={props.src} alt={props.alt} width={"100%"} height={"100%"} />;
};

export default RemoteImage;
