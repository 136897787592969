import ConcealedNegative from "./image-components/badges/concealedCarry/concealed-negative";
import ConcealedNeutral from "./image-components/badges/concealedCarry/concealed-neutral";
import ConcealedPositive from "./image-components/badges/concealedCarry/concealed-positive";
import LeosaBlue from "./image-components/badges/leosa/leosa-blue";
import LeosaGrey from "./image-components/badges/leosa/leosa-grey";
import LeosaRed from "./image-components/badges/leosa/leosa-red";
import MenuPolicyNeutral from "./image-components/badges/menu/menuPolicy-neutral";
import MenuPolicyPositive from "./image-components/badges/menu/menuPolicy-positive";
import MetalDetectorNeutral from "./image-components/badges/metalDetector/metal-detector-neutral";
import MetalDetectorPositive from "./image-components/badges/metalDetector/metal-detector-positive";
import OpenCarryNegative from "./image-components/badges/openCarry/open-carry-negative";
import OpenCarryNeutral from "./image-components/badges/openCarry/open-carry-neutral";
import OpenCarryPositive from "./image-components/badges/openCarry/open-carry-positive";
import Logo from "./image-components/logo";
import LogoFull from "./image-components/logo-full";
import MissingPageImage from "./image-components/missing-page-image";
import SearchImage from "./image-components/searchImage";
import SpecialOfferImage from "./image-components/specialOffer";

const ImageHandler = ({
  name,
  imageProps,
}: {
  name: string;
  imageProps?: any;
}) => {
  const Images: any = {
    logo: <Logo />,
    logofull: <LogoFull />,
    specialOffers: <SpecialOfferImage imageProps={imageProps} />,
    search: <SearchImage imageProps={imageProps} />,
    "leosaPolicy-neutral": <LeosaGrey imageProps={imageProps} />,
    "leosaPolicy-negative": <LeosaRed imageProps={imageProps} />,
    "leosaPolicy-positive": <LeosaBlue imageProps={imageProps} />,
    "concealedCarryPolicy-positive": (
      <ConcealedPositive imageProps={imageProps} />
    ),
    "concealedCarryPolicy-neutral": (
      <ConcealedNeutral imageProps={imageProps} />
    ),
    "concealedCarryPolicy-negative": (
      <ConcealedNegative imageProps={imageProps} />
    ),
    "openCarryPolicy-positive": <OpenCarryPositive imageProps={imageProps} />,
    "openCarryPolicy-neutral": <OpenCarryNeutral imageProps={imageProps} />,
    "openCarryPolicy-negative": <OpenCarryNegative imageProps={imageProps} />,
    "metalDetectorPolicy-positive": (
      <MetalDetectorPositive imageProps={imageProps} />
    ),
    "metalDetectorPolicy-neutral": (
      <MetalDetectorNeutral imageProps={imageProps} />
    ),
    "menuPolicy-positive": <MenuPolicyPositive imageProps={imageProps} />,
    "menuPolicy-neutral": <MenuPolicyNeutral imageProps={imageProps} />,
    missingPage: <MissingPageImage imageProps={imageProps} />,
  };

  if (!Images[name]) {
    return null;
  }

  return Images[name];
};

export default ImageHandler;
