import { applyActionCode, AuthErrorCodes } from "@firebase/auth";
import { FirebaseError } from "@firebase/util";
import { useEffect, useState } from "react";
import { getFirebaseAuth } from "../../lib/firebase/firebase";
import ImageHandler from "../image-handler/image-handler";
import { LogoContainer } from "../register/styled/register-styled";
import EmailConfirmationDisplay from "./components/email-confirmation-display";

const EmailConfirmation = ({ oobCode }: { oobCode: string }) => {
  const [verified, setVerified] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const verifyEmail = async () => {
    try {
      const auth = getFirebaseAuth();
      await applyActionCode(auth, oobCode);
      setVerified(true);
    } catch (e) {
      if (e instanceof FirebaseError) {
        if (e.code === AuthErrorCodes.INVALID_OOB_CODE) {
          setError("Verification Link has expired. Please initiate another.");
          return;
        } else {
          setError("Could not verify email at this time.");
          return;
        }
      } else {
        setError("Could not verify email at this time.");
      }
    }
  };

  useEffect(() => {
    verifyEmail();
  }, []);

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <LogoContainer style={{ marginBottom: 20 }}>
        <div style={{ marginTop: "auto" }}>
          <ImageHandler name="logo" />
        </div>
      </LogoContainer>
      {error === null ? <EmailConfirmationDisplay verified={verified} /> : null}
      {error !== null ? (
        <p style={{ color: "red", textAlign: "center" }}>{error}</p>
      ) : null}
    </div>
  );
};

export default EmailConfirmation;
