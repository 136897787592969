import styled from "styled-components";

export const Content = styled.div`
  height: 100%;
  margin-left: 30px;
`;

export const Wrapper = styled.div`
  height: 100%;
`;
