import { useContext, useEffect } from "react";
import { useQuery, useQueryClient } from "react-query";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import ViewBusiness from "../../../components/view-business/view-business";
import { getPointOfInterest } from "../../../lib/api/points-of-interest";
import { Loader } from "../../../styled/loader";
import { PointOfInterest } from "../../../ts/point-of-interest";
import {
  BusinessViewContainer,
  LoaderContainer,
} from "../styled/business-view-styled";
import {
  ActiveMarkerContext,
  MapContext,
  MarkersContext,
  QueryValueContext,
} from "../util/contexts";
import { MarkerData } from "./Map.tsx/MapView";

const BusinessView = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { queryInfo } = useContext(QueryValueContext);
  const { placeId } = useParams();
  const { map } = useContext(MapContext);
  const { markers, setMarkers } = useContext(MarkersContext);
  const { activeMarker, setActiveMarker } = useContext(ActiveMarkerContext);

  const { data, isLoading, refetch } = useQuery<PointOfInterest>(
    ["/poi", placeId],
    () => getPointOfInterest(placeId ?? ""),
    {
      staleTime: Infinity,
      refetchIntervalInBackground: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        //if poi is not in existing markers, add to map
        const el = markers.find((a) => a.placeId === placeId);
        if (!el) {
          const newMarker: MarkerData = {
            placeId: data.placeId,
            pointOfInterest: data,
            lat: data.lat,
            lng: data.lng,
          };
          //move map as well
          map?.panTo({
            lat: data.lat,
            lng: data.lng,
          });
          map?.setZoom(15);
          setMarkers([...markers, newMarker]);
          setActiveMarker(placeId);
        } else {
          setActiveMarker(placeId);
        }
      },
      // initialData: () => {
      //   return queryClient
      //     .getQueryData<PointOfInterest[]>(["/poi/search", queryInfo])
      //     ?.find((a) => a.placeId === placeId);
      // },
    }
  );

  const handlePostBadgeSuccess = (newBadgeAlignments: any) => {
    // refetch();
    // queryClient.set
  };

  useEffect(() => {
    //if active marker isn't current place id, set to
    try {
      if (activeMarker !== placeId && setActiveMarker) {
        setActiveMarker(placeId);
      }
    } catch (e) {}
  }, [placeId, markers, activeMarker, setActiveMarker]);

  if (!placeId) {
    return <Navigate to="/home" />;
  }

  if (isLoading) {
    return (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    );
  }

  if (!data) {
    return null;
  }

  return (
    <BusinessViewContainer>
      <ViewBusiness
        onPostBadgeSuccess={handlePostBadgeSuccess}
        pointOfInterest={data}
        handleClose={() => {
          if (setActiveMarker) {
            setActiveMarker(undefined);
          }
          navigate("/home");
        }}
      />
    </BusinessViewContainer>
  );
};

export default BusinessView;
