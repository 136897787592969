import { ArticleCategory } from "../../ts/article";
import ArticleCategoryCard from "../article-category-card/article-category-card";

import {
  ArticleCategoryTableContainer,
  CardWrapper,
} from "./styled/article-category-table-styled";

interface ArticleCategoryTableProps {
  categories: ArticleCategory[];
  onRowClick: (categoryId: number) => void;
}

const ArticleCategoryTable = (props: ArticleCategoryTableProps) => {
  return (
    <ArticleCategoryTableContainer>
      {props.categories.map((category) => (
        <CardWrapper
          key={category.id}
          onClick={() => props.onRowClick(category.id)}
        >
          <ArticleCategoryCard
            isLeosa={category.leosaOnly}
            label={category.name}
            imageUrl={category.imageUrl!}
          />
        </CardWrapper>
      ))}
    </ArticleCategoryTableContainer>
  );
};

export default ArticleCategoryTable;
