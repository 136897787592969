import styled from "styled-components";

export const ResultsTableMobileHeaderContainer = styled.div``;

export const ResultsTableQueryDisplay = styled.div`
  margin: 17px 42px 5px 16px;
  color: #000000;
  font-weight: 400;
  font-size: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const ResultsClearButton = styled.div`
  cursor: pointer;
  position: absolute;
  top: 18px;
  right: 15px;
  width: 24px;
  height: 24px;
`;

export const ResultsFoundDisplay = styled.div`
  color: #8c8c8c;
  font-weight: 400;
  font-size: 14px;
  margin: 0 16px 10px 18px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
