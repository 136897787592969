import { useQuery } from "react-query";
import { getBannerAd } from "../../../lib/api/banner-ad";

export const useDataQuery = (uid?: string) => {
  const query = useQuery(
    ["/banner-advertisement", uid],
    () => getBannerAd(uid ?? ""),
    {
      onError: () => {},
    }
  );

  return query;
};
