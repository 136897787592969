import styled from "styled-components";
import { mobileMax } from "../../../lib/media-breakpoints";

export const UpdatePasswordPageContainer = styled.div``;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 70px;

  @media (max-width: ${mobileMax}px) {
    margin: 0 20px;
  }
`;
