import React from "react";
import IconHandler from "../../icon-handler/icon-handler";
import { BackButtonContainer } from "../styled/back-button-styled";

const BackButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <BackButtonContainer>
      <IconHandler
        name={"chevronLeft"}
        iconProps={{
          onClick: () => {
            onClick();
          },
        }}
      />
    </BackButtonContainer>
  );
};

export default BackButton;
