import styled from "styled-components";
export const PolicyUpdateSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PolicyUpdateText = styled.p`
  color: #244169;
  font-size: 16px;
`;

export const ButtonSection = styled.div`
  display: flex;
`;
