import styled from "styled-components";
import { mobileMax } from "../../../lib/media-breakpoints";

export const SearchViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  flex: 1;
  overflow-y: auto;

  @media (max-width: ${mobileMax}px) {
    display: flex;
    flex-direction: column;

    align-items: stretch;
  }
`;

export const ResultsContainer = styled.div`
  margin-bottom: 100px;

  @media (min-width: ${mobileMax + 1}px) {
    margin-top: 40px;
  }
`;

export const SearchInputWrapper = styled.div`
  @media (min-width: ${mobileMax + 1}px) {
    margin-right: 4em;
  }
`;

export const SearchbarWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 10px;
  height: 45px;
`;

export const CategoriesSectionWrapper = styled.div`
  @media (min-width: ${mobileMax + 1}px) {
    margin-top: 30px;
  }
  @media (max-width: ${mobileMax}px) {
    margin-bottom: 120px;
  }
`;

export const Instructions = styled.p`
  margin: 0px 20px 30px 20px;
  color: #ababab;
  font-size: 16px;
  text-align: center;

  @media (min-width: ${mobileMax + 1}px) {
    margin-top: 20px;
  }
`;
