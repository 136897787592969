import { Navigate, Route, Routes } from "react-router-dom";
import AboutTheAppPage from "../AboutTheApp/AboutTheAppPage";
import BookmarksPage from "../Bookmarks/BookmarksPage";
import BulletRedeemPage from "../BulletRedeem/BulletRedeemPage";
import CategoriesPage from "../CategoriesPage/CategoriesPage";
import CurrentEventsPage from "../CurrentEvents/CurrentEventsPage";
import HomeScreen from "../HomeScreen/HomeScreen";
import HowToUseTheAppPage from "../HowToUseTheApp/HowToUseTheAppPage";
import LeosaBillPage from "../LeosaBill/LeosaBillPage";
import LocationHelpPage from "../LocationHelp/LocationHelp";
import PrivacyPage from "../PrivacyPage/PrivacyPage";
import RedeemBulletItemPage from "../RedeemBulletItem/RedeemBulletItemPage";

import ReferralPage from "../Referral/ReferralPage";
import SuggestPostPage from "../SuggestPost/SuggestPostPage";
import SupportPage from "../Support/SupportPage";
import TermsPage from "../TermsPage/TermsPage";
import ViewArticlePage from "../ViewArticle/ViewArticlePage";
import ViewArticlesPage from "../ViewArticles/ViewArticlesPage";
import ViewBulletItemPage from "../ViewBulletItem/ViewBulletItem";
import ViewSponsorsPage from "../ViewSponsors/ViewSponsors";
import MenuItemsSection from "./components/menu-section";

import {
  MainMenuPageContainer,
  RightSideWrapper,
  TitleSection,
  TitleSectionLabel,
} from "./styled/main-menu-page-styled";

const MainMenuPage = () => {
  return (
    <MainMenuPageContainer>
      <TitleSection>
        <Routes>
          <Route index element={<TitleSectionLabel>Menu</TitleSectionLabel>} />
          <Route path="/current-events">
            <Route
              path="/current-events/:categoryId"
              element={<TitleSectionLabel>News</TitleSectionLabel>}
            />
            <Route
              path=""
              element={<TitleSectionLabel>News</TitleSectionLabel>}
            />
          </Route>
          <Route
            path="/article/*"
            element={<TitleSectionLabel>News</TitleSectionLabel>}
          />
          <Route
            path="/suggest-post"
            element={<TitleSectionLabel>News</TitleSectionLabel>}
          />
          <Route
            path="/categories"
            element={<TitleSectionLabel>News</TitleSectionLabel>}
          />
          <Route
            path="/bookmarks"
            element={<TitleSectionLabel>News</TitleSectionLabel>}
          />
          <Route
            path="/ammunition"
            element={<TitleSectionLabel>Ammunition</TitleSectionLabel>}
          />
          <Route
            path="/ammunition/offers"
            element={<TitleSectionLabel>Ammunition</TitleSectionLabel>}
          />
          <Route
            path="/ammunition/offer/:offer"
            element={<TitleSectionLabel>Ammunition</TitleSectionLabel>}
          />
          <Route
            path="/ammunition/redeem/:offer"
            element={<TitleSectionLabel>Ammunition</TitleSectionLabel>}
          />
          <Route
            path="/leosa-bill"
            element={<TitleSectionLabel>LEOSA Bill</TitleSectionLabel>}
          />
          <Route
            path="/view-sponsors"
            element={<TitleSectionLabel>Sponsors</TitleSectionLabel>}
          />
          <Route
            path="/home-screen"
            element={<TitleSectionLabel>Home Screen</TitleSectionLabel>}
          />
          <Route
            path="/location-help"
            element={<TitleSectionLabel>Help with Location</TitleSectionLabel>}
          />
          <Route
            path="/about-the-app"
            element={<TitleSectionLabel>About USA PACS</TitleSectionLabel>}
          />
          <Route
            path="/how-to-use-the-app"
            element={<TitleSectionLabel>How To Use The App</TitleSectionLabel>}
          />
          <Route
            path="/terms"
            element={<TitleSectionLabel>Terms</TitleSectionLabel>}
          />
          <Route
            path="/privacy"
            element={<TitleSectionLabel>Privacy</TitleSectionLabel>}
          />
          <Route
            path="/support"
            element={<TitleSectionLabel>Support</TitleSectionLabel>}
          />
          <Route path="*" element={<Navigate to="/page-not-found" />} />
        </Routes>
      </TitleSection>
      <RightSideWrapper>
        <Routes>
          <Route index element={<MenuItemsSection />} />

          <Route path="/current-events">
            <Route
              path="/current-events/:categoryId"
              element={<ViewArticlesPage />}
            />
            <Route path="" element={<ViewArticlesPage />} />
          </Route>
          <Route path="/article/:article" element={<ViewArticlePage />} />
          <Route path="/suggest-post" element={<SuggestPostPage />} />
          <Route
            path="/article/:category/:article"
            element={<ViewArticlePage />}
          />
          <Route path="/categories" element={<CategoriesPage />} />
          <Route path="/bookmarks" element={<BookmarksPage />} />
          <Route path={"/ammunition"} element={<ReferralPage />} />
          <Route path={"/leosa-bill"} element={<LeosaBillPage />} />
          <Route path={"/view-sponsors"} element={<ViewSponsorsPage />} />
          <Route path={"/home-screen"} element={<HomeScreen />} />
          <Route path={"/location-help"} element={<LocationHelpPage />} />
          <Route path={"/about-the-app"} element={<AboutTheAppPage />} />
          <Route path="/ammunition/offers" element={<BulletRedeemPage />} />
          <Route
            path="/ammunition/offer/:offer"
            element={<ViewBulletItemPage />}
          />
          <Route
            path="/ammunition/redeem/:offer"
            element={<RedeemBulletItemPage />}
          />
          <Route
            path={"/how-to-use-the-app"}
            element={<HowToUseTheAppPage />}
          />
          <Route path={"/terms"} element={<TermsPage />} />
          <Route path={"/privacy"} element={<PrivacyPage />} />
          <Route path={"/support"} element={<SupportPage />} />
        </Routes>
      </RightSideWrapper>
    </MainMenuPageContainer>
  );
};

export default MainMenuPage;
