import { useNavigate } from "react-router";
import { PrimaryButton } from "../../../styled/buttons";

const EmailVerified = () => {
  const navigate = useNavigate();

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <p style={{ margin: "0 0 20px 0" }}>Your email has been verified.</p>
      <PrimaryButton
        onClick={() => {
          navigate("/");
        }}
      >
        RETURN
      </PrimaryButton>
    </div>
  );
};

export default EmailVerified;
