import React from "react";
import { Link } from "react-router-dom";
import { DesktopOnly, MobileOnly } from "../../../styled/media-hidden";
import IconHandler from "../../icon-handler/icon-handler";
import {
  IconContainer,
  IconsSectionContainer,
  MenuButton,
} from "../styled/icons-section-styled";

const IconsSection = () => {
  return (
    <IconsSectionContainer>
      <IconContainer>
        <Link to="/account">
          <IconHandler name="user" iconProps={{}} />
        </Link>
      </IconContainer>
      <IconContainer>
        <DesktopOnly>
          <Link to="/menu">
            <MenuButton>MENU</MenuButton>
          </Link>
        </DesktopOnly>
        <MobileOnly>
          <Link to="/menu">
            <IconHandler name="hamburger" iconProps={{}} />
          </Link>
        </MobileOnly>
      </IconContainer>
    </IconsSectionContainer>
  );
};

export default IconsSection;
