import { useQuery } from "react-query";
import { getBannerAdPlans } from "../../lib/api/banner-ad";
import { Loader } from "../../styled/loader";
import AdvertisementPlansTable from "./advertisement-plans-table";

interface AdvertisementPlansTableWrapperProps {
  onClick: (id: string) => void;
}

const AdvertisementPlansTableWrapper = (
  props: AdvertisementPlansTableWrapperProps
) => {
  const { data, isLoading, isError } = useQuery("/banner-ad/plans", () =>
    getBannerAdPlans()
  );

  if (isLoading) {
    return <Loader />;
  }

  if (isError || !data) {
    return <p>Could not get advertising plans at this time.</p>;
  }

  return <AdvertisementPlansTable plans={data} onClick={props.onClick} />;
};

export default AdvertisementPlansTableWrapper;
