import styled from "styled-components";

export const PhotoPickerContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export interface FileInputProps {
  text: string;
  onChange: (e: any) => void;
}

export const FileInput = styled.input.attrs((props) => ({
  type: "file",
}))<FileInputProps>`
  width: 73px;
  height: 33px;
  overflow: hidden;
  cursor: pointer;

  ::-webkit-file-upload-button {
    visibility: hidden;
  }

  ::before {
    content: "${(props) => props.text}";
    border: solid #516581 2px;

    font-size: 10px;
    border-radius: 3px;
    background: none;
    color: #516581;
    display: flex;
    text-align: center;
    width: 68px;
    height: 29px;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    text-align: center;
  }
`;
