import { SpecialOffer } from "../../ts/special-offer";
import {
  SpecialOfferTableContainer,
  AdContainer,
  AdImage,
  RowsContainer,
} from "./styled";

interface SpecialOffersTableProps {
  specialOffers: SpecialOffer[];
  onItemClick?: (uid: string) => void;
}

const SpecialOffersTable = (props: SpecialOffersTableProps) => {
  return (
    <SpecialOfferTableContainer>
      <RowsContainer>
        {props.specialOffers.map((ad) => (
          <AdContainer key={ad.uid}>
            <AdImage
              src={`${ad.imageUrl}`}
              onClick={() => {
                if (props.onItemClick) props.onItemClick(ad.uid);
              }}
            />
          </AdContainer>
        ))}
      </RowsContainer>
    </SpecialOfferTableContainer>
  );
};

export default SpecialOffersTable;
