import styled from "styled-components";
import { mobileMax } from "../../lib/media-breakpoints";

export const Header = styled.div``;

export const BusinessPhotoManagerPageContainer = styled.div``;

export const Content = styled.div`
  margin: 10px 60px;

  @media (max-width: ${mobileMax}px) {
    margin: 20px 20px;
  }
`;

export const Wrapper = styled.div`
  margin: 64px 60px;

  @media (max-width: ${mobileMax}px) {
    margin: 20px 20px;
  }
`;
