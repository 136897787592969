import { AuthErrorCodes, getAuth } from "@firebase/auth";
import { FirebaseError } from "@firebase/util";
import { useState } from "react";
import { useNavigate } from "react-router";
import { sendVerificationEmail } from "../../lib/firebase/firebase";
import ImageHandler from "../image-handler/image-handler";

import {
  ConfirmButton,
  ConfirmEmailContainer,
  LargeParagraphText,
  LogoContainer,
  LogoutButton,
  MainTextBody,
  ParagraphText,
  ResendButton,
  TitleText,
} from "./styled/confirm-email-styled";

const ConfirmEmail = ({ email }: { email: string }) => {
  const navigate = useNavigate();

  const [checking, setChecking] = useState<boolean>(false);
  const [resendActive, setResendActive] = useState(true);

  const [response, setReseponse] = useState<string | undefined>(undefined);

  const handleConfirmClick = async () => {
    const auth = getAuth();

    const user = auth.currentUser;
    setChecking(true);
    await user?.reload();

    if (user?.emailVerified) {
      setChecking(false);
      navigate("/");
    } else {
      setChecking(false);
      setReseponse("Email has not been verified.");
    }
  };

  const handleResendClick = async () => {
    setResendActive(false);

    const auth = getAuth();

    const user = auth.currentUser;

    if (!user) {
      navigate("/");
      return;
    }

    try {
      await sendVerificationEmail(user);
      setReseponse("Email has been sent. Please check your inbox.");
      setTimeout(() => {
        setResendActive(true);
      }, 5000);
    } catch (e) {
      if (e instanceof FirebaseError) {
        if (e.code === AuthErrorCodes.TOO_MANY_ATTEMPTS_TRY_LATER) {
          setReseponse(
            "Too many recent attempts. Please check your inbox and spam folder or try again later."
          );
        } else {
          setReseponse(
            "Could not re-send email at this time. Please try again later."
          );
        }
      } else {
        setReseponse(undefined);
      }

      setResendActive(false);
      setTimeout(() => {
        setResendActive(true);
      }, 5000);
    }
  };

  return (
    <ConfirmEmailContainer>
      <LogoContainer>
        <div style={{ marginTop: "auto" }}>
          <ImageHandler name="logo" />
        </div>
      </LogoContainer>
      <MainTextBody>
        <TitleText>YOU'RE ALMOST THERE!</TitleText>
        <ParagraphText>
          A verification email has been sent to <br />
          {email}
        </ParagraphText>
        <ParagraphText>
          Please check your email and follow the
          <br />
          link to activate your USA PACS account.
        </ParagraphText>
      </MainTextBody>
      <ConfirmButton
        active={!checking}
        onClick={() => {
          handleConfirmClick();
        }}
      >
        CONFIRM
      </ConfirmButton>

      <LargeParagraphText>
        Be sure to add USA PACS to your home screen on your mobile device!
      </LargeParagraphText>
      <ParagraphText>
        Click on the Bookmarks button and choose "Add to Home Screen".
      </ParagraphText>

      <LargeParagraphText>Didn't receive an email?</LargeParagraphText>
      <ResendButton
        style={resendActive === false ? { background: "#7B7B7B" } : {}}
        disabled={resendActive === false}
        onClick={() => {
          handleResendClick();
        }}
      >
        RESEND VERIFICATION EMAIL
      </ResendButton>
      {response ? (
        <p
          style={{
            textAlign: "center",
            margin: "20px 20px",
          }}
        >
          {response}
        </p>
      ) : null}

      <LogoutButton
        onClick={async () => {
          const auth = getAuth();

          if (auth.currentUser) {
            await auth.signOut();
          }
        }}
      >
        LOGOUT
      </LogoutButton>
    </ConfirmEmailContainer>
  );
};

export default ConfirmEmail;
