import { withFormik } from "formik";
import BannerAdDetailsUpdate, {
  BannerAdDetailsUpdateProps,
  BannerAdDetailsUpdateSchema,
  BannerAdDetailsUpdateValues,
} from "./banner-ad-details-update";

interface BannerAdsDetailsUpdateWrapperProps {
  existingWebsiteLink?: string;
  onSubmit: (values: BannerAdDetailsUpdateValues) => void;
  loading: boolean;
}

const BannerAdsDetailsUpdateWrapper = (
  props: BannerAdsDetailsUpdateWrapperProps
) => {
  const Form = withFormik<
    BannerAdDetailsUpdateProps,
    BannerAdDetailsUpdateValues
  >({
    handleSubmit: (values) => {
      props.onSubmit(values);
    },
    mapPropsToValues: () => ({
      websiteLink: props.existingWebsiteLink ?? "",
    }),
    validateOnBlur: false,
    validateOnChange: false,
    validateOnMount: false,
    validationSchema: BannerAdDetailsUpdateSchema,
  })(BannerAdDetailsUpdate);

  return <Form loading={props.loading} />;
};

export default BannerAdsDetailsUpdateWrapper;
