import styled from "styled-components";
import { mobileMax } from "../../../lib/media-breakpoints";

export const BadgesDisplayContainer = styled.div`
  display: flex;

  @media (min-width: ${mobileMax + 1}px) {
    height: 50px;
  }

  @media (max-width: ${mobileMax}px) {
  }
`;

export const BadgeIconContainer = styled.div`
  height: 100%;
`;
