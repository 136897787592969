import styled from "styled-components";
import { mobileMax } from "../../../lib/media-breakpoints";

export const EditProfileContainer = styled.div``;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 60px;

  @media (max-width: ${mobileMax}px) {
    margin: 20px 15px;
  }
`;
