import metalDetectorNeutral from "../../../images/badges/metalDetectorPolicy/metalDetectorPolicy_grey.png";

const MetalDetectorNeutral = ({ imageProps }: any) => {
  return (
    <img
      src={metalDetectorNeutral}
      alt="metal-detector-policy-neutral"
      {...imageProps}
    />
  );
};

export default MetalDetectorNeutral;
