import styled from "styled-components";

export const Wrapper = styled.div``;

export const Content = styled.div``;

export const DetailTitle = styled.div`
  font-weight: bold;
  margin-bottom: 2px;
  :not(:first-child) {
    margin-top: 15px;
  }
`;

export const Detail = styled.div`
  margin-top: 3px;
`;
