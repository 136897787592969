import React from "react";

import { ErrorMessage, Field, FormikProps } from "formik";

import {
  Disclaimer,
  FieldsWrapper,
  FormContainer,
  FormLabel,
  SubmitButton,
} from "../styled/global-styled";
import {
  UploadButtonsContainer,
  UploadContainer,
} from "../styled/step-three-form-styled";
import { LoadingOverlay } from "../../../pages/Dashboard/styled/loading-overlay";

import * as Yup from "yup";
import FormikPhotoPicker from "../../formik-photo-picker/formik-photo-picker";
import { StepThreeFormProps } from "../utils/stepThreeFormikContext";

export interface StepThreeFormValues {
  department: string;
  unit: string;
  rank: string;
  frontPhoto: any;
  backPhoto: any;
}

const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

export const stepThreeSchema: Yup.SchemaOf<StepThreeFormValues> = Yup.object({
  department: Yup.string().required("Department is required"),
  unit: Yup.string().required("Unit is required"),
  rank: Yup.string().required("Rank is required"),
  frontPhoto: Yup.mixed()
    .test("exists", "Please upload a front photo.", (value) =>
      value ? true : false
    )
    .test("fileSize", "The front photo is too large. ", (value) => {
      if (!value) return false;
      return value.size <= 6000000;
    })
    .test("fileType", "Unsupported File Format", (value) => {
      if (!value) return false;
      return SUPPORTED_FORMATS.includes(value.type);
    }),
  backPhoto: Yup.mixed()
    .test("exists", "Please upload a back photo.", (value) =>
      value ? true : false
    )
    .test("fileSize", "The back photo is too large.", (value) => {
      if (!value) return false;
      return value.size <= 6000000;
    })
    .test("fileType", "Unsupported File Format", (value) => {
      if (!value) return false;
      return SUPPORTED_FORMATS.includes(value.type);
    }),
});

const StepThreeForm = (
  props: StepThreeFormProps & FormikProps<StepThreeFormValues>
) => {
  const { values, handleSubmit, setFieldValue, isSubmitting } = props;

  let canSubmit = false;
  try {
    stepThreeSchema.validateSync(values);
    canSubmit = true;
  } catch (e) {
    canSubmit = false;
  }

  return (
    <form onSubmit={handleSubmit}>
      <FormContainer>
        <FieldsWrapper>
          <FormLabel htmlFor="department">Department</FormLabel>
          <ErrorMessage
            name="department"
            component="span"
            className="error-message"
          />
          <Field type="text" name="department" />
        </FieldsWrapper>
        <FieldsWrapper>
          <FormLabel htmlFor="unit">Unit</FormLabel>
          <ErrorMessage
            name="unit"
            component="span"
            className="error-message"
          />
          <Field type="text" name="unit" />
        </FieldsWrapper>
        <FieldsWrapper>
          <FormLabel htmlFor="rank">Rank</FormLabel>
          <ErrorMessage
            name="rank"
            component="span"
            className="error-message"
          />
          <Field type="text" name="rank" />
        </FieldsWrapper>

        <UploadContainer>
          <p
            style={{
              color: "#1B3963",
              alignSelf: "flex-start",
              marginBottom: 5,
            }}
          >
            Upload ID:
          </p>
          <Disclaimer style={{ marginBottom: 15 }}>
            Files must be less than 6MB (.png .jpeg only)
          </Disclaimer>
          <ErrorMessage
            name="frontPhoto"
            className="error-message"
            component="span"
          />
          <ErrorMessage
            name="backPhoto"
            className="error-message"
            component="span"
          />
          <UploadButtonsContainer>
            <FormikPhotoPicker
              onChange={(file) => {
                setFieldValue("frontPhoto", file);
              }}
              inputName={"photo-front"}
              buttonText={"FRONT"}
            />
            <FormikPhotoPicker
              onChange={(file) => {
                setFieldValue("backPhoto", file);
              }}
              inputName={"photo-front"}
              buttonText={"BACK"}
            />
          </UploadButtonsContainer>
        </UploadContainer>

        <SubmitButton
          disabled={isSubmitting || props.disabled}
          active={!isSubmitting && !props.disabled}
        >
          CREATE ACCOUNT
        </SubmitButton>
      </FormContainer>
    </form>
  );
};

export default StepThreeForm;
