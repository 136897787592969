import { useInfiniteQuery, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import BackButton from "../../components/back-button/back-button";
import BulletItemTable from "../../components/bullet-item-table/bullet-item-table";
import {
  getAmmunitionOffers,
  GetAmmunitionOffersResponse,
} from "../../lib/api/ammunition";
import { getUserDetails } from "../../lib/api/user";
import { Loader } from "../../styled/loader";
import { BulletItem } from "../../ts/bullet-item";
import {
  Body,
  BulletLabel,
  BulletRedeemPageContainer,
  Content,
  Header,
} from "./styled";

interface BulletRedeemPageProps {}

const BulletRedeemPage = (props: BulletRedeemPageProps) => {
  const navigate = useNavigate();
  const userQuery = useQuery("/me/details", () => getUserDetails());
  const bulletQuery = useInfiniteQuery(
    "/ammunition/offers",
    ({ pageParam = 0 }) => getAmmunitionOffers(pageParam),
    {
      getNextPageParam: (lastPage) => lastPage.nextPage,
      staleTime: Infinity,
    }
  );

  if (userQuery.isLoading || bulletQuery.isLoading) {
    return (
      <BulletRedeemPageContainer>
        <Loader />
      </BulletRedeemPageContainer>
    );
  }

  if (userQuery.isError || bulletQuery.isError) {
    return (
      <BulletRedeemPageContainer>
        <p>Could not get Ammunitions Offers at this time.</p>
      </BulletRedeemPageContainer>
    );
  }

  const items: BulletItem[] = [];
  if (bulletQuery.data) {
    bulletQuery.data.pages.forEach((page) => {
      items.push(...page.offers);
    });
  }

  return (
    <BulletRedeemPageContainer>
      <div style={{ marginBottom: 20 }}>
        <BackButton handleBack={() => navigate("/menu/ammunition")} />
      </div>
      <Content>
        <Header>
          <BulletLabel>
            You have {userQuery.data?.bullets ?? "0"} Bullets.
          </BulletLabel>
        </Header>
        <Body>
          {bulletQuery.data ? (
            <BulletItemTable
              bulletItems={items}
              hasMore={bulletQuery.hasNextPage ? true : false}
              handleLoadMore={() => bulletQuery.fetchNextPage()}
              loading={bulletQuery.isLoading || bulletQuery.isFetching}
              handleRowClick={(id) => {
                navigate(`/menu/ammunition/offer/${id}`);
              }}
            />
          ) : null}
        </Body>
      </Content>
    </BulletRedeemPageContainer>
  );
};

export default BulletRedeemPage;
