import styled from "styled-components";
import { mobileMax } from "../../../lib/media-breakpoints";
import { applyBoxShadow } from "../../../styled/box-shadow";

export const ConfirmEmailContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const LogoContainer = styled.div`
  flex: 0 0 280px;
  display: flex;
  flex-direction: column;
`;

export const MainTextBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    margin-bottom: 0;
  }
`;

export const TitleText = styled.h1`
  margin-top: 70px;
  color: #000000;
  text-align: center;

  font-size: 20px;
  @media (min-width: ${mobileMax}px) {
    font-size: 24px;
  }
`;

export const ParagraphText = styled.p`
  margin-top: 18px;
  margin-bottom: 0;
  text-align: center;
  font-size: 14px;
  margin-left: 30px;
  margin-right: 30px;
  color: #1b3963;
  @media (min-width: ${mobileMax}px) {
    font-size: 18px;
  }
`;

export const ConfirmButton = styled.button<{ active: boolean }>`
  flex: 0 0 42px;
  width: 147px;
  background: ${(props) => (props.active ? "#8a98b3" : "#7A7A7A")};
  color: white;
  border: none;
  border-radius: 3px;
  font-size: 12px;
  margin-top: 21px;
  ${applyBoxShadow()};
`;

export const ResendButton = styled.button`
  width: 251px;
  flex: 0 0 42px;
  background: #576480;
  color: white;
  border-radius: 3px;
  border: none;
  font-size: 12px;
  margin-top: 18px;
  ${applyBoxShadow()}
`;

export const LargeParagraphText = styled.p`
  margin-top: 39px;
  margin-bottom: 0;
  text-align: center;
  font-size: 16px;
  margin-left: 30px;
  margin-right: 30px;
  color: #1b3963;
  @media (min-width: ${mobileMax}px) {
    font-size: 20px;
  }
`;

export const LogoutButton = styled.button`
  flex: 0 0 42px;
  width: 147px;
  background: #8a98b3;
  color: white;
  border: none;
  border-radius: 3px;
  font-size: 12px;
  margin-top: 40px;
  ${applyBoxShadow()};
`;
