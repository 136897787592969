import moment from "moment";
import { AdvertisementSubmitButton } from "../../styled/advertisement-form-elements";

import {
  ActionButton,
  Content,
  SectionDisplay,
  SectionTitle,
  Container,
} from "./styled";

interface BannerAdInfoProps {
  status?: string;
  expirationDate?: string;
  handleButtonClick: () => void;
  loading: boolean;
}

const STATUS_DISPLAYS: { [key: string]: string } = {
  review: "In Review",
  inactive: "Paused",
  active: "Active",
  disabled: "Disabled",
};

const BannerAdInfo = (props: BannerAdInfoProps) => {
  return (
    <Container>
      <Content>
        <SectionTitle>Status</SectionTitle>
        {props.status && props.expirationDate !== null ? (
          <SectionDisplay>{STATUS_DISPLAYS[props.status]}</SectionDisplay>
        ) : (
          <SectionDisplay>Pending Payment</SectionDisplay>
        )}
        {props.expirationDate && props.expirationDate !== null ? (
          <>
            <SectionTitle>Expires On</SectionTitle>
            <SectionDisplay>
              {moment(props.expirationDate).format("MM/DD/YYYY")}
            </SectionDisplay>{" "}
          </>
        ) : (
          <SectionDisplay></SectionDisplay>
        )}
        {props.status !== "review" && props.expirationDate !== null ? (
          <AdvertisementSubmitButton
            onClick={() => props.handleButtonClick()}
            disabled={props.loading}
          >
            {props.status === "active"
              ? "Pause Advertisement"
              : props.status === "inactive"
              ? "Resume Advertisement"
              : null}
          </AdvertisementSubmitButton>
        ) : null}
      </Content>
    </Container>
  );
};

export default BannerAdInfo;
