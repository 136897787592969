import { withFormik } from "formik";
import SentryHandler from "../../lib/sentry/sentry";

import BannerAdForm, {
  BannerAdFormProps,
  BannerAdFormSchema,
  BannerAdFormValues,
} from "./banner-ad-form";

interface BannerAdFormWrapperProps {
  onSubmit: (
    image: File,
    websiteLink: string,
    planId: string,
    salesCode?: string
  ) => void;
}

const BannerAdFormWrapper = (props: BannerAdFormWrapperProps) => {
  const Form = withFormik<BannerAdFormProps, BannerAdFormValues>({
    handleSubmit: (values) => {
      if (values.image && values.planId) {
        props.onSubmit(
          values.image,
          values.websiteLink,
          values.planId,
          values.salesCode
        );
      } else {
        if (!values.image) {
          SentryHandler.captureMessage(
            "Banner Ad Form was submitted without Image"
          );
        } else if (!values.planId) {
          SentryHandler.captureMessage(
            "Banner Ad Form was submitted without plan ID"
          );
        } else {
          SentryHandler.captureMessage(
            "Banner Ad Form was submitted without Image and plan ID"
          );
        }
      }
    },
    mapPropsToValues: () => ({
      websiteLink: "",
      image: undefined,
      planId: "",
      salesCode: "",
    }),
    validateOnChange: false,
    validateOnMount: false,
    validateOnBlur: false,
    validationSchema: BannerAdFormSchema,
  })(BannerAdForm);

  return <Form />;
};

export default BannerAdFormWrapper;
