import { ErrorMessage, Field, FieldProps, FormikProps } from "formik";

import { FieldLabel } from "../../styled/edit-profile-form-elements";

import { FormContainer, SubmitButton } from "../register/styled/global-styled";

import * as Yup from "yup";

import {
  RedeemBulletItemFormContainer,
  StyledField,
  FieldsWrapper,
} from "./styled";

import { Title, Subtitle } from "../../styled/typography";
import { GradientBorder } from "../../styled/gradient-border";

export interface RedeemBulletItemFormProps {}

export interface RedeemBulletItemFormValues {
  streetAddress: string;
  streetAddressTwo?: string;
  city: string;
  state: string;
  zip: string;
  country: string;
}

export const RedeemBulletItemFormSchema: Yup.SchemaOf<RedeemBulletItemFormValues> = Yup.object(
  {
    streetAddress: Yup.string().required("Please enter your street address."),
    streetAddressTwo: Yup.string(),
    city: Yup.string().required("Please enter your city."),
    state: Yup.string().required("Please enter your state."),
    zip: Yup.string().required("Please enter your zip/postal code."),
    country: Yup.string().required("Please enter your country."),
  }
);

const RedeemBulletItemForm = (
  props: RedeemBulletItemFormProps & FormikProps<RedeemBulletItemFormValues>
) => {
  const { handleSubmit, isSubmitting } = props;

  return (
    <RedeemBulletItemFormContainer>
      <form onSubmit={handleSubmit}>
        <FormContainer>
          <Title style={{ margin: "0 30px" }}>Shipping</Title>
          <Subtitle
            style={{
              color: "black",
              fontSize: 16,
              fontWeight: "normal",
              margin: "10px 30px",
            }}
          >
            Please let us know where you would like your offer sent.
          </Subtitle>
          <FieldsWrapper>
            <FieldLabel>Street Address</FieldLabel>
            <ErrorMessage
              name="streetAddress"
              component="span"
              className="error-message"
            />
            <Field name="streetAddress">
              {({ field }: FieldProps) => (
                <StyledField type="input" {...field} />
              )}
            </Field>
            <GradientBorder style={{ height: 3 }} />
          </FieldsWrapper>
          <FieldsWrapper>
            <FieldLabel>Street Address Line 2 (Optional) </FieldLabel>
            <ErrorMessage
              name="streetAddressTwo"
              component="span"
              className="error-message"
            />
            <Field name="streetAddressTwo">
              {({ field }: FieldProps) => (
                <StyledField type="input" {...field} />
              )}
            </Field>
            <GradientBorder style={{ height: 3 }} />
          </FieldsWrapper>
          <FieldsWrapper>
            <FieldLabel>City </FieldLabel>
            <ErrorMessage
              name="city"
              component="span"
              className="error-message"
            />
            <Field name="city">
              {({ field }: FieldProps) => (
                <StyledField type="input" {...field} />
              )}
            </Field>
            <GradientBorder style={{ height: 3 }} />
          </FieldsWrapper>
          <FieldsWrapper>
            <FieldLabel>State </FieldLabel>
            <ErrorMessage
              name="state"
              component="span"
              className="error-message"
            />
            <Field name="state">
              {({ field }: FieldProps) => (
                <StyledField type="input" {...field} />
              )}
            </Field>
            <GradientBorder style={{ height: 3 }} />
          </FieldsWrapper>
          <FieldsWrapper>
            <FieldLabel>ZIP/Postal Code </FieldLabel>
            <ErrorMessage
              name="zip"
              component="span"
              className="error-message"
            />
            <Field name="zip">
              {({ field }: FieldProps) => (
                <StyledField type="input" {...field} />
              )}
            </Field>
            <GradientBorder style={{ height: 3 }} />
          </FieldsWrapper>
          <FieldsWrapper>
            <FieldLabel>Country </FieldLabel>
            <ErrorMessage
              name="country"
              component="span"
              className="error-message"
            />
            <Field name="country">
              {({ field }: FieldProps) => (
                <StyledField type="input" {...field} />
              )}
            </Field>
            <GradientBorder style={{ height: 3 }} />
          </FieldsWrapper>
          <SubmitButton active={!isSubmitting}>SUBMIT</SubmitButton>
        </FormContainer>
      </form>
    </RedeemBulletItemFormContainer>
  );
};

export default RedeemBulletItemForm;
