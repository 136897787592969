import React from "react";
import { useEffect } from "react";
import { useMutation } from "react-query";
import { ClaimError } from "../../../lib/api/errors/claim-error";
import { claimBusiness } from "../../../lib/api/points-of-interest";
import { PrimaryButton } from "../../../styled/buttons";
import { Loader } from "../../../styled/loader";
import { ErrorIcon } from "../../../styled/status-icons";
import IconHandler from "../../icon-handler/icon-handler";

import ReactGA from "react-ga";

export interface ClaimSectionProps {
  placeId: string;
  long: number;
  lat: number;
}

const ClaimSection = (props: ClaimSectionProps) => {
  const { mutate, isLoading, isError, error, isSuccess, reset } = useMutation(
    ["/claim", props.placeId],
    () => claimBusiness(props.placeId, props.lat, props.long),
    {
      onSuccess: () => {
        ReactGA.event({
          category: "user_action",
          action: "claim_start",
        });
      },
    }
  );

  const handleClick = () => {
    try {
      const confirmed = window.confirm(
        "Are you the business owner? You should only claim this business if you own or represent this business."
      );

      if (confirmed) {
        mutate();
      }
    } catch (e) {}
  };

  useEffect(() => {
    if (props.placeId && reset) {
      reset();
    }
  }, [props.placeId, reset]);

  if (isLoading) {
    return <Loader />;
  }

  if (isError && error) {
    if (error instanceof ClaimError) {
      if (error.message === "User Already Started Claim") {
        return (
          <div style={{ display: "flex", alignItems: "center", marginTop: 30 }}>
            <ErrorIcon>!</ErrorIcon>
            <p
              style={{ color: "red", fontWeight: "bold", margin: "0 0 0 5px" }}
            >
              You have already started a claim for this business. Please view
              your claims to complete.
            </p>
          </div>
        );
      } else if (error.message === "max_claims") {
        return (
          <div style={{ display: "flex", alignItems: "center", marginTop: 30 }}>
            <ErrorIcon>!</ErrorIcon>
            <p
              style={{ color: "red", fontWeight: "bold", margin: "0 0 0 5px" }}
            >
              You have reached the max amount of claims. Please visit your
              claims to complete or cancel.
            </p>
          </div>
        );
      }
    }

    return (
      <div style={{ display: "flex", alignItems: "center", marginTop: 30 }}>
        <ErrorIcon>!</ErrorIcon>
        <p style={{ color: "red", fontWeight: "bold", margin: "0 0 0 5px" }}>
          Could not claim business at this time.
        </p>
      </div>
    );
  }

  if (isSuccess) {
    return (
      <div style={{ display: "flex", alignItems: "center", marginTop: 30 }}>
        <IconHandler name="checkmark" iconProps={{}} />
        <p style={{ color: "green", margin: "0 0 0 5px" }}>
          Your claim has been submitted. USA PACS will be in touch shortly.
        </p>
      </div>
    );
  }

  return (
    <PrimaryButton
      style={{ width: "100%", marginTop: 30, flex: "0 0 50px" }}
      onClick={() => {
        handleClick();
      }}
    >
      CLAIM BUSINESS
    </PrimaryButton>
  );
};

export default ClaimSection;
