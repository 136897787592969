import { useState } from "react";
import { useQuery } from "react-query";
import { Route, Routes, useLocation } from "react-router";
import { textSearch } from "../../lib/api/points-of-interest";
import { PointOfInterest } from "../../ts/point-of-interest";
import useGeoLocation from "../../lib/custom-hooks/useLocation";
import SearchView from "./components/SearchView";
import {
  DashboardPageContainer,
  LeftSide,
  RightSide,
} from "./styled/dashboard-page-styled";
import { LoadingOverlay } from "./styled/loading-overlay";
import MapView, { MarkerData } from "./components/Map.tsx/MapView";
import {
  MapContext,
  QueryValueContext,
  ActiveMarkerContext,
  MarkersContext,
} from "./util/contexts";
import { useEffect } from "react";
import BannerAdsDisplay from "../../components/banner-ad-display/banner-ad-display";

export interface QueryInfoState {
  queryTerm: string;
  displayValue: string;
  lat?: number;
  lng?: number;
  topRightCornerCoordinate?: google.maps.LatLng;
  bottomLeftCornerCoordinate?: google.maps.LatLng;
}

const DashboardPage = () => {
  const [queryInfo, setQueryInfo] = useState<QueryInfoState>({
    queryTerm: "",
    displayValue: "",
    lat: undefined,
    lng: undefined,
    topRightCornerCoordinate: undefined,
    bottomLeftCornerCoordinate: undefined,
  });

  const [markers, setMarkers] = useState<MarkerData[]>([]);
  const [map, setMap] = useState<google.maps.Map>();
  const [userHasInteracted, setUserHasInteracted] = useState(false);

  const browserLocation = useLocation();

  const { location, permission } = useGeoLocation();

  const [activeMarker, setActiveMarker] =
    useState<string | undefined>(undefined);

  const { data, isLoading, isFetched, isFetching } = useQuery<
    PointOfInterest[]
  >(
    ["/poi/search", queryInfo],
    () =>
      textSearch(
        queryInfo.queryTerm,
        queryInfo.lat,
        queryInfo.lng,
        queryInfo.topRightCornerCoordinate,
        queryInfo.bottomLeftCornerCoordinate
      ),
    {
      enabled: queryInfo.queryTerm !== "",
      staleTime: Infinity,
    }
  );

  const handleSearch = async (value: string) => {
    try {
      if (isLoading) {
        return;
      }

      const mapCenter = map?.getCenter();
      if (!mapCenter) {
        return;
      }

      setQueryInfo({
        queryTerm: value,
        displayValue: value,
        lat: mapCenter.lat(),
        lng: mapCenter.lng(),
        topRightCornerCoordinate: map?.getBounds()?.getNorthEast(),
        bottomLeftCornerCoordinate: map?.getBounds()?.getSouthWest(),
      });
    } catch (e) {}
  };

  useEffect(() => {
    if (map && activeMarker) {
      // let poi = data?.find((a) => a.placeId === activeMarker);
      let marker = markers.find(
        (a) => a.pointOfInterest.placeId === activeMarker
      );
      if (marker) {
        let poi = marker?.pointOfInterest;

        if (poi) {
          map.panTo({
            lat: poi.lat,
            lng: poi.lng,
          });
        }
      }
    }
  }, [activeMarker, map, data, markers]);

  useEffect(() => {
    if (data) {
      const markerData: MarkerData[] = data.map((poi) => ({
        placeId: poi.placeId,
        lat: poi.lat,
        lng: poi.lng,
        pointOfInterest: poi,
      }));

      setMarkers(markerData);
    }
  }, [data]);

  return (
    <DashboardPageContainer>
      <MapContext.Provider value={{ map, setMap }}>
        <QueryValueContext.Provider
          value={{
            queryInfo: queryInfo,
            value: queryInfo.queryTerm,
            displayValue: queryInfo.displayValue,
            setValue: (value: string, displayValue: string) => {
              const mapCenter = map?.getCenter();
              setUserHasInteracted(true);
              if (!mapCenter) {
                return;
              }
              setQueryInfo({
                queryTerm: value,
                displayValue: displayValue,
                lat: mapCenter.lat(),
                lng: mapCenter.lng(),
                topRightCornerCoordinate: map?.getBounds()?.getNorthEast(),
                bottomLeftCornerCoordinate: map?.getBounds()?.getSouthWest(),
              });
            },
          }}
        >
          <ActiveMarkerContext.Provider
            value={{ activeMarker, setActiveMarker }}
          >
            <MarkersContext.Provider value={{ markers, setMarkers }}>
              <LeftSide>
                {isLoading === true ||
                (!location && permission === "granted") ? (
                  <LoadingOverlay />
                ) : null}
                <Routes>
                  <Route
                    path="*"
                    element={
                      <SearchView
                        results={data ?? []}
                        handleSearch={handleSearch}
                        isLoading={isLoading}
                        empty={data !== undefined && data.length === 0}
                      />
                    }
                  />
                </Routes>
                <BannerAdsDisplay />
              </LeftSide>
            </MarkersContext.Provider>
          </ActiveMarkerContext.Provider>
          <RightSide>
            <MapView
              shouldPerformInitialQuery={
                !isFetched &&
                !isFetching &&
                markers.length === 0 &&
                !userHasInteracted &&
                !browserLocation.pathname.includes("/business/")
              }
              setActiveMarker={(placeId: string) => {
                setActiveMarker(placeId);
              }}
              activeMarkerPlaceId={activeMarker}
              // markerData={(data ?? []).map((pointOfInterest) => ({
              //   placeId: pointOfInterest.placeId,
              //   lat: pointOfInterest.lat,
              //   lng: pointOfInterest.lng,
              //   pointOfInterest,
              // }))}
              markerData={markers}
            />
          </RightSide>
        </QueryValueContext.Provider>
      </MapContext.Provider>
    </DashboardPageContainer>
  );
};

export default DashboardPage;
