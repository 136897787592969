import { BannerAdvertisement } from "../../../ts/banner-advertisement";
import { BannerAdPlan } from "../../../ts/banner-advertisement-plan";
import { getFirebaseUserToken } from "../../firebase/firebase";
import SentryHandler from "../../sentry/sentry";

import axios from "../axios-instance";

const REFERESH_BANNER_AD_AMOUNT = 5;

export const getBannerAds = async (
  existingBannerAdIds: number[]
): Promise<BannerAdvertisement[]> => {
  const { data } = await axios.post<BannerAdvertisement[] | string>(
    "/banner-ads",
    {
      existingBannerAdIds: JSON.stringify(existingBannerAdIds),
      number: REFERESH_BANNER_AD_AMOUNT,
    }
  );

  if (typeof data === "string") {
    throw Error("Could not banner advertisements.");
  }

  return data;
};

interface GetBannerAdsResponse {
  bannerAds: BannerAdvertisement[];
  message?: string;
}

export const getUserBannerAds = async (): Promise<BannerAdvertisement[]> => {
  try {
    const token = await getFirebaseUserToken();

    const { data } = await axios.get<GetBannerAdsResponse>("/me/banner-ads", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (data.message) {
      throw Error(data.message);
    }

    return data.bannerAds;
  } catch (e: any) {
    if (e.response?.data?.message) {
      throw Error(e.response.data.message);
    } else {
      throw Error("Error");
    }
  }
};

interface CreateBannerAdResponse {
  bannerAd: BannerAdvertisement;
  message?: string;
}

export const createBannerAd = async (
  websiteLink: string,
  image: File | undefined,
  planId: string,
  salesCode?: string
): Promise<BannerAdvertisement> => {
  try {
    if (!image) {
      throw Error("Please upload an image");
    }

    const token = await getFirebaseUserToken();

    const formData = new FormData();

    formData.append("image", image);
    formData.append("websiteLink", websiteLink);
    formData.append("planId", planId);
    if (salesCode) {
      formData.append("salesCode", salesCode);
    }

    const { data } = await axios.post<CreateBannerAdResponse>(
      "/banner-ad/web",
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (data.message) {
      throw Error(data.message);
    }

    return data.bannerAd;
  } catch (e: any) {
    SentryHandler.reportException(e);
    if (e.response?.data?.message) {
      throw Error(e.response.data.message);
    } else {
      throw Error("Error");
    }
  }
};

interface GetBannerAdResponse {
  bannerAd: BannerAdvertisement;
  message?: string;
}

export const getBannerAd = async (
  uid: string
): Promise<BannerAdvertisement> => {
  try {
    const token = await getFirebaseUserToken();

    const { data } = await axios.get<GetBannerAdResponse>(
      `/banner-ad?uid=${uid}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (data.message) {
      throw Error(data.message);
    }

    return data.bannerAd;
  } catch (e: any) {
    //TODO: dont report if is login error
    // SentryHandler.reportException(e);
    if (e.response?.data?.message) {
      throw Error(e.response.data.message);
    } else {
      throw Error("Error");
    }
  }
};

interface PauseBannerAdResponse {
  bannerAd: BannerAdvertisement;
  message?: string;
}
export const PauseBannerAd = async (
  id: number
): Promise<BannerAdvertisement> => {
  try {
    const token = await getFirebaseUserToken();

    const { data } = await axios.post<PauseBannerAdResponse>(
      "/banner-ad/pause",
      { bannerAdId: id },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (data.message) {
      throw Error(data.message);
    }

    return data.bannerAd;
  } catch (e: any) {
    SentryHandler.reportException(e);
    if (e.response?.data?.message) {
      throw Error(e.response.data.message);
    } else {
      throw Error("Error");
    }
  }
};

interface ResumeBannerAdResponse {
  bannerAd: BannerAdvertisement;
  message?: string;
}
export const resumeBannerAd = async (
  id: number
): Promise<BannerAdvertisement> => {
  try {
    const token = await getFirebaseUserToken();

    const { data } = await axios.post<ResumeBannerAdResponse>(
      "/banner-ad/resume",
      { bannerAdId: id },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (data.message) {
      throw Error(data.message);
    }

    return data.bannerAd;
  } catch (e: any) {
    //TODO: dont report if is login error
    // SentryHandler.reportException(e);
    if (e.response?.data?.message) {
      throw Error(e.response.data.message);
    } else {
      throw Error("Error");
    }
  }
};

interface UpdateBannerAdImageResponse {
  bannerAd: BannerAdvertisement;
  message?: string;
}

export const updateBannerAdImage = async (
  id: number,
  image: File
): Promise<BannerAdvertisement> => {
  try {
    const token = await getFirebaseUserToken();

    const formData = new FormData();

    formData.append("bannerAdId", String(id));
    formData.append("image", image);

    const { data } = await axios.post<UpdateBannerAdImageResponse>(
      "/banner-ad/update",
      formData,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (data.message) {
      throw Error(data.message);
    }

    return data.bannerAd;
  } catch (e: any) {
    //TODO: dont report if is login error
    // SentryHandler.reportException(e);
    if (e.response?.data?.message) {
      throw Error(e.response.data.message);
    } else {
      throw Error("Error");
    }
  }
};

interface UpdateBannerAdDetailsResponse {
  bannerAd: BannerAdvertisement;
  message?: string;
}

export const updateBannerAdDetails = async (
  id: number,
  websiteLink: string
): Promise<BannerAdvertisement> => {
  try {
    const token = await getFirebaseUserToken();

    const formData = new FormData();

    formData.append("bannerAdId", String(id));
    formData.append("websiteLink", websiteLink);

    const { data } = await axios.post<UpdateBannerAdDetailsResponse>(
      "/banner-ad/update-details",
      formData,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (data.message) {
      throw Error(data.message);
    }

    return data.bannerAd;
  } catch (e: any) {
    //TODO: dont report if is login error
    // SentryHandler.reportException(e);
    if (e.response?.data?.message) {
      throw Error(e.response.data.message);
    } else {
      throw Error("Error");
    }
  }
};

interface GetBannerAdPlansResponse {
  plans: BannerAdPlan[];
  message?: string;
}

export const getBannerAdPlans = async (): Promise<BannerAdPlan[]> => {
  try {
    const { data } = await axios.get<GetBannerAdPlansResponse>(
      "/banner-ad/plans"
    );

    if (data.message) {
      throw Error(data.message);
    }

    return data.plans;
  } catch (e: any) {
    SentryHandler.reportException(e);
    if (e.response?.data?.message) {
      throw Error(e.response.data.message);
    } else {
      throw Error("Error");
    }
  }
};

export interface BannerAdCapacityResponse {
  value: string;
  message?: string;
}

export const getBannerAdCapacity = async (): Promise<string> => {
  try {
    const { data } = await axios.get("/settings/banner-ads-enabled");

    return data.value;
  } catch (e: any) {
    // SentryHandler.reportException(e);
    if (e.response?.data?.message) {
      throw Error(e.response.data.message);
    } else {
      throw Error("Error");
    }
  }
};

interface DeleteBannerAdResponse {
  deleted: number;
  message?: string;
}

export const deleteBannerAd = async (uid?: string): Promise<number> => {
  try {
    const token = await getFirebaseUserToken();

    const { data } = await axios.post<DeleteBannerAdResponse>(
      "/banner-ad/delete",
      {
        uid,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    return data.deleted;
  } catch (e: any) {
    SentryHandler.reportException(e);
    if (e.response?.data?.message) {
      throw Error(e.response.data.message);
    } else {
      throw Error("Error");
    }
  }
};

interface GetSponsorsResponse {
  sponsors: BannerAdvertisement[];
  nextPage?: number;
  hasMore: boolean;
  message?: string;
}

export const getSponsors = async (
  page: number
): Promise<GetSponsorsResponse> => {
  try {
    const { data } = await axios.get<GetSponsorsResponse>(
      "/sponsors?page=" + page
    );

    if (data.message) {
      throw Error(data.message);
    }

    return data;
  } catch (e: any) {
    // SentryHandler.reportException(e);
    if (e.response?.data?.message) {
      throw Error(e.response.data.message);
    } else {
      throw Error("Error");
    }
  }
};
